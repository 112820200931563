import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";
import { createFiqlFilter } from "../../lib/helper";
import { downloadFile } from "../../utils/commonUtils";
import getKeySearchPromises from "../../utils/getKeySearchPromises";

export const REPORT_TOKEN = "REPORT_TOKEN";
export const OWNER_CALL_REPORT = "OWNER_CALL_REPORT";
export const SALES_REPORT = "SALES_REPORT";
export const OWNER_INVOICE_REPORT = "OWNER_INVOICE_REPORT";
export const AGENT_PERFORMANCE_REPORT = "AGENT_PERFORMANCE_REPORT";
export const GET_SALES_REPORT_OPP = "GET_SALES_REPORT_OPP";
export const GET_USER_REPORT_OPP = "GET_USER_REPORT_OPP";
export const RESET_SALES_REPORT_OPP = "RESET_SALES_REPORT_OPP";
export const RESET_STATEMOVEMENT_REPORT = "RESET_STATEMOVEMENT_REPORT";
export const GET_STATE_MOVEMENT_REPORT = "GET_STATE_MOVEMENT_REPORT";
export const GET_ALL_CITIES_DATA = "GET_ALL_CITIES_DATA";

export const OWNER_CALL_REPORT_FAILURE = "OWNER_CALL_REPORT_FAILURE";
export const SALES_REPORT_FAILURE = "SALES_REPORT_FAILURE";
export const OWNER_INVOICE_REPORT_FAILURE = "OWNER_INVOICE_REPORT_FAILURE";
export const AGENT_PERFORMANCE_REPORT_FAILURE = "AGENT_PERFORMANCE_REPORT_FAILURE";
export const DOWNLOAD_PAYMENT_REPORT = "DOWNLOAD_PAYMENT_REPORT";
export const PAYMENT_REPORT_FAILURE = "PAYMENT_REPORT_FAILURE";

export const getToken = () => {
  return (dispatch) => {
    const url = `/trusted?username=${Constants.tableauUsername}`;
    return instance({
      url,
      method: "post",
    })
      .then((response) => {
        const reportToken = response.data;
        dispatch({
          type: REPORT_TOKEN,
          reportToken,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
const fixTimezoneOffset = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

const selectorArrFun = (keyFilter, resData, datefilter, cities, allCities) => {
  let selectorArr = [];
  let citiesArr = [];

  if (cities && allCities) {
    cities.map((item) => {
      let index = allCities.findIndex((p) => p.label === item);
      let valueonIndex = allCities[index].id;
      citiesArr = [...citiesArr, valueonIndex];
    });
  }
  let newDateFilter = {
    startDate: new Date(datefilter.startDate),
    endDate: new Date(datefilter.endDate),
  };
  const { paging = { start: 0, rows: 0 } } = newDateFilter || {};
  for (let i = 0; i < resData.length; i++) {
    const filters = {};
    if (keyFilter.addAsFilter) {
      const fiqlFilter = createFiqlFilter(
        keyFilter,
        resData[i].agentId,
        newDateFilter,
        filters
      );
      if (citiesArr.length > 0 && fiqlFilter.and.length > 0) {
        fiqlFilter.and.map((item) => {
          if (item.equal) {
            item.equal.cityIds = citiesArr;
          }
        });
      }
      if (fiqlFilter.and.length > 0) {
        filters.filters = fiqlFilter;
      }
    }

    filters.key = keyFilter.key;
    filters.team = true;
    filters.paging = paging;
    filters.agentId = resData[i].agentId;

    if (keyFilter.sortKey) {
      filters.sort = keyFilter.sortKey;
    }
    selectorArr.push(filters);
  }
  return selectorArr;
};

const mergeApiData = (d1, d2) => {
  let data = [];
  for (let i = 0; i < d1.length; i++) {
    data.push({ ...d1[i], ...d2[i] });
  }
  if (data) return data;
  return null;
};

export const getOwnerCallReport = (startDate, endDate, clientType) => {
  const newEndDate = new Date(endDate.setHours(23, 59, 59, 999));
  const newStartDate = new Date(startDate.setHours(0, 0, 0, 0));
  const url = `/mystique/v1/soc/callreport.csv?clientType=${clientType}&dateFrom=${newStartDate.toISOString()}&dateTo=${newEndDate.toISOString()}`;
  return (dispatch) => {
    dispatch({
      type: OWNER_CALL_REPORT,
      msg: "Downloading Report...",
    });
    if (clientType === "HousingOwner") {
      return dispatch(
        downloadReport({
          url,
          reportType: "Call-Detail",
          startDate,
          endDate,
          clientType,
          errorActionData: {
            type: OWNER_CALL_REPORT_FAILURE,
            msg: "Timeout. Please select a shorter time period.",
          },
        })
      );
    }
    downloadFile(`call_details_report.csv`, url);
    // window.open(url, "_blank");
  };
};

export const getSalesReport = (startDate, endDate, clientType) => {
  const typeMap = { HousingOwner: "OWNER", Housing: "BROKER" };
  const url = `/sapna/sales_report.csv?clientType=${typeMap[clientType]}&startDate=${
    fixTimezoneOffset(startDate).toISOString().split("T")[0]
  }&endDate=${fixTimezoneOffset(endDate).toISOString().split("T")[0]}`;
  return (dispatch) => {
    dispatch({
      type: SALES_REPORT,
      msg: "Downloading Report...",
    });

    if (clientType === "HousingOwner") {
      return dispatch(
        downloadReport({
          url,
          reportType: "Sales",
          startDate,
          endDate,
          clientType,
          errorActionData: {
            type: SALES_REPORT_FAILURE,
            msg: "Timeout. Please select a shorter time period.",
          },
        })
      );
    }
    downloadFile(`sales_report.csv`, url);
    // window.open(url, "_blank");
  };
};

const downloadReport = ({
  url,
  reportType,
  startDate,
  endDate,
  clientType = "",
  errorActionData,
  data,
  method,
  credentialsRequired = false,
}) => {
  return (dispatch) => {
    instance({
      url,
      method: method,
      withCredentials: credentialsRequired,
      responseType: "blob", // important
      data,
    })
      .then((res) => window.URL.createObjectURL(new Blob([res.data])))
      .then((href) => {
        Object.assign(document.createElement("a"), {
          href,
          download: startDate
            ? `${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}-${clientType}-${reportType}-report.csv`
            : `${endDate.toLocaleDateString()}-${clientType}-${reportType}-report.csv`,
        }).click();
      })
      .catch(() => {
        dispatch(errorActionData);
      });
  };
};

export const getSalesReportOpp = (
  userId,
  dates,
  cities = null,
  allCities = null
) => {
  let { startDate, endDate } = dates;
  startDate = new Date(startDate).getTime();
  endDate = new Date(endDate).getTime();
  const keyFilter = Constants.salesReportActivityDone;
  const url = `/sapna/v1/report/salesconnect-report/${userId}`;
  return (dispatch) => {
    dispatch({
      type: RESET_SALES_REPORT_OPP,
    });
    return instance({
      url,
      method: "post",
      data: {
        startDate: startDate,
        endDate: endDate,
        city: cities,
      },
    })
      .then((response) => {
        let resultData1 = response.data.data;
        if (resultData1) {
          let selectorArrFilter = selectorArrFun(
            keyFilter,
            response.data.data,
            dates,
            cities,
            allCities
          );
          const promise = getKeySearchPromises(
            "mystique/v1/soc/key-search?isSalesConnect=false&salesOpportunitySelectorsArray=",
            JSON.stringify(selectorArrFilter)
          );
          promise
            .then((response) => {
              let resultData2 = response;
              let mdata = [];
              mdata = mergeApiData(resultData1, resultData2);
              dispatch({
                type: GET_SALES_REPORT_OPP,
                saleConnReports: mdata,
              });
            })
            .catch((err) => {
              dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err),
              });
            });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getUserDetailsReportOpp = (
  userId,
  dates,
  cities = null,
  allCities = null
) => {
  let { startDate, endDate } = dates;
  startDate = new Date(startDate).getTime();
  endDate = new Date(endDate).getTime();
  const keyFilter = Constants.salesReportActivityDone;
  const url = `/sapna/v1/report/salesconnect-report/${userId}`;
  return (dispatch) => {
    dispatch({
      type: RESET_SALES_REPORT_OPP,
    });
    return instance({
      url,
      method: "post",
      data: {
        startDate: startDate,
        endDate: endDate,
        city: cities,
      },
    })
      .then((response) => {
        let resultData1 = response.data.data;
        if (resultData1) {
          let selectorArrFilter = selectorArrFun(
            keyFilter,
            response.data.data,
            dates,
            cities,
            allCities
          );
          const promise = getKeySearchPromises(
            "mystique/v1/soc/key-search?isSalesConnect=false&salesOpportunitySelectorsArray=",
            JSON.stringify(selectorArrFilter)
          );
          promise
            .then((response) => {
              let resultData2 = response;
              let mdata = [];
              mdata = mergeApiData(resultData1, resultData2);
              dispatch({
                type: GET_USER_REPORT_OPP,
                userDetailsReports: mdata,
              });
            })
            .catch((err) => {
              dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err),
              });
            });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getOwnerInvoiceReport = (startDate, endDate, clientType) => {
  const typeMap = { HousingOwner: "OWNER", Housing: "BROKER" };
  const url = `/sapna/invoice_report.csv?startDate=${
    fixTimezoneOffset(startDate).toISOString().split("T")[0]
  }&endDate=${fixTimezoneOffset(endDate).toISOString().split("T")[0]}&clientType=${
    typeMap[clientType]
  }`;
  return (dispatch) => {
    dispatch({
      type: OWNER_INVOICE_REPORT,
      msg: "Downloading Report...",
    });

    return dispatch(
      downloadReport({
        url,
        reportType: "Invoice",
        startDate,
        endDate,
        errorActionData: {
          type: OWNER_INVOICE_REPORT_FAILURE,
          msg: "Timeout. Please select a shorter time period.",
        },
      })
    );

    // window.open(url, "_blank");
  };
};

export const downLoadPaymentReports = (agentId = null, oldFilters) => {
  return (dispatch) => {
    const salesConnectEntities = Constants.salesConnectEntities;
    const selectorArr = [];
    const { paging = { start: 0, rows: 0 } } = oldFilters || {};
    const keyFilter = salesConnectEntities["payment-failed-report"];

    // Date Filter
    const startDate = keyFilter.dateRange.fromDate;
    const endDate = keyFilter.dateRange.toDate;
    const range = {};
    range["createdAt"] = {
      from: startDate.getTime(),
      to: endDate.getTime(),
    };
    const filters = {
      filters: {
        and: [
          { range: range },
          {
            equal: {
              statusId: keyFilter.statusId,
            },
          },
          {
            equal: { ownerOpportunityStageId: keyFilter.ownerOpportunityStageId },
          },
          {
            equal: { opportunitySource: keyFilter.opportunitySource },
          },
        ],
      },
    };

    // Other Filters
    filters.agentId = agentId;
    filters.team = true;
    filters.paging = paging;
    filters.key = keyFilter.key;
    if (keyFilter.sortKey) {
      filters.sort = keyFilter.sortKey;
    }

    selectorArr.push(filters);
    const strSelector = JSON.stringify(selectorArr);
    const url = `/mystique/v1/soc/download-payment-failed-report?salesOpportunitySelectorsArray=${strSelector}`;
    dispatch({
      type: DOWNLOAD_PAYMENT_REPORT,
      msg: "Payment Report is downloading...",
    });

    return dispatch(
      downloadReport({
        url,
        reportType: "PaymentReport",
        startDate,
        endDate,
        credentialsRequired: true,
        errorActionData: {
          type: PAYMENT_REPORT_FAILURE,
          msg: "Please try again.",
        },
      })
    );
  };
};

export const getAgentPerformanceReport = (startDate, endDate) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `/mystique/soc-performance/agent_performance_report.csv`;

  const data = {
    startDate,
    endDate,
    timeZone,
  };

  return (dispatch) => {
    return dispatch(
      downloadReport({
        url,
        reportType: "Performance",
        startDate,
        endDate,
        errorActionData: {
          type: AGENT_PERFORMANCE_REPORT_FAILURE,
          msg: "Timeout. Please select a shorter time period.",
        },
        data,
        method: "post",
      })
    );
  };
};

export const getStateMovementReports = (dates) => {
  let { startDate, endDate } = dates;
  startDate = new Date(startDate).getTime();
  endDate = new Date(endDate).getTime();
  const url = `sapna/v1/opportunity/salesconnect-status-movement?fromDate=${startDate}&toDate=${endDate}&debug=true&debug=true`;
  return (dispatch) => {
    dispatch({
      type: RESET_STATEMOVEMENT_REPORT,
    });
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: GET_STATE_MOVEMENT_REPORT,
          stateMovementReports: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAllCitiesValues = () => {
  return (dispatch) => {
    const url = `/petra/data/v2/entity/city?selector={"fields":["id","label"],"paging":{"start":0,"rows":1000}}`;
    return instance({
      url,
    })
      .then((response) => {
        if (response.data.data) {
          const allCities = response.data.data;

          dispatch({
            type: GET_ALL_CITIES_DATA,
            value: allCities,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
