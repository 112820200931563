import {
  DISABLE_PACKAGES,
  FAMILY_PACKAGES,
  PACKAGE_CREATED,
  PACKAGE_DELETED,
  PACKAGE_NETSUITE_UPDATED,
  PACKAGE_NETSUITE_UPDATION_FAILED,
  PACKAGE_UPDATED,
  PACKAGE_UPDATION_FAILED,
  PRODUCT_MAPPINGS,
  PRODUCT_MAPPINGS_ADDED,
  PRODUCT_MAPPINGS_DELETED,
  REQUIRED_PRICING_PARAMETERS_LIST,
  RESET_PACKAGE_UPDATION_STATUS,
} from "../actions/managePackages.actions";

const defaultState = {};
const managePackagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FAMILY_PACKAGES:
      return {
        ...state,
        familyPackages: action.payload,
      };
    case DISABLE_PACKAGES:
      return {
        ...state,
        packageDisabledStatus: action.payload,
        snackbar: {
          state: action.payload,
          message: "Packages deleted successfully",
          type: "success",
        },
      };
    case REQUIRED_PRICING_PARAMETERS_LIST:
      return {
        ...state,
        allPackageParameters: action.payload,
      };
    case PACKAGE_UPDATED:
    case PACKAGE_CREATED:
    case PACKAGE_DELETED:
      return {
        ...state,
        packageModified: action.payload,
        snackbar: {
          state: true,
          message: action.payload
            ? "Package Updated Successfully"
            : "Package Update Failed",
          type: action.payload ? "success" : "error",
        },
      };
    case PACKAGE_NETSUITE_UPDATED:
      return {
        ...state,
        packageModified: action.payload,
        snackbar: {
          state: true,
          message: action.payload
            ? "Package Updated Successfully"
            : "Package Update Failed",
          type: action.payload ? "success" : "error",
        },
      };
    case PRODUCT_MAPPINGS:
      return {
        ...state,
        productMappings: action.payload,
        productPackageId: action.productPackageId,
      };
    case PRODUCT_MAPPINGS_DELETED:
    case PRODUCT_MAPPINGS_ADDED:
      return {
        ...state,
        packageModified: action.status,
        snackbar: {
          state: true,
          message: action.status
            ? "Mappings Updated successfully"
            : "Mappings Update Failed",
          type: action.status ? "success" : "error",
        },
      };
    case RESET_PACKAGE_UPDATION_STATUS:
      return {
        ...state,
        packageModified: false,
        packageUpdationError: false,
      };
    case PACKAGE_UPDATION_FAILED:
      return {
        ...state,
        packageUpdationError: true,
        snackbar: {
          state: true,
          type: "error",
          duration: action.duration || null,
          message:
            action.errMsg || "Some error occurred, try updating package again!",
        },
      };
    case PACKAGE_NETSUITE_UPDATION_FAILED:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          duration: action.duration || null,
          message:
            action.errMsg || "Some error occurred, try updating package again!",
        },
      };
  }
};

export default managePackagesReducer;
