import {
  SET_MICROSITE_FORM_DATA,
  RESET_MICROSITE_FORM_DATA,
  SET_MICROSITE_LIST,
  RESET_MICROSITE_LIST,
  SET_MICROSITE_DEDICATED_DATA,
} from "../actions/microsite.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MICROSITE_FORM_DATA: {
      const { section, data } = action.payload;
      return {
        ...state,
        microsite: {
          ...state.microsite,
          [section]: data,
        },
      };
    }
    case RESET_MICROSITE_FORM_DATA: {
      return {
        ...state,
        microsite: defaultState.microsite,
      };
    }
    case SET_MICROSITE_LIST: {
      return {
        ...state,
        micrositeList: action.payload,
      };
    }
    case RESET_MICROSITE_LIST: {
      return {
        ...state,
        micrositeList: defaultState.micrositeList,
      };
    }
    case SET_MICROSITE_DEDICATED_DATA: {
      return {
        ...state,
        microsite: action.payload,
      };
    }
  }
};

export default reducer;
