import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const APPROVAL_STATUS = "APPROVAL_STATUS";
export const APPROVAL_DETAILS = "APPROVAL_DETAILS";
export const APPROVAL_PAGE_DETAILS = "APPROVAL_PAGE_DETAILS";
export const APPROVAL_IN_PROGRESS = "APPROVAL_IN_PROGRESS";
export const APPROVAL_ERROR = "APPROVAL_ERROR";

export const confirmApproval = (actionId, remark) => {
  return (dispatch) => {
    const url = `/sapna/v2/task`;
    const data = {
      taskKey: actionId,
      remark,
    };
    dispatch({
      type: APPROVAL_IN_PROGRESS,
      payload: true,
    });
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        const approvalStatus = response.data.data;
        dispatch({
          type: APPROVAL_STATUS,
          approvalStatus,
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          const errorResponse = {
            taskResponseStatus: "Failed",
            message: Constants.getErrorMessage(err),
          };
          dispatch({
            type: APPROVAL_STATUS,
            approvalStatus: errorResponse,
          });
        } else {
          dispatch({
            type: APPROVAL_ERROR,
            errMsg: Constants.getErrorMessage(err),
          });
        }
      });
  };
};

export const getApprovalDetails = (token) => {
  return (dispatch) => {
    const url = `/sapna/v3/task`;

    return instance({
      url,
      method: "post",
      data: {
        taskKey: token,
      },
    })
      .then((response) => {
        if (response.data.data) {
          const approvalDetails = response.data.data;
          dispatch({
            type: APPROVAL_DETAILS,
            approvalDetails,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          const errorResponse = {
            taskResponseStatus: "Failed",
            message: Constants.getErrorMessage(err),
          };
          dispatch({
            type: APPROVAL_DETAILS,
            approvalDetails: errorResponse,
          });
        } else {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
        }
      });
  };
};

export const getApprovalPageDetails = (paymentId) => {
  return (dispatch) => {
    const url = `sapna/v1/approval-page-details/${paymentId}`;

    return instance({
      url,
      baseURL: "/",
    })
      .then((response) => {
        if (response.data.data) {
          const approvalPageDetails = response.data.data;
          dispatch({
            type: APPROVAL_PAGE_DETAILS,
            approvalPageDetails,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          const errorResponse = {
            taskResponseStatus: "Failed",
            message: Constants.getErrorMessage(err),
          };
          dispatch({
            type: APPROVAL_PAGE_DETAILS,
            approvalPageDetails: null,
            approvalPageDetailsError: errorResponse,
          });
        } else {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
        }
      });
  };
};
