import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";

export const GET_EVENTS = "GET_EVENTS";
export const RESET_PACKAGE_FAMILIY_UPDATION_STATUS =
  "RESET_PACKAGE_FAMILIY_UPDATION_STATUS";
export const getEventsHelper = () => {
  return (dispatch) => {
    getEvents(dispatch);
  };
};
const dispatchError = (err, dispatch) => {
  dispatch({
    type: Constants.API_FAIL,
    errMsg: Constants.getErrorMessage(err),
  });
};
const getEvents = (dispatch) => {
  const url = "/sapna/event";
  return instance({
    url,
    method: "GET",
  })
    .then(({ data: { data: events = [] } = {} }) => {
      dispatch({
        type: GET_EVENTS,
        events,
      });
    })
    .catch((err) => dispatchError(err, dispatch));
};

export const createEvent = (eventInfo) => {
  const url = "/sapna/event";
  return (dispatch) => {
    return instance({
      url,
      method: "POST",
      data: { ...eventInfo, updateSlot: undefined },
    })
      .then((response) => {
        getEvents(dispatch);
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: "Event Created Successfully!",
        });
        return response;
      })
      .catch((err) => dispatchError(err, dispatch));
  };
};

export const updateEvent = (id, eventInfo) => {
  let url = `/sapna/event/${id}`;
  if (eventInfo.updateSlot) {
    url = url.concat("?updateSlot=true");
  }
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data: { ...eventInfo, updateSlot: undefined },
    })
      .then((response) => {
        getEvents(dispatch);
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: "Event Updated Successfully!",
        });
        return response;
      })
      .catch((err) => dispatchError(err, dispatch));
  };
};

export const deleteEvent = (id) => {
  const url = `/sapna/event/${id}`;
  return (dispatch) => {
    return instance({
      url,
      method: "DELETE",
    })
      .then(() => {
        getEvents(dispatch);
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: "Event Deleted Successfully!",
        });
      })
      .catch((err) => dispatchError(err, dispatch));
  };
};

export const resetPackageFamilyUpdateStatus = () => (dispatch) =>
  dispatch({ type: RESET_PACKAGE_FAMILIY_UPDATION_STATUS });
