import accountReducer from "./account.reducer";
import adProductConfigReducer from "./adProductConfig.reducer";
import agentReducer from "./agent.reducer";
import approvalActionReducer from "./approvalAction.reducer";
import approvalPageReducer from "./approvalPage.reducer";
import campaignModuleReducer from "./campaignModule.reducer";
import commentReducer from "./comment.reducer";
import constantsReducer from "./constants.reducer";
import contentPackagesPageReducer from "./contentPackagesPage.reducer";
import detailDialogReducer from "./detailDialog.reducer";
import discountMatrixReducer from "./discountMatrix.reducer";
import documentReducer from "./document.reducer";
import eventManagementReducer from "./eventManagement.reducer";
import flatPanelReducer from "./flatPanel.reducer";
import headerReducer from "./header.reducer";
import homeReducer from "./home.reducer";
import incentiveReducer from "./incentive.reducer";
import localityAgentMappingReducer from "./localityAgentMapping.reducer";
import loginReducer from "./login.reducer";
import managePackagesReducer from "./managePackages.reducer";
import managePricingReducer from "./managePricing.reducer";
import manageRegionsReducer from "./manageRegions.reducer";
import manualDialingReducer from "./manualDialing.reducer";
import matrixReducer from "./commonMatrix.reducer";
import mergeAccountsReducer from "./mergeAccounts.reducer";
import micrositeReducer from "./microsite.reducer";
import myAccountsReducer from "./myAccounts.reducer";
import mortgageCityHeadsReducer from "./mortgageCityHeads.reducer";
import myOppurtunitiesReducer from "./myOppurtunities.reducer";
import opportunitySearchReducer from "./opportunitySearch.reducer";
import oppurtunityReducer from "./oppurtunity.reducer";
import paymentReducer from "./payment.reducer";
import performance from "./performance.reducer";
import productConfigReducer from "./productConfig.reducer";
import productManagementReducer from "./productManagement.reducer";
import productPackageFamilyReducer from "./productPackageFamily.reducer";
import productReducer from "./product.reducer";
import profileReducer from "./profile.reducer";
import renewalReportReducer from "./renewalReport.reducer";
import reportsReducer from "./reports.reducer";
import rmMigrationReducer from "./rmMigration.reducer";
import salesClosureDateReducer from "./salesClosureDate.reducer";
import siteTakeoverReducer from "./siteTakeover.reducer";
import slotAvailabilityReducer from "./slotAvailability.reducer";
import teleSalesDashboardReducer from "./teleSalesDashboard.reducer";
import termsheetReducer from "./termsheet.reducer";
import userManagementReducer from "./userManagement.reducer";
import virtualTourReducer from "./virtualTour.reducer";
import npSlotReducer from "./npSlot.reducer";
import migrationOfAgentsCases from "./migrationOfAgentsCases.reducer";
import netsuit from "./netsuit.reducer";
import erpDataReducer from "./erptracking.reducer";
import churnScheduler from "./churnScheduler.reducer";
import liveViewAggregatedData from "./liveView.reducer";
import monetizableCities from "./monetizableCities.reducer";
import audienceMaximixerReducer from "./audienceMaximizer.reducer";
import manualActivationReducer from "./manualActivation.reducer";
import storiesReducer from "./stories.reducer";
import leadFeebackReducer from "./leadFeedback.reducer";
import slotCappingReducer from "./slotCapping.reducer";

const reducers = [
  agentReducer,
  accountReducer,
  commentReducer,
  matrixReducer,
  constantsReducer,
  contentPackagesPageReducer,
  detailDialogReducer,
  approvalActionReducer,
  discountMatrixReducer,
  documentReducer,
  eventManagementReducer,
  headerReducer,
  homeReducer,
  incentiveReducer,
  localityAgentMappingReducer,
  loginReducer,
  managePackagesReducer,
  managePricingReducer,
  manageRegionsReducer,
  mergeAccountsReducer,
  micrositeReducer,
  monetizableCities,
  mortgageCityHeadsReducer,
  myAccountsReducer,
  myOppurtunitiesReducer,
  opportunitySearchReducer,
  oppurtunityReducer,
  teleSalesDashboardReducer,
  paymentReducer,
  productReducer,
  productConfigReducer,
  productManagementReducer,
  productPackageFamilyReducer,
  reportsReducer,
  salesClosureDateReducer,
  slotAvailabilityReducer,
  termsheetReducer,
  userManagementReducer,
  virtualTourReducer,
  manualDialingReducer,
  adProductConfigReducer,
  rmMigrationReducer,
  renewalReportReducer,
  performance,
  campaignModuleReducer,
  siteTakeoverReducer,
  profileReducer,
  npSlotReducer,
  flatPanelReducer,
  approvalPageReducer,
  npSlotReducer,
  migrationOfAgentsCases,
  netsuit,
  erpDataReducer,
  churnScheduler,
  liveViewAggregatedData,
  audienceMaximixerReducer,
  manualActivationReducer,
  storiesReducer,
  leadFeebackReducer,
  slotCappingReducer,
];

export default reducers;
