import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  OFFLINE_STATE,
  VARIOUS_CONFIG,
  RESET_PASSWORD_EMAIL_SENT,
  RESET_PASSWORD_EMAIL_RESET,
  PLATFORM_IS_MAKAAN,
} from "../actions/login.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case VARIOUS_CONFIG:
      return {
        ...state,
        variousConfig: action.config,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        showError: false,
        isLoggedOut: true,
        isLoggedIn: false,
        userInfo: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        hasLoginFailed: false,
        userInfo: action.userInfo,
        enableRenewalDashboard: true,
        enableNpStopSlot: action.userInfo && action.userInfo.id === 9493307,
      };
    case OFFLINE_STATE:
      return {
        ...state,
        hasLoginFailed: true,
        isLoggedIn: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        showError: true,
        hasLoginFailed: true,
        isLoggedOut: true,
        errorMessage: action.message,
        isLoggedIn: false,
      };
    case RESET_PASSWORD_EMAIL_SENT:
      return {
        ...state,
        resetPasswordEmailSent: true,
        resetPasswordMessage: action.payload,
      };
    case RESET_PASSWORD_EMAIL_RESET:
      return {
        ...state,
        resetPasswordMessage: null,
        resetPasswordEmailSent: null,
      };
    case PLATFORM_IS_MAKAAN:
      return {
        ...state,
        isPlatformMakaan: action.platformIsMakaan,
      };
  }
};

export default reducer;
