import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";
import { audienceMaximizerSlotsParser } from "./audienceMaximizerUtils";
import { getAllCitiesList } from "./siteTakeover.actions";
import { getSellerUuidFromList } from "./audienceMaximizerUtils";

export const RESET_AM_FORM_DATA = "RESET_AM_FORM_DATA";
export const SET_AM_FORM_DATA = "SET_AM_FORM_DATA";
export const RESET_AM_PRODUCT_DATA = "RESET_AM_PRODUCT_DATA";
export const SET_AM_LIST = "SET_AM_LIST";
export const PROJECTS_LIST_AM = "PROJECTS_LIST_AM";
export const SET_SLOT_DEDICATED_DATA_AM = "SET_SLOT_DEDICATED_DATA_AM";
export const SET_PROJECT_ID_AM = "SET_PROJECT_ID_AM";
export const SET_SELLERS_LIST_AM = "SET_SELLERS_LIST_AM";
export const SET_FSL_CITIES = "SET_FSL_CITIES";
export const SET_FAS_CITIES = "SET_FAS_CITIES";

export const resetFormData = () => (dispatch) =>
  dispatch({ type: RESET_AM_FORM_DATA });
export const setFormData = (section, data, index) => (dispatch) =>
  dispatch({
    type: SET_AM_FORM_DATA,
    payload: { section, data, index },
  });
export const createAudienceMaximizer =
  (data, updateForm = false) =>
  (dispatch) => {
    const url = `${process.env.DOMAINS.ADVERT}products/create-packages`;
    const editUrl = `${process.env.DOMAINS.ADVERT}products/update-packages?jsession=true`;
    return instance({
      url: updateForm ? editUrl : url,
      withCredentials: true,
      method: "POST",
      data,
    })
      .then((response) => {
        let { data: { data: { message: msg } = {} } = {} } = response;
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: msg,
        });
      })
      .catch((err) => {
        const errMsg = Constants.getErrorMessage(err);
        dispatch({
          type: Constants.API_FAIL,
          errMsg: errMsg,
        });
        return Promise.reject(errMsg);
      });
  };
export const getAudienceMaximizerList = (pageNo, totalPages) => (dispatch) => {
  let promise = getProjectIds();
  return promise
    .then((data) => {
      const slotId = data["Audience Maximizer"] || "";
      dispatch({ type: SET_PROJECT_ID_AM, payload: data });
      const url = `${process.env.DOMAINS.ADVERT}products/get-all-packages?row_per_page=10&current_page_no=${pageNo}&promoted_entity_type_id=2&slot_status_id=0&placeholder_ids=${slotId}`;
      Promise.all([
        instance({
          url,
          withCredentials: true,
          method: "GET",
        }),
        getAllCitiesList(),
      ]).then(([{ data: { data = {} } = {} }, cityData]) => {
        dispatch({
          type: SET_AM_LIST,
          payload: {
            results: audienceMaximizerSlotsParser(data.results, cityData),
            hasNextPage: pageNo === totalPages ? data.next_page_exist : false,
            totalPages,
          },
        });
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
      return Promise.reject(errMsg);
    });
};

export const getProjectList = (project) => (dispatch) => {
  const url = `${
    process.env.DOMAINS.SEARCH
  }api/v1/search/suggest?property_category=residential&service=buy&string=${project}&type=project${
    !isNaN(project) ? `&entity_ids[]=${project}` : ""
  }`;
  if (!project) return;
  return instance({
    url,
    method: "GET",
  })
    .then((response) => {
      dispatch({ type: PROJECTS_LIST_AM, payload: response.data.data.results });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const getFSLCities = (searchTerm) => (dispatch) => {
  const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/suggest?super_type=polygon&service_type=buy&input=${searchTerm}`;
  return instance({ url, method: "GET" })
    .then(({ data = [] }) => {
      dispatch({
        type: SET_FSL_CITIES,
        payload: data,
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const getFASCities = (searchTerm) => (dispatch) => {
  const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/suggest?super_type=polygon&service_type=buy&feature_type=locality,city&input=${searchTerm}`;
  return instance({ url, method: "GET" })
    .then(({ data = [] }) => {
      dispatch({
        type: SET_FAS_CITIES,
        payload: data,
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};
export const getAudienceMaximizerSlotData = (slotId) => (dispatch) => {
  const url = `/sapna/v1/housing/helper/8?slot_id=${slotId}&source=am_panel`;
  let promises = [getAllCitiesList(), getProjectIds()];
  let cities = [];
  let projectMap = {};
  let slotData = {};
  Promise.all(promises)
    .then(([cityData, projectMapData]) => {
      cities = cityData;
      projectMap = projectMapData;
      return instance({ url, method: "GET" })
        .then(({ data: { data = {} } = {} }) => {
          slotData = data;
          const { promoted_entity = {} } = slotData;
          const { entity_id: projectId = "" } = promoted_entity;
          return dispatch(getProjectsData(projectId));
        })
        .then((projectArr = []) => {
          const { name = "" } = projectArr[0] || [];
          dispatch({
            type: SET_SLOT_DEDICATED_DATA_AM,
            payload: audienceMaximizerSlotsParser(
              slotData,
              cities,
              projectMap,
              name
            ),
          });
        })
        .catch((error) => {
          const errMsg = Constants.getErrorMessage(error);
          dispatch({
            type: Constants.API_FAIL,
            errMsg: errMsg,
          });
        });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const getProjectIds = () => {
  const url = `${process.env.DOMAINS.AP}products-mapping`;
  return new Promise((resolve, reject) => {
    instance({ url, method: "GET" })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSellerUUID = (projectId) => (dispatch) => {
  let url = `${process.env.DOMAINS.VENUS}api/v7/new-projects/${projectId}/webapp?version=v3`;
  let sellerList = [];
  return instance({ url, method: "GET" })
    .then((response) => {
      const sellersInfo = response?.data?.data?.sellers_info ?? [];
      sellerList = getSellerUuidFromList(sellersInfo);
      const locality = response?.data?.data?.polygons_hash?.locality ?? null;
      const projectRegion = locality ? [{ ...locality }] : [];
      dispatch({
        type: SET_SELLERS_LIST_AM,
        payload: {
          sellerList,
          projectRegion,
        },
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const getProjectIdData = () => (dispatch) => {
  getProjectIds()
    .then((data) => {
      dispatch({ type: SET_PROJECT_ID_AM, payload: data });
    })
    .catch((error) => {
      const { data: { apiErrors: { details = "", displayPhrase = "" } = {} } = {} } =
        error || {};
      dispatch({
        type: Constants.API_FAIL,
        errMsg: details || displayPhrase,
      });
    });
};

export const getProjectsData =
  (projectIds = "") =>
  (dispatch) => {
    const url = `${process.env.DOMAINS.VENUS}api/v7/new-projects/bulk-projects-data?project_ids=${projectIds}`;
    return new instance({ url, method: "GET" })
      .then(({ data: { data = [] } = {} }) => {
        return data;
      })
      .catch((error) => {
        const {
          response: {
            data: { apiErrors: { details = "", displayPhrase = "" } = {} } = {},
          } = {},
        } = error || {};
        dispatch({
          type: Constants.API_FAIL,
          errMsg: details || displayPhrase,
        });
        return [];
      });
  };

export const getLocalityData = (localityId = "") => {
  const url = `${process.env.DOMAINS.REGIONS}api/v3/polygon/polygon_info?api_name=GET_POLYGON_INFO&source=web&uuid=${localityId}`;
  return new instance({ url, method: "GET" }).then(
    ({ data: { locality_details: localityInfo = {} } = {} }) => {
      return localityInfo;
    }
  );
};
