import { instance } from "../../lib/api"
import Constants from '../../utils/CommonConstants'

export const  PACKAGE_PRICING = 'PACKAGE_PRICING'
export const PRICING_DELETED = 'PRICING_DELETED'
export const RESET_PRICING_STATUS = 'RESET_PRICING_STATUS'
export const PRICING_ADDED = 'PRICING_ADDED'
export const PRICING_UPDATED = 'PRICING_UPDATED'
export const FILE_UPLOADED = 'FILE_UPLOADED';

export const getPackagePricing = (packageId) => {
    const url = `/sapna/v2/package-price/${packageId}`
    return (dispatch)=>{
        return instance({
            url,
            method: 'get'
        }).then(response =>{
            dispatch({
                type: PACKAGE_PRICING,
                payload: parseLocalityNames(response.data.data)
            })
        }).catch(err=>{
            dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err)
            })
        })
    }
}

export const deletePricings = (pricingIds) =>{
    const url=`/sapna/v2/package-price/delete`
    return (dispatch)=>{
        return instance({
            url,
            method: 'put',
            data: pricingIds
        }).then(response => {
            dispatch({
                type: PRICING_DELETED,
                status: response.data.data ? true: false
            })
        }).catch(err => {
            dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err)
            })
        })
    }
}

export const addPackagePricing = (packageId , data) => {
    const url = ` /sapna/v2/package-price/add/${packageId}`
    return (dispatch)=>{
        return instance({
            url,
            method: 'post',
            data
        }).then(response => {
            dispatch({
                type: PRICING_ADDED,
                status: response.data.data ? true: false 
            })
        }).catch(err => {
            dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err)
            })
        })
    }
}

export const editPackagePricing = (pricingId ,data) => {
    const url = `/sapna/v2/package-price/updatePackagePrice/${pricingId}`
    return (dispatch)=>{
        return instance({
            url,
            method: 'put',
            data
        }).then(response => {
            dispatch({
                type: PRICING_UPDATED,
                status: response.data.data ? true: false
            })
        }).catch(err => {
            dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err)
            })
        })
    }
}

export const resetPricingModificationStatus = () => {
    return (dispatch)=>{
        dispatch({
            type: RESET_PRICING_STATUS
        })
    }
}

export const bulkPricingUpdate = (excelSheet) => {
    const url = `/sapna/v2/package-price/csv/updatePackagePrice`
    return (dispatch)=>{
        return instance({
            url,
            method: 'post',
            data: excelSheet
        }).then(response=> {
            dispatch({
                type: FILE_UPLOADED
            })
        }).catch(err => {
            dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err)
            })
        })

    }
}

const parseLocalityNames = (data) => {
    return data.map(d => {
        if(d.pricing_parameters && d.pricing_parameters.localityName){
            return {
                ...d,
                pricing_parameters: {
                    ...d.pricing_parameters,
                    localityName: JSON.parse(d.pricing_parameters.localityName)
                }
            }
        }
        else return d;
    })
}