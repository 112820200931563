import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { routesMapping } from "./routesConfig.js";
import ProtectedRoute from "./components/ProtectedRoute";
const GoogleLogin = lazy(() => import("./containers/Login/GoogleLogin.js"));
const OneLogin = lazy(() => import("./containers/Login/OneLogin.js"));

const Account = lazy(() => import("./containers/Account/Account"));

const Payment = lazy(() => import("./containers/Payment/Payment"));

const Termsheet = lazy(() => import("./containers/Termsheet/Termsheet"));

const ViewChurnScheduler = lazy(() =>
  import("./containers/ViewChurnScheduler/ViewChurnScheduler")
);

const Opportunity = lazy(() => import("./containers/Opportunity/Opportunity"));

const EntityList = lazy(() => import("./containers/EntityList/EntityList"));

const ApprovalAction = lazy(() =>
  import("./containers/ApprovalAction/ApprovalAction")
);

const AgentPerformance = lazy(() =>
  import("./containers/Agent/Performance/Performance")
);

const MergeIPDetectedAccountDetails = lazy(() =>
  import("./containers/MergeIPDetectedAccountDetails/MergeIPDetectedAccountDetails")
);

const ContentPackages = lazy(() =>
  import("./containers/ContentPackagesPage/ContentPackagesPage")
);

const TenantMonetizationOpportunity = lazy(() =>
  import("./containers/TenantMonetizationOpportunity/TenantMonetizationOpportunity")
);
const BuilderOpportunity = lazy(() =>
  import("./containers/TeleSalesOpportunity/BuilderOpportunity/BuilderOpportunity")
);

const BrokerOpportunity = lazy(() =>
  import("./containers/TeleSalesOpportunity/BrokerOpportunity/BrokerOpportunity")
);

const OwnerOpportunity = lazy(() =>
  import("./containers/TeleSalesOpportunity/OwnerOpportunity/OwnerOpportunity")
);

const OpportunityAssignment = lazy(() =>
  import("./containers/OpportunityAssignment/OpportunityAssignment")
);

const CreateSlot = lazy(() => import("./containers/Microsite/CreateSlot"));

const SlotData = lazy(() => import("./containers/Microsite/SlotData"));

const VirtualTourCreateSlot = lazy(() =>
  import("./containers/VirtualTour/CreateSlot")
);

const VirtualTourSlotData = lazy(() => import("./containers/VirtualTour/SlotData"));

const SalesConnectOpp = lazy(() =>
  import("./containers/SalesConnectOpp/SalesConnectOpp")
);

const SalesReportOpp = lazy(() =>
  import("./containers/SalesConnectOpp/SalesReportOpp")
);

const StateMovementReports = lazy(() =>
  import("./containers/SalesConnectOpp/StateMovementReports")
);

const CreateSiteTakeover = lazy(() =>
  import("./containers/SiteTakeover/CreateSiteTakeover")
);

const EditSiteTakeover = lazy(() =>
  import("./containers/SiteTakeover/EditSiteTakeover")
);

const RenewalDashboard = lazy(() =>
  import("./containers/RenewalDashboard/RenewalDashboard")
);
const ApprovalDetails = lazy(() =>
  import("./containers/ApprovalDetails/ApprovalDetails")
);

const NPSlot = lazy(() => import("./containers/NPSlot/NPSlot"));

const ApprovalPage = lazy(() => import("./containers/ApprovalPage/ApprovalPage"));

const SwapApprovalPage = lazy(() =>
  import("./containers/SwapApprovalPage/SwapApprovalPage")
);

const AudienceMaximizerCreateSlot = lazy(() =>
  import("./containers/AudienceMaximizer/CreateSlot")
);

const AudienceMaximizerEditSlot = lazy(() =>
  import("./containers/AudienceMaximizer/EditSlot")
);

const OwnerSalesConfig = lazy(() => import("./containers/OwnerSalesConfig/index"));
const StoriesCreateSlot = lazy(() => import("./containers/Stories/CreateSlot"));
const StoriesEditSlot = lazy(() => import("./containers/Stories/EditSlot"));

const LeadFeedback = lazy(() => import("./containers/LeadFeedback"));
const LeadDelivery = lazy(() => import("./containers/LeadDelivery"));
const Unauthorized = lazy(() => import("./containers/Unauthorized"));
const renderRoutes = (routes, parentRoles = []) => {
  return routes?.map((nav, index) => {
    const combinedRoles = [...parentRoles, ...(nav.roles || [])];

    if (nav?.nestedMenus) {
      return (
        <React.Fragment key={index}>
          {renderRoutes(nav?.nestedMenus, combinedRoles)}
        </React.Fragment>
      );
    }
    if (combinedRoles.length > 0) {
      return (
        <Route
          key={index}
          path={nav.route}
          element={
            <ProtectedRoute roles={combinedRoles} component={nav.component} />
          }
        />
      );
    }
    return <Route key={index} path={nav.route} element={<nav.component />} />;
  });
};

export default (
  <Routes>
    {renderRoutes(routesMapping)}
    <Route
      path="/"
      element={process.env.BUILD_ENV === "prod" ? <GoogleLogin /> : <OneLogin />}
    />
    <Route path="/unauthorized" element={<Unauthorized />} />
    <Route path="lead-delivery">
      <Route path=":profileUuid" element={<LeadDelivery />} />
    </Route>
    <Route path="account">
      <Route path=":accountId" element={<Account />} />
    </Route>

    <Route path="payment">
      <Route path="" element={<Payment />} />
      <Route path=":paymentId" element={<Payment />} />
    </Route>

    <Route path="termsheet">
      <Route path=":termsheetId" element={<Termsheet />} />
    </Route>
    <Route path="agent">
      <Route path="performance" element={<AgentPerformance />} />
    </Route>
    <Route path="view-churn-scheduler" element={<ViewChurnScheduler />} />

    <Route path="opportunity">
      <Route path="assignment-page">
        <Route path=":opportunityId" element={<OpportunityAssignment />} />
      </Route>
      <Route path=":opportunityId" element={<Opportunity />} />
    </Route>

    <Route path="owner-opportunity">
      <Route path=":opportunityId" element={<OwnerOpportunity />} />
    </Route>

    <Route path="broker-opportunity">
      <Route path=":opportunityId" element={<BrokerOpportunity />} />
    </Route>
    <Route path="builder-opportunity">
      <Route path=":opportunityId" element={<BuilderOpportunity />} />
    </Route>
    <Route path="tenant-opportunity">
      <Route path=":opportunityId" element={<TenantMonetizationOpportunity />} />
    </Route>

    <Route path="list">
      <Route path=":keyName" element={<EntityList />} />
    </Route>
    <Route path="action" element={<ApprovalAction />} />

    <Route path="merge-ip-detected-accounts">
      <Route path=":id" element={<MergeIPDetectedAccountDetails />} />
    </Route>

    <Route path="content-package-page" element={<ContentPackages />} />
    <Route path="renewal-dashboard" element={<RenewalDashboard />} />

    <Route path="microsite">
      <Route path="create-slot" element={<CreateSlot />} />
      <Route path=":micrositeId" element={<SlotData />} />
    </Route>

    <Route path="virtual-tour">
      <Route path="create-slot" element={<VirtualTourCreateSlot />} />
      <Route path=":tourId" element={<VirtualTourSlotData />} />
    </Route>

    <Route path="site-takeover">
      <Route path="create-slot" element={<CreateSiteTakeover />} />
      <Route path=":siteId" element={<EditSiteTakeover />} />
    </Route>

    <Route path="sales-connect-opp" element={<SalesConnectOpp />} />
    <Route path="sales-report-opp" element={<SalesReportOpp />} />
    <Route path="state-movement-reports" element={<StateMovementReports />} />
    <Route path="approval-details">
      <Route path=":paymentId" element={<ApprovalDetails />} />
    </Route>
    <Route path="approval-page" element={<ApprovalPage />} />

    <Route path="np-slot">
      <Route path=":opmId" element={<NPSlot />} />
    </Route>
    <Route path="swap-approval-details">
      <Route path=":swapId" element={<SwapApprovalPage />} />
    </Route>
    <Route path="/owner-sales-config" element={<OwnerSalesConfig />} />
    <Route path="audience-maximizer">
      <Route path="create-slot" element={<AudienceMaximizerCreateSlot />} />
      <Route path=":slotId" element={<AudienceMaximizerEditSlot />} />
    </Route>
    <Route path="stories">
      <Route path="create-slot" element={<StoriesCreateSlot />} />
      <Route path=":slotId" element={<StoriesEditSlot />} />
    </Route>
    <Route path="lead-feedback">
      <Route path="" element={<LeadFeedback />} />
    </Route>
  </Routes>
);
