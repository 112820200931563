// Returns a promise.
import axios from "axios";
import { requestHandler, responseHandler } from "./cacheHelper";
// import { datadogLogs } from "@datadog/browser-logs";
// import { store } from "../redux/store";
export const instance = axios.create({ timeout: 15000000 });
const errorHandler = (error) => {
  // datadogLogs.logger.log(
  //   error.message,
  //   {
  //     type: "api_error",
  //     error: error.response,
  //     userId: store.getState()?.userInfo?.id,
  //   },
  //   "error"
  // );
  return Promise.reject(error);
};
instance.interceptors.request.use((request) => {
  requestHandler(request);
  return request;
});

instance.interceptors.response.use(
  (response) => {
    responseHandler(response);
    return response;
  },
  (error) => errorHandler(error)
);

export default (url) => axios(url);
