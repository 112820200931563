import {
  DISCOUNT_APPROVAL_MATRIX,
  DISCOUNT_MATRIX_NETWORK_ERR,
} from "../actions/discountMatrix.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case DISCOUNT_APPROVAL_MATRIX:
      return {
        ...state,
        discountApprovalMatrix: action.payload,
      };
    case DISCOUNT_MATRIX_NETWORK_ERR:
      return {
        ...state,
        discountMatrixNetworkErr: {},
      };
  }
};

export default reducer;
