import React, { Suspense } from "react";

import App from "./components/App/App";
import { BrowserRouter } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import { datadogLogs } from "@datadog/browser-logs";
import store from "./redux/store";

datadogLogs.init({
  clientToken:
    process.env.NODE_ENV === "production" ? process.env.DATADOG_CLIENT_TOKEN : "",
  site: "datadoghq.com",
  forwardErrorsToLogs: false,
  sampleRate: 100,
});
const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Suspense fallback={<LinearProgress />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);

// eslint-disable-line no-undef

if (module.hot) {
  module.hot.accept();
}
