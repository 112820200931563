import {
  RESET_PAYMENT_DETAIL,
  PAYMENT_DETAIL_BY_ID,
  PAYMENT_CREATED,
  PAYMENT_UPDATED,
  PAYMENT_RECOLLECTED,
  PAYMENT_REPRESENTED,
  PAYMENT_CREATION_FAILED,
  PRE_PAYMENT_LINK_SEND,
  ACCOUNT_PAYMENT_BUCKET_DETAILS_ERROR,
  ACCOUNT_PAYMENT_BUCKET_DETAILS_IN_PROGRESS,
  ACCOUNT_PAYMENT_BUCKET_DETAILS_SUCCESS,
  UNUTILIZED_PAYMENTS_IN_PROGRESS,
  UNUTILIZED_PAYMENTS_ERROR,
  UNUTILIZED_PAYMENTS_SUCCESS,
} from "../actions/payment.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAYMENT_RECOLLECTED:
      return {
        ...state,
        newPayment: action.payload,
        snackbar: {
          state: true,
          message: "Payment Recollected",
          type: "success",
        },
      };
    case PAYMENT_REPRESENTED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Payment Represented",
          type: "success",
        },
      };
    case RESET_PAYMENT_DETAIL:
      return {
        ...state,
        payment: null,
        newPayment: null,
      };
    case PRE_PAYMENT_LINK_SEND:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Pre Payment Link Sent Successfully",
          type: "success",
        },
      };
    case PAYMENT_DETAIL_BY_ID:
      return {
        ...state,
        paymentByMilestoneId: null,
        paymentById: action.payload,
      };
    case PAYMENT_UPDATED:
      return {
        ...state,
        newPayment: action.payload,
        snackbar: {
          state: true,
          message: "Payment updated",
          type: "success",
        },
      };
    case PAYMENT_CREATED:
      return {
        ...state,
        newPayment: action.payload,
        snackbar: {
          state: true,
          message: "New payment created",
          type: "success",
        },
      };
    case PAYMENT_CREATION_FAILED:
      return {
        ...state,
        newPayment: {
          creationFailed: true,
        },
      };
    case ACCOUNT_PAYMENT_BUCKET_DETAILS_IN_PROGRESS:
      return {
        ...state,
        accountPaymentBucketTypeDetails: {
          ...state.accountPaymentBucketTypeDetails,
          [action.accountId]: {
            ...state.accountPaymentBucketTypeDetails[action.accountId],
            [action.paymentType]: {
              inProgress: action.payload,
              error: null,
              success: null,
            },
          },
        },
      };
    case ACCOUNT_PAYMENT_BUCKET_DETAILS_SUCCESS:
      return {
        ...state,
        accountPaymentBucketTypeDetails: {
          ...state.accountPaymentBucketTypeDetails,
          [action.accountId]: {
            ...state.accountPaymentBucketTypeDetails[action.accountId],
            [action.paymentType]: {
              inProgress: false,
              error: null,
              success: action.payload,
            },
          },
        },
      };
    case ACCOUNT_PAYMENT_BUCKET_DETAILS_ERROR:
      return {
        ...state,
        accountPaymentBucketTypeDetails: {
          ...state.accountPaymentBucketTypeDetails,
          [action.accountId]: {
            ...state.accountPaymentBucketTypeDetails[action.accountId],
            [action.paymentType]: {
              inProgress: false,
              error: action.payload,
              success: null,
            },
          },
        },
      };
    case UNUTILIZED_PAYMENTS_SUCCESS:
      return {
        ...state,
        unutilizedPayments: {
          ...state.unutilizedPayments,
          [action.accountId]: {
            ...state.unutilizedPayments[action.accountId],
            [action.modeId]: {
              inProgress: false,
              error: null,
              success: action.payload,
            },
          },
        },
      };
    case UNUTILIZED_PAYMENTS_ERROR:
      return {
        ...state,
        unutilizedPayments: {
          ...state.unutilizedPayments,
          [action.accountId]: {
            ...state.unutilizedPayments[action.accountId],
            [action.modeId]: {
              inProgress: false,
              error: action.payload,
              success: null,
            },
          },
        },
      };
    case UNUTILIZED_PAYMENTS_IN_PROGRESS:
      return {
        ...state,
        unutilizedPayments: {
          ...state.unutilizedPayments,
          [action.accountId]: {
            ...state.unutilizedPayments[action.accountId],
            [action.modeId]: {
              inProgress: true,
              error: null,
              success: null,
            },
          },
        },
      };
  }
};

export default reducer;
