import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const GET_MONETIZABLE_CITIES_SUCCESS = "GET_MONETIZABLE_CITIES_SUCCESS";

export const DELETE_MONETIZABLE_CITY_SUCCESS = "DELETE_MONETIZABLE_CITY_SUCCESS";
export const DELETE_MONETIZABLE_CITY_RESET = "DELETE_MONETIZABLE_CITY_RESET";

export const POST_MONETIZABLE_CITY_RESET = "POST_MONETIZABLE_CITY_RESET";
export const POST_MONETIZABLE_CITY_SUCCESS = "POST_MONETIZABLE_CITY_SUCCESS";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const RESET_SNACKBAR = "RESET_SNACKBAR";

const _dispatchResetSnackbar = (dispatch) => {
  dispatch({
    type: RESET_SNACKBAR,
  });
};

export const getMonetizableCities = () => {
  const url = "/sapna/v1/get-housing-monetizable-cities";

  return (dispatch) =>
    instance({
      url,
    })
      .then((response) => {
        let { data: { data } = {} } = response;
        dispatch({
          type: GET_MONETIZABLE_CITIES_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SHOW_SNACKBAR,
          snackbarType: "error",
          msg: Constants.getErrorMessage(err),
        });
      })
      .finally(() => _dispatchResetSnackbar(dispatch));
};

export const deteleMonetizableCity = (cityId) => {
  if (!cityId) return;

  const url = `/sapna/v1/housing-monetizable-cities/${cityId}`;

  return (dispatch) => {
    dispatch({
      type: DELETE_MONETIZABLE_CITY_RESET,
    });
    instance({
      url,
      method: "DELETE",
    })
      .then((response) => {
        const { data: { data, error } = {} } = response;
        if (error) {
          dispatch({
            type: SHOW_SNACKBAR,
            snackbarType: "error",
            msg: `Error: ${JSON.stringify(error)}`,
          });
        } else {
          dispatch({
            type: DELETE_MONETIZABLE_CITY_SUCCESS,
          });
          dispatch({
            type: SHOW_SNACKBAR,
            msg: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SHOW_SNACKBAR,
          snackbarType: "error",
          msg: Constants.getErrorMessage(err),
        });
      })
      .finally(() => _dispatchResetSnackbar(dispatch));
  };
};

export const postNewMonetizableCity = (cityData) => {
  if (!cityData) return;

  const url = "/sapna/v1/housing-monetizable-cities";

  return (dispatch) => {
    dispatch({
      type: POST_MONETIZABLE_CITY_RESET,
    });
    instance({
      url,
      method: "POST",
      data: cityData,
    })
      .then((response) => {
        let { data: { data, error } = {} } = response;
        if (error) {
          dispatch({
            type: SHOW_SNACKBAR,
            snackbarType: "error",
            msg: `Error: ${JSON.stringify(error)}`,
          });
        } else {
          dispatch({
            type: POST_MONETIZABLE_CITY_SUCCESS,
            payload: data,
          });
          dispatch({
            type: SHOW_SNACKBAR,
            msg: `${cityData[0].cityName} city added successfully`,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SHOW_SNACKBAR,
          snackbarType: "error",
          msg: Constants.getErrorMessage(err),
        });
      })
      .finally(() => _dispatchResetSnackbar(dispatch));
  };
};
