import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const MERGE_ACCOUNT_DETAILS = "MERGE_ACCOUNT_DETAILS";
export const MERGED_ACCOUNTS = "MERGED_ACCOUNTS";
export const GET_ACCOUNT_ID = "GET_ACCOUNT_ID";

export const getAccountDetails = (accountIdsList) => {
  const url = `/mystique/v1/sac/key-search?salesAccountSelectorsArray=[{"team":true,"sort":[{"field":"accountId","sortOrder":"DESC"}],"paging":{"start":0,"rows":20},"key":"BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER","filters":{"and":[{"equal":{"accountId":${JSON.stringify(
    accountIdsList
  )}}}]}}]`;
  return (dispatch) => {
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        const data = response.data && response.data.data;
        if (data && data.length === 1 && data[0] === null) {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: "No Such Accounts Exists.",
          });
        } else {
          const results = (data[0] && data[0].results) || [];
          dispatch({
            type: MERGE_ACCOUNT_DETAILS,
            mergeAccountDetails: results.length
              ? results.reduce(
                  (prev, curr) => (prev[curr.accountId] = curr) && prev,
                  {}
                )
              : null,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const mergeAccounts = (targetAccountId, listOfAccountIdsToMerge) => {
  const url = `/sapna/v1/account/merge-accounts/${targetAccountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data: listOfAccountIdsToMerge,
    })
      .then((response) => {
        dispatch({
          type: MERGED_ACCOUNTS,
          status: { status: response.data.statusCode === "2XX" },
        });
      })
      .catch((err) => {
        dispatch({
          type: MERGED_ACCOUNTS,
          status: { status: false },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAccountIdfromUUID = (uuid) => {
  return (dispatch) => {
    let filterSelecter = {
      team: true,
      sort: [
        {
          field: "accountId",
          sortOrder: "DESC",
        },
      ],
      paging: {
        start: 0,
        rows: 100,
      },
      key: "BASED_ON_OUTER_FILTER_WITH_COMPANY_ID_AGENT_FILTER",
      filters: {
        and: [
          {
            equal: {
              housingProfileId: uuid,
            },
          },
        ],
      },
    };

    const url = `/mystique/v1/sac/key-search?salesAccountSelectorsArray=[${JSON.stringify(
      filterSelecter
    )}]`;
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        dispatch({
          type: GET_ACCOUNT_ID,
          payload: response.data.data,
        });
        return response.data.data;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
