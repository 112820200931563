import { instance } from '../../lib/api';
import Constants from '../../utils/CommonConstants';

export const AGENT_PERFORMANCE_ACCOUNT = 'AGENT_PERFORMANCE_ACCOUNT';
export const AGENT_PERFORMANCE_OPPORTUNITY = 'AGENT_PERFORMANCE_OPPORTUNITY';
export const AGENT_INDIVIDUAL_PERFORMANCE = 'AGENT_INDIVIDUAL_PERFORMANCE';
export const AGENT_INDIVIDUAL_PERFORMANCE_RESET = 'AGENT_INDIVIDUAL_PERFORMANCE_RESET';


const baseUrlsForTotal = {
  account: '/mystique/v1/sac/get-actions?salesAccountSelector',
  opportunity: '/mystique/v1/soc/get-actions?salesOpportunitySelector'
};
export const getIndividualPerfomance = (key, type, timeRange, agentId, isTotal, activityType) => {
  const selector = {
    key,
    agentId,
    timeRange,
    team: false,
    paging: {
      start: 0,
      rows: 100
    }
  };
  const url = isTotal ? `${baseUrlsForTotal[type]}=${JSON.stringify(selector)}&activityType=${activityType}` : `${Constants.performanceBaseUrls[type]}=${JSON.stringify(selector)}`;
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
    .then(response => {
      const { data } = response.data;
      if ((isTotal && data && data.length !== 0 ) || (data && data[0] && data[0].results)) {
        dispatch({
          type: AGENT_INDIVIDUAL_PERFORMANCE,
          payload: isTotal ? data : data[0].results
        });
      }
      else {
        dispatch({
          type: AGENT_INDIVIDUAL_PERFORMANCE,
          payload: []
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err)
      });
    });
  };
};

export const resetIndividualPerfomance = () => {
  return (dispatch) => {
    dispatch({
      type: AGENT_INDIVIDUAL_PERFORMANCE_RESET
    });
  };
};

export const getAgentPerformance = (keys, type, timeRange, agentId) => {
  const selector = keys.map(key => (
    {
      key,
      agentId,
      timeRange,
      team: true,
      groupByField: 'agentId',
      paging: {
        start: 0,
        rows: 1000
      }
    }
  ));

  const url = `${Constants.performanceBaseUrls[type]}=${JSON.stringify(selector)}`;
  return (dispatch) => {
    return instance({
      url,
      method: 'get'
    })
    .then(response => {
      const { data } = response.data;
      const payload = {};
      keys.forEach((key, index) => {
        const { groups } = data[index];
        groups.forEach(group => {
          payload[group._id] = payload[group._id] || {};
          payload[group._id][key] = group.count || 0;
        });
      });
      dispatch({
        type: `AGENT_PERFORMANCE_${type.toUpperCase()}`,
        payload: payload
      });
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err)
      });
    });
  };
};