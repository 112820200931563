import {
  APPROVAL_STATUS,
  APPROVAL_DETAILS,
  APPROVAL_PAGE_DETAILS,
  APPROVAL_IN_PROGRESS,
  APPROVAL_ERROR,
} from "../actions/approvalAction.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case APPROVAL_IN_PROGRESS:
      return {
        ...state,
        approvalInProgress: action.payload,
      };
    case APPROVAL_STATUS:
      return {
        ...state,
        approvalStatus: action.approvalStatus,
        approvalInProgress: false,
      };
    case APPROVAL_DETAILS:
      return {
        ...state,
        approvalDetails: action.approvalDetails,
      };
    case APPROVAL_PAGE_DETAILS:
      return {
        ...state,
        approvalPageDetails: action.approvalPageDetails,
        approvalPageDetailsError: action.approvalPageDetailsError,
      };
    case APPROVAL_ERROR:
      return {
        ...state,
        approvalInProgress: false,
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Something went wrong!",
        },
      };
  }
};

export default reducer;
