import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const API_FAIL = "API_FAIL";
export const ACTION_LIST = "ACTION_LIST";
export const PROCESSED_DATA = "PROCESSED_DATA";
export const TICKETS_DATA = "TICKETS_DATA";
export const PAYMENTS_DATA = "PAYMENTS_DATA";
export const APPROVALS_DATA = "APPROVALS_DATA";

const dateDiffInDays = (a, b) => {
  var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
};
const fillActions = (processedData, actionList, startDate) => {
  for (let i = 0; i < actionList.length; i++) {
    const action = actionList[i];
    var index = dateDiffInDays(startDate, new Date(action.followupTime));
    processedData[index].actions.push(action);
    processedData[index].meeting = true;
  }
  return processedData;
};
const processData = (actionList, startDate, endDate) => {
  const processedData = [];
  var currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const currentDateElements = currentDate.toDateString().split(" "); // Tue Mar 13 2018
    const currentDateString = currentDate.toString();
    const dayData = {
      dateString: currentDateString,
      date: currentDateElements[2],
      day: currentDateElements[0],
      meeting: false,
      actions: [],
    };
    processedData.push(dayData);
    var nextDate = currentDate.setDate(currentDate.getDate() + 1);
    currentDate = new Date(nextDate);
  }
  return fillActions(processedData, actionList, startDate);
};

export const getActions = (startDate, endDate, commentIds) => {
  // const startDateISO = startDate.toISOString().split("T")[0];
  // const endDateISO = endDate.toISOString().split("T")[0];
  const url = `/mystique/v1/dashboard/actions?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}&commentIds=${commentIds}`;
  // const url = `/sapna/v1/dashboard/actions?startDate=${startDateISO}&endDate=${endDateISO}&commentIds=${commentIds}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    }).then((response) => {
      const actionList = response.data.data;
      const processedData = processData(actionList, startDate, endDate);
      dispatch({
        type: PROCESSED_DATA,
        processedData,
      });
    });
    // .catch((err) => {
    // dispatch({
    //   type: API_FAIL,
    //   errMsg: Constants.getErrorMessage(err),
    // });
    // });
  };
};

export const getFieldsData = (fieldType, statusList) => {
  const url = `/sapna/v1/dashboard/${fieldType}?types=${statusList}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const responseData = response.data.data;
        const responseObject = {
          type: `${fieldType}_DATA`.toLocaleUpperCase(),
          responseData,
        };
        dispatch(responseObject);
      })
      .catch((err) => {
        dispatch({
          type: API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
