import { GET_EVENTS } from "../actions/eventManagement.actions";
const defaultState = {};
const eventManagementReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        events: action.events,
      };
  }
};
export default eventManagementReducer;
