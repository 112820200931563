const constants = {
  NO_OZONTEL_CALLBACK: "No Ozonetel Callback",

  telesalesOpportunityDtoType: "salesOpportunityActionCreationDto",

  telesalesOpportunityBottomCommentType: "opportunity",
  activityTypeCALL: "Call",
};

export default constants;
