import { instance } from '../../lib/api'
import Constants from '../../utils/CommonConstants'

export const AGENT_LOCALITY_MAPPING = 'AGENT_LOCALITY_MAPPING'
export const AGENT_LOCALITY_MAPPING_ADDED = 'AgentLocalityMappingAdded'
export const AGENT_LOCALITY_MAPPING_DELETED = 'AgentLocalityMappingDeleted'

const salesAgentLocalityMappingUrlV1 = `/sapna/v1/sales-agent-locality-mapping`;
const salesAgentLocalityMappingUrlV2 = `/sapna/v2/sales-agent-locality-mapping`;

function getAgentIdLocalityMappingMap(dataArr = []){
    return dataArr.reduce( (acc, currVal) => {
        const {
            id,
        } = currVal
        acc[id] = {...currVal}
        return acc
    }, {})
}

export const getLocalityAgentMapping = () => {
  return (dispatch) => {
    const url = salesAgentLocalityMappingUrlV1;
    return instance({
      url,
      method: 'get'
    })
    .then((response) => {
      const {
        data: {
            data: localityAgentMappingArray
        } = {}
      } = response
      const agentIdLocalityMappingMap = getAgentIdLocalityMappingMap(localityAgentMappingArray)
      dispatch({
        type: AGENT_LOCALITY_MAPPING,
        agentIdLocalityMappingMap
      });
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err)
      });
    });
  };
}

export const addAgentLocalityMapping = (data) => {
    const url = salesAgentLocalityMappingUrlV2
    return (dispatch)=>{
        return instance({
            url,
            method: 'post',
            data
        }).then(response => {
            dispatch({
                type: Constants.SNACKBAR_NOTIFICATION,
                msg: 'Agent Locality Mapping Updated Successfully',
                snackBarType: AGENT_LOCALITY_MAPPING_ADDED
            })
        }).catch(err => {
            dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err)
            })
        })
    }
}

export const deleteAgentLocalityMapping = (data) => {
    const url = salesAgentLocalityMappingUrlV2
    return (dispatch)=>{
        return instance({
            url,
            method: 'delete',
            data
        }).then(response => {
            dispatch({
                type: Constants.SNACKBAR_NOTIFICATION,
                msg: 'Agent Locality Mapping Deleted Successfully',
                snackBarType: AGENT_LOCALITY_MAPPING_DELETED
            })
        }).catch(err => {
            dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err)
            })
        })
    }
}