/* eslint-disable react/display-name */
import Constants from "../../utils/CommonConstants";
import "./LiveViewOfAgents.scss";
import React from "react";
export const checkLoggedInUserRole = (roles) => {
  const mainRole = Object.keys(Constants.performanceMapping).filter((mappingRole) =>
    roles.find((role) => role === Constants.performanceMapping[mappingRole])
  )[0];

  return mainRole;
};

export const LiveViewTableColumns = {
  name: {
    label: "Agent name ",
    key: "name",
  },
  email: {
    label: "Agent email",
    key: "email",
  },
  status: {
    label: "Online Status",
    key: "loginStatus",
    type: "function",
    getValue: (v) => (
      <div className={`agent-status  ${v === true ? "active" : ""}`}></div>
    ),
  },
  firstActivity: {
    label: "First Activity",
    key: "firstActivity",
    type: "function",
    getValue: (v) => <div> {v ? new Date(v).toLocaleString() : "-"}</div>,
  },
  lastActivity: {
    label: "Last Activity",
    key: "lastActivity",
    type: "function",
    getValue: (v) => <div> {v ? new Date(v).toLocaleString() : "-"}</div>,
  },
  callStatus: {
    label: "Call Status",
    key: "callStatus",
  },
  callStartTime: {
    label: "Current call start time",
    key: "callStartTime",
    type: "function",
    getValue: (v) => <div> {v ? new Date(v).toLocaleString() : "-"}</div>,
  },
  currentOpportunityId: {
    label: " Opp. id working on ",
    key: "opportunityId",
  },
  campaign: {
    label: "campaign assigned (if any)",
    key: "campaign",
  },
};

export const AggregatedLiveViewColumn = {
  name: {
    label: "Agent name ",
    key: "name",
  },
  email: {
    label: "Agent email",
    key: "email",
  },
  status: {
    label: "Online Status",
    key: "loginStatus",
    type: "function",
    getValue: (v) => (
      <div className={`agent-status  ${v === true ? "active" : ""}`}></div>
    ),
  },
  totalAgents: {
    label: "Total Agents",
    key: "agentIds",
    type: "function",
    getValue: (v) => <div>{!v ? "--" : (v || []).length}</div>,
  },
  onLineAgents: {
    label: "Online Agents",
    key: "onlineAgentIds",
    type: "function",
    getValue: (v) => <div>{!v ? "--" : (v || []).length}</div>,
  },
  offLineAgents: {
    label: "Offline Agents",
    key: "offlineAgentIds",
    type: "function",
    getValue: (v) => <div>{!v ? "--" : (v || []).length}</div>,
  },
  onCallAgents: {
    label: "OnCall Agents",
    key: "onCallAgentIds",
    type: "function",
    getValue: (v) => <div>{!v ? "--" : (v || []).length}</div>,
  },
  idleAgents: {
    label: "Idle Agents",
    key: "idleAgentIds",
    type: "function",
    getValue: (v) => <div>{!v ? "--" : (v || []).length}</div>,
  },
};
