import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const DOCUMENTS = "DOCUMENTS";
export const DOCUMENT_DELETED = "DOCUMENT_DELETED";
export const DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED";
export const DOCUMENT_DUPLICATE = "DOCUMENT_DUPLICATE";
export const MORTGAGE_DOCUMENT_UPLOADED = "MORTGAGE_DOCUMENT_UPLOADED";
export const SUBMITTED_IN_MORTGAGE_DB = "SUBMITTED_IN_MORTGAGE_DB";

export const getDocuments = (type, id, domainId) => {
  return (dispatch) => {
    let url = `/pixie/data/v1/entity/document?objectId=${id}&objectType=${type}&domainId=${domainId}`;
    if (type == "Termsheet") url += "&documentType=SignedTermsheet";
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data.data;
        const documents = {};
        data.forEach((document) => {
          if (!documents[document.objectMediaType.displayName]) {
            documents[document.objectMediaType.displayName] = [];
          }
          documents[document.objectMediaType.displayName].push({
            id: document.id,
            url: document.absoluteUrl,
          });
        });
        dispatch({
          type: DOCUMENTS,
          documents,
        });
      })
      .catch(() => {
        dispatch({
          type: Constants.API_FAIL,
        });
      });
  };
};

export const deleteImage = (imageId) => {
  return (dispatch) => {
    const url = `/pixie/data/v1/entity/document/${imageId}`;
    return instance({
      url,
      method: "delete",
    })
      .then((response) => {
        dispatch({
          type: DOCUMENT_DELETED,
        });
      })
      .catch(() => {
        dispatch({
          type: Constants.API_FAIL,
        });
      });
  };
};
export const submitToMortgageDb = (data, id) => {
  return (dispatch) => {
    const url = `mortgage/api/v0/additional-docs-url/${id}`;
    return instance({
      url,
      method: "post",
      data,
    })
      .then(() => {
        dispatch({
          type: SUBMITTED_IN_MORTGAGE_DB,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const uploadFile = (docDetails) => {
  return (dispatch) => {
    const url = "/pixie/data/v1/entity/document";
    return instance({
      url,
      method: "post",
      data: docDetails,
    })
      .then((response) => {
        if (
          docDetails &&
          docDetails.get("objectType") === "MortgagePartner" &&
          response?.data
        ) {
          const absoluteUrl = response.data.absoluteUrl || "";
          dispatch({
            type: MORTGAGE_DOCUMENT_UPLOADED,
            payload: absoluteUrl,
          });
        } else {
          dispatch({
            type: DOCUMENT_UPLOADED,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status == 409) {
          dispatch({
            type: DOCUMENT_DUPLICATE,
          });
        }
      });
  };
};
