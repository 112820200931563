import { DUMMY_FILTER_KEY, excludeKeys, SEPARATOR } from "./data";

const filterMap = {
  cities: ({ value: citiesAndZonesData, ...rest }) => {
    const zoneFilter = {
      key: DUMMY_FILTER_KEY,
      name: "ZONE",
      type: "list",
      value: getZonalValues(citiesAndZonesData),
      multiple: false,
    };

    const cityFilter = {
      ...rest,
      value: getCitiesValues(citiesAndZonesData),
      isDependent: true,
      dependentOnKey: DUMMY_FILTER_KEY,
    };
    return [zoneFilter, cityFilter];
  },
};

export const parseCampaignFilters = (filters) => {
  return filters.reduce((acc, filter) => {
    const { key } = filter;
    if (filterMap[key]) {
      const parsedFilters = filterMap[key](filter);
      return [
        ...acc,
        ...(Array.isArray(parsedFilters) ? parsedFilters : [parsedFilters]),
      ];
    }
    return [...acc, filter];
  }, []);
};

const getZonalValues = (value) => {
  const zones = new Set();
  value.forEach(({ zone }) => {
    zones.add(zone);
  });
  return [...zones];
};

const getCitiesValues = (value) => {
  return value.map(({ city_id: id, label, state, zone }) => ({
    id,
    label: `${label}, ${state}`,
    dependencyValue: zone,
  }));
};

export const modifyCampaignFiltersRequestPayload = (filters) => {
  return Object.entries(filters).reduce((acc, [key, val]) => {
    if (excludeKeys.includes(key)) return acc;
    return {
      ...acc,
      [key]: val.map((value) =>
        value.includes(SEPARATOR) ? value.split(SEPARATOR)[0] : value
      ),
    };
  }, {});
};
