import {
  AGGREGATED_AGENTS_DATA,
  RESET_AGGREGATED_AGENTS_DATA,
} from "../actions/liveViewOfAgents.action";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case AGGREGATED_AGENTS_DATA:
      return {
        ...state,
        liveViewAggregatedData: action.payload,
      };
    case RESET_AGGREGATED_AGENTS_DATA:
      return {
        ...state,
        liveViewAggregatedData: undefined,
      };
  }
};

export default reducer;
