import { MIGRATION_CASES_INITIATED, MIGRATION_CASES_FAILED } from "../actions/migrationOfAgentsCases.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case MIGRATION_CASES_INITIATED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Owner Cases migration initiated",
          type: "success",
        },
      };
    case MIGRATION_CASES_FAILED:
    return {
    ...state,
    snackbar: {
        state: true,
        type: "error",
        duration: null,
        message:
          action.errMsg || "Some error occurred, please try after some time",
      }
    };
  }
};

export default reducer;
