import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const APPROVAL_LANDING_PAGE_DETAILS = "APPROVAL_LANDING_PAGE_DETAILS";
export const APPROVAL_LANDING_PAGE_DETAILS_ERROR =
  "APPROVAL_LANDING_PAGE_DETAILS_ERROR";

export const SUBMIT_APPROVAL_SUCCESS = "SUBMIT_APPROVAL_SUCCESS";
export const SUBMIT_APPROVAL_ERROR = "SUBMIT_APPROVAL_ERROR";
export const SUBMIT_APPROVAL_IN_PROGRESS = "SUBMIT_APPROVAL_IN_PROGRESS";
export const SUBMIT_REJECTION_IN_PROGRESS = "SUBMIT_REJECTION_IN_PROGRESS";

const approvalPageErrorMessages = {
  view: "You are not authorized to view Approval Details.",
  approve: "You are not authorized to approve/reject this approval.",
};

export const getApprovalLandingPageDetails = (entityId, approvalType) => {
  return (dispatch) => {
    const url = `sapna/v1/approval/package-more-details?entityId=${entityId}&approvalType=${approvalType}`;

    return instance({
      url,
      baseURL: "/",
    })
      .then((response) => {
        const data = response.data.data;
        dispatch({
          type: APPROVAL_LANDING_PAGE_DETAILS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: APPROVAL_LANDING_PAGE_DETAILS_ERROR,
          payload: Constants.getErrorMessage(err, approvalPageErrorMessages.view),
        });
      });
  };
};
export const submitApproval = (actionId, remark, type) => {
  return (dispatch) => {
    type === "approve"
      ? dispatch({ type: SUBMIT_APPROVAL_IN_PROGRESS, payload: true })
      : null;
    type === "reject"
      ? dispatch({ type: SUBMIT_REJECTION_IN_PROGRESS, payload: true })
      : null;

    const url = `/sapna/v2/task`;
    const data = {
      taskKey: actionId,
      remark,
    };
    return instance({
      url,
      method: "post",
      data,
    })
      .then(() => {
        dispatch({
          type: SUBMIT_APPROVAL_SUCCESS,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_APPROVAL_ERROR,
          payload: Constants.getErrorMessage(err, approvalPageErrorMessages.approve),
        });
      });
  };
};
export const resetSubmitApprovalSuccess = () => ({
  type: SUBMIT_APPROVAL_SUCCESS,
  payload: null,
});
export const resetSubmitApprovalError = () => ({
  type: SUBMIT_APPROVAL_ERROR,
  payload: null,
});
