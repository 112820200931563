import {
  GET_ACTIVE_CAMPAIGNS,
  GET_CAMPAIGN_FILTERS,
  SNACKBAR_STATUS,
} from "../actions/campaignModule.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_FILTERS:
      return {
        ...state,
        filterData: action.payload,
      };
    case GET_ACTIVE_CAMPAIGNS:
      return {
        ...state,
        activeCampaigns: action.payload,
      };
    case SNACKBAR_STATUS:
      return {
        ...state,
        snackbar: {
          state: true,
          type: action.status,
          duration: null,
          message: action.msg,
        },
      };
  }
};

export default reducer;
