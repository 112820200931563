import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const RESET_VT_FORM_DATA = "RESET_VT_FORM_DATA";
export const SET_VT_FORM_DATA = "SET_VT_FORM_DATA";
export const SET_VT_ALL_FORM_DATA = "SET_VT_ALL_FORM_DATA";
export const RESET_VT_LIST = "RESET_VT_LIST";
export const SET_VT_LIST = "SET_VT_LIST";
export const UPDATE_VT_DATA = "UPDATE_VT_DATA";

export const validateField = ({
  field,
  sectionKey,
  formData,
  questions,
  tourId,
  store,
}) => {
  const { validate, required, propKey } = field;
  let newField = { ...field };
  let hasError = false;
  if (required) {
    if (!formData[sectionKey] || !formData[sectionKey][propKey]) {
      newField.errorText = `This field is required.`; // `This field(${propKey}) is required in ${sectionKey}`
      hasError = true;
    }
  }
  if (validate && !newField.hasError) {
    let res = validate({
      field,
      formData,
      questions: questions,
      sectionKey,
      value: formData[sectionKey] && formData[sectionKey][propKey],
      store,
      tourId,
    });
    if (res !== true) {
      newField.errorText = res;
      hasError = true;
    }
  }
  return { newField, hasError };
};

export const validateDynamicField = ({
  field,
  sectionKey,
  formData,
  questions,
  dynamicArr,
  tourId,
  store,
}) => {
  const { validate, required, propKey } = field;
  let newField = { ...field, errorIndex: [] };
  let hasError = false;
  dynamicArr.forEach(({ [propKey]: value }, index) => {
    if (required) {
      if (!formData[sectionKey] || !value) {
        newField.errorText = `This field is required.`; // `This field(${propKey}) is required in ${sectionKey}`
        newField.errorIndex.push(index);
        hasError = true;
      }
    }
    if (validate && !newField.hasError) {
      let res = validate({
        field,
        value,
        formData,
        questions: questions,
        sectionKey,
        tourId,
        store,
      });
      if (res !== true) {
        newField.errorText = res;
        newField.errorIndex.push(index);
        hasError = true;
      }
    }
  });

  return { newField, hasError };
};

export const validateVirtualTourFormData = (formData, questions, tourId) => (
  dispatch,
  getState
) => {
  let store = getState();
  let formHasError = false;
  let newQuestions = questions.map((section) => {
    const { propKey: sectionKey, fields } = section;
    let hasError = false;
    let newFields = fields.map((field) => {
      let newField;
      if (field.type === "dynamic_v2") {
        // only check for first value
        newField = { ...field };
        let dynamicArr = formData[sectionKey] || [{}];
        newField.fields = newField.fields.map((field) => {
          const { newField: modifiedField, hasError: err } = validateDynamicField({
            field,
            dynamicArr,
            formData,
            sectionKey,
            questions,
            tourId,
            store,
          });
          if (err) {
            hasError = true;
          }
          return modifiedField;
        });
      } else {
        const { newField: modifiedField, hasError: err } = validateField({
          field,
          formData,
          sectionKey,
          questions,
          tourId,
          store,
        });
        newField = modifiedField;
        if (err) {
          hasError = true;
        }
      }
      return newField;
    });
    if (hasError) {
      formHasError = true;
    }
    return { ...section, error: hasError, fields: newFields };
  });
  return { newQuestions, hasError: formHasError };
};

export const resetFormData = () => (dispatch) =>
  dispatch({ type: RESET_VT_FORM_DATA });
export const setFormData = (section, data, index) => (dispatch) =>
  dispatch({
    type: SET_VT_FORM_DATA,
    payload: { section, data, index },
  });
function formatDate(d) {
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
}
export const formatDateToPickerString = (date) => {
  const [hour, minute] = date.toTimeString().split(" ")[0].split(":");
  return `${formatDate(date)}T${hour}:${minute}`;
};
const formatDateTime = (date) => {
  date = new Date(date);
  return `${formatDate(date)}T${date.toTimeString().split(" ")[0]}.000+0530`;
};
const createSlotApiData = (data) => {
  const {
    slotDetails: { opportunityId, amount, startTime, eventId },
    propertyDetails: {
      propertyId,
      service,
      profileType,
      profileUuid,
      sellingType,
      remarks,
    },
    pocDetails = [],
  } = data;
  return {
    payment_details: {
      amount: parseInt(amount),
    },
    products: {
      82: {
        polygon_uuids: "",
        profile_uuid: profileUuid,
        opportunity_id: opportunityId,
        event_id: eventId,
        start_date: formatDateTime(new Date()),
        profile_type: profileType,
        product_details: {
          webinar_start_date_time: formatDateTime(startTime),
          remarks,
          promoted_listing_id: propertyId,
          service_type: service,
          poc_details: pocDetails,
          selling_type: sellingType,
        },
      },
    },
  };
};

export const createSlot = (data) => (dispatch) => {
  const url = `${process.env.DOMAINS.AP}api/v0/create-video-tour-slot`;
  return instance({
    url,
    withCredentials: true,
    method: "POST",
    data: createSlotApiData(data),
  })
    .then(() => {})
    .catch((err) => {
      const errMsg = Constants.getErrorMessage(err);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
      return Promise.reject(errMsg);
    });
};
/*
* product_details => webinar_start_time, poc_details, remarks
package_details => status_id(9)
* */

export const getData = (id) => (dispatch, getState) => {
  const {
    virtualTour: { data: { [id]: dta } = {} },
  } = getState();
  let promise = Promise.resolve();
  if (!dta) {
    promise = dispatch(getList());
  }
  return promise.then(() => {
    const {
      virtualTour: { data: { [id]: dta } = {} },
    } = getState();
    if (!dta) {
      throw new Error("Data not present in store");
    }
    const {
      webinar_start_date_time: startTime,
      promoted_listing_id: propertyId,
      service_type: service,
      remarks,
      poc_details: pocDetails,
      event_id: eventId,
    } = dta;
    dispatch({
      type: SET_VT_ALL_FORM_DATA,
      payload: {
        slotDetails: { id, startTime, eventId },
        propertyDetails: { propertyId, service, remarks },
        pocDetails,
      },
    });
  });
};
export const updateSlot = (data) => (dispatch) => {
  const {
    slotDetails: { id, startTime, eventId } = {},
    propertyDetails: { remarks },
    pocDetails,
  } = data;
  const url = `${process.env.DOMAINS.ADVERT}/api/v0/update-video-tour-slot/${id}`;
  return instance({
    url,
    method: "PUT",
    data: {
      slot_id: parseInt(id),
      package_details: { event_id: eventId },
      promoted_products: {
        82: {
          product_details: {
            webinar_start_date_time: formatDateTime(startTime),
            poc_details: pocDetails,
            remarks,
          },
        },
      },
    },
  })
    .then(() => {})
    .catch((err) => {
      const errMsg = Constants.getErrorMessage(err);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
      return Promise.reject(errMsg);
    });
};

export const getList = () => (dispatch) => {
  const url = `/sapna/v1/housing/helper/14`;
  return instance({
    url,
    method: "GET",
  })
    .then(({ data: { data = [] } = {} }) => {
      const payload = data.reduce(
        (res, data, index) => {
          data.webinar_end_date_time = formatDateToPickerString(
            new Date(data.webinar_end_date_time)
          );
          data.webinar_start_date_time = formatDateToPickerString(
            new Date(data.webinar_start_date_time)
          );
          data.index = index;
          res.data[data.id] = data;
          res.list.push(data.id);
          return res;
        },
        { list: [], data: {} }
      );
      let { list, data: dta } = payload;
      list = list.sort();
      list.forEach((id, index) => {
        dta[id].index = index;
      });
      dispatch({ type: SET_VT_LIST, payload: { list, data: dta } });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
      return Promise.reject(errMsg);
    });
};

export const resetList = () => (dispatch) => dispatch({ type: RESET_VT_LIST });

export const cancelSlot = (data) => (dispatch) => {
  const { status_id: status, id } = data || {};
  if (status === 4 || status === 9) {
    return;
  }

  let newStatus = 4;
  let newStatusName = "Cancelled";

  if (new Date() > new Date(data.webinar_start_date_time)) {
    newStatus = 9;
    newStatusName = "Promotion Ended";
  }
  const url = `${process.env.DOMAINS.ADVERT}/api/v0/update-video-tour-slot/${id}`;
  return instance({
    url,
    method: "PUT",
    data: {
      slot_id: id,
      package_details: { status_id: newStatus },
    },
  })
    .then(() => {
      dispatch({
        type: UPDATE_VT_DATA,
        payload: { id, data: { status_id: newStatus, status_name: newStatusName } },
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
      return Promise.reject(errMsg);
    });
};
