import {
  RENEWAL_REPORT_DETAILS,
  SET_RENEWAL_REPORT_OFFLINE_FILTERS,
  SET_AGENT_WISE_OFFLINE_FILTERS,
  SET_RENEWAL_REPORT_USER_ID,
  SET_RENEWAL_ALERT_USER_ID,
  SET_MONTHLY_SUMMARY_AGENT_USER_ID,
  RENEWAL_SUMMARY_MONTH_WISE,
  RENEWAL_CHART_SUMMARY_MONTH_WISE,
  SET_RENEWAL_REPORT_SELECTED_MONTHS,
  SET_MONTHLY_SUMMARY_SELECTED_MONTHS,
  SET_MONTHLY_SUMMARY_AGENT_WISE_SELECTED_MONTHS,
  RENEWAL_SUMMARY_AGENT_WISE,
  RENEWAL_ALERT_DETAILS,
  SET_RENEWAL_ALERT_OFFLINE_FILTERS,
  SET_UPCOMING_RENEWALS_WEEKLY,
  SET_UPCOMING_RENEWALS_MONTHLY,
  SET_RENEWAL_REPORT_AGENT_LIST,
  SET_MONTHLY_SUMMARY_AGENT_LIST,
  SET_MONTHLY_SUMMARY_AGENT_WISE_AGENT_LIST,
  SET_RENEWAL_REPORT_DOWNLOAD_IN_PROGRESS,
  SET_RENEWAL_ALERT_AGENT_LIST,
  SET_SUMMARY_AGENT_WISE_DOWNLOAD_IN_PROGRESS,
  SET_RENEWAL_ALERT_DOWNLOAD_IN_PROGRESS,
  SET_ALL_CITIES,
  SET_RENEWAL_REPORT_AVERAGES,
  SET_MONTHLY_CHART_AVERAGES,
} from "../actions/renewalReport.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case RENEWAL_REPORT_DETAILS:
      return {
        ...state,
        renewalReportDetails: action.renewalReportDetails,
      };
    case SET_RENEWAL_REPORT_OFFLINE_FILTERS:
      return {
        ...state,
        renewalReportOfflineFilters: action.value,
      };
    case SET_AGENT_WISE_OFFLINE_FILTERS:
      return {
        ...state,
        agentWiseOfflineFilters: action.value,
      };
    case SET_RENEWAL_ALERT_OFFLINE_FILTERS:
      return {
        ...state,
        renewalAlertOfflineFilters: action.value,
      };
    case SET_RENEWAL_REPORT_USER_ID:
      return {
        ...state,
        renewalReportUserId: action.value,
      };
    case SET_RENEWAL_ALERT_USER_ID:
      return {
        ...state,
        renewalAlertUserId: action.value,
      };
    case SET_MONTHLY_SUMMARY_AGENT_USER_ID:
      return {
        ...state,
        monthlySummaryAgentUserId: action.value,
      };
    case RENEWAL_SUMMARY_MONTH_WISE:
      return {
        ...state,
        renewalSummaryMonthWise: action.renewalSummaryMonthWise,
      };
    case SET_RENEWAL_REPORT_SELECTED_MONTHS:
      return {
        ...state,
        renewalReportSelectedMonths: action.value,
      };
    case SET_MONTHLY_SUMMARY_SELECTED_MONTHS:
      return {
        ...state,
        monthlySummarySelectedMonths: action.value,
      };
    case SET_MONTHLY_SUMMARY_AGENT_WISE_SELECTED_MONTHS:
      return {
        ...state,
        monthlySummaryAgentWiseSelectedMonths: action.value,
      };
    case RENEWAL_SUMMARY_AGENT_WISE:
      return {
        ...state,
        renewalSummaryAgentWise: action.renewalSummaryAgentWise,
      };
    case RENEWAL_ALERT_DETAILS:
      return {
        ...state,
        renewalAlertDetails: action.renewalAlertDetails,
      };
    case SET_UPCOMING_RENEWALS_WEEKLY:
      return {
        ...state,
        upComingRenewals: {
          ...state.upComingRenewals,
          weekly: { data: action.renewalDetailsWeekly, error: action.errMsg },
        },
      };
    case SET_UPCOMING_RENEWALS_MONTHLY:
      return {
        ...state,
        upComingRenewals: {
          ...state.upComingRenewals,
          monthly: { data: action.renewalDetailsMonthly, error: action.errMsg },
        },
      };
    case SET_RENEWAL_REPORT_AGENT_LIST:
      return {
        ...state,
        renewalReportAgentList: action.value,
      };
    case SET_MONTHLY_SUMMARY_AGENT_LIST:
      return {
        ...state,
        monthlySummaryAgentList: action.value,
      };
    case SET_MONTHLY_SUMMARY_AGENT_WISE_AGENT_LIST:
      return {
        ...state,
        monthlySummaryAgentWiseAgentList: action.value,
      };
    case SET_RENEWAL_ALERT_AGENT_LIST:
      return {
        ...state,
        renewalAlertAgentList: action.value,
      };
    case RENEWAL_CHART_SUMMARY_MONTH_WISE:
      return {
        ...state,
        renewalChartSummaryMonthWise: action.renewalChartSummaryMonthWise,
      };
    case SET_RENEWAL_REPORT_DOWNLOAD_IN_PROGRESS:
      return {
        ...state,
        renewalReportDownloadInProgress: action.value,
      };
    case SET_SUMMARY_AGENT_WISE_DOWNLOAD_IN_PROGRESS:
      return {
        ...state,
        renewalSummaryAgentDownloadInProgress: action.value,
      };
    case SET_RENEWAL_ALERT_DOWNLOAD_IN_PROGRESS:
      return {
        ...state,
        renewalAlertDownloadInProgress: action.value,
      };
    case SET_ALL_CITIES:
      return {
        ...state,
        allCities: action.value,
      };
    case SET_RENEWAL_REPORT_AVERAGES:
      return {
        ...state,
        renewalReportAverages: action.value,
      };
    case SET_MONTHLY_CHART_AVERAGES:
      return {
        ...state,
        monthlyChartAverages: action.value,
      };
  }
};

export default reducer;
