import {
  ALL_CITY_LIST,
  SET_ST_LIST,
  RESET_ST_LIST,
  UPDATE_ST_DATA,
  RESET_ST_FORM_DATA,
  SET_SITETAKEOVER_DEDICATED_DATA,
  SET_ST_FORM_DATA,
  BUILDER_LIST,
  SELLER_LIST,
} from "../actions/siteTakeover.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ALL_CITY_LIST:
      return {
        ...state,
        allCities: action.allCities,
      };
    case BUILDER_LIST:
      return {
        ...state,
        builders: action.builders,
      };
    case SELLER_LIST:
      return {
        ...state,
        sellers: action.sellers,
      };
    case SET_ST_LIST: {
      return {
        ...state,
        siteTakeoverList: action.payload?.results || [],
        siteTakeoverTotalPages:
          action.payload.totalPages + (action.payload.hasNextPage ? 1 : 0),
      };
    }
    case RESET_ST_LIST: {
      return {
        ...state,
        siteTakeoverList: defaultState.siteTakeoverList,
      };
    }
    case UPDATE_ST_DATA: {
      return {
        ...state,
        siteTakeover: {
          ...state.siteTakeover,
          data: {
            ...state.siteTakeover.data,
            [action.payload.id]: {
              ...(state.siteTakeover.data[action.payload.id] || {}),
              ...action.payload.data,
            },
          },
        },
      };
    }
    case SET_ST_FORM_DATA: {
      const { section, data } = action.payload;
      return {
        ...state,
        siteTakeover: {
          ...state.siteTakeover,
          formData: {
            ...state.siteTakeover.formData,
            [section]: data,
          },
        },
      };
    }
    case RESET_ST_FORM_DATA: {
      return {
        ...state,
        siteTakeover: {
          ...state.siteTakeover,
          formData: {},
        },
      };
    }
    case SET_SITETAKEOVER_DEDICATED_DATA: {
      return {
        ...state,
        siteTakeover: {
          ...state.siteTakeover,
          formData: action.payload,
        },
      };
    }
  }
};

export default reducer;
