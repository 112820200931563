import { UPDATE_SALES_CLOSURE_DATE } from "../actions/salesClosureDate.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SALES_CLOSURE_DATE:
      return {
        ...state,
        salesClosureDateMap: {
          ...state.salesClosureDateMap,
          ...action.payload,
        },
      };
  }
};

export default reducer;
