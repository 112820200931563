const gaEventQueue = (function () {
  let queue = [];
  return {
    push: (gaEvent) => queue.push(gaEvent),
    clearQueue: () => {
      queue = [];
    },
    isEmpty: () => queue.length === 0,
    getQueue: () => queue,
  };
})();

export default gaEventQueue;
