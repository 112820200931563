import {
  getFormattedDate,
  getMicrositeProductId,
  micrositeDedicatedApiParser,
  micrositeSlotsParser,
} from "./micrositeUtils";

import { instance } from "../../lib/api";

export const RESET_MICROSITE_FORM_DATA = "RESET_MICROSITE_FORM_DATA";
export const SET_MICROSITE_FORM_DATA = "SET_MICROSITE_FORM_DATA";
export const RESET_MICROSITE_LIST = "RESET_MICROSITE_LIST";
export const SET_MICROSITE_LIST = "SET_MICROSITE_LIST";
export const CREATE_MICROSITE_SLOT = "CREATE_MICROSITE_SLOT";
export const GET_MICROSITES_LIST = "GET_MICROSITES_LIST";
export const GET_MICROSITE_DATA = "GET_MICROSITE_DATA";
export const SET_MICROSITE_DEDICATED_DATA = "SET_MICROSITE_DEDICATED_DATA";

export const resetMicrositeFormData = () => (dispatch) =>
  dispatch({ type: RESET_MICROSITE_FORM_DATA });
export const setMicrositeFormData = (section, data, index) => (dispatch) =>
  dispatch({
    type: SET_MICROSITE_FORM_DATA,
    payload: { section, data, index },
  });

export const validateMicrositeFormData = (data, questions) => {
  let errors = [];
  const productId = getMicrositeProductId();
  const {
    promoted_products: { [productId]: { product_details: formData } = {} } = {},
  } = data;
  const { theme } = formData;
  questions.forEach(({ propKey, validate }) => {
    let errMsg = "";
    if (!validate) return;
    if (propKey === "highlights" && theme === "impacto") {
      errMsg = validate(formData[propKey], formData["highlights_image"]);
      !!errMsg && errors.push(errMsg);
      return;
    }
    errMsg = validate(formData[propKey]);
    !!errMsg && errors.push(errMsg);
  });
  return errors;
};

export const validateCreateSlotData = (data) => {
  let errors = [];
  const productId = getMicrositeProductId();
  const { products } = data || {};
  const slotData = products[productId] || {};
  Object.keys(slotData).forEach((key) => {
    switch (key) {
      case "start_date":
        if (slotData["start_date"] === getFormattedDate(""))
          errors.push("Please enter start date");
        break;
      case "end_date":
        if (slotData["end_date"] === getFormattedDate(""))
          errors.push("Please enter end date");
        if (Date.parse(slotData["end_date"]) < Date.parse(slotData["start_date"])) {
          errors.push("Please enter end date greater than start date");
        }
        break;
      case "entity_id":
        if (!slotData["entity_id"]) errors.push("Please enter project ID");
        break;
      case "opportunity_id":
        if (!slotData["opportunity_id"]) errors.push("Please enter opportunity ID");
        break;
    }
  });
  return errors;
};

export const createMicrositeSlot = (data) => () => {
  const url = `${process.env.DOMAINS.ADVERT}/api/v0/create-builder-slots`;
  return instance({
    url,
    method: "POST",
    data,
    withCredentials: true,
  });
};

export const updateMicrositeSlot = (data) => () => {
  const { slot_id: slotId } = data;
  const url = `${process.env.DOMAINS.ADVERT}/api/v0/slots/update/${slotId}`;
  return instance({
    url,
    method: "PUT",
    data,
    withCredentials: true,
  });
};

export const getMicrositesList = () => (dispatch) => {
  const productId = getMicrositeProductId();
  const url = `${process.env.DOMAINS.ADVERT}/api/v2/get-all-promoted-entities?placeholder_ids=${productId}`;
  return instance({
    url,
    method: "GET",
  }).then(({ data: { data: { data = [] } } = {} }) => {
    const [{ entity_details: micrositeSlots }] = data;
    dispatch({
      type: SET_MICROSITE_LIST,
      payload: micrositeSlotsParser(micrositeSlots),
    });
  });
};

export const resetMicrositeList = () => (dispatch) =>
  dispatch({ type: RESET_MICROSITE_LIST });

// http://ap.internal.staging.housing.com/api/v0/slots/${slotId} - GET
// https://spacex.proptiger-ws.com/sapna/v1/housing/helper/8?slot_id=832713
export const getMicrositeData = (slotId) => (dispatch) => {
  const url = `/sapna/v1/housing/helper/8?slot_id=${slotId}`;
  return instance({
    url,
    method: "GET",
  }).then(({ data: { data = {} } = {} }) => {
    dispatch({
      type: SET_MICROSITE_DEDICATED_DATA,
      payload: micrositeDedicatedApiParser(data),
    });
  });
};

// http://images.internal.staging.housing.com/uploader/create_file
// https://spacex.proptiger-ws.com/sapna/v1/housing/helper/10
export const uploadImageToImageService = (slotId) => (data) => {
  const url = "/sapna/v1/housing/helper/10";
  const { attr_id, extension, md5, name } = data;
  const apiData = {
    upload_source: "web",
    re_upload: "true",
    authenticity_token: process.env.IMAGE_SERVICE_AUTH_TOKEN,
    housing_file: {
      attr_id,
      extension: extension || "jpg",
      md5,
      name,
      is_private: false,
      obj_id: slotId,
      status: "new",
    },
  };
  return instance({
    url,
    method: "POST",
    data: apiData,
  })
    .then(() => {
      return getImagesFromImageService({ slotId });
    })
    .catch(({ response: { data: { errors } = {} } = {} }) => {
      if (errors === "Tags Tags are compulsory") {
        return getImagesFromImageService({ slotId });
      }
    });
};

// http://images.internal.staging.housing.com/api/files/list
// https://spacex.proptiger-ws.com/sapna/v1/housing/helper/11
export const getImagesFromImageService = ({ slotId }) => {
  const url = `/sapna/v1/housing/helper/11?platform=desktop&source_name=WEB&housing_app_name=HousingAds&resource_name=Microsite&attr_name=microsite_images&obj_id=${slotId}`;
  return instance({
    url,
    method: "GET",
  }).then(({ data: { data = {} } = {} }) => {
    return data.map(({ absolute_url: absoluteUrl }) =>
      absoluteUrl.replace("version", "original")
    );
  });
};
