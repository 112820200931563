import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const NETSUIT_DATA_PUSH = "NETSUIT_DATA_PUSH";

export const netsuitdatapush = (domain, entityNm, id) => {
  return (dispatch) => {
    const url = `sapna/v1/erp/pushManualErpEvents?entityName=${entityNm}&domain=${domain}`;
    const array = JSON.parse("[" + id + "]");
    return instance({
      url,
      method: "POST",
      data: array,
    })
      .then((response) => {
        // const { data } = response.data;
        dispatch({
          type: NETSUIT_DATA_PUSH,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: "Unable to push data.",
        });
      });
  };
};
