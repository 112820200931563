import { getUserChildrenList } from "./userManagement.actions";
import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const MIGRATION_CASES_INITIATED = "MIGRATION_CASES_INITIATED";
export const MIGRATION_CASES_FAILED = "MIGRATION_CASES_FAILED";

export const getAgentsForMigration = () => {
  return (dispatch, getState) => {
    const { userInfo: { id } = {} } = getState();
    return dispatch(getUserChildrenList(id, false));
  };
};

export const migrateAgentsCases = ({ fromAgent, toAgent }) => {
  return (dispatch) => {
    const url = "/sapna/v1/opportunity/migration";
    const data = {
      newAgent: toAgent.userId,
      oldAgent: fromAgent.userId,
      reAssignAccount: false,
      assignedAt: null,
    };
    return instance({
      url,
      method: "put",
      data,
    })
      .then((res) => {
        dispatch({
          type: MIGRATION_CASES_INITIATED,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: MIGRATION_CASES_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
  };
};
