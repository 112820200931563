import {
  PRODUCTS,
  PRODUCTS_DEACTIVATED,
  PRODUCT_CREATED,
  PRODUCT_UPDATED,
  RESET_PRODUCT_UPDATION_STATUS,
} from "../actions/productManagement.actions";

const defaultState = {};
const productPackageFamilyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case PRODUCTS_DEACTIVATED:
    case PRODUCT_CREATED:
    case PRODUCT_UPDATED:
      return {
        ...state,
        productsModified: true,
        snackbar: {
          state: true,
          message: "Product Updated successfully",
          type: "success",
        },
      };
    case RESET_PRODUCT_UPDATION_STATUS:
      return {
        ...state,
        productsModified: false,
      };
  }
};

export default productPackageFamilyReducer;
