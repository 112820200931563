import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const NETSUIT_DATA_GET = "NETSUIT_DATA_GET";

export const erpEventDataGet = (domain, entityNm, id) => {
  return (dispatch) => {
    const url = `sapna/v1/erp/get-entity-records?entityName=${entityNm}&domain=${domain}`;
    const array = JSON.parse("[" + id + "]");

    return instance(url, {
      method: "POST",
      data: array,
    })
      .then((response) => {
        const { data: { data } = {} } = response;
        dispatch({
          type: NETSUIT_DATA_GET,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: NETSUIT_DATA_GET,
          payload: new Array(),
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetEventDataGet = () => {
  return (dispatch) => {
    return dispatch({
      type: NETSUIT_DATA_GET,
      payload: null,
    });
  };
};
