import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";
import getKeySearchPromises from "../../utils/getKeySearchPromises";

export const MANUAL_CALL = "MANUAL_CALL";
export const SEARCH_OPPORTUNITY = "SEARCH OPPORTUNITY";
export const CALL_INITIATED = "CallInitiated";

export const getCallResponse = (contactNumber, props, accountType) => {
  const sourceId = accountType == "Broker" ? 31 : 18;
  return (dispatch) => {
    const url = "/kira/app/v3/manual/callNow";
    return instance({
      method: "post",
      url,
      params: {
        sourceDomain: "Proptiger",
      },
      data: {
        toNo: contactNumber,
        sourceId,
        callType: "manual",
      },
      headers: {
        "Content-type": "application/json",
        Cookie: "XCP=424bf206-e977-40e5-aab1-4107322e7e6a",
      },
    })
      .then((response) => {
        const {
          data: {
            communicationId,
            providerSessionId,
            communicationLog: { receiverNumber, did },
          },
          statusCode,
        } = response.data;
        const manualCallData = {
          communicationId,
          sessionId: providerSessionId,
          receiverNumber,
          did,
        };
        dispatch({
          type: MANUAL_CALL,
          manualCallData,
        }); //add to store
        dispatch(searchOpportunity(contactNumber, props));
        return statusCode === "2XX" ? manualCallData : Promise.reject();
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const searchOpportunity = (contactNumber, props) => {
  const { router } = props;
  return (dispatch) => {
    const { url, jsonSelectors } = generateURL(true, contactNumber);
    const promise = getKeySearchPromises(url, jsonSelectors);
    return promise
      .then((response) => {
        let { results = [] } = (response && response[0]) || {};
        if (results.length) {
          dispatch({
            type: SEARCH_OPPORTUNITY,
            opportunitySearchData: {
              opportunityId: results[0].opportunityId,
              caseId: results[0].id,
            },
          });
          dispatch(createCallDetail(props));
        } else {
          const { url, jsonSelectors } = generateURL(false, contactNumber);
          const promise = getKeySearchPromises(url, jsonSelectors);
          return promise
            .then((response) => {
              let { results = [] } = response[0];
              if (results.length) {
                dispatch({
                  type: SEARCH_OPPORTUNITY,
                  opportunitySearchData: {
                    opportunityId: results[0].opportunityId,
                    caseId: results[0].id,
                  },
                });
                dispatch(createCallDetail(props));
              } else {
                router.navigate(`/opportunity-search`);
              }
            })
            .catch((err) => {
              dispatch({
                type: Constants.API_FAIL,
                errMsg: Constants.getErrorMessage(err),
              });
            });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const createCallDetail = (props) => {
  const { router } = props;
  return (dispatch, getState) => {
    const {
      manualCallData: {
        communicationId,
        sessionId,
        receiverNumber,
        did,
        opportunityId,
        caseId,
      },
      userInfo: { id },
    } = getState();
    const url = "/mystique/v1/create-call-detail";
    return instance({
      method: "post",
      url,
      data: {
        agentId: id,
        communicationId: communicationId,
        entityId: opportunityId,
        caseTypeId: 10,
        did: did,
        receiverNumber: receiverNumber,
        caseId: caseId,
        sid: sessionId,
      },
    })
      .then(() => {
        router.navigate(`/opportunity/${opportunityId}`);
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const callViaOpportunityId = (opportunityId) => (dispatch) => {
  const url = `/mystique/v1/cases/opportunity/${opportunityId}/call?type=SOCASE&subType=HousingOwner&callType=manual`;
  return instance({
    method: "get",
    url,
  })
    .then((response) => {
      const { statusCode, error } = response.data;
      if (!["200", "2XX"].includes(statusCode) && error) {
        return Promise.reject();
      }
      return response;
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};

const generateURL = (statusArr = false, contactNumber) => {
  if (statusArr) {
    return {
      url: "/mystique/v1/soc/key-search?salesOpportunitySelectorsArray=",
      jsonSelectors: `[{"team":true,"caseSubTypeId":13,"sort":[{"field":"opportunityId","sortOrder":"DESC"}],"paging":{"start":0,"rows":10},"filters":{"and":[{"equal":{"statusId":[1,57]}},{"equal":{"phoneNumbers":"${contactNumber}"}}]},"key":"BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER"}]`,
    };
  } else {
    return {
      url: "/mystique/v1/soc/key-search?salesOpportunitySelectorsArray=",
      jsonSelectors: `[{"team":true,"caseSubTypeId":13,"sort":[{"field":"opportunityId","sortOrder":"DESC"}],"paging":{"start":0,"rows":10},"filters":{"and":[{"equal":{"phoneNumbers":"${contactNumber}"}}]},"key":"BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER"}]`,
    };
  }
};
