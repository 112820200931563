import { getFormattedDate } from "./siteTakeoverUtils";
import constant from "../../utils/CommonConstants";
import { updateDuration } from "../../utils/dateUtils";

const DeviceMap = {
  desktop: 1,
  mobile_site: 2,
  amp: 3,
  android: 4,
  ios: 5,
};

export const storiesSlotsParser = (
  storiesSlotsData,
  projectMap = {},
  projectName
) => {
  if (Array.isArray(storiesSlotsData)) {
    return storiesSlotsData.map(
      ({
        slot_id: slotId,
        start_date: startDate,
        end_date: endDate,
        promoted_entity_type: promotedEntity = "New Projects",
        entity_id: entityId,
        opportunity_id: opportunityId,
        slot_status: slotStatus,
        product_type_id,
        entity_id: projectId,
        polygon_name: polygonName,
        builder_name: sellerUuid,
        ...rest
      }) => ({
        slotId,
        startDate,
        endDate,
        promotedEntity,
        opportunityId,
        entityId,
        slotStatus,
        productType: Object.values(constant.productTypes).reduce((res, item) => {
          const test = { ...res };
          test[item.value] = item.label;
          return test;
        }, {})[product_type_id],
        sellerUuid,
        projectId,
        polygonName,
        ...rest,
      })
    );
  }

  const {
    start_datetime,
    end_datetime,
    promoted_entity,
    slot_product_mappings,
    locality_details,
    devices = [],
    ...rest
  } = storiesSlotsData || {};

  const { promoted_entity_type_id, entity_id, supply_user_uuid } = promoted_entity;

  const audienceMaximizerId = projectMap["Video Stories"] || "";

  const {
    video_stories_thumbnail_url,
    video_stories_template_1_url,
    video_stories_template_2_url,
    video_stories_type: videoStoriesMaker,
    video_image_list: videoImageList,
    video_stories_language,
    video_stories_has_audio = false,
  } = slot_product_mappings[audienceMaximizerId] || {};
  const deviceResultMap = {};
  for (const key in DeviceMap) {
    deviceResultMap[key] = devices.includes(DeviceMap[key]);
  }

  return {
    slotDetails: {
      start_date: start_datetime,
      end_date: end_datetime,
      entity_type_id: promoted_entity_type_id,
      polygon_id: locality_details.map(
        ({ polygon_name: name, polygon_uuid: uuid }) => ({ name, uuid })
      ),
      video_stories_thumbnail_url,
      entity_id,
      supply_user_uuid,
      projectName,
      duration: updateDuration(start_datetime, end_datetime),
      is_live_anchor_video: !!videoStoriesMaker,
      video_stories_has_audio,
      ...rest,
    },
    deviceType: deviceResultMap,
    storiesVideo1: {
      video_stories_template_1_url,
    },
    storiesVideo2: {
      video_stories_template_2_url,
    },
    automatedVideo: {
      video_stories_type: videoStoriesMaker,
      video_image_list: videoImageList?.map((original) => ({
        medium: original?.replace("version", "medium"),
        original,
      })),
      video_stories_language,
    },
  };
};

export const createStoriesApiData = (
  formData = {},
  projectMap,
  update = false,
  slotId
) => {
  const {
    slotDetails: {
      entity_type_id,
      start_date,
      end_date,
      opportunity_id,
      inner_churn_radius,
      outer_churn_radius,
      supply_user_uuid,
      entity_id,
      product_type_id,
      event_id,
      product_activation_id,
      amount,
      organic_soft_target,
      organic_hard_target,
      notes,
      send_activation_mail = false,
      polygon_id,
      is_premium = false,
      is_locality_churn = false,
      video_stories_thumbnail_url,
      deliver_developer_broker_leads = true,
      is_live_anchor_video: isLiveAnchorVideo = false,
      video_stories_has_audio = false,
    } = {},
    deviceType = {},
    storiesVideo1,
    storiesVideo2,
    automatedVideo: {
      video_stories_type,
      video_image_list,
      video_stories_language,
    } = {},
  } = formData;
  const storiesId = projectMap["Video Stories"] || "";
  const { video_stories_template_1_url } = storiesVideo1 || {};
  const { video_stories_template_2_url } = storiesVideo2 || {};
  const devices = Object.keys(deviceType || [])
    .filter((key) => deviceType[key])
    .map((key) => DeviceMap[key]);
  const poly = (polygon_id || []).map(({ uuid }) => uuid).join(",");
  const imagesList = video_image_list || [];
  return {
    package_details: {
      entity_type_id,
      start_date: getFormattedDate(start_date),
      end_date: getFormattedDate(end_date),
      inner_churn_radius,
      outer_churn_radius,
      opportunity_id,
      supply_user_uuid,
      entity_id,
      devices,
      product_type_id,
      event_id,
      product_activation_id,
      amount,
      organic_soft_target,
      organic_hard_target,
      notes,
      send_activation_mail,
      deliver_developer_broker_leads,
      is_premium,
      is_locality_churn,
    },
    promoted_products: {
      [storiesId]: {
        polygon_id: poly,
        ...(!isLiveAnchorVideo
          ? {
              video_stories_template_1_url,
              video_stories_template_2_url,
              video_stories_thumbnail_url,
              video_stories_has_audio,
            }
          : {
              video_stories_type,
              video_image_list: imagesList.map(({ original }) => original),
              video_stories_language,
            }),
      },
    },
    ...(update && { slot_id: parseInt(slotId) }),
  };
};
export const validateCreateStoriesData = (
  apiData,
  questions,
  projectMap,
  isMelaOrJustbaatFlow
) => {
  const { package_details: slotDetails, promoted_products: promotedProducts } =
    apiData;
  const storiesId = projectMap["Video Stories"] || "";
  let errors = [];
  const {
    polygon_id: polygonId = "",
    video_stories_template_2_url,
    video_stories_template_1_url,
    video_stories_type: videoStoriesMaker,
    video_image_list: videoImageList,
    video_stories_language,
  } = promotedProducts[storiesId] || {};
  const questionFieldArray = questions.map((question) => question.fields).flat();
  for (let question of questionFieldArray) {
    if (question.required) {
      if (
        question.propKey === "start_date" &&
        slotDetails["start_date"] === getFormattedDate("")
      ) {
        errors.push(`Please enter ${question.title}`);
      } else if (
        question.propKey === "end_date" &&
        slotDetails["end_date"] === getFormattedDate("")
      ) {
        errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "polygon_id") {
        if (!polygonId) errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "video_stories_type" && isMelaOrJustbaatFlow) {
        if (!videoStoriesMaker) errors.push(`Please ${question.title}`);
      } else if (question.propKey === "video_image_list" && isMelaOrJustbaatFlow) {
        if (!videoImageList || videoImageList?.length < 5)
          errors.push(`Please enter ${question.title}`);
      } else if (
        question.propKey === "video_stories_language" &&
        isMelaOrJustbaatFlow
      ) {
        if (!(video_stories_language || video_stories_language === 0))
          errors.push(`Please enter ${question.title}`);
      } else if (slotDetails[question.propKey] === undefined) {
        errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "opportunity_id") {
        if (!slotDetails["opportunity_id"] || !slotDetails["opportunity_id"].length)
          errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "supply_user_uuid") {
        if (!slotDetails[question.propKey])
          errors.push(`Please enter ${question.title}`);
      }
    } else if (
      !isMelaOrJustbaatFlow &&
      (question.propKey === "video_stories_template_2_url" ||
        question.propKey === "video_stories_template_1_url")
    ) {
      if (
        !(video_stories_template_2_url || video_stories_template_1_url) &&
        errors.indexOf("Please upload video template 1 or 2") === -1
      ) {
        errors.push(`Please upload video template 1 or 2`);
      }
    }
  }

  if (getUtcTime(slotDetails["start_date"]) > getUtcTime(slotDetails["end_date"])) {
    errors.push("Start Date should be earlier than or equal to End Date");
  }

  return errors;
};

const getUtcTime = (date) => {
  const dateSplitArr = date.split("/");
  const utcFormatDate = `${dateSplitArr[1]}/${dateSplitArr[0]}/${dateSplitArr[2]}`;
  return new Date(utcFormatDate).getTime();
};

export const getSellerUuidFromList = (data = []) => {
  if (data && data.length > 0) {
    return (
      data
        //.filter(({ seller_label }) => seller_label === 'Developer')
        .map(({ profile_uuid, name }) => ({ uuid: profile_uuid, name }))
    );
  }
};

export const getImageListFromObject = (imagesObj, coverPhotoUrl) => {
  if (coverPhotoUrl) {
    const coverImage = {
      absolute_url: coverPhotoUrl,
      caption: "Cover Image",
    };
    imagesObj.brochureImages = imagesObj.brochureImages?.length
      ? [coverImage, ...imagesObj.brochureImages]
      : [coverImage];
  }

  const { verifiedImages } = imagesObj;
  delete imagesObj.verifiedImages;
  imagesObj.verifiedImages = verifiedImages;
  // moving verified images to the last

  return Object.values(imagesObj)
    .flatMap((item) => item || [])
    .filter((item) => item?.absolute_url)
    .map(({ absolute_url: original, caption }) => ({
      large: original.replace("version", "large"),
      medium: original.replace("version", "medium"),
      original,
      caption,
    }));
};
