import {
  PROJECT_LIST,
  UUID_LIST,
  GET_PROJECT_DETAIL_CHURN,
  GET_RESALE_RENT_PRICE_CHURN,
  LOCALITY_LIST,
  PROPERTY_TYPE_LIST,
  CONFIG_LIST,
  CHURN_RESPONSE,
  STOP_MANUAL_CHURN,
  CHURN_SCHEDULER_LIST,
  CHURN_SCHEDULER_LIST_APPEND,
  DRY_CHURN_RESPONSE,
  MULTI_DRY_CHURN_RESPONSE,
  DRY_CHURN_LOADING,
  SELLER_SEARCH_UUID_LIST,
  SELLER_OPERATIONAL_LOCALITY_LIST,
  PROJECT_UUIDS,
} from "../actions/churnScheduler.actions";

const defaultState = {
  isFetchingChurn: false,
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case PROJECT_LIST:
      return {
        ...state,
        projects: action.projects,
      };
    case UUID_LIST:
      return {
        ...state,
        uuids: action.uuids,
      };
    case GET_RESALE_RENT_PRICE_CHURN:
      return {
        ...state,
        resaleRentDetail: action.resaleRentDetail,
      };
    case GET_PROJECT_DETAIL_CHURN:
      return {
        ...state,
        projectDetail: action.projectDetail,
      };
    case LOCALITY_LIST:
      return {
        ...state,
        localitiesByCity: action.localitiesByCity,
      };
    case PROPERTY_TYPE_LIST:
      return {
        ...state,
        propertyType: action.propertyType,
      };
    case CONFIG_LIST:
      return {
        ...state,
        config: action.config,
      };

    case CHURN_RESPONSE:
      return {
        ...state,
        churnSchedulerResponse: action.churnSchedulerResponse,
        isFetchingChurn: false,
      };

    case CHURN_SCHEDULER_LIST:
      return {
        ...state,
        churnSchedulerList: action.churnSchedulerList,
        churnSchedulerListCount: action.churnSchedulerListCount,
        stopManualChurnResponse: false,
      };

    case CHURN_SCHEDULER_LIST_APPEND:
      return {
        ...state,
        churnSchedulerList: [
          ...state.churnSchedulerList,
          ...action.churnSchedulerList,
        ],
        churnSchedulerListCount: action.churnSchedulerListCount,
        stopManualChurnResponse: false,
      };

    case STOP_MANUAL_CHURN:
      return {
        ...state,
        stopManualChurnResponse: action.stopManualChurnResponse,
      };

    case DRY_CHURN_LOADING:
      return {
        ...state,
        isFetchingChurn: action.payload,
      };

    case DRY_CHURN_RESPONSE:
      return {
        ...state,
        dryRunChrunNumberResponse: action.dryRunChrunNumberResponse,
        isFetchingChurn: false,
      };

    case MULTI_DRY_CHURN_RESPONSE:
      return {
        ...state,
        dryRunChrunNumberResponse: action.dryRunChrunNumberResponse,
      };

    case PROJECT_UUIDS:
      return {
        ...state,
        sellerProjects: action.sellerProjects,
      };

    case SELLER_SEARCH_UUID_LIST:
      return {
        ...state,
        sellerUuids: { ...action.payload },
      };

    case SELLER_OPERATIONAL_LOCALITY_LIST:
      return {
        ...state,
        sellerOperationalLocalities: { ...action.payload },
      };
  }
};

export default reducer;
