import {
  DOCUMENTS,
  DOCUMENT_DELETED,
  DOCUMENT_UPLOADED,
  DOCUMENT_DUPLICATE,
  MORTGAGE_DOCUMENT_UPLOADED,
  SUBMITTED_IN_MORTGAGE_DB,
} from "../actions/documents.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      };
    case DOCUMENT_DELETED:
      return {
        ...state,
        documentStatus: {
          deleted: true,
        },
        snackbar: {
          state: true,
          message: "Document Deleted Successfully!",
          type: "success",
        },
      };
    case DOCUMENT_UPLOADED:
      return {
        ...state,
        documentStatus: {
          uploaded: true,
        },
        snackbar: {
          state: true,
          message: "Document Uploaded Successfully!",
          type: "success",
        },
      };
    case DOCUMENT_DUPLICATE:
      return {
        ...state,
        documentStatus: {
          duplicate: true,
        },
        snackbar: {
          state: true,
          message: "Document Already Exists!",
          type: "warning",
        },
      };
    case MORTGAGE_DOCUMENT_UPLOADED:
      return {
        ...state,
        documentStatus: {
          uploaded: true,
        },
        mortgageDocumentStatus: {
          uploaded: true,
          uploadedDocumentUrl: action.payload,
        },
        snackbar: {
          state: true,
          message: "Document Uploaded Successfully!",
          type: "success",
        },
      };
    case SUBMITTED_IN_MORTGAGE_DB:
      return {
        ...state,
        mortgageDocumentStatus: {
          uploaded: false,
        },
      };
  }
};

export default reducer;
