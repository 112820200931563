import {
  GET_MONETIZABLE_CITIES_SUCCESS,
  DELETE_MONETIZABLE_CITY_SUCCESS,
  DELETE_MONETIZABLE_CITY_RESET,
  SHOW_SNACKBAR,
  RESET_SNACKBAR,
  POST_MONETIZABLE_CITY_SUCCESS,
  POST_MONETIZABLE_CITY_RESET,
} from "../actions/monetizableCities.actions";

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_MONETIZABLE_CITIES_SUCCESS:
      return {
        ...state,
        monetizableCitiesList: action.payload,
      };
    case DELETE_MONETIZABLE_CITY_RESET:
      return {
        ...state,
        isMonetizableCityDeleted: false,
      };
    case DELETE_MONETIZABLE_CITY_SUCCESS:
      return {
        ...state,
        isMonetizableCityDeleted: true,
      };
    case POST_MONETIZABLE_CITY_RESET:
      return {
        ...state,
        isMonetizableCityAdded: false,
      };
    case POST_MONETIZABLE_CITY_SUCCESS:
      return {
        ...state,
        monetizableCitiesList: action.payload,
        isMonetizableCityAdded: true,
      };
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: {
          state: true,
          type: action.snackbarType || "success",
          duration: action.duration || null,
          message: action.msg || "Action successfully completed!",
        },
      };
    case RESET_SNACKBAR:
      return {
        ...state,
        snackbar: {},
      };
  }
};

export default reducer;
