import { instance } from "../../lib/api";
import { isBrokerTelesalesAgentUserManagement } from "../../utils/brokerTelesales";
import Constants from "../../utils/CommonConstants";
import { setSnackbar } from "./account.actions";

export const CHILD_USER_LIST = "CHILD_USER_LIST";
export const CHILD_USER_LIST_RESET = "CHILD_USER_LIST_RESET";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const MASTER_DESIGNATION = "MASTER_DESIGNATION";
export const MAKAAN_CITY_LIST_FORMATTED = "MAKAAN_CITY_LIST_FORMATTED";
export const AGENT_CITY_UPDATED = "AGENT_CITY_UPDATED";
export const AGENT_BRANCH_CITY_MAPPING = "AGENT_BRANCH_CITY_MAPPING";
export const GET_AGENT_PHONE = "GET_AGENT_PHONE";
export const AGENT_CONTACT_UPDATED = "AGENT_CONTACT_UPDATED";
export const MADROX_GET_CONTACT = "MADROX_GET_CONTACT";
export const MADROX_CONTACT_UPDATED = "MADROX_CONTACT_UPDATED";
export const ALL_CAMPAIGNS = "ALL_CAMPAIGNS";
export const CAMPAIGN_ADDED = "CAMPAIGN_ADDED";
export const GET_AGENT_CAMPAIGN = "GET_AGENT_CAMPAIGN";
export const SET_VIRTUAL_NUMBER_INFO = "SET_VIRTUAL_NUMBER_INFO";
export const VIRTUAL_NUMBER_RESSIGNED = "VIRTUAL_NUMBER_RESSIGNED";
export const USER_MANAGEMENT_DOWNLOAD_CSV = "USER_MANAGEMENT_DOWNLOAD_CSV";
export const HRIS_DETAILS_SUCCESS = "HRIS_DETAILS_SUCCESS";
export const HRIS_DETAILS_IN_PROGRESS = "HRIS_DETAILS_IN_PROGRESS";
export const HRIS_DETAILS_ERROR = "HRIS_DETAILS_ERROR";
export const CHILD_USER_LIST_FAIL = "CHILD_USER_LIST_FAIL";

const PUT = "PUT";
const POST = "POST";
const agentCityUrlMap = {
  [PUT]: "/sapna/v2/sales-agent-branch-city-mapping/update",
  [POST]: "/sapna/v2/sales-agent-branch-city-mapping",
};

const processRoles = (roles) => {
  const {
    additionalAdminRoles,
    additionalLkpAdminRoles,
    additionalRoleValueMapping,
  } = Constants;
  let filteredRoles = [];
  roles.forEach((role) => {
    if (
      additionalAdminRoles.includes(role) ||
      additionalLkpAdminRoles.includes(role)
    ) {
      filteredRoles = filteredRoles.concat(additionalRoleValueMapping[role]);
    } else {
      filteredRoles.push(role);
    }
  });
  return [...new Set(filteredRoles)];
};

export const markUserInactive = (data) => {
  // const url = `/madrox/data/v1/entity/company/company-users/${data.id}`;
  const url = `/sapna/v1/account/update-assigned-accounts/?userId=${data.userId}&agentCompanyUserId=${data.id}`;
  const dataObj = {};
  dataObj.id = data.id;
  dataObj.status = "Inactive";
  return (dispatch) => {
    return instance({
      url,
      method: "put",
      data: dataObj,
    })
      .then(() => {
        dispatch({
          type: USER_UPDATE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: USER_UPDATE_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
const submitCityDetail = (
  agentId,
  {
    salesBranchId,
    departmentId,
    subDepartmentId,
    agentCityIds,
    cityBranchMapping,
    region,
    tier,
    salesVertical,
    businessUnit,
    salesChannel,
    roleType,
    company,
  },
  method,
  dispatch
) => {
  let postDetails =
    method === POST
      ? [
          {
            agentId,
            salesBranchId,
            departmentId,
            subDepartmentId,
            agentCityIds,
            cityBranchMapping,
            region,
            tier,
            salesVertical,
            businessUnit,
            salesChannel,
            roleType,
            company,
          },
        ]
      : {
          departmentId,
          subDepartmentId,
          agentId,
          salesBranchId,
          agentCityIds,
          cityBranchMapping,
          region,
          tier,
          salesVertical,
          businessUnit,
          salesChannel,
          roleType,
          company,
        };
  return instance({
    url: agentCityUrlMap[method],
    method,
    data: postDetails,
  }).then(
    () => {
      dispatch({
        type: AGENT_CITY_UPDATED,
        errMsg: "Agent city updated",
      });
      getAgentCityBranchList(dispatch);
    },
    (err) => {
      dispatch({
        type: USER_UPDATE_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    }
  );
};

const submitCampaignDetail = (userId, campaigns, methodType, dispatch) => {
  const url = `mystique/v1/agent-campaign/agent/${userId}`;
  return instance({
    url,
    method: "POST",
    data: campaigns,
  })
    .then(() => {
      dispatch({
        type: CAMPAIGN_ADDED,
        payload: methodType === POST ? "Campaign Added" : "Campaigns Updated",
      });
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};
export const getAgentCampaigns = (agentId) => {
  const url = `/mystique/v1/agent-campaign/agent/${agentId}`;
  return (dispatch, getState) => {
    const { userInfo: { isHousingOwnerAgent } = {} } = getState();
    if (!isHousingOwnerAgent) {
      return;
    }
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        dispatch({
          type: GET_AGENT_CAMPAIGN,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const submitContactDetail = ({ userId, user, cityCallMethodType, dispatch }) => {
  let { phoneDetails } = user;
  let url, data;
  if (cityCallMethodType === POST) {
    url = `/sapna/v1/account/activate-calling?userId=${userId}&contactNumber=${phoneDetails.mobile}`;
  } else {
    url = `/mystique/v1/agentsphone?sourceId=18`;
    data = phoneDetails;
  }

  return instance({
    url,
    method: "put",
    data,
  }).then(
    () => {
      dispatch({
        type: AGENT_CONTACT_UPDATED,
        errMsg: "Agent contact updated.",
      });
      getOwnerAgentsPhone(dispatch);
    },
    (err) => {
      dispatch({
        type: USER_UPDATE_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    }
  );
};

export const getAgentCityBranchListAction = () => {
  return (dispatch) => {
    getAgentCityBranchList(dispatch);
  };
};

function getAgentCityBranchList(dispatch) {
  instance({
    url: "/sapna/v2/sales-agent-branch-city-mapping?start=0&rows=5000",
    method: "get",
  }).then(
    (result) => {
      if (result.data.data && result.data.data.length) {
        dispatch({
          type: AGENT_BRANCH_CITY_MAPPING,
          agentBranchCityMap: result.data.data.reduce((result, data) => {
            result[data.agentId] = {
              salesBranchId: data.salesBranchId,
              departmentId: data.departmentId,
              agentCityIds: data.agentCityIds,
              cityBranchMapping: data.cityBranchMapping,
              region: data.region,
              tier: data.tier,
              salesVertical: data.salesVertical,
              businessUnit: data.businessUnit,
              salesChannel: data.salesChannel,
              roleType: data.roleType,
              company: data.company,
            };
            return result;
          }, {}),
        });
      }
    },
    () => {
      dispatch({
        type: Constants.API_FAIL,
      });
    }
  );
}

export const deleteUserRoles = (rolesToDelete, userId) => {
  const url = `madrox/data/v1/entity/user/role`;
  return (dispatch) => {
    const data = {
      id: userId,
      roles: rolesToDelete,
    };

    return instance({
      url,
      method: "delete",
      data,
    })
      .then(() => {
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: "Roles Updated Successfully",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const submitAgentDetail = ({
  user,
  userInfo,
  companyId = Constants.companyId.housing,
  cityCallMethodType,
  isOwnerAgent = false,
  contactAlreadyExist,
}) => {
  return (dispatch) => {
    (user.id ? updateUserDetails : createUserDetails)(
      user,
      userInfo,
      companyId,
      dispatch
    ).then((result) => {
      const userId = cityCallMethodType === POST ? result.userId : user.userId;
      const isBrokerTelesalesAgent = isBrokerTelesalesAgentUserManagement(user.role);
      const {
        city: salesBranchId,
        department: departmentId,
        subDepartment: subDepartmentId,
        agentCityIds,
        campaign,
        cityBranchMapping,
        region,
        tier,
        salesVertical,
        businessUnit,
        salesChannel,
        roleType,
        company,
        role = [],
      } = user;

      result &&
        result.id &&
        (salesBranchId || departmentId || subDepartmentId || agentCityIds) &&
        submitCityDetail(
          userId,
          {
            salesBranchId,
            departmentId,
            subDepartmentId,
            agentCityIds,
            cityBranchMapping,
            region,
            tier,
            salesVertical,
            businessUnit,
            salesChannel,
            roleType,
            company,
          },
          cityCallMethodType,
          dispatch
        );
      if (campaign) {
        const methodType = user.id ? PUT : POST;
        const campaignIdsValid = campaign.length === 0 && methodType === POST;
        !campaignIdsValid &&
          isOwnerAgent &&
          !isBrokerTelesalesAgent &&
          submitCampaignDetail(userId, campaign, methodType, dispatch);
      }
      if ((role || []).indexOf(Constants.OOA) > -1 && contactAlreadyExist) {
        submitContactDetail({ userId, user, cityCallMethodType: "POST", dispatch });
      }
      if ((role || []).includes(Constants.BTTL) && user.designation == 38) {
        cityCallMethodType = contactAlreadyExist ? "PUT" : "POST";
        submitContactDetail({ userId, user, cityCallMethodType, dispatch });
      }
      if (isOwnerAgent) {
        cityCallMethodType = contactAlreadyExist ? "PUT" : "POST";
        submitContactDetail({ userId, user, cityCallMethodType, dispatch });
      }
    });
  };
};

const updateUserDetails = (user, ...args) => {
  if (user.id) {
    const dispatch = args && args[2];
    const url = `/madrox/data/v1/entity/company/company-users/${user.id}`;
    const dataObj = {};
    dataObj.email = user.email;
    dataObj.name = user.name;
    dataObj.parentId = user.manager;
    dataObj.designationId = user.designation;
    dataObj.userRoles = processRoles(user.role);
    return instance({
      url,
      method: "put",
      data: dataObj,
    }).then(
      () => {
        dispatch({
          type: USER_UPDATE_SUCCESS,
        });
        return user;
      },
      (err) => {
        dispatch({
          type: USER_UPDATE_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return false;
      }
    );
  }
};
const createUserDetails = (
  user,
  userInfo,
  companyId = Constants.companyId.housing,
  dispatch
) => {
  const url = "/madrox/data/v1/entity/company/company-users";
  const dataObj = {};
  dataObj.status = "Active";
  dataObj.email = user.email;
  dataObj.name = user.name;
  dataObj.companyId = companyId;
  dataObj.parentId = user.manager;
  dataObj.departmentId = 16;
  dataObj.user = {};
  dataObj.user.domainId = userInfo.domainId;
  dataObj.user.countryId = userInfo.countryId;
  dataObj.designationId = user.designation;
  dataObj.userRoles = ["Ticketing"].concat(processRoles(user.role));
  dataObj.joiningDate = user.dateOfJoining;
  dataObj.dateOfRelieving = user.dateOfRelieving;
  dataObj.employeeCode = user.employeeCode;
  return instance({
    url,
    method: "post",
    data: dataObj,
  }).then(
    (response) => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        msg: "User created successfully",
      });
      return response.data.data;
    },
    (err) => {
      dispatch({
        type: USER_UPDATE_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
      return false;
    }
  );
};

// function getAllNodeChildlist (childList = []) {
//   const ChildMap = {}
//   childList.forEach(user => {
//     if (ChildMap[user.parentId]) {
//       ChildMap[user.parentId].push(user.userId)
//     } else {
//       ChildMap[user.parentId] = [user.userId]
//     }
//   })
//   const allChildListMap = {}
//   Object.keys(ChildMap).forEach(userId => {
//     allChildListMap[userId] = allChildRecursive(ChildMap[userId], ChildMap)
//   })
//   return allChildListMap
// }

// function allChildRecursive (childs, ChildMap) {
//   if (!(childs && childs.length)) {
//     return []
//   } else {
//     const nextLevelChilds = childs.reduce((result, uid) => {
//       return result.concat(allChildRecursive(ChildMap[uid], ChildMap))
//     }, [])
//     return childs.concat(nextLevelChilds)
//   }
// }
const isIcrmUser = (user) => {
  const amoebaRolesToExclude = Constants.amoebaRoles;
  const lookupRolesToInclude = Constants.lookupRolesToInclude;

  const userRoles = user?.user?.userRoles;
  if (!userRoles) return false;

  const hasAmoebaRoles = userRoles.some((role) =>
    amoebaRolesToExclude.includes(role?.role?.name)
  );
  const hasLookupRoles = userRoles.some((role) =>
    lookupRolesToInclude.includes(role?.role?.name)
  );

  return hasAmoebaRoles && !hasLookupRoles;
};
const _getUserChildrenListUrl = (
  userId,
  apiVersion = "v1",
  {
    start = 0,
    rows = 10,
    agentId,
    email,
    // allStatus = true,
    // userAllStatus = true,
  } = {}
) => {
  let filter, optionalUrlParams;

  if (agentId) {
    filter = `{ "equal": { "agentIds": ["${agentId}"] } }`;
  } else if (email) {
    filter = `{ "equal": { "emailIds": ["${email}"] } }`;
  }
  const filters = filter ? `,"filters":{"and":[${filter}]}` : "";

  if (apiVersion === "v1") {
    optionalUrlParams = "";
  } else if (apiVersion === "v2") {
    optionalUrlParams = `,"paging":{"start":${start},"rows":${rows}}${filters}`;
  }

  const urlParamsStatus = "status=active";
  // const urlParamsAllStatus = `allStatus=${allStatus}`;
  // const urlParamsUserAllStatus = `userAllStatus=${userAllStatus}`;
  const urlParamFromCache = "fromCache=false";
  const urlParamUserId = `userId=${userId}`;
  const urlParamSelectorFields = [
    "userId",
    "designationId",
    "priority",
    "userRoles",
    "role",
    "roleId",
    "company",
    "email",
    "name",
    "parentId",
    "contactNumbers",
    "contactNumber",
    "user",
    "id",
    "status",
    "employeeCode",
    "joiningDate",
    "departmentId",
  ];
  const urlParamSelector = `selector={"fields":${JSON.stringify(
    urlParamSelectorFields
  )}${optionalUrlParams}}`;

  const urlParams = [
    // urlParamsAllStatus,
    // urlParamsUserAllStatus,
    urlParamsStatus,
    urlParamFromCache,
    urlParamUserId,
    urlParamSelector,
  ];

  return `/madrox/app/${apiVersion}/entity/child-list?${urlParams.join("&")}`;
};

export const getUserChildrenList = (
  userId,
  isMakaan,
  apiVersion = "v1",
  urlQueryParams
) => {
  const url = _getUserChildrenListUrl(userId, apiVersion, urlQueryParams);
  return (dispatch) => {
    dispatch({
      type: CHILD_USER_LIST_RESET,
      childUserList: null,
    });
    instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data = {} } = response.data || {};
        const childUserList = (apiVersion === "v1" ? data : data.results).filter(
          (user) => !isIcrmUser(user)
        );
        const childUserListTotalCount = childUserList.length;
        const managerRoleIds = isMakaan
          ? Constants.MakaanSalesManagerRoleIds
          : Constants.HousingSalesManagerRoleIds;
        const managerList = [];
        const userIdNameChildMapping = {};
        const userIdChildMap = {};
        const userEmpCodeMap = {};
        if (childUserList && childUserList.length) {
          childUserList.forEach((user) => {
            const { employeeCode = "" } = user;
            userIdNameChildMapping[user.userId] = user.name;
            userIdChildMap[user.userId] = user;
            if (employeeCode) {
              userEmpCodeMap[employeeCode] = user;
            }
            var userRoles = user.user ? user.user.userRoles : [];
            let managerAdded = false;
            if (userRoles && userRoles.length) {
              userRoles.forEach((role) => {
                if (
                  role.role &&
                  role.role.name &&
                  managerRoleIds.includes(role.role.name) &&
                  !managerAdded
                ) {
                  managerList.push(user);
                  managerAdded = true;
                }
              });
            }
          });
        }
        dispatch({
          type: CHILD_USER_LIST,
          childUserList,
          childUserListTotalCount,
          // allChildListMap: getAllNodeChildlist(childUserList),
          managerList,
          userIdNameChildMapping,
          userEmpCodeMap,
          userIdChildMap,
        });
        return childUserList;
      })
      .catch(() => {
        dispatch({
          type: CHILD_USER_LIST_FAIL,
        });
      });
  };
};

export const getMasterDesignations = (companyId = 611174) => {
  return (dispatch) => {
    return instance
      .get(`/madrox/data/v1/entity/company/${companyId}/designations`)
      .then((response) => {
        if (response.data && response.data.data && response.data.data.length) {
          const masterDesignations = [];
          const masterDesignationsMap = {};
          response.data.data.map(({ id: designationId, label }) => {
            masterDesignations.push({ designationId, label });
            masterDesignationsMap[designationId] = label;
          });
          dispatch({
            type: MASTER_DESIGNATION,
            masterDesignations,
            masterDesignationsMap,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getCitiesFormatted = () => {
  return (dispatch) => {
    const url = "/sapna/v1/master-sales-branches";
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        const makaanCitiesMap = {};
        const makaanCities = [];
        response.data.data.forEach(({ id, name }) => {
          makaanCities.push({ id, name });
          makaanCitiesMap[id] = name;
        });
        dispatch({
          type: MAKAAN_CITY_LIST_FORMATTED,
          makaanCities,
          makaanCitiesMap,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

function getOwnerAgentsPhone(dispatch) {
  const url = "/mystique/v1/agentsphone?sourceId=18"; // sourceId=18 for owner agents
  instance({
    url,
    method: "GET",
  })
    .then((response) => {
      const agentIdPhoneMap = {};
      response.data.data.forEach((f) => {
        agentIdPhoneMap[f.agentId] = f;
      });
      dispatch({
        type: GET_AGENT_PHONE,
        agentIdPhoneMap,
      });
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
}

export const getOwnerAgentsPhoneAction = () => {
  return (dispatch) => {
    getOwnerAgentsPhone(dispatch);
  };
};

export const getMadroxContactNumber = (userId) => (dispatch) => {
  const url = `madrox/data/v2/entity/user-details?userIds=${userId}&selector={"fields":["id","contactNumbers","contactNumber","priority"]}`;
  return instance({
    url,
    method: "GET",
  })
    .then((response) => {
      const { data: { data: [{ id, contactNumbers = [] } = {}] = [] } = {} } =
        response;
      const madroxContactNumber = contactNumbers.find(
        (contact) => contact.priority === 1
      );
      dispatch({
        type: MADROX_GET_CONTACT,
        madroxContactNumberUserDetails: {
          contactNumber: madroxContactNumber
            ? madroxContactNumber.contactNumber
            : "",
          id,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};

export const updateMadroxContactNumber = ({ id: userId, contactNumber }) => {
  const url = "madrox/app/v2/entity/update-contact-details?priority=1";
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data: {
        userId,
        contactNumber,
      },
    })
      .then(() => {
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          snackBarType: MADROX_CONTACT_UPDATED,
          msg: "Contact updated Successfully!",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getCampaigns = () => {
  const url = `/mystique/v1/agent-campaign`;
  return (dispatch, getState) => {
    const { userInfo: { isHousingOwnerAgent } = {} } = getState();
    if (!isHousingOwnerAgent) {
      return;
    }
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: ALL_CAMPAIGNS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          payload: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getVirtualNumber = (virtualNumberId) => {
  const url = `/kira/data/v1/entity/virtual-number?filters=(id==${virtualNumberId})&start=0&rows=1`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const [{ number }] = response.data.data || [{}];
        number
          ? dispatch(setVirtualNumberInfo({ number }))
          : dispatch(setSnackbar("error", "No virtual Number present"));
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          payload: Constants.getErrorMessage(err),
        });
      });
  };
};

export const setVirtualNumberInfo =
  (payload = undefined) =>
  (dispatch) =>
    dispatch({
      type: SET_VIRTUAL_NUMBER_INFO,
      payload,
    });

export const reassignVirtualNumber = (agentId, caseSubType) => {
  let finalCaseSubType = caseSubType || "HousingOwner";
  const url = `mystique/v1/agentsphone/vn-reallocate/${agentId}?caseType=SOCASE&caseSubType=${finalCaseSubType}`;
  return (dispatch) => {
    return instance({
      url,
      method: "post",
    })
      .then(() => {
        dispatch({
          type: VIRTUAL_NUMBER_RESSIGNED,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          payload: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getHrisDetails = (employeeCode) => {
  const url = `madrox/data/v1/hris-user-details/${employeeCode}`;
  return (dispatch) => {
    dispatch({
      type: HRIS_DETAILS_IN_PROGRESS,
      payload: true,
      employeeCode,
    });

    return instance({
      url,
    })
      .then((response) => {
        const {
          data: { data: { data: [employeeData = null] = [] } = {} },
        } = response;
        if (employeeData) {
          dispatch({
            type: HRIS_DETAILS_SUCCESS,
            payload: employeeData,
            employeeCode,
          });
        } else {
          dispatch({
            type: HRIS_DETAILS_ERROR,
            payload: "No employee exists with this id",
            employeeCode,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: HRIS_DETAILS_ERROR,
          payload: Constants.getErrorMessage(err),
          employeeCode,
        });
      });
  };
};
