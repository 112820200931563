import { instance } from '../../lib/api';
import Constants from '../../utils/CommonConstants';

export const UPDATE_SALES_CLOSURE_DATE = 'UPDATE_SALES_CLOSURE_DATE';


export const getSalesClosureDate = (month, year) => {
    return (dispatch) => {
        const url = `/sapna/v1/sales-month-closure-date?month=${month}&year=${year}`;
        return instance({
            url,
            method: 'GET'
            }).then((response) => {
                const { data } = response.data
                dispatch({
                    type: UPDATE_SALES_CLOSURE_DATE,
                    payload: {
                        [`${data.monthNumber}_${data.year}`]: data.closureDate
                    }
                });
            }).catch((err) => {
                dispatch({
                    type: Constants.API_FAIL,
                    errMsg: Constants.getErrorMessage(err)
                });
        });
    };
};

export const setSalesClosureDate = (data) => {
    return (dispatch) => {
        const url = `/sapna/v1/sales-month-closure-date`;
        return instance({
            url,
            method: 'POST',
            data
            }).then((response) => {
                const { data } = response.data
                dispatch({
                    type: UPDATE_SALES_CLOSURE_DATE,
                    payload: {
                        [`${data.monthNumber}_${data.year}`]: data.closureDate
                    }
                });
            }).catch((err) => {
                dispatch({
                    type: Constants.API_FAIL,
                    errMsg: Constants.getErrorMessage(err)
                });
        });
    };
};