import { useRef, useEffect } from "react";

// can be used to get previous value of state and props in functional components
// it is used to replicate the functionality of componentWillReceiveProps/componentDidUpdate lifecycle method
const usePrevious = (value) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
