import { instance } from "../lib/api";

export default (url, jsonSelectors, batchSize = 5) => {
  const promises = [];
  try {
    const selectors = JSON.parse(jsonSelectors);
    for (let i = 0; i < selectors.length; i += batchSize) {
      const batch = selectors.slice(i, i + batchSize);
      const batchPromise = instance({
        method: "get",
        url: `${url}${JSON.stringify(batch)}`,
      });
      promises.push(batchPromise);
    }
    return Promise.all(promises).then((values) => {
      return values.reduce((acc, val) => {
        return acc.concat(val.data.data);
      }, []);
    });
    // eslint-disable-next-line brace-style
  } catch (e) {
    throw new Error(`Error in getKeySearchPromises: ${e.message}`);
  }
};
