import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";

export const RESET_AD_PRODUCT_CONFIG_FORM_DATA = "RESET_AD_PRODUCT_CONFIG_FORM_DATA";
export const SET_AD_PRODUCT_CONFIG_FORM_DATA = "SET_AD_PRODUCT_CONFIG_FORM_DATA";
export const UPDATE_AD_PRODUCT_CONFIG = "UPDATE_AD_PRODUCT_CONFIG";

const showError = (err, dispatch) => {
  {
    return dispatch({
      type: Constants.API_FAIL,
      errMsg: Constants.getErrorMessage(err),
    });
  }
};
export const resetAdProductConfigFormData = () => (dispatch) =>
  dispatch({ type: RESET_AD_PRODUCT_CONFIG_FORM_DATA });
export const setAdProductConfigFormData = (section, data) => (dispatch) =>
  dispatch({
    type: SET_AD_PRODUCT_CONFIG_FORM_DATA,
    payload: { section, data },
  });

export const validateAdProductConfigFormData = (data, questions) => {
  let errors = [];
  questions.forEach(({ propKey, validate }) => {
    let errMsg = "";
    if (!validate) return;
    errMsg = validate(data[propKey]);
    !!errMsg && errors.push(errMsg);
  });
  return errors;
};

export const updateAdProductConfig = (data) => (dispatch) => {
  const url = `${process.env.DOMAINS.AP}api/v0/product-configuration-details`;
  return instance({
    url,
    method: "POST",
    withCredentials: true,
    data,
  })
    .then(() => {
      dispatch({ type: UPDATE_AD_PRODUCT_CONFIG });
    })
    .catch((err) => showError(err, dispatch));
};

export const getAdProductConfigData = (section = "virtual_tour_configuration") => (
  dispatch
) => {
  const url = `${process.env.DOMAINS.AP}api/v0/product-configuration-details`;
  return instance({
    url,
    withCredentials: true,
    method: "GET",
  })
    .then((res) => {
      const { data } = res;
      dispatch({
        type: SET_AD_PRODUCT_CONFIG_FORM_DATA,
        payload: { section, data },
      });
    })
    .catch((err) => showError(err, dispatch));
};
