import Constants from "../utils/CommonConstants";

export const isBrokerTelesalesAgent = (userInfo) => {
  return userInfo?.roles?.includes(Constants.brokerTelesalesAgentRole);
};

export const isBrokerTelesalesAgentUserManagement = (userRoles) => {
  return (
    userRoles?.includes("Broker Tele-Sales") ||
    userRoles?.includes("Broker Tele-Sales Team Lead") ||
    userRoles?.includes("Broker Tele-Sales Process Manager") ||
    userRoles?.includes("Broker Tele-Sales Business Manager")
  );
};
