import {
  GOT_COMMENTS,
  GET_COMMENTS_FAILED,
  GET_TERMINAL_COMMENTS,
  COMMENT_ADDED,
  COMMENT_FAILED,
  COMMENT_RESET,
  COMMENT_USERS,
  GOT_CALL_CATEGORY,
  GET_CALL_CATEGORY_FAILED,
} from "../actions/comment.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case GOT_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case GET_TERMINAL_COMMENTS:
      return {
        ...state,
        terminalComments: action.payload,
      };
    case GET_COMMENTS_FAILED:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Fetching comments failed!",
        },
      };
    case COMMENT_USERS:
      return {
        ...state,
        bottomCommentUsers: action.payload,
      };
    case COMMENT_ADDED:
      return {
        ...state,
        commentAdded: true,
        snackbar: {
          state: true,
          message: "Comment added!",
          type: "success",
        },
      };
    case COMMENT_FAILED:
      return {
        ...state,
        commentFailed: true,
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg,
        },
      };
    case COMMENT_RESET:
      return {
        ...state,
        commentAdded: null,
        commentFailed: null,
      };
    case GOT_CALL_CATEGORY:
      return {
        ...state,
        callCategory: action.payload,
      };
    case GET_CALL_CATEGORY_FAILED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: action.message || "Fetching call status failed!",
          type: "error",
        },
      };
  }
};

export default reducer;
