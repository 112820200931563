import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const CITY_LOCALITIES = "CITY_LOCALITIES";
export const REGION_CREATE_SUCCESS = "REGION_CREATE_SUCCESS";
export const REGION_DELETE_SUCCESS = "REGION_DELETE_SUCCESS";
export const GOT_MANAGE_REGIONS = "GOT_MANAGE_REGIONS";

export const getLocalities = (cityUuid, text) => {
  text = text.trim();

  const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/suggest?service_type=leads&feature_type=locality&polygon_uuid=${cityUuid}&input=${text}`;
  return (dispatch) => {
    if (!text) {
      dispatch({
        type: CITY_LOCALITIES,
        cityLocalities: [],
      });
      return;
    }
    instance({
      url,
      method: "GET",
    })
      .then((response) => {
        dispatch({
          type: CITY_LOCALITIES,
          cityLocalities: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "API_FAIL",
        });
      });
  };
};

export const createNewRegion = (data, id) => {
  const url = id ? `/sapna/v1/region/${id}` : "/sapna/v1/region";
  return (dispatch) => {
    instance({
      url,
      data,
      method: id ? "PUT" : "POST",
    })
      .then(() => {
        dispatch({
          type: REGION_CREATE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: "API_FAIL",
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const deleteRegion = (id) => {
  const url = `/sapna/v1/region/${id}`;
  return (dispatch) => {
    instance({
      url,
      method: "DELETE",
    })
      .then(() => {
        dispatch({
          type: REGION_DELETE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: "API_FAIL",
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getRegionsForCity = (id) => {
  const url = `/sapna/v1/region?filters=cityId==${id}`;
  return (dispatch) => {
    instance({
      url,
      method: "GET",
    })
      .then((response) => {
        const { data: { data } = {} } = response;
        const localitiesNameIdMapByManageRegions = data.reduce((acc, currRegion) => {
          const { localityRegionMappings = [] } = currRegion;
          const localities = localityRegionMappings.reduce(
            (innerAcc, currLocality) => {
              const { localityName, localityId } = currLocality;
              innerAcc[localityName] = localityId;
              return innerAcc;
            },
            {}
          );
          acc = { ...localities, ...acc };
          return acc;
        }, {});
        dispatch({
          type: GOT_MANAGE_REGIONS,
          payload: data,
          localitiesNameIdMapByManageRegions,
        });
      })
      .catch(() => {
        dispatch({
          type: "API_FAIL",
        });
      });
  };
};
