import { getNestedAgents, setAgentsData } from "./performance.actions";
import { getOwnerUserStatus, getMainRole } from "./login.actions";
import Constants from "../../utils/CommonConstants";
import { checkLoggedInUserRole } from "../../containers/LiveViewOfAgents/utils";
import { paddingForCalendar } from "../../utils/dateUtils";
import { instance } from "../../lib/api";
import { intersection, difference } from "../../utils/arrayUtils";

export const AGGREGATED_AGENTS_DATA = "AGGREGATED_AGENTS_DATA";
export const RESET_AGGREGATED_AGENTS_DATA = "RESET_AGGREGATED_AGENTS_DATA";

export const keyValueMap = (values, key) => {
  const obj = {};
  (values || []).forEach((res) => {
    obj[res[key]] = res;
  });

  return obj;
};

const processData = ([
  agents,
  loginStatusData = [],
  callStatusData = [],
  aggregatedData,
]) => {
  return (dispatch, getState) => {
    const {
      variousConfig: { ownerAgentStatusMapping: { Oncall, Online } = {} } = {},
      userInfo: { id, email, roles, firstName, lastName } = {},
      liveViewAggregatedData = {},
    } = getState();
    const loginData = keyValueMap(loginStatusData, "agentId");
    const callData = keyValueMap(callStatusData, "agentId");
    const mainRole = dispatch(getMainRole());
    if (mainRole === Constants.TSA || mainRole === Constants.BTS) {
      agents.push({
        userId: id,
        email,
        roles,
        name: (firstName || "") + (lastName || ""),
      });
    }

    let aggregatedDataValues = aggregatedData || liveViewAggregatedData;

    const onlineAgentIds = (aggregatedDataValues[id] || []).onlineAgentIds || [];
    const offlineAgentIds = difference(
      (aggregatedDataValues[id] || []).childIds,
      onlineAgentIds
    );

    const onCallAgentIds = (aggregatedDataValues[id] || []).onCallIds || [];
    const idleAgentIds = difference(
      (aggregatedDataValues[id] || []).childIds,
      onCallAgentIds
    );
    agents.forEach((res) => {
      loginData[res.userId] &&
        ((res["loginStatus"] = loginData[res.userId].statusId === Online),
        (res["firstActivity"] = loginData[res.userId].firstActivity),
        (res["lastActivity"] = loginData[res.userId].lastActivity),
        (res["activeActivityTime"] = loginData[res.userId].activeActivityTime));
      callData[res.userId] &&
        ((res["callStatus"] =
          callData[res.userId].statusId === Oncall ? "OnCall" : "Idle"),
        (res["callStartTime"] = callData[res.userId].callStartTime),
        (res["opportunityId"] = callData[res.userId].opportunityId),
        (res["communicationId"] = callData[res.userId].communicationId));

      aggregatedDataValues[res.userId] &&
        aggregatedDataValues[res.userId].childIds &&
        ((res["onlineAgentIds"] = intersection(
          aggregatedDataValues[res.userId].childIds,
          onlineAgentIds
        )),
        (res["offlineAgentIds"] = intersection(
          aggregatedDataValues[res.userId].childIds,
          offlineAgentIds
        )),
        (res["onCallAgentIds"] = intersection(
          aggregatedDataValues[res.userId].childIds,
          onCallAgentIds
        )),
        (res["idleAgentIds"] = intersection(
          aggregatedDataValues[res.userId].childIds,
          idleAgentIds
        )),
        (res["agentIds"] = aggregatedDataValues[res.userId].childIds));
    });
    return agents;
  };
};

export const resetLiveViewAggregatedData = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_AGGREGATED_AGENTS_DATA,
    });
  };
};

export const getLiveViewOfAgents = ({
  id,
  filter,
  role,
  type,
  agentTypeFilter,
  accountType,
}) => {
  return (dispatch, getState) => {
    const { liveViewAggregatedData } = getState();
    const aggregatedFlag =
      !liveViewAggregatedData &&
      [
        Constants.HOM,
        Constants.adminRole,
        Constants.TSPO,
        Constants.BTPM,
        "admin-broker",
        "admin-owner",
      ].includes(role);

    return Promise.all([
      dispatch(getNestedAgents({ id, filter })),
      dispatch(
        getOwnerUserStatus({ team: true, agentId: id, agentTypeFilter, accountType })
      ),
      dispatch(
        getOwnerUserStatus({
          team: true,
          agentId: id,
          activityCaseType: "CALL",
          agentTypeFilter,
          accountType,
        })
      ),
      aggregatedFlag && dispatch(getAggregatedData(accountType)),
    ]).then((response) => {
      const data = dispatch(processData(response));
      dispatch(setAgentsData({ type, role, managerChildren: data }));
    });
  };
};

const parseLiveViewAggregatedData = (
  data,
  status,
  aggregatedAgents = {},
  parent = []
) => {
  const { child, ...rest } = data;
  const childIds = (child || []).map((res) => res.userId);
  const { Online, Oncall } = status;
  const onlineAgentIds = [];
  const onCallIds = [];

  for (let i = 0; i < (child || []).length; i++) {
    child[i] &&
      child[i].activitiesByActivityCaseTypes &&
      child[i].activitiesByActivityCaseTypes.LOGIN &&
      child[i].activitiesByActivityCaseTypes.LOGIN[0] &&
      child[i].activitiesByActivityCaseTypes.LOGIN[0].statusId === Online &&
      onlineAgentIds.push(child[i].userId);
    child[i] &&
      child[i].activitiesByActivityCaseTypes &&
      child[i].activitiesByActivityCaseTypes.CALL &&
      child[i].activitiesByActivityCaseTypes.CALL[0] &&
      child[i].activitiesByActivityCaseTypes.CALL[0].statusId === Oncall &&
      onCallIds.push(child[i].userId);

    const {
      [child[i].userId]: {
        childIds: nestedChildIds,
        onlineAgentIds: nestedOnlineChildIds,
        onCallIds: nestedOnCallIds,
      },
    } = parseLiveViewAggregatedData(child[i], status, aggregatedAgents, [
      data.userId,
      ...parent,
    ]);
    childIds.push(...nestedChildIds);
    onlineAgentIds.push(...nestedOnlineChildIds);
    onCallIds.push(...nestedOnCallIds);
  }

  aggregatedAgents[data.userId] = {
    ...rest,
    child,
    childIds,
    onlineAgentIds,
    onCallIds,
    parent,
  };

  return aggregatedAgents;
};

export const getAggregatedData = (accountType = "Owner") => {
  return (dispatch, getState) => {
    const today = new Date();

    const stringDateFormat = `${today.getFullYear()}-${paddingForCalendar(
      today.getMonth() + 1
    )}-${paddingForCalendar(today.getDate())}`;

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const activityUserHierarchySelector = {
      caseSubType: accountType === "Owner" ? "HousingOwner" : "Housing",
      startDate: stringDateFormat,
      endDate: stringDateFormat,
      timeZone: timezone,
      activityCaseTypes: ["LOGIN", "CALL"],
    };
    const url = `/mystique/v0/activity/user-hierarchy?activityUserHierarchySelector=${JSON.stringify(
      activityUserHierarchySelector
    )}`;

    return instance({
      url,
      method: "get",
    })
      .then((res) => {
        const {
          variousConfig: { ownerAgentStatusMapping: { Online, Oncall } = {} } = {},
        } = getState();

        const liveViewAggregatedData = parseLiveViewAggregatedData(res.data.data, {
          Online,
          Oncall,
        });

        dispatch({
          type: AGGREGATED_AGENTS_DATA,
          payload: liveViewAggregatedData,
        });
        return liveViewAggregatedData;
      })
      .catch(() => {});
  };
};

export const checkAgentIsLoaded = (agentId, localState) => {
  return (_, getState) => {
    const {
      variousConfig: { ownerAgentStatusMapping: { Online, Oncall } = {} } = {},
      teamLeaders,
      processManagers,
      agents,
      userInfo: { roles = [] } = {},
      liveViewAggregatedData = {},
    } = getState();
    const signedInRole = checkLoggedInUserRole(roles);
    const parents = (liveViewAggregatedData[agentId] || {}).parent || [];

    const { teamLeader, processManager } = localState;
    let agentsArray = teamLeader
      ? agents
      : processManager
      ? teamLeaders
      : processManagers;
    if (
      [Constants.TSA, Constants.TSTL, Constants.BTS, Constants.BTTL].includes(
        signedInRole
      )
    ) {
      agentsArray = agents;
    }

    if (
      (signedInRole === Constants.TSPO || signedInRole === Constants.BTPM) &&
      !teamLeader
    ) {
      agentsArray = teamLeaders;
    }
    const agentIds = agentsArray.map((res) => res.userId);
    let currentParent = null;
    const inViewParentId =
      parents.length && parents.find((parent) => agentIds.indexOf(parent) > -1);
    if (inViewParentId > 0) {
      currentParent = agentsArray.find((res) => res.userId === inViewParentId);
    }
    return {
      agentNotPresent: agentIds.indexOf(agentId) === -1,
      currentParent,
      Online,
      Oncall,
    };
  };
};

export const updateAggregatedData =
  ({ userId, activityCaseType, callStatus, loginStatus }) =>
  (dispatch, getState) => {
    const {
      userInfo: { id },
      liveViewAggregatedData,
    } = getState();

    const updatedData = { ...liveViewAggregatedData };
    if (((updatedData[id] || {}).childIds || []).indexOf(userId) === -1) {
      return;
    }

    if (!(activityCaseType === "LOGIN" || activityCaseType === "CALL")) {
      return;
    }

    const agentPresent =
      updatedData[id][
        activityCaseType === "LOGIN" ? "onlineAgentIds" : "onCallIds"
      ].indexOf(userId) > -1;
    activityCaseType === "LOGIN" &&
      (agentPresent && !loginStatus
        ? (updatedData[id].onlineAgentIds = updatedData[id].onlineAgentIds.filter(
            (res) => res !== userId
          ))
        : !agentPresent && loginStatus
        ? updatedData[id].onlineAgentIds.push(userId)
        : null);
    activityCaseType === "CALL" &&
      (agentPresent && callStatus !== "OnCall"
        ? (updatedData[id].onCallIds = updatedData[id].onCallIds.filter(
            (res) => res !== userId
          ))
        : !agentPresent && callStatus === "OnCall"
        ? updatedData[id].onCallIds.push(userId)
        : null);

    dispatch({
      type: AGGREGATED_AGENTS_DATA,
      payload: updatedData,
    });
  };
