import {
  ACTIVE_AGENTS,
  ACTIVE_OWNER_CASES_OLD,
  ACTIVE_OWNER_CASES_NEW,
  OWNER_CASES_MIGRATED,
  RESET_OWNER_CASES,
} from "../actions/rmMigration.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIVE_AGENTS:
      return {
        ...state,
        activeAgents: action.payload,
      };
    case ACTIVE_OWNER_CASES_OLD:
      return {
        ...state,
        activeOwnerCases: {
          ...state.activeOwnerCases,
          oldAgentCases: action.payload || [],
        },
      };
    case ACTIVE_OWNER_CASES_NEW:
      return {
        ...state,
        activeOwnerCases: {
          ...state.activeOwnerCases,
          newAgentCases: action.payload || [],
        },
      };
    case RESET_OWNER_CASES:
      return {
        ...state,
        activeOwnerCases: null,
      };
    case OWNER_CASES_MIGRATED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Owner Cases migrated successfully",
          type: "success",
        },
      };
  }
};

export default reducer;
