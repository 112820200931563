/* eslint-disable prettier/prettier */
import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const GOT_REGIONS = "GOT_REGIONS";
export const GOT_PACKAGES = "GOT_PACKAGES";
export const PRODUCT_PRICE = "PRODUCT_PRICE";
export const PRODUCT_PRICE_RESET = "PRODUCT_PRICE_RESET";
export const PACKAGE_ADDED = "PACKAGE_ADDED";
export const RESET_PACKAGE = "RESET_PACKAGE";
export const PACKAGE_FAMILY = "PACKAGE_FAMILY";
export const ALLOWED_LOCALITY = "ALLOWED_LOCALITY";
export const GET_EVENT_PRODUCT_CAPPING = "GET_EVENT_PRODUCT_CAPPING";
export const RESET_EVENT_PRODUCT_CAPPING = "RESET_EVENT_PRODUCT_CAPPING";
export const HOUSING_LOCALITY = "HOUSING_LOCALITY";
export const MAKAAN_CITY_TIER_MAP = "MAKAAN_CITY_TIER_MAP";
export const HOUSING_SUBSCRIPTION_REGIONS = "HOUSING_SUBSCRIPTION_REGIONS";
export const UPDATED_ACTIVATION_PARAMETERS = "UPDATED_ACTIVATION_PARAMETERS";
export const ALL_PRODUCTS = "ALL_PRODUCTS";
export const COMPLIMENTARY_PRODUCTS_LIST = "COMPLIMENTARY_PRODUCTS_LIST";
export const SET_PARENT_COMPLIMENTARY_PRODUCT = "SET_PARENT_COMPLIMENTARY_PRODUCT";
export const SET_COMPLIMENTARY_PRODUCT_DETAILS = "SET_COMPLIMENTARY_PRODUCT_DETAILS";
export const SET_COMPLIMENTARY_PRODUCT_DETAILS_ERROR =
  "SET_COMPLIMENTARY_PRODUCT_DETAILS_ERROR";
export const COMPLIMENTARY_PRODUCTS_LIST_ERROR = "COMPLIMENTARY_PRODUCTS_LIST_ERROR";
export const ADD_COMPLIMENTARY_PRODUCT_IN_PROGRESS =
  "ADD_COMPLIMENTARY_PRODUCT_IN_PROGRESS";
export const ADD_COMPLIMENTARY_PRODUCT_SUCCESS = "ADD_COMPLIMENTARY_PRODUCT_SUCCESS";
export const ADD_COMPLIMENTARY_PRODUCT_ERROR = "ADD_COMPLIMENTARY_PRODUCT_ERROR";
export const STOP_COMPLIMENTARY_PRODUCT_SUCCESS =
  "STOP_COMPLIMENTARY_PRODUCT_SUCCESS";
export const STOP_COMPLIMENTARY_PRODUCT_ERROR = "STOP_COMPLIMENTARY_PRODUCT_ERROR";
export const STOP_COMPLIMENTARY_PRODUCT_IN_PROGRESS =
  "STOP_COMPLIMENTARY_PRODUCT_IN_PROGRESS";
export const PRODUCT_ACTIVATION_PARAMETERS_UPDATE_IN_PROGRESS =
  "PRODUCT_ACTIVATION_PARAMETERS_UPDATE_IN_PROGRESS";
export const RESET_HOUSING_LOCALITY = "RESET_HOUSING_LOCALITY";
export const PACKAGE_FAMILY_DETAILS_IN_PROGRESS = "PACKAGE_FAMILY_DETAILS_IN_PROGRESS";
export const PACKAGE_FAMILY_DETAILS_FAIL="PACKAGE_FAMILY_DETAILS_FAIL";
const complimentaryUnAuthorizedErrorMessages = {
  add: "You are not authorized to add Complimentary Products.",
  view: "You are not authorized to view Complimentary Products.",
};
const { campaignPackage } = Constants.customPackageFamilies;

function parsePackageFamilies(families) {
  families.forEach((f) => {
    f.familyEvent = f.familyEvent == null ? undefined : f.familyEvent;
    f.familyPackages.forEach((pkg) => {
      pkg.required_fields = JSON.parse(pkg.requiredPricingParameters).reduce(
        (result, field) => {
          result[field.name] = {
            type: field.type,
            available_values: field.allowedValues,
          };
          return result;
        },
        {}
      );
      if (pkg.packageProductMappings) {
        pkg.packageProductMappings.forEach((product) => {
          try {
            product.activationParameters = JSON.parse(product.activationParameters);
            product.products.requiredActivationParametersList = JSON.parse(
              product.products.requiredActivationParameters
            );
          } catch (exp) {
            product.activationParameters =
              product.products.requiredActivationParametersList = null;
          }
        });
      }
    });
  });
  return families;
}

export const getPackageFamilies = (activeOnly = true, clientTypeId = null) => {
  return (dispatch) => {
    dispatch({
      type: PACKAGE_FAMILY_DETAILS_IN_PROGRESS,
      payload: true,
    });
    let url = `/sapna/v3/get-package-families?active_only=${activeOnly}`; // '/api/v0/get-package-families';
    if (clientTypeId) {
      url = url.concat(`&client_type_id=${clientTypeId}`);
    }
    // const url = '/sapna/v1/housing/helper/4';
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: PACKAGE_FAMILY,
          // payload: response.data
          payload: parsePackageFamilies(response.data.data),
        });
        return response.data.data;
      })
      .catch((err) => {
        dispatch({
          type: PACKAGE_FAMILY_DETAILS_FAIL,
          message: err.message,
          payload:false,
        });
      });
  };
};

export const resetUpdateActivationParamsShow = () => {
  return (dispatch) => {
    dispatch();
  };
};

export const updateActivationParameters = (data) => {
  return (dispatch) => {
    const url = "/sapna/v1/opportunity/product-mapping/bulk-update";
    dispatch({
      type: PRODUCT_ACTIVATION_PARAMETERS_UPDATE_IN_PROGRESS,
      payload: true,
    });
    return (
      instance({
        url,
        data,
        method: "PUT",
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          dispatch({
            type: UPDATED_ACTIVATION_PARAMETERS,
            payload: {
              status: true,
              msgText: "Activation Parameters updated successfully",
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: UPDATED_ACTIVATION_PARAMETERS,
            payload: {
              status: false,
              msgText:
                Constants.getErrorMessage(err) ||
                "Activation Parameters updation Failed",
            },
          });
        })
    );
  };
};

export const getPackagesByFamilyId = (familyId, clientTypeId) => {
  return (dispatch) => {
    let url = `/sapna/v2/get-packages?package_family_id=${
      familyId === campaignPackage.id ? campaignPackage.realId : familyId
    }`;
    if (clientTypeId) {
      url += `&client_type_id=${clientTypeId}`;
    }
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: GOT_PACKAGES,
          payload: {
            familyId,
            data: processPackageData(response.data.data, familyId),
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          message: err.message,
        });
      });
  };
};

function processPackageData(data, familyId) {
  if (data && data.length) {
    return data.reduce((result, pkg) => {
      if (familyId !== campaignPackage.id || pkg.name.includes("Value")) {
        const requiredFieldsMap = {};
        pkg.requiredFields.forEach((elm) => {
          requiredFieldsMap[elm.name] = {
            type: elm.type,
            available_values: elm.allowedValues,
          };
        });
        pkg.required_fields = requiredFieldsMap;
        pkg.is_price_applicable = pkg.isPriceApplicable;
        result.push(pkg);
      }
      return result;
    }, []);
  } else {
    return [];
  }
}

export const getRegions = () => {
  return (dispatch) => {
    const url = "/sapna/v1/region";
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const regionMap = {};
        response.data.data.forEach((region) => {
          regionMap[region.id] = region.name;
        });
        dispatch({
          type: GOT_REGIONS,
          payload: response.data.data,
          regionMap,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAllowedLocalityForRegion = (regionId) => {
  return (dispatch) => {
    const url =
      "/sapna/v1/locality-region-mapping?fields=localityId,regionId" +
      (regionId ? `&filters=regionId==${regionId}` : "");
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: ALLOWED_LOCALITY,
          payload: {
            regionId,
            data: response.data.data.map((l) => l.localityId),
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getHousingLocality = (uuid, value) => {
  if (uuid === Constants.Zunheboto_uuid) {
    uuid = Constants.mumbai_all_uuid;
  }
  const uuidQueryFilter = uuid ? `&polygon_uuid=${uuid}` : "";
  return (dispatch) => {
    const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/suggest?service_type=leads&feature_type=locality${uuidQueryFilter}&input=${value}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: HOUSING_LOCALITY,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetHousingLocality = () => (dispatch) => {
  dispatch({
    type: RESET_HOUSING_LOCALITY,
  })
}

export const getHousingSubscriptionRegions = () => {
  return (dispatch) => {
    const url = "/sapna/v1/housing-subscription-regions";
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const housingSubscriptionRegionMap = {};
        response.data.data.forEach((hsr) => {
          housingSubscriptionRegionMap[hsr.id] = hsr.name;
        });
        dispatch({
          type: HOUSING_SUBSCRIPTION_REGIONS,
          payload: response.data.data,
          housingSubscriptionRegionMap,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getEventProductCapping = ({ oppId, cityUUID, packageId}) => {
  return (dispatch) => {

    if (!oppId || !packageId) {
      return;
    }

    const url =
      `/sapna/v1/event-product-capping/${oppId}?${cityUUID ? `cityUuid=${cityUUID}`: ""}&packageId=${packageId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: GET_EVENT_PRODUCT_CAPPING,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
    };
};

export const disposeGetEventProductCapping = () => {
  return (dispatch) => {
    return dispatch({
      type: RESET_EVENT_PRODUCT_CAPPING
    });
    };
};

export const getPriceByProductDetail = (data, packageId, accountId = null) => {
  const bodyData = {};
  for (const key in data) {
    // const label = key.replace(/locality/g, 'locality_id').replace(/_\w/g, word => word[1].toUpperCase());
    const value = data[key];
    if (value) {
      bodyData[key] = value;
    }
  }
  let url = `/sapna/v2/package-price/${packageId}`;
  if (accountId) {
    url = url.concat(`?accountId=${accountId}`);
  }
  return (dispatch) => {
    return instance({
      url,
      data: bodyData,
      method: "POST",
    })
      .then((response) => {
        dispatch({
          type: PRODUCT_PRICE,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetProductPrice = () => {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_PRICE_RESET,
    });
  };
};

export const addPackage = (data) => {
  return (dispatch) => {
    const url = "/sapna/v2/opportunity/package-mapping";
    return instance({
      url,
      data,
      method: "POST",
    })
      .then((response) => {
        dispatch({
          type: PACKAGE_ADDED,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err) || "Unable to add package",
        });
      });
  };
};

export const resetPackage = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PACKAGE,
    });
  };
};

// eslint-disable-next-line no-unused-vars
export const getMakaanCitiesForTier = (data) => {
  return (dispatch) => {
    return Promise.all(
      Constants.makaanCitiesTierList.map((tier) => {
        return instance({
          url: `/sapna/v1/city-tier-mapping?tier=${tier}`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.data) {
              return { [tier]: response.data.data };
            } else {
              return {};
            }
          })
          .catch(() => ({}));
      })
    ).then((result) => {
      let makaanCityTierMap = {};
      result.forEach((data) => {
        makaanCityTierMap = {
          ...makaanCityTierMap,
          ...data,
        };
      });
      dispatch({
        type: MAKAAN_CITY_TIER_MAP,
        makaanCityTierMap,
      });
    });
  };
};

export const getAllProducts = () => {
  return (dispatch) => {
    const url = "/sapna/v1/products";
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: ALL_PRODUCTS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getComplimentaryProducts = (opportunityId) => (dispatch) => {
  if (opportunityId) {
    const url = `sapna/v1/complementary-products?opportunityId=${opportunityId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: COMPLIMENTARY_PRODUCTS_LIST,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPLIMENTARY_PRODUCTS_LIST_ERROR,
          payload: Constants.getErrorMessage(
            err,
            complimentaryUnAuthorizedErrorMessages.add
          ),
        });
      });
  }
};
export const getLinkedComplimentryProducts = (opmId) => (dispatch) => {
  if (opmId) {
    const url = `sapna/v1/complementary-products/linked-products?opportunityProductMappingId=${opmId}`;
    dispatch(resetComplimentaryDetails());
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: SET_COMPLIMENTARY_PRODUCT_DETAILS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_COMPLIMENTARY_PRODUCT_DETAILS_ERROR,
          payload: Constants.getErrorMessage(
            err,
            complimentaryUnAuthorizedErrorMessages.view
          ),
        });
      });
  }
};

export const addComplimentaryProduct = (data) => (dispatch) => {
  const url = "sapna/v1/complementary-products/add";
  dispatch(setAddComplimentryInProgress(true));
  return instance({
    url,
    method: "POST",
    data,
  })
    .then(() => {
      dispatch({
        type: ADD_COMPLIMENTARY_PRODUCT_SUCCESS,
        payload: true,
      });
    })
    .catch((err) => {
      dispatch({
        type: ADD_COMPLIMENTARY_PRODUCT_ERROR,
        payload: Constants.getErrorMessage(err),
      });
    });
};

export const stopComplimentaryProduct = (id) => (dispatch) => {
  const url = "sapna/v1/complementary-products/remove";
  dispatch(setStopComplimentaryInProgress(id, true));
  return instance({
    url,
    method: "POST",
    headers: { "content-type": "application/json" },
    data: id,
  })
    .then(() => {
      dispatch({
        type: STOP_COMPLIMENTARY_PRODUCT_SUCCESS,
        payload: true,
        id,
      });
    })
    .catch((err) => {
      dispatch({
        type: STOP_COMPLIMENTARY_PRODUCT_ERROR,
        payload: Constants.getErrorMessage(err),
        id,
      });
    });
};

export const setParentComplimentaryProduct = (payload) => ({
  type: SET_PARENT_COMPLIMENTARY_PRODUCT,
  payload,
});

export const resetComplimentaryDetails = () => ({
  type: SET_COMPLIMENTARY_PRODUCT_DETAILS,
  payload: null,
});

export const resetComplimentaryListError = () => ({
  type: COMPLIMENTARY_PRODUCTS_LIST_ERROR,
  payload: null,
});

export const resetComplimentaryDetailsError = () => ({
  type: SET_COMPLIMENTARY_PRODUCT_DETAILS_ERROR,
  payload: null,
});

export const resetAddComplimentaryError = () => ({
  type: ADD_COMPLIMENTARY_PRODUCT_ERROR,
  payload: null,
});

export const resetAddComplimentarySuccess = () => ({
  type: ADD_COMPLIMENTARY_PRODUCT_SUCCESS,
  payload: null,
});

export const setAddComplimentryInProgress = (value) => ({
  type: ADD_COMPLIMENTARY_PRODUCT_IN_PROGRESS,
  payload: value,
});

export const setStopComplimentaryInProgress = (id, value) => ({
  type: STOP_COMPLIMENTARY_PRODUCT_IN_PROGRESS,
  payload: value,
  id,
});
