import {
  AGENT_PERFORMANCE_ACCOUNT,
  AGENT_PERFORMANCE_OPPORTUNITY,
  AGENT_INDIVIDUAL_PERFORMANCE,
  AGENT_INDIVIDUAL_PERFORMANCE_RESET,
} from "../actions/agent.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case AGENT_PERFORMANCE_ACCOUNT:
      return {
        ...state,
        agentPerformanceAccount: action.payload,
      };
    case AGENT_PERFORMANCE_OPPORTUNITY:
      return {
        ...state,
        agentPerformanceOpportunity: action.payload,
      };
    case AGENT_INDIVIDUAL_PERFORMANCE:
      return {
        ...state,
        individualPerformance: action.payload,
      };
    case AGENT_INDIVIDUAL_PERFORMANCE_RESET:
      return {
        ...state,
        individualPerformance: null,
      };
  }
};

export default reducer;
