import Cache from "../../utils/cache";

import Constants from "../../utils/CommonConstants";
import axios from "axios";
import { instance } from "../../lib/api";
import ownerConstants from "../../utils/ownerConstants";
import { paddingForCalendar } from "../../utils/dateUtils";
import { setSnackbar } from "./account.actions";
import { CHANGE_AGENT_STATUS_API } from "./teleSalesDashboard.actions";

export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const OFFLINE_STATE = "OFFLINE_STATE";
export const VARIOUS_CONFIG = "VARIOUS_CONFIG";
export const CONFIG_FAILED = "CONFIG_FAILED";
export const RESET_PASSWORD_EMAIL_SENT = "RESET_PASSWORD_EMAIL_SENT";
export const RESET_PASSWORD_EMAIL_RESET = "RESET_PASSWORD_EMAIL_RESET";
export const PLATFORM_IS_MAKAAN = "PLATFORM_IS_MAKAAN";
const AGENT_STATUS_UPDATE = "AGENT_STATUS_UPDATE";

const extractBasicDetails = (userDetails) => {
  const { id, email, firstName, lastName, roles, domainId, countryId } = userDetails;
  const nonContentRoles = Object.values(Constants.modifyRoles).concat(
    Constants.readOnlyRoles
  );
  let returnObj = {
    id,
    email,
    roles,
    lastName,
    firstName,
    domainId,
    countryId,
    isAdmin: userDetails.roles && userDetails.roles.includes(Constants.adminRole),
    isReadOnly: isReadOnlyUser(roles),
    isMasterData:
      userDetails.roles && userDetails.roles.includes("LOOKUP_MASTER_DATA"),
  };

  returnObj.isContentUser =
    userDetails.roles && userDetails.roles.includes(Constants.contentUserRole);

  returnObj.isHousingOwnerAgent =
    userDetails.roles && userDetails.roles.includes(Constants.housingOwnerAgentRole);

  returnObj.isContentUserOnly =
    returnObj.isContentUser &&
    userDetails.roles &&
    !userDetails.roles.some((role) => nonContentRoles.includes(role));

  returnObj.isFinanceUser =
    userDetails.roles && userDetails.roles.includes(Constants.financeUserRole);

  returnObj.isOwnerTelesalesAgent = userDetails.roles?.includes(
    Constants.housingOwnerAgentRole
  );

  returnObj.isBrokerTelesalesAgent = userDetails.roles?.includes(
    Constants.brokerTelesalesAgentRole
  );

  returnObj.isBuilderTelesalesAgent = userDetails.roles?.includes(
    Constants.builderTelesalesAgentRole
  );

  return returnObj;
};

const isReadOnlyUser = (roles) => {
  if (!roles) return true;
  const modifyRoles = Object.values(Constants.modifyRoles);
  return !roles.some(
    (role) => modifyRoles.includes(role) || role === Constants.contentUserRole
  );
};

const getIdNameMap = (objArr, name) => {
  const obj = {};
  objArr.forEach((o) => {
    if (name === "branchMapping") {
      obj[o.branchId] = o;
    } else {
      obj[o.id] = o;
    }
  });
  return obj;
};

const getOwnerAgentStatusMap = (results, key = "status") => {
  const obj = {};
  results
    .filter(
      (res) => ownerConstants.agentStatusMappingRequired.indexOf(res[key]) > -1
    )
    .forEach((res) => {
      obj[res[key]] = res.id;
    });
  return obj;
};

export const getVariousConfig = (isOnlyRefData = false) => {
  const promises = (
    isOnlyRefData ? Constants.configUrls.slice(0, 1) : Constants.configUrls
  ).map((url) => axios.get(url));

  return (dispatch) => {
    return axios
      .all(promises)
      .then(
        axios.spread((...responses) => {
          const config = {};
          responses.forEach((response, index) => {
            let resp = response.data.data;

            if (resp.results) {
              //  mystique config API
              if (index === 2) {
                config["ownerAgentStatusMapping"] = {
                  ...config["ownerAgentStatusMapping"],
                  ...getOwnerAgentStatusMap(resp.results, "reason"),
                };
                return;
              }
              config["mystiqueCaseAssignStatus"] = getIdNameMap(resp.results);
              config["ownerAgentStatusMapping"] = getOwnerAgentStatusMap(
                resp.results
              );
            } else {
              //  rest all sapna config API
              resp.forEach((conf) => {
                config[conf.name] = getIdNameMap(conf.data, conf.name);
              });
            }
          });
          dispatch({
            type: VARIOUS_CONFIG,
            config,
          });
        })
      )
      .catch((err) => {
        dispatch({
          type: CONFIG_FAILED,
          errMsg: err.message,
        });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    const url = "/madrox/app/v1/logout";
    return instance({
      url,
      method: "post",
      headers: {
        "X-host": window.location.hostname,
      },
    })
      .then(() => {
        dispatch({
          type: LOGOUT_SUCCESS,
          userInfo: null,
        });
        Cache.eraseClientCache(Cache.CLIENT_CACHE);
      })
      .catch(() => {
        dispatch({
          type: LOGOUT_FAIL,
        });
      });
  };
};

const logoutUnauthorizedUser = (unauthorized = false, dispatch) => {
  const url = "/madrox/app/v1/logout";
  instance({
    url,
    method: "post",
    headers: {
      "X-host": window.location.hostname,
    },
  })
    .then(() => {
      if (unauthorized) {
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          snackBarType: "error",
          msg: "Unauthorized Access",
        });
      }
    })
    .catch(() => {});
};

const verifyUserByEmailId = (user, dispatch) => {
  const emailIdsNotAllowed = Constants.emailIdsNotAllowed;

  if (emailIdsNotAllowed.includes(user?.email)) {
    const unauthorized = true;
    logoutUnauthorizedUser(unauthorized, dispatch);
    return false;
  }
  return true;
};
const verifyUserByRole = (user, dispatch) => {
  const allowedRoleOfUser = user.roles?.find(
    (role) => Constants.getAllowedRolesForLogin().indexOf(role) !== -1
  );
  if (allowedRoleOfUser) {
    dispatch({
      type: LOGIN_SUCCESS,
      userInfo: extractBasicDetails(user),
    });
    const { SAPPHIRE_OWNER_FLAG } = process.env;
    if (
      user.roles.includes(Constants.brokerTelesalesAgentRole) ||
      !SAPPHIRE_OWNER_FLAG ||
      user.roles.includes(Constants.builderTelesalesAgentRole)
    ) {
      const url = `/sapphire/v1/agents/status`;
      instance({
        url,
        method: "get",
      })
        .then((response) => {
          let agentStatus = response.data.data.status || 0;
          dispatch({
            type: AGENT_STATUS_UPDATE,
            payload: agentStatus,
          });
        })
        .catch(() => {});
    }
  } else {
    const unauthorized = true;
    logoutUnauthorizedUser(unauthorized, dispatch);
  }
};

export const doLogin = (formData) => {
  return (dispatch, getState) => {
    const { username, password, isLsq } = formData;
    if (!(username && password)) {
      dispatch({
        type: LOGIN_FAIL,
      });
      return;
    }
    const url = "/madrox/app/v1/login";
    let data = `username=${username}&password=${password}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-host": window.location.hostname,
    };
    if (isLsq) {
      headers.LSQ = isLsq;
    }
    return instance({
      url,
      headers,
      data,
      method: "post",
    })
      .then((response) => {
        const user = response.data.data;
        verifyUserByRole(user, dispatch, getState);
      })
      .catch((e) => {
        dispatch({
          type: LOGIN_FAIL,
          message: e?.response?.data?.error?.msg || "Login Failed",
        });
      });
  };
};

export const doSocialLogin = (acceesToken, isLsq) => {
  return (dispatch) => {
    let url = `madrox/app/v1/login/google?access_token=${acceesToken}`;
    const headers = {
      "X-host": window.location.hostname,
    };
    if (isLsq) {
      headers.LSQ = true;
    }
    return instance({
      url,
      headers,
      method: "get",
    })
      .then((reponse) => {
        const user = reponse.data.data;
        if (verifyUserByEmailId(user, dispatch)) {
          verifyUserByRole(user, dispatch);
        }
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
          message: error?.response?.data?.error?.msg || "Login Failed",
        });
      });
  };
};

export const resetPassword = (email) => {
  return (dispatch) => {
    const url = `/madrox/app/v1/reset-password`;
    const data = new FormData();
    data.set("email", email);
    data.set("domainId", 2);
    return axios({
      method: "post",
      url,
      data,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then((response) => {
        dispatch({
          type: RESET_PASSWORD_EMAIL_SENT,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetPasswordReset = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_EMAIL_RESET,
    });
  };
};

export const getUser = (isLsq) => {
  return (dispatch, getState) => {
    let url = "/madrox/app/v1/user/details?checkHRIS=true";
    const headers = {};
    if (isLsq) {
      headers.LSQ = true;
    }

    return instance({
      url,
      headers,
      method: "get",
    })
      .then((response) => {
        const user = response.data.data;
        verifyUserByRole(user, dispatch, getState);
      })
      .catch((err) => {
        const { status } = err.response || {};
        dispatch({
          type: OFFLINE_STATE,
        });
        if (status != 401) {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
        } else {
          logoutUnauthorizedUser();
        }
      });
  };
};

export const getMainRole = () => {
  return (_, getState) => {
    const { userInfo: { roles } = {} } = getState();
    const mainRole = Object.keys(Constants.performanceMapping).filter(
      (mappingRole) =>
        roles.find((role) => role === Constants.performanceMapping[mappingRole])
    )[0];
    return mainRole;
  };
};

export const getOwnerUserStatus = ({
  team = false,
  agentId,
  activityCaseType = "LOGIN",
  agentTypeFilter,
  accountType = "Owner",
} = {}) => {
  return (dispatch, getState) => {
    const today = new Date();
    const mainRole =
      ownerConstants.userRoleMappingForStatus[dispatch(getMainRole())];
    const stringDateFormat = `${today.getFullYear()}-${paddingForCalendar(
      today.getMonth() + 1
    )}-${paddingForCalendar(today.getDate())}`;
    const {
      userInfo: { id },
    } = getState();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const activitySelector = {
      activityCaseType,
      caseSubType: accountType === "Owner" ? "HousingOwner" : "Housing",
      startDate: stringDateFormat,
      endDate: stringDateFormat,
      timeZone: timezone,
      agentTypeFilter: agentTypeFilter || mainRole,
      team,
      agentId: agentId || id,
    };
    const url = `/mystique/v0/activity?activitySelector=${JSON.stringify(
      activitySelector
    )} `;
    return instance({
      url,
      method: "get",
    })
      .then((res) => res.data.data)
      .catch(() => {});
  };
};

export const getOwnerUserStatusBackwardCompatible = () => {
  return (dispatch, getState) => {
    return dispatch(getOwnerUserStatus()).then((data) => {
      const { variousConfig: { ownerAgentStatusMapping: { Online } = {} } = {} } =
        getState();

      // eslint-disable-next-line
      const status = (data[0] || {}).statusId == Online;

      return status ? 1 : 0;
    });
  };
};

export const changeOwnerAgentStatus = (status) => {
  return (dispatch, getState) => {
    dispatch({
      type: CHANGE_AGENT_STATUS_API,
      payload: true,
    });
    const reasonName = ownerConstants.reasonMapping.LoginActivity;
    const { variousConfig: { ownerAgentStatusMapping } = {} } = getState();
    if (!ownerAgentStatusMapping) {
      setSnackbar("error", "Owner Activity Statuses unavailable");
      return;
    }
    const { Offline, Online } = ownerAgentStatusMapping;
    const payload = {
      caseSubType: "HousingOwner",
      reasonId: ownerAgentStatusMapping[reasonName],
      cs: status ? Offline : Online,
      ns: status ? Online : Offline,
      activityCaseType: "LOGIN",
    };
    const url = `/mystique/v0/activity`;
    return instance({
      url,
      method: "put",
      data: payload,
    })
      .then((response) => {
        const newStatus = response?.data?.data?.statusId == Online ? 1 : 0;
        dispatch({
          type: AGENT_STATUS_UPDATE,
          payload: newStatus,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      })
      .finally(() => {
        dispatch({
          type: CHANGE_AGENT_STATUS_API,
          payload: false,
        });
      });
  };
};
