import Perfume from "perfume.js";
import gaEventQueue from "../utils/gaEventQueue";
const gaOptions = { siteSpeedSampleRate: 10 };
const gaUniversalId = "UA-185620606-2";

export const trackPerformanceMetrics = () => {
  loadGA();
  new Perfume({
    analyticsTracker: (options) => {
      const { metricName, data } = options;
      switch (metricName) {
        case "navigationTiming":
          if (data && data.timeToFirstByte) {
            trackGATiming(data.timeToFirstByte, {
              timingCategory: "timeToFirstByte",
            });
          }
          break;
        case "fp":
          trackGATiming(data, {
            timingCategory: "firstPaint",
          });
          break;
        case "fcp":
          trackGATiming(data, { timingCategory: "firstContentfulPaint" });
          break;
        case "lcp":
          trackGATiming(data, {
            timingCategory: "largestContentfulPaint",
          });
          break;
        case "cls":
          trackGATiming(data, {
            timingCategory: "cumulativeLayoutShift",
          });
          break;
        case "tbt":
          trackGATiming(data, {
            timingCategory: "totalBlockingTime",
          });
          break;
      }
    },
  });
};
const trackGATiming = (data, options) => {
  const ga = window.ga;
  ga("send", {
    hitType: "timing",
    timingValue: Math.round(data),
    timingVar: "load",
    ...options,
  });
};
export const loadGA = () => {
  window.ga =
    window.ga ||
    function () {
      (window.ga.q = window.ga.q || []).push(arguments);
    };
  const ga = window.ga;
  ga.l = +new Date();
  ga("create", gaUniversalId, gaOptions);
};

const fireGAEvents = () => {
  if (!gaEventQueue.isEmpty() && window.ga && typeof window.ga === "function") {
    const ga = window.ga;
    if (ga && ga.getAll && ga.getAll().length) {
      gaEventQueue.getQueue().forEach((gaEventObj) => {
        let trackerName = ga.getAll()[0].get("name");
        ga(trackerName + ".send", gaEventObj);
      });
      gaEventQueue.clearQueue();
    }
  }
};

export const gaTrackingFn = (getEventObj, localState) => (dispatch, getState) => {
  if (!getEventObj) {
    return;
  }
  const globalState = getState();
  const data = getEventObj(globalState, localState);
  sendGA(data);
};

export const sendGA = ({ category, action, label } = {}) => {
  if (!(category && action && label)) {
    return;
  }
  const url = window.location.pathname + window.location.search;
  const event = {
    hitType: "event",
    eventCategory: category,
    eventAction: action,
    eventLabel: typeof label === "object" ? JSON.stringify(label) : label,
    page: url,
    location: document.URL,
    time: Date.now(),
  };
  gaEventQueue.push(event);
  fireGAEvents();
};
