import {
  PACKAGE_PRICING,
  PRICING_DELETED,
  RESET_PRICING_STATUS,
  PRICING_ADDED,
  PRICING_UPDATED,
  FILE_UPLOADED,
} from "../actions/managePricing.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case PACKAGE_PRICING:
      return {
        ...state,
        packagePricings: action.payload,
      };
    case PRICING_DELETED:
    case PRICING_ADDED:
    case PRICING_UPDATED:
      return {
        ...state,
        pricingModified: action.status,
        snackbar: {
          state: true,
          message: action.status
            ? "Pricings Updated successfully"
            : "Pricing Update Failed",
          type: action.status ? "success" : "error",
        },
      };
    case RESET_PRICING_STATUS: {
      return {
        ...state,
        pricingModified: false,
      };
    }
    case FILE_UPLOADED: {
      return {
        ...state,
        snackbar: {
          state: true,
          message: "file uploaded successfully",
          type: "success",
        },
      };
    }
  }
};

export default reducer;
