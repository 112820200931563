import {
  COMMON_MATRIX,
  COMMON_MATRIX_NETWORK_ERR,
} from "../actions/commonMatrix.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case COMMON_MATRIX:
      return {
        ...state,
        matrixData: action.matrixData,
      };
    case COMMON_MATRIX_NETWORK_ERR:
      return {
        ...state,
        commonMatrixNetworkErr: {},
      };
  }
};

export default reducer;
