import {
  CALLING_CUSTOMER,
  CALLING_QUEUE_CASE,
  OWNER_PACKAGES,
  SHARE_LINK,
} from "./teleSalesDashboard.actions";

import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";

export const pullTMCase = () => {
  const url = "/mystique/v1/soc/pull/tenant";
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: CALLING_QUEUE_CASE,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const handleCall = (caseId, number) => {
  const url = `/mystique/v1/cases/${caseId}/call?type=SOCASE&custNumber=${number}&subType=TenantMonetization`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: CALLING_CUSTOMER,
          payload: {
            status: response.data.data.status,
            id: response.data.data.id,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getTenantPackages = () => {
  const url = "/sapna/v2/package-price/tenant";
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: OWNER_PACKAGES,
          payload: {
            TenantMonetization: { ...response.data.data },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const shareLink = (data) => {
  const url = "/sapna/tenant/v1/share-link";
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        dispatch({
          type: SHARE_LINK,
          payload: response.data.data,
          snackbar: response.data.data
            ? "Link shared successfully"
            : "Link sharing failed",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
