import {
  AGENT_LIST,
  ASSIGNMENT_SUCCEED,
  ACCOUNT_NAMES,
  ACCOUNT_LIST,
  TICKETS_ATTRIBUTES,
  CREATED_TICKET_SUCCESSULLY,
  MASS_ASSIGNMENT_FAILED,
  HOUSING_ACCOUNT_DETAILS,
  ACCOUNT_ADDED,
  RESET_TICKET_ATTRIBUTES,
  ACCOUNT_MAPPED_TO_CITY,
  RESET_MAPPED_ACCOUNT,
  USER_DESIGNATION,
  ACCOUNTS_LIST_TYPEAHEAD,
  RESET_HOUSING_ACCOUNT_NAMES,
  TICKETS_SUBCATEGORIES,
  EXISTING_ACCOUNT_DETAILS,
  CHILD_AGENT_MAP,
  UPDATE_DYNAMIC_PROPERTY,
  UPDATE_DYNAMIC_PROPERTY_FAIL,
} from "../actions/myAccounts.actions";
import React from "react";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case AGENT_LIST:
      return {
        ...state,
        agents: action.agents,
      };
    case ASSIGNMENT_SUCCEED:
      return {
        ...state,
        assignmentSucceed: action.assignmentSucceed,
        snackbar: {
          state: true,
          message: "Account(s) assigned successfully!",
          type: "success",
        },
      };
    case MASS_ASSIGNMENT_FAILED:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          message: action.errMsg || "Failed to assign accounts!",
        },
      };
    case ACCOUNT_NAMES:
      return {
        ...state,
        accountNames: action.accountNames,
      };
    case ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.accountList,
      };
    case TICKETS_ATTRIBUTES:
      return {
        ...state,
        ticketsAttributes: action.ticketsAttributes,
      };
    case CREATED_TICKET_SUCCESSULLY: {
      return {
        ...state,
        dialog: {
          type: "TicketCreated",
          state: true,
          message: `${action.ticketLink.key} Created Successfully`,
          content: (
            <div>
              <a
                href={action.ticketLink.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here{" "}
              </a>{" "}
              to view or modify Ticket Details{" "}
            </div>
          ),
        },
        createdTicketId: parseInt(action.ticketLink.key.split("-")[1]),
      };
    }
    case HOUSING_ACCOUNT_DETAILS:
      return {
        ...state,
        housingAccountDetails: { ...action.housingAccountDetails },
      };
    case ACCOUNT_ADDED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Account added Successfully!",
          type: "success",
        },
      };
    case RESET_TICKET_ATTRIBUTES:
      return {
        ...state,
        ticketsAttributes: null,
      };
    case ACCOUNT_MAPPED_TO_CITY:
      return {
        ...state,
        mappedAccount: action.payload,
        snackbar: {
          state: true,
          message: `Account mapped to city. AccountId#${action.payload.id}`,
          type: "success",
        },
      };
    case RESET_MAPPED_ACCOUNT:
      return {
        ...state,
        mappedAccount: null,
      };
    case USER_DESIGNATION:
      return {
        ...state,
        designation: action.designation,
      };
    case ACCOUNTS_LIST_TYPEAHEAD:
      return {
        ...state,
        accountsListTypeAhead: action.accountsListTypeAhead,
      };
    case RESET_HOUSING_ACCOUNT_NAMES:
      return {
        ...state,
        accountNames: [],
      };
    case TICKETS_SUBCATEGORIES:
      return {
        ...state,
        ticketSubcategories: action.ticketSubcategories,
      };
    case EXISTING_ACCOUNT_DETAILS:
      return {
        ...state,
        existingAccountDetails: action.existingAccountDetails,
      };
    case CHILD_AGENT_MAP:
      return {
        ...state,
        agentChildMap: action.payload,
      };
    case UPDATE_DYNAMIC_PROPERTY: {
      const { propKey, propValue } = action.payload;
      let updated = false;

      const properties = (state.dynamicProperties || []).map((obj) => {
        const [key] = Object.entries(obj)[0];
        if (key === propKey) {
          updated = true;
          return { [propKey]: propValue };
        }
        return obj;
      });

      if (!updated) {
        properties.push({ [propKey]: propValue });
      }

      return {
        ...state,
        dynamicPropertyFail: false,
        dynamicProperties: properties,
      };
    }
    case UPDATE_DYNAMIC_PROPERTY_FAIL:
      return {
        ...state,
        dynamicPropertyFail: true,
        snackbar: {
          state: true,
          type: "error",
          duration: action.duration || null,
          message: action.errMsg || "Something went wrong!",
        },
      };
  }
};

export default reducer;
