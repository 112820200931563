import Constants from "./CommonConstants";

export const hasValidRoles = (roles) => {
  const { [Constants.TSPO]: tspoRoles, [Constants.HOM]: homRoles } =
    Constants.additionalRoleValueMapping;

  return (
    tspoRoles.every((role) => roles.includes(role)) ||
    homRoles.every((role) => roles.includes(role))
  );
};

export const sensitiveMasker = ({
  value,
  condition = Constants.phoneMaskerFlag,
  digits = 8,
}) => {
  const regex = new RegExp(`[0-9](?!([0-9]{${digits}}))`, "g");
  return condition
    ? value && typeof value === "string" && value.replace(regex, "X")
    : value;
};
