import { GET_VARIOUS_CONFIG, NOTIFICATION_GET } from "../actions/header.actions";

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case NOTIFICATION_GET:
      return {
        ...state,
        oldNotifications: action.payload,
      };
    case "PULL_OPPORTUNITY_ERROR":
      return {
        ...state,
        errorDialogMessage: action.payload,
      };
    case GET_VARIOUS_CONFIG:
      return {
        ...state,
        canCallVariousConfigApis: true,
      };
  }
};

export default reducer;
