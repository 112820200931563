import { instance } from "../../lib/api";
import axios from "axios";
import Constants from "../../utils/CommonConstants";
import triggerFileDownload from "../../utils/triggerFileDownload";

const cancelTokenMap = {
  "renewal-report": { token: null, callInProgress: false },
  "renewal-monthly-summary": { token: null, callInProgress: false },
  "renewal-monthly-summary-agent-wise": { token: null, callInProgress: false },
  "renewal-chart-summary": { token: null, callInProgress: false },
  "renewal-alert": { token: null, callInProgress: false },
};

const timeout = 60000;
export const RENEWAL_REPORT_DETAILS = "RENEWAL_REPORT_DETAILS";
export const RENEWAL_SUMMARY_MONTH_WISE = "RENEWAL_SUMMARY_MONTH_WISE";
export const RENEWAL_CHART_SUMMARY_MONTH_WISE = "RENEWAL_CHART_SUMMARY_MONTH_WISE";
export const RENEWAL_SUMMARY_AGENT_WISE = "RENEWAL_SUMMARY_AGENT_WISE";
export const RENEWAL_ALERT_DETAILS = "RENEWAL_ALERT_DETAILS";
export const SET_RENEWAL_REPORT_OFFLINE_FILTERS =
  "SET_RENEWAL_REPORT_OFFLINE_FILTERS";
export const SET_AGENT_WISE_OFFLINE_FILTERS = "SET_AGENT_WISE_OFFLINE_FILTERS";
export const SET_RENEWAL_ALERT_OFFLINE_FILTERS = "SET_RENEWAL_ALERT_OFFLINE_FILTERS";
export const SET_RENEWAL_REPORT_USER_ID = "SET_RENEWAL_REPORT_USER_ID";
export const SET_MONTHLY_SUMMARY_AGENT_USER_ID = "SET_MONTHLY_SUMMARY_AGENT_USER_ID";
export const SET_RENEWAL_ALERT_USER_ID = "SET_RENEWAL_ALERT_USER_ID";
export const SET_RENEWAL_REPORT_SELECTED_MONTHS =
  "SET_RENEWAL_REPORT_SELECTED_MONTHS";
export const SET_MONTHLY_SUMMARY_SELECTED_MONTHS =
  "SET_MONTHLY_SUMMARY_SELECTED_MONTHS";
export const SET_MONTHLY_SUMMARY_AGENT_WISE_SELECTED_MONTHS =
  "SET_MONTHLY_SUMMARY_AGENT_WISE_SELECTED_MONTHS";
export const SET_UPCOMING_RENEWALS_WEEKLY = "SET_UPCOMING_RENEWALS_WEEKLY";
export const SET_UPCOMING_RENEWALS_MONTHLY = "SET_UPCOMING_RENEWALS_MONTHLY";
export const SET_RENEWAL_REPORT_AGENT_LIST = "SET_RENEWAL_REPORT_AGENT_LIST";
export const SET_MONTHLY_SUMMARY_AGENT_LIST = "SET_MONTHLY_SUMMARY_AGENT_LIST";
export const SET_MONTHLY_SUMMARY_AGENT_WISE_AGENT_LIST =
  "SET_MONTHLY_SUMMARY_AGENT_WISE_AGENT_LIST";
export const SET_RENEWAL_ALERT_AGENT_LIST = "SET_RENEWAL_ALERT_AGENT_LIST";
export const SET_RENEWAL_REPORT_DOWNLOAD_IN_PROGRESS =
  "SET_RENEWAL_REPORT_DOWNLOAD_IN_PROGRESS";
export const SET_SUMMARY_AGENT_WISE_DOWNLOAD_IN_PROGRESS =
  "SET_SUMMARY_AGENT_WISE_DOWNLOAD_IN_PROGRESS";
export const SET_RENEWAL_ALERT_DOWNLOAD_IN_PROGRESS =
  "SET_RENEWAL_ALERT_DOWNLOAD_IN_PROGRESS";
export const SET_ALL_CITIES = "SET_ALL_CITIES";
export const SET_RENEWAL_REPORT_AVERAGES = "SET_RENEWAL_REPORT_AVERAGES";
export const SET_MONTHLY_CHART_AVERAGES = "SET_MONTHLY_CHART_AVERAGES";

const agentListCache = {};

const modifyFilters = (filters) => {
  return {
    ...filters,
    month:
      (filters.month &&
        filters.month.map((month) => Constants.getDateAsYYYYMMDDhhmmss(month))) ||
      [],
    sellerType: filters.profileType || [],
    profileType: filters.sellerType || [] /*  &&
        filters.sellerType
          .map((type) => (type === "Developer" ? ["Builder", "Owner"] : type))
          .flat()) ||
      [] */,
    city: filters.city ? (filters.city.length ? filters.city : null) : null,
    /* might need later, so commenting it out for now */
    /* renewalType:
      (filters.renewalType &&
        filters.renewalType
          .map((type) =>
            type === "30-60D Renewal" ? ["30-45D Renewal", "45-60D Renewal"] : type
          )
          .flat()) ||
      [], */
  };
};

export const setRenewalReportOfflineFilters = (filter) => ({
  type: SET_RENEWAL_REPORT_OFFLINE_FILTERS,
  value: filter,
});

export const setAgentWiseOfflineFilters = (filter) => ({
  type: SET_AGENT_WISE_OFFLINE_FILTERS,
  value: filter,
});

export const setRenewalAlertOfflineFilters = (filter) => ({
  type: SET_RENEWAL_ALERT_OFFLINE_FILTERS,
  value: filter,
});

export const setRenewalReportUserId = (id) => ({
  type: SET_RENEWAL_REPORT_USER_ID,
  value: id,
});

export const setMonthlySummaryAgentUserId = (id) => ({
  type: SET_MONTHLY_SUMMARY_AGENT_USER_ID,
  value: id,
});

export const setRenewalAlertUserId = (id) => ({
  type: SET_RENEWAL_ALERT_USER_ID,
  value: id,
});

export const setRenewalReportSelectedMonths = (months) => ({
  type: SET_RENEWAL_REPORT_SELECTED_MONTHS,
  value: months,
});

export const setMonthlySummarySelectedMonths = (months) => ({
  type: SET_MONTHLY_SUMMARY_SELECTED_MONTHS,
  value: months,
});

export const setMonthlySummaryAgentWiseSelectedMonths = (months) => ({
  type: SET_MONTHLY_SUMMARY_AGENT_WISE_SELECTED_MONTHS,
  value: months,
});

const moveSelectedUserToTop = (userId, renewalReportData = []) => {
  // move current user row to the top
  const currentUserId = userId;
  const currentUserRowIndex = renewalReportData.findIndex(
    (data) => data.agentId === currentUserId
  );
  if (currentUserRowIndex !== -1) {
    const currentUserRow = {
      ...renewalReportData[currentUserRowIndex],
      currentSelectedUser: true,
    };
    renewalReportData.splice(currentUserRowIndex, 1);
    return [currentUserRow, ...renewalReportData];
  }
  return renewalReportData;
};

export const getRenewalReportDetails = (id, filter) => {
  const CancelToken = axios.CancelToken;

  return (dispatch) => {
    if (
      cancelTokenMap["renewal-report"]["callInProgress"] &&
      cancelTokenMap["renewal-report"]["token"]
    ) {
      // another API call is already in progress and we have the cancel token to cancel that call
      cancelTokenMap["renewal-report"]["token"]();
      cancelTokenMap["renewal-report"]["token"] = null;
    }
    const url = `sapna/v1/report/renewal-report/${id}`;

    //reset details before fetch
    dispatch({
      type: RENEWAL_REPORT_DETAILS,
      renewalReportDetails: null,
    });
    cancelTokenMap["renewal-report"]["callInProgress"] = true;
    return instance({
      url,
      method: "post",
      data: { ...modifyFilters(filter) },
      timeout,
      cancelToken: new CancelToken((cToken) => {
        cancelTokenMap["renewal-report"]["token"] = cToken;
      }),
    })
      .then((response) => {
        if (response.data.data) {
          const renewalReportDetails = moveSelectedUserToTop(id, response.data.data);
          dispatch({
            type: RENEWAL_REPORT_DETAILS,
            renewalReportDetails,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
            dispatch({
              type: RENEWAL_REPORT_DETAILS,
              renewalReportDetails: [],
            });
          }
        }
        cancelTokenMap["renewal-report"]["callInProgress"] = false;
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          // handle error
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
          dispatch({
            type: RENEWAL_REPORT_DETAILS,
            renewalReportDetails: [],
          });
          cancelTokenMap["renewal-report"]["callInProgress"] = false;
        }
      });
  };
};

export const getRenewalMonthlySummary = (id, filter) => {
  const CancelToken = axios.CancelToken;

  return (dispatch) => {
    if (
      cancelTokenMap["renewal-monthly-summary"]["callInProgress"] &&
      cancelTokenMap["renewal-monthly-summary"]["token"]
    ) {
      // another API call is already in progress and we have the cancel token to cancel that call
      cancelTokenMap["renewal-monthly-summary"]["token"]();
      cancelTokenMap["renewal-monthly-summary"]["token"] = null;
    }
    const url = `sapna/v1/report/renewal-summary/monthwise/${id}`;

    //reset details before fetch
    dispatch({
      type: RENEWAL_SUMMARY_MONTH_WISE,
      renewalSummaryMonthWise: null,
    });
    cancelTokenMap["renewal-monthly-summary"]["callInProgress"] = true;
    return instance({
      url,
      method: "post",
      data: { ...modifyFilters(filter) },
      timeout,
      cancelToken: new CancelToken((cToken) => {
        cancelTokenMap["renewal-monthly-summary"]["token"] = cToken;
      }),
    })
      .then((response) => {
        if (response.data.data) {
          const renewalSummaryMonthWise = response.data.data;
          dispatch({
            type: RENEWAL_SUMMARY_MONTH_WISE,
            renewalSummaryMonthWise,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
            dispatch({
              type: RENEWAL_SUMMARY_MONTH_WISE,
              renewalSummaryMonthWise: [],
            });
          }
        }
        cancelTokenMap["renewal-monthly-summary"]["callInProgress"] = false;
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          // handle error
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
          dispatch({
            type: RENEWAL_SUMMARY_MONTH_WISE,
            renewalSummaryMonthWise: [],
          });
          cancelTokenMap["renewal-monthly-summary"]["callInProgress"] = false;
        }
      });
  };
};

export const getRenewalChartSummary = (id, filter) => {
  const CancelToken = axios.CancelToken;
  return (dispatch) => {
    if (
      cancelTokenMap["renewal-chart-summary"]["callInProgress"] &&
      cancelTokenMap["renewal-chart-summary"]["token"]
    ) {
      // another API call is already in progress and we have the cancel token to cancel that call
      cancelTokenMap["renewal-chart-summary"]["token"]();
      cancelTokenMap["renewal-chart-summary"]["token"] = null;
    }

    const url = `sapna/v1/report/renewal-summary/chart/${id}`;

    //reset details before fetch
    dispatch({
      type: RENEWAL_CHART_SUMMARY_MONTH_WISE,
      renewalChartSummaryMonthWise: null,
    });
    cancelTokenMap["renewal-chart-summary"]["callInProgress"] = true;
    return instance({
      url,
      method: "post",
      data: { ...modifyFilters(filter) },
      timeout,
      cancelToken: new CancelToken((cToken) => {
        cancelTokenMap["renewal-chart-summary"]["token"] = cToken;
      }),
    })
      .then((response) => {
        if (response.data.data) {
          const renewalChartSummaryMonthWise = response.data.data;
          dispatch({
            type: RENEWAL_CHART_SUMMARY_MONTH_WISE,
            renewalChartSummaryMonthWise,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
            dispatch({
              type: RENEWAL_CHART_SUMMARY_MONTH_WISE,
              renewalChartSummaryMonthWise: [],
            });
          }
        }
        cancelTokenMap["renewal-monthly-summary"]["callInProgress"] = false;
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          // handle error
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
          dispatch({
            type: RENEWAL_CHART_SUMMARY_MONTH_WISE,
            renewalChartSummaryMonthWise: [],
          });
          cancelTokenMap["renewal-chart-summary"]["callInProgress"] = false;
        }
      });
  };
};

export const getRenewalMonthlySummaryAgentWise = (id, filter) => {
  const CancelToken = axios.CancelToken;
  return (dispatch) => {
    if (
      cancelTokenMap["renewal-monthly-summary-agent-wise"]["callInProgress"] &&
      cancelTokenMap["renewal-monthly-summary-agent-wise"]["token"]
    ) {
      // another API call is already in progress and we have the cancel token to cancel that call
      cancelTokenMap["renewal-monthly-summary-agent-wise"]["token"]();
      cancelTokenMap["renewal-monthly-summary-agent-wise"]["token"] = null;
    }

    const url = `sapna/v1/report/renewal-summary/agentwise/${id}`;

    //reset details before fetch
    dispatch({
      type: RENEWAL_SUMMARY_AGENT_WISE,
      renewalSummaryAgentWise: null,
    });
    cancelTokenMap["renewal-monthly-summary-agent-wise"]["callInProgress"] = true;
    return instance({
      url,
      method: "post",
      data: { ...modifyFilters(filter) },
      timeout,
      cancelToken: new CancelToken((cToken) => {
        cancelTokenMap["renewal-monthly-summary-agent-wise"]["token"] = cToken;
      }),
    })
      .then((response) => {
        if (response.data.data) {
          const renewalSummaryAgentWise = moveSelectedUserToTop(
            id,
            response.data.data
          );
          dispatch({
            type: RENEWAL_SUMMARY_AGENT_WISE,
            renewalSummaryAgentWise,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
            dispatch({
              type: RENEWAL_SUMMARY_AGENT_WISE,
              renewalSummaryAgentWise: [],
            });
          }
        }
        cancelTokenMap["renewal-monthly-summary-agent-wise"][
          "callInProgress"
        ] = false;
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          // handle error
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
          dispatch({
            type: RENEWAL_SUMMARY_AGENT_WISE,
            renewalSummaryAgentWise: [],
          });
          cancelTokenMap["renewal-monthly-summary-agent-wise"][
            "callInProgress"
          ] = false;
        }
      });
  };
};

export const getRenewalAlertDetails = (id, filter) => {
  const CancelToken = axios.CancelToken;
  return (dispatch) => {
    if (
      cancelTokenMap["renewal-alert"]["callInProgress"] &&
      cancelTokenMap["renewal-alert"]["token"]
    ) {
      // another API call is already in progress and we have the cancel token to cancel that call
      cancelTokenMap["renewal-alert"]["token"]();
      cancelTokenMap["renewal-alert"]["token"] = null;
    }

    const url = `sapna/v1/report/renewal-alert/${id}`;

    //reset details before fetch
    dispatch({
      type: RENEWAL_ALERT_DETAILS,
      renewalAlertDetails: null,
    });
    cancelTokenMap["renewal-alert"]["callInProgress"] = true;
    return instance({
      url,
      method: "post",
      data: filter,
      timeout,
      cancelToken: new CancelToken((cToken) => {
        cancelTokenMap["renewal-alert"]["token"] = cToken;
      }),
    })
      .then((response) => {
        if (response.data.data) {
          const renewalAlertDetails = moveSelectedUserToTop(id, response.data.data);
          dispatch({
            type: RENEWAL_ALERT_DETAILS,
            renewalAlertDetails,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
            dispatch({
              type: RENEWAL_ALERT_DETAILS,
              renewalAlertDetails: [],
            });
          }
        }
        cancelTokenMap["renewal-alert"]["callInProgress"] = false;
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          // handle error
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
          dispatch({
            type: RENEWAL_ALERT_DETAILS,
            renewalAlertDetails: [],
          });
          cancelTokenMap["renewal-alert"]["callInProgress"] = false;
        }
      });
  };
};

export const getUpComingRenewalWeekly = (id) => {
  return (dispatch) => {
    const url = `sapna/v1/report/renewal-alert/${id}`;
    const { sellerType, profileType, upForRenewal } =
      Constants.getDefaultRenewalReportFilter();
    return instance({
      url,
      method: "post",
      data: {
        sellerType,
        profileType,
        elapseFlag: [],
        upForRenewal: [upForRenewal[0]],
      },
    })
      .then((response) => {
        if (response.data.data) {
          const renewalDetailsWeekly = Constants.addUpComingRenewals(
            response.data.data
          );
          dispatch({
            type: SET_UPCOMING_RENEWALS_WEEKLY,
            renewalDetailsWeekly,
            errMsg: null,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: SET_UPCOMING_RENEWALS_WEEKLY,
              renewalDetailsWeekly: null,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: SET_UPCOMING_RENEWALS_WEEKLY,
          renewalDetailsWeekly: null,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getUpComingRenewalMonthly = (id) => {
  return (dispatch) => {
    const url = `sapna/v1/report/renewal-alert/${id}`;
    const { sellerType, profileType, upForRenewal } =
      Constants.getDefaultRenewalReportFilter();

    return instance({
      url,
      method: "post",
      data: {
        sellerType,
        profileType,
        elapseFlag: [],
        upForRenewal: upForRenewal.slice(0, 3),
      },
    })
      .then((response) => {
        if (response.data.data) {
          const renewalDetailsMonthly = Constants.addUpComingRenewals(
            response.data.data
          );
          dispatch({
            type: SET_UPCOMING_RENEWALS_MONTHLY,
            renewalDetailsMonthly,
            errMsg: null,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: SET_UPCOMING_RENEWALS_MONTHLY,
              renewalDetailsMonthly: null,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: SET_UPCOMING_RENEWALS_MONTHLY,
          renewalDetailsMonthly: null,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getChildAgents = (id, dispatchType) => {
  return (dispatch) => {
    //prevent calling child-list multiple times
    if (agentListCache[id]) {
      dispatch({
        type: dispatchType,
        value: agentListCache[id],
      });
      return;
    }
    const url = `/madrox/app/v1/entity/child-list?userId=${id}&status=active&fromCache=false&selector={"fields":["name", "userId"]}`;
    return instance({
      url,
    })
      .then((response) => {
        if (response.data.data) {
          const childAgents = response.data.data;
          agentListCache[id] = childAgents;
          dispatch({
            type: dispatchType,
            value: childAgents,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const downloadReportCSV = (id, filter, reportType, dispatchType) => {
  return (dispatch) => {
    const url = `sapna/v1/report/${reportType}/${id}/download`;
    const reportDisptachType = dispatchType;
    const data = filter.month ? { ...modifyFilters(filter) } : filter;
    dispatch({
      type: reportDisptachType,
      value: true,
    });
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        if (response.data.data) {
          const renewalDetailsCSV = response.data.data;
          triggerFileDownload(`${reportType}.xls`, renewalDetailsCSV);
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
          }
        }
        dispatch({
          type: reportDisptachType,
          value: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        dispatch({
          type: reportDisptachType,
          value: false,
        });
      });
  };
};

export const getAllCities = () => {
  return (dispatch) => {
    const url = `/petra/data/v2/entity/city?selector={"fields":["label"],"paging":{"start":0,"rows":1000}}`;
    return instance({
      url,
    })
      .then((response) => {
        if (response.data.data) {
          const allCities = response.data.data;

          dispatch({
            type: SET_ALL_CITIES,
            value: allCities,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getRenewalReportAverages = (userId, filter) => {
  return (dispatch) => {
    const url = `/sapna/v1/report/renewal-report/${userId}/detail`;
    dispatch({
      type: SET_RENEWAL_REPORT_AVERAGES,
      value: null,
    });
    return instance({
      url,
      method: "post",
      data: { ...modifyFilters(filter) },
      timeout,
    })
      .then((response) => {
        if (response.data.data) {
          const averages = response.data.data;

          dispatch({
            type: SET_RENEWAL_REPORT_AVERAGES,
            value: averages,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getMonthlyChartAverages = (userId, filter) => {
  return (dispatch) => {
    const url = `/sapna/v1/report/renewal-summary/monthwise/${userId}/detail`;
    dispatch({
      type: SET_MONTHLY_CHART_AVERAGES,
      value: null,
    });
    return instance({
      url,
      method: "post",
      data: { ...modifyFilters(filter) },
      timeout,
    })
      .then((response) => {
        if (response.data.data) {
          const averages = response.data.data;

          dispatch({
            type: SET_MONTHLY_CHART_AVERAGES,
            value: averages,
          });
        } else {
          if (response.data.error && response.data.error.msg) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: response.data.error.msg,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
