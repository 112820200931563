import {
  SET_AD_PRODUCT_CONFIG_FORM_DATA,
  RESET_AD_PRODUCT_CONFIG_FORM_DATA,
  UPDATE_AD_PRODUCT_CONFIG,
} from "../actions/adProductConfig.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_AD_PRODUCT_CONFIG_FORM_DATA:
      // eslint-disable-next-line no-case-declarations
      const { section, data } = action.payload;
      return {
        ...state,
        adProductConfig: {
          ...state.adProductConfig,
          [section]: data,
        },
      };

    case RESET_AD_PRODUCT_CONFIG_FORM_DATA:
      return {
        ...state,
        adProductConfig: defaultState.adProductConfig,
      };
    case UPDATE_AD_PRODUCT_CONFIG: {
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Ad Product Configurations Updated!",
          type: "success",
        },
      };
    }
  }
};

export default reducer;
