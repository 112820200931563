import { ownerOpportunityCommonLabel } from "./ownerGA";
export default {
  CALL_TRIGGERED: (globalState, localState) => {
    return ownerOpportunityCommonLabel({
      action: "call_triggered",
      globalState,
      localState,
    });
  },
  CALL_TRIGGERED_FAILURE: (globalState, localState) => {
    return ownerOpportunityCommonLabel({
      action: "call_triggered_failure",
      globalState,
      localState,
    });
  },
  CALL_STATUS_RECEIVED: (globalState) => {
    const communicationId = getCommunicationId(globalState);
    return ownerOpportunityCommonLabel({
      action: "call_status_received",
      globalState,
      localState: { communicationId },
    });
  },
  CALL_STATUS_RECEIVED_FAILURE: (globalState) => {
    const communicationId = getCommunicationId(globalState);
    return ownerOpportunityCommonLabel({
      action: "call_status_received_failure",
      globalState,
      localState: { communicationId },
    });
  },
};

const getCommunicationId = (globalState) => {
  const {
    callingQueueOpportunity: {
      salesOpportunityLastCallDetail: { communicationId } = {},
    } = {},
    callingId,
  } = globalState;
  return communicationId || callingId;
};
