import {
  GOT_CONTENT_OPPORTUNITIES,
  OPPORTUNITY_PACKAGE_STATUS_CHANGE,
} from "../actions/contentPackagesPage.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case GOT_CONTENT_OPPORTUNITIES:
      return {
        ...state,
        contentOpportunities: action.contentOpportunities,
      };
    case OPPORTUNITY_PACKAGE_STATUS_CHANGE:
      return {
        ...state,
        opportunityPackageStatusChange: action.payload,
      };
  }
};

export default reducer;
