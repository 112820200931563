import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";
export const FAMILY_PACKAGES = "FAMILY_PACKAGES";
export const DISABLE_PACKAGES = "DISABLE_PACKAGES";
export const REQUIRED_PRICING_PARAMETERS_LIST = "REQUIRED_PRICING_PARAMETERS_LIST";
export const PACKAGE_CREATED = "PACKAGE_CREATED";
export const PACKAGE_UPDATED = "PACKAGE_UPDATED";
export const PACKAGE_UPDATION_FAILED = "PACKAGE_UPDATION_FAILED";
export const PACKAGE_NETSUITE_UPDATED = "PACKAGE_NETSUITE_UPDATED";
export const PACKAGE_NETSUITE_UPDATION_FAILED = "PACKAGE_NETSUITE_UPDATION_FAILED";
export const PACKAGE_DELETED = "PACKAGE_DELETED";
export const PRODUCT_MAPPINGS_ADDED = "PRODUCT_MAPPINGS_ADDED";
export const PRODUCT_MAPPINGS_DELETED = "PRODUCT_MAPPINGS_DELETED";
export const PRODUCT_MAPPINGS = "PRODUCT_MAPPINGS";
export const RESET_PACKAGE_UPDATION_STATUS = " RESET_PACKAGE_UPDATION_STATUS";
const showError = (err, dispatch) => {
  return dispatch({
    type: Constants.API_FAIL,
    errMsg: Constants.getErrorMessage(err),
  });
};
export const getPackagesForGivenFamilyIds = (familyIds, activeOnly = true) => {
  return (dispatch) => {
    if (!familyIds || !familyIds.length) return;
    Promise.all(
      familyIds.map((familyId) => {
        const url = `/sapna/v2/get-packages?package_family_id=${familyId.id}&active_only=${activeOnly}`;
        return instance({
          url,
          method: "GET",
        }).then((response) => {
          return response.data.data;
        });
      })
    )
      .then((response) => {
        dispatch({
          type: FAMILY_PACKAGES,
          payload: getFamilyPackageMap(familyIds, response),
        });
      })
      .catch(() => {
        dispatch({
          type: "API_FAIL",
        });
      });
  };
};
export const deletePackages = (data) => {
  return (dispatch) => {
    const url = `/sapna/v2/get-packages/delete`;
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        dispatch({
          type: PACKAGE_DELETED,
          payload: response.data.data ? true : false,
        });
      })
      .catch((err) => showError(err, dispatch));
  };
};
export const getPackageParameters = () => {
  return (dispatch) => {
    const url = "/sapna/v1/package-parameters";
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: REQUIRED_PRICING_PARAMETERS_LIST,
          payload: response.data.data,
        });
      })
      .catch((err) => showError(err, dispatch));
  };
};
export const createPackage = (data) => {
  const url = "/sapna/v2/get-packages/create";
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        dispatch({
          type: PACKAGE_CREATED,
          payload: response.data.data ? true : false,
        });
      })
      .catch((err) => {
        dispatch({
          type: PACKAGE_CREATED,
          payload: false,
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const updatePackage = (data, packageId) => {
  const url = `/sapna/v2/get-packages/update/${packageId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        dispatch({
          type: PACKAGE_UPDATED,
          payload: response.data.data ? true : false,
        });
      })
      .catch((err) => {
        dispatch({
          type: PACKAGE_UPDATION_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const updatePackageNetsuiteStatus = (data, packageFamilyId) => {
  const url = `/sapna/v1/packages/enable-netsuite?package_family_id=${packageFamilyId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        const updatedPackage = response?.data?.data || [];
        if (updatedPackage.length && updatedPackage[0].isNetsuiteEnabled)
          dispatch({
            type: PACKAGE_NETSUITE_UPDATED,
            payload: true,
          });
        else
          dispatch({
            type: PACKAGE_NETSUITE_UPDATION_FAILED,
            errMsg: "Allocation percentage is not 100 for given package id",
          });
      })
      .catch((err) => {
        dispatch({
          type: PACKAGE_NETSUITE_UPDATION_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getProductMappings = (packageId) => {
  const url = `/sapna/v2/get-packages/details/${packageId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: PRODUCT_MAPPINGS,
          payload: response.data.data.packageProductMappings,
          productPackageId: packageId,
        });
      })
      .catch((err) => showError(err, dispatch));
  };
};
export const addMappings = (packageId, data) => {
  const url = `/sapna/v2/package-products/create-mappings/${packageId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        dispatch({
          type: PRODUCT_MAPPINGS_ADDED,
          status: response.data.data ? true : false,
        });
      })
      .catch((err) => showError(err, dispatch));
  };
};
export const deleteMappings = (productsIds, packageId) => {
  const url = `/sapna/v2/package-products/delete-mappings?productIds=${productsIds.toString()}&packageId=${packageId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "delete",
    })
      .then(() => {
        dispatch({
          type: PRODUCT_MAPPINGS_DELETED,
          status: true,
        });
      })
      .catch((err) => showError(err, dispatch));
  };
};
const getFamilyPackageMap = (familyIds, packageList) => {
  const packageMap = {};
  familyIds.forEach((id, index) => {
    packageMap[id] = packageList[index];
  });
  return packageList.reduce(
    (list, value, index) =>
      list.concat(
        value.map((pkg) => ({
          ...pkg,
          familyId: familyIds[index].id,
          familyName: familyIds[index].name,
        }))
      ),
    []
  );
};
export const resetUpdationStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PACKAGE_UPDATION_STATUS,
    });
  };
};
