import { OWNER_PACKAGE_CITIES } from "../actions/account.actions";
import {
  AGENT_STATUS_UPDATE,
  CALLING_CUSTOMER,
  CALLING_QUEUE,
  CALLING_QUEUE_CASE,
  CALLING_QUEUE_COUNT,
  GET_OWNER_FIELD_LIST,
  OWNER_DASHBOARD_STATS,
  OWNER_PACKAGES,
  PAYMENT_DONE_ACTIVATION_COUNT,
  PAYMENT_DONE_ACTIVATION_DATA,
  RESET_CALLING_CUSTOMER,
  RESET_OWNER_CALLING_STATUS,
  SEND_PACKAGE_INFO,
  SHARE_LINK,
  PACKAGE_HISTORY,
  OWNER_COMMERCIAL_PACKAGES,
  CHANGE_AGENT_STATUS_API,
  AGENT_NUMBER_INFO,
  CALL_HANGUP_SUCCESS,
  CALL_HANGUP_FAIL,
  RESET_CALL_HANGUP,
} from "../actions/teleSalesDashboard.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case OWNER_DASHBOARD_STATS:
      return {
        ...state,
        filters: action.payload.filters,
        dashboard: {
          ...state.dashboard,
          ...action.payload.dashboard,
        },
      };
    case GET_OWNER_FIELD_LIST:
      return {
        ...state,
        data: action.data,
      };
    case CALLING_CUSTOMER: {
      const {
        payload: { status, id, message },
      } = action;
      return {
        ...state,
        ownerCallingStatus: true,
        snackbar: {
          state: true,
          message: message,
          type: status === "error" ? "error" : "info",
        },
        callingId: id,
      };
    }
    case RESET_CALLING_CUSTOMER:
      return {
        ...state,
        callingId: null,
      };
    case OWNER_PACKAGES:
      return {
        ...state,
        ownerPackageMap: action.payload,
      };
    case OWNER_COMMERCIAL_PACKAGES:
      return {
        ...state,
        ownerCommercialPackageMap: action.payload,
      };
    case OWNER_PACKAGE_CITIES: {
      const { serviceType, packageType, cities } = action.payload;
      return {
        ...state,
        ownerPackageMap: {
          ...state.ownerPackageMap,
          [serviceType]: {
            ...state.ownerPackageMap[serviceType],
            [packageType]: {
              ...state.ownerPackageMap[serviceType][packageType],
              cities,
            },
          },
        },
      };
    }
    case SHARE_LINK:
      return {
        ...state,
        shareLinkCreatedOpp: action.payload,
        snackbar: {
          state: true,
          message: action.snackbar,
          type: "info",
        },
      };
    case SEND_PACKAGE_INFO:
      return {
        ...state,
        snackbar: {
          state: true,
          message: action.payload,
          type: "info",
        },
      };
    case CALLING_QUEUE: {
      return {
        ...state,
        data: {
          totalCount: action.data.totalCount,
          results: action.data.data,
        },
        callingId: null,
      };
    }
    case CALLING_QUEUE_COUNT: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [action.queueType]: action.payload,
        },
      };
    }
    case CALLING_QUEUE_CASE:
      return {
        ...state,
        callingQueueOpportunity: action.payload.pullCaseData || {},
        timerStartTime: action.payload.startOfTimer,
      };
    case AGENT_STATUS_UPDATE:
      return {
        ...state,
        agentStatus: action.payload,
      };
    case AGENT_NUMBER_INFO:
      return {
        ...state,
        agentNumberInfo: action.payload,
      };
    case CHANGE_AGENT_STATUS_API:
      return {
        ...state,
        agentStatusApiLoading: action.payload,
      };
    case RESET_OWNER_CALLING_STATUS:
      return {
        ...state,
        ownerCallingStatus: null,
      };
    case PAYMENT_DONE_ACTIVATION_COUNT: {
      const { key, totalCount } = action.payload;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [key]: totalCount,
        },
      };
    }
    case PAYMENT_DONE_ACTIVATION_DATA: {
      const { totalCount, results } = action.payload;
      return {
        ...state,
        data: {
          totalCount,
          results,
        },
        callingId: null,
      };
    }
    case PACKAGE_HISTORY: {
      const { packagesHistory } = action.payload;
      return {
        ...state,
        packagesHistory,
      };
    }
    case CALL_HANGUP_SUCCESS: {
      return {
        ...state,
        snackbar: {
          state: true,
          type: "success",
          duration: action.duration || null,
          message: action.errMsg || "Action successfully completed!",
        },
        callHangupSuccess: true,
      };
    }
    case CALL_HANGUP_FAIL: {
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          duration: action.duration || null,
          message: action.errMsg || "Something went wrong!",
        },
        callHangupSuccess: null,
      };
    }
    case RESET_CALL_HANGUP: {
      return {
        ...state,
        callHangupSuccess: null,
      };
    }
  }
};

export default reducer;
