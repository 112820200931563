import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const GET_PROFILE_UUIDS_SUCCESS = "GET_PROFILE_UUIDS_SUCCESS";
export const GET_PROFILE_UUIDS_RESET = "GET_PROFILE_UUIDS_RESET";

export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_RESET = "GET_PACKAGES_RESET";

export const POST_PACKAGE_ADD_RESET = "POST_PACKAGE_ADD_RESET";
export const POST_PACKAGE_ADD_SUCCESS = "POST_PACKAGE_ADD_SUCCESS";

export const STOP_PACKAGE_ADD_RESET = "STOP_PACKAGE_ADD_RESET";
export const STOP_PACKAGE_ADD_SUCCESS = "STOP_PACKAGE_ADD_SUCCESS";

export const MA_SHOW_SNACKBAR = "MA_SHOW_SNACKBAR";
export const MA_RESET_SNACKBAR = "MA_RESET_SNACKBAR";

const _dispatchResetSnackbar = (dispatch) => {
  dispatch({
    type: MA_RESET_SNACKBAR,
  });
};

export const resetProfileUuids = () => {
  return (dispatch) =>
    dispatch({
      type: GET_PROFILE_UUIDS_RESET,
    });
};

export const fetchPackageCounts = (searchConfig, profileUuid) => {
  return (dispatch) => {
    const configForActiveSlots = { ...searchConfig, profileUuid };
    const configForUpcomingSlots = { ...searchConfig, statusId: 11, profileUuid };

    return Promise.all([
      dispatch(getFilteredPackages(configForActiveSlots)),
      dispatch(getFilteredPackages(configForUpcomingSlots)),
    ])
      .then(([activeSlotCount, upcomingSlotCount]) => {
        return activeSlotCount + upcomingSlotCount;
      })
      .catch((err) => {
        dispatch({
          type: MA_SHOW_SNACKBAR,
          snackbarType: "error",
          msg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getFilteredPackages = (searchConfig) => {
  const { statusId, profileUuid, pageNumber, pageSize } = searchConfig;
  let url = `${process.env.DOMAINS.ADVERT}api/v0/broker-subscription-slots/list?page_number=${pageNumber}&page_size=${pageSize}`;

  if (statusId) url = url.concat(`&status_id=${statusId}`);
  if (profileUuid) url = url.concat(`&profile_uuid=${profileUuid}`);

  return (dispatch) =>
    instance({
      url,
      withCredentials: true,
    })
      .then((response) => {
        let {
          data: {
            data: { broker_subscription_slot_dtos = [], total_count } = {},
          } = {},
        } = response;
        dispatch({
          type: GET_PACKAGES_SUCCESS,
          payload: { total: total_count, data: broker_subscription_slot_dtos },
        });
        return total_count;
      })
      .catch((err) => {
        dispatch({
          type: MA_SHOW_SNACKBAR,
          snackbarType: "error",
          msg: Constants.getErrorMessage(err),
        });
      })
      .finally(() => _dispatchResetSnackbar(dispatch));
};

export const getMAProfileUuids = (q, isEdit) => {
  const url = `${process.env.DOMAINS.LOGIN}api/v1/predict-profiles?profile_type=Broker&include_inactive=false&q=${q}`;

  return (dispatch) =>
    instance({
      url,
      withCredentials: true,
    })
      .then((response) => {
        let { data = [] } = response;
        dispatch({
          type: GET_PROFILE_UUIDS_SUCCESS,
          payload: data,
          key: isEdit ? "editProfileUuids" : "maProfileUuids",
        });
      })
      .catch((err) => {
        dispatch({
          type: MA_SHOW_SNACKBAR,
          snackbarType: "error",
          msg: Constants.getErrorMessage(
            err,
            "Authentication Required. Please login to account panel"
          ),
        });
      })
      .finally(() => _dispatchResetSnackbar(dispatch));
};

export const postNewMAPackage = (packageData, isEdit = false) => {
  if (!packageData) return;
  let type = "create-packages";

  if (isEdit) {
    type = "update-packages";
  }

  const url = `${process.env.DOMAINS.ADVERT}api/v0/broker-subscription-slots/${type}?jsession=true`;
  const serializedPackageData = {};

  Object.entries(packageData).forEach(
    ([key, value]) =>
      (serializedPackageData[Constants.camelCaseToSnakeCase(key)] = value)
  );

  return (dispatch) => {
    dispatch({
      type: POST_PACKAGE_ADD_RESET,
    });
    instance({
      url,
      method: "POST",
      withCredentials: true,
      data: serializedPackageData,
    })
      .then((response) => {
        let { data: { error } = {} } = response;
        if (error) {
          dispatch({
            type: MA_SHOW_SNACKBAR,
            snackbarType: "error",
            msg: `Error: ${JSON.stringify(error)}`,
          });
        } else {
          dispatch({
            type: POST_PACKAGE_ADD_SUCCESS,
          });
          dispatch({
            type: MA_SHOW_SNACKBAR,
            msg: isEdit
              ? `Package edited successfully`
              : `Package added successfully`,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: MA_SHOW_SNACKBAR,
          snackbarType: "error",
          msg: Constants.getErrorMessage(err),
        });
      })
      .finally(() => _dispatchResetSnackbar(dispatch));
  };
};

export const stopMAPackage = (id) => {
  if (!id) return;

  const url = `${process.env.DOMAINS.ADVERT}api/v0/broker-subscription-slots/stop-packages?ids=${id}`;

  return (dispatch) => {
    dispatch({
      type: STOP_PACKAGE_ADD_RESET,
    });
    instance({
      url,
      method: "GET",
      withCredentials: true,
    })
      .then((response) => {
        let { data: { error } = {} } = response;
        if (error) {
          dispatch({
            type: MA_SHOW_SNACKBAR,
            snackbarType: "error",
            msg: `Error: ${JSON.stringify(error)}`,
          });
        } else {
          dispatch({
            type: STOP_PACKAGE_ADD_SUCCESS,
          });
          dispatch({
            type: MA_SHOW_SNACKBAR,
            msg: `Package Stopped successfully`,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: MA_SHOW_SNACKBAR,
          snackbarType: "error",
          msg: Constants.getErrorMessage(err),
        });
      })
      .finally(() => _dispatchResetSnackbar(dispatch));
  };
};
