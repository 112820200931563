import { MORTGAGE_CITY_HEADS } from "../actions/mortgageCityHeads.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case MORTGAGE_CITY_HEADS:
      return {
        ...state,
        mortgageCityHeads: action.payload,
      };
  }
};

export default reducer;
