import {
  RESET_AM_FORM_DATA,
  SET_AM_FORM_DATA,
  SET_AM_LIST,
  PROJECTS_LIST_AM,
  SET_SLOT_DEDICATED_DATA_AM,
  SET_PROJECT_ID_AM,
  SET_SELLERS_LIST_AM,
  SET_FAS_CITIES,
  SET_FSL_CITIES,
} from "../actions/audienceMaximizer.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_AM_FORM_DATA: {
      const { section, data } = action.payload;
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          formData: {
            ...state.audienceMaximizer.formData,
            [section]: data,
          },
        },
      };
    }
    case RESET_AM_FORM_DATA: {
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          formData: {},
          editDataLoaded: false,
        },
      };
    }
    case SET_AM_LIST: {
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          list: action.payload.results || [],
          totalPages:
            action.payload.totalPages + (action.payload.hasNextPage ? 1 : 0),
        },
      };
    }
    case PROJECTS_LIST_AM: {
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          projects: action.payload || [],
        },
      };
    }
    case SET_PROJECT_ID_AM: {
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          projectMap: action.payload,
        },
      };
    }
    case SET_SELLERS_LIST_AM: {
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          sellerList: action.payload.sellerList,
          projectRegion: action.payload.projectRegion,
        },
      };
    }
    case SET_SLOT_DEDICATED_DATA_AM: {
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          formData: {
            ...action.payload,
          },
          editDataLoaded: true,
        },
      };
    }
    case SET_FAS_CITIES: {
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          fasCities: action.payload,
        },
      };
    }
    case SET_FSL_CITIES: {
      return {
        ...state,
        audienceMaximizer: {
          ...state.audienceMaximizer,
          fslCities: action.payload,
        },
      };
    }
  }
};

export default reducer;
