import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";

export const GET_PRODUCT_CONFIG = "GET_PRODUCT_CONFIG";

const showError = (err, dispatch) => {
  {
    return dispatch({
      type: Constants.API_FAIL,
      errMsg: Constants.getErrorMessage(err),
    });
  }
};
export const getProductConfigHelper = (ppmId) => {
  return (dispatch) => {
    getProductConfig(dispatch, ppmId);
  };
};

const parseParametersAndReturn = (productConfig = []) => {
  return productConfig.map(({ parameters, ...restParameters }) => {
    const obj = JSON.parse(parameters);
    let ppQuantity, ppPromotedDays, ppCityUUID, ppHSR;
    if (obj["quantity"]) ppQuantity = obj["quantity"];
    if (obj["promotedDays"]) ppPromotedDays = obj["promotedDays"];
    if (obj["cityUUID"]) ppCityUUID = obj["cityUUID"];
    if (obj["housingSubscriptionRegionId"])
      ppHSR = obj["housingSubscriptionRegionId"];
    return { ...restParameters, ppQuantity, ppPromotedDays, ppHSR, ppCityUUID };
  });
};

const getProductConfig = (dispatch, ppmId) => {
  const url = `/sapna/product-configs/${ppmId}`;
  return instance({
    url,
    method: "GET",
  })
    .then(({ data: { data: productConfig = [] } = {} }) => {
      dispatch({
        type: GET_PRODUCT_CONFIG,
        productConfig: parseParametersAndReturn(productConfig),
      });
    })
    .catch((err) => showError(err, dispatch));
};

export const createProductConfig = (packageProductMappingId, productConfigInfo) => {
  const url = `/sapna/product-configs/${packageProductMappingId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "POST",
      data: productConfigInfo,
    })
      .then((response) => {
        getProductConfig(dispatch, packageProductMappingId);
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: "Product Config Created Successfully!",
        });
        return response;
      })
      .catch((err) => showError(err, dispatch));
  };
};

export const updateProductConfig = (id, productConfigInfo) => {
  const url = `/sapna/product-configs/${id}`;
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data: productConfigInfo,
    })
      .then((response) => {
        getProductConfig(dispatch, productConfigInfo.packageProductMappingId);
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: "Product Config Updated Successfully!",
        });
        return response;
      })
      .catch((err) => showError(err, dispatch));
  };
};

export const deleteProductConfig = (id, ppmId) => {
  const url = `/sapna/product-configs/${id}`;
  return (dispatch) => {
    return instance({
      url,
      method: "DELETE",
    })
      .then((response) => {
        getProductConfig(dispatch, ppmId);
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: "Product Config Deleted Successfully!",
        });
        return response;
      })
      .catch((err) => showError(err, dispatch));
  };
};
