import {
  SET_VT_LIST,
  RESET_VT_LIST,
  UPDATE_VT_DATA,
  RESET_VT_FORM_DATA,
  SET_VT_ALL_FORM_DATA,
  SET_VT_FORM_DATA,
} from "../actions/virtualTour.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_VT_LIST: {
      return {
        ...state,
        virtualTour: {
          ...state.virtualTour,
          ...action.payload,
        },
      };
    }
    case RESET_VT_LIST: {
      return {
        ...state,
        virtualTour: {
          ...state.virtualTour,
          list: [],
          data: {},
        },
      };
    }
    case UPDATE_VT_DATA: {
      return {
        ...state,
        virtualTour: {
          ...state.virtualTour,
          data: {
            ...state.virtualTour.data,
            [action.payload.id]: {
              ...(state.virtualTour.data[action.payload.id] || {}),
              ...action.payload.data,
            },
          },
        },
      };
    }
    case SET_VT_FORM_DATA: {
      const { section, data } = action.payload;
      return {
        ...state,
        virtualTour: {
          ...state.virtualTour,
          formData: {
            ...state.virtualTour.formData,
            [section]: data,
          },
        },
      };
    }
    case RESET_VT_FORM_DATA: {
      return {
        ...state,
        virtualTour: {
          ...state.virtualTour,
          formData: {},
        },
      };
    }
    case SET_VT_ALL_FORM_DATA: {
      return {
        ...state,
        virtualTour: {
          ...state.virtualTour,
          formData: action.payload,
        },
      };
    }    
  }
};

export default reducer;
