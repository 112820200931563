export const intersection = (a, b) =>
  (a || []).filter((n) => (b || []).indexOf(n) > -1);
export const difference = (a, b) =>
  (a || []).filter((n) => (b || []).indexOf(n) === -1);

export const aggregateToObject = (key, data) => {
  const result = {};
  data.forEach((res) => {
    if (!result[res[key]]) {
      result[res[key]] = [];
    }
    result[res[key]].push(res);
  });

  return result;
};
