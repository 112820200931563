import {
  APPROVAL_LANDING_PAGE_DETAILS,
  APPROVAL_LANDING_PAGE_DETAILS_ERROR,
  SUBMIT_APPROVAL_SUCCESS,
  SUBMIT_APPROVAL_ERROR,
  SUBMIT_APPROVAL_IN_PROGRESS,
  SUBMIT_REJECTION_IN_PROGRESS,
} from "../actions/approvalPage.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case APPROVAL_LANDING_PAGE_DETAILS:
      return {
        ...state,
        approvalLandingPageDetails: action.payload,
        approvalLandingPageDetailsError: null,
      };
    case APPROVAL_LANDING_PAGE_DETAILS_ERROR:
      return {
        ...state,
        approvalLandingPageDetails: null,
        approvalLandingPageDetailsError: action.payload,
      };
    case SUBMIT_APPROVAL_SUCCESS:
      return {
        ...state,
        submitApprovalSuccess: action.payload,
        submitApprovalError: null,
        submitApprovalInProgress: false,
        submitRejectionInProgress: false,
      };
    case SUBMIT_APPROVAL_ERROR:
      return {
        ...state,
        submitApprovalSuccess: null,
        submitApprovalError: action.payload,
        submitApprovalInProgress: false,
        submitRejectionInProgress: false,
      };
    case SUBMIT_APPROVAL_IN_PROGRESS:
      return {
        ...state,
        submitApprovalInProgress: action.payload,
      };
    case SUBMIT_REJECTION_IN_PROGRESS:
      return {
        ...state,
        submitRejectionInProgress: action.payload,
      };
  }
};

export default reducer;
