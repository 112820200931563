import { NETSUIT_DATA_GET } from "../actions/erpTracking.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case NETSUIT_DATA_GET:
      return {
        ...state,
        erpData: action.payload,
      };
  }
};

export default reducer;
