import { updateDuration } from "../../utils/dateUtils";

const entityType = 2;
const devices = [1, 2]; // 1 -> web, 2 -> mobile
export const getFormattedDate = (dateStr) => {
  const date = new Date(dateStr);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};
const getDurationInDays = (startDate, endDate) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const getValue = function (obj, props) {
  return props.split(".").reduce(function (acc, p) {
    if (acc == null) return;
    return acc[p];
  }, obj);
};

const updateValue = function (obj, props) {
  obj.forEach((element, index) => {
    if (getValue(element, props)) {
      obj[index].imageData.absolute_url = obj[index].imageData.absolute_url.replace(
        "version",
        "original"
      );
    }
  });
  return obj;
};
export const getMicrositeProductId = () => {
  let id;
  switch (process.env.NODE_ENV) {
    case "development":
      id = "75";
      break;
    default:
      id = "75";
      break;
  }
  return id;
};

export const createSlotApiData = (data, update) => {
  const productId = getMicrositeProductId();
  const {
    slotDetails: {
      startDate,
      endDate,
      slotId,
      deliver_developer_broker_leads = true,
    } = {},
    projectDetails: { projectId, projectName } = {},
    polygonDetails: { polygonId, polygonName } = {},
    productDetails: { opportunityId, amount, urlText } = {},
    header_logo,
    ad_logo,
    carouselTag: { carouselTag } = {},
    project_carousel = {},
    realm_carousel = [],
    carouselImages = [],
    overviewImages = [],
    offer_details: { offerTitle } = {},
    usp_details: { project_usp: projectUSP } = {},
    offer_items = [],
    themeDetails: { theme } = {},
    highlights = [],
    paymentPlans,
    overview_details = {},
    digitour = {},
    testimonials = [],
    highlights_image,
  } = data;

  const basicData = {
    entity_id: projectId,
    entity_type_id: entityType,
    devices,
    duration_in_days: getDurationInDays(startDate, endDate),
    start_date: getFormattedDate(startDate),
    end_date: getFormattedDate(endDate),
    polygon_uuids: polygonId,
    opportunity_id: opportunityId,
    amount,
    deliver_developer_broker_leads,
  };
  const redirectionURL = urlText
    ? `microsite/${projectId}-${urlText.split(" ").join("-")}`
    : `microsite/${projectId}`;
  const productDetails = {
    theme,
    redirection_url: redirectionURL,
    project_name: projectName,
    polygon_name: polygonName,
    carousel_tag: { carousel_tag: carouselTag },
    header_logo,
    ad_logo,
    project_carousel: {
      ...project_carousel,
      carousel_images: carouselImages,
    },
    realm_carousel,
    offer_details: {
      offer_title: offerTitle,
      offer_items,
    },
    usp_details: { project_usp: projectUSP },
    highlights,
    payment_plans: paymentPlans,
    overview_details: {
      ...overview_details,
      overview_images: overviewImages,
    },
    digitour,
    testimonials,
    highlights_image,
  };

  let apiData = {
    products: {
      [productId]: {
        ...basicData,
        product_details: productDetails,
      },
    },
  };

  if (update) {
    basicData.entity_type = entityType;
    delete basicData.entity_type_id;

    apiData = {
      slot_id: slotId,
      package_details: {
        ...basicData,
      },
      promoted_products: {
        [productId]: {
          product_details: productDetails,
        },
      },
    };
  }
  return apiData;
};

export const micrositeSlotsParser = (micrositeSlots) => {
  micrositeSlots = micrositeSlots.map((slot) => {
    const {
      entity_id: entityId,
      slot_id: slotId,
      slot_start_date: startDate,
      slot_end_date: endDate,
      slot_status: status,
      seller_uuid: sellerId,
      polygon_uuids: ploygonIds = [],
    } = slot;
    return {
      entityId,
      slotId,
      startDate,
      endDate,
      status,
      sellerId,
      ploygonId: ploygonIds[0],
    };
  });
  return micrositeSlots;
};

export const micrositeDedicatedApiParser = (data) => {
  const {
    id: slotId,
    start_datetime: startDate,
    end_datetime: endDate,
    promoted_entity: { entity_id: entityId, supply_user_uuid: sellerId } = {},
    locality_details: [localityDetails = {}] = [],
    locality_info: {
      polygon_name: polygonName,
      polygon_uuid: polygonId,
    } = localityDetails,
    opportunity_id: opportunityId,
    amount,
    deliver_developer_broker_leads,
    product_details: {
      theme,
      redirection_url: redirectionURL = "",
      project_name: projectName,
      seller_name: sellerName,
      project_carousel = {},
      realm_carousel: realmCarousel = [],
      carousel_tag: { carousel_tag },
      header_logo,
      ad_logo,
      offer_details: { offer_title = "", offer_items = [] } = {},
      overview_details,
      overview_details: { overview_images } = {},
      usp_details: { project_usp: projectUSP = "" } = {},
      highlights,
      payment_plans: paymentPlans,
      digitour,
      testimonials,
      highlights_image,
    } = {},
  } = data;

  if (getValue(ad_logo, "image.absolute_url")) {
    ad_logo.image.absolute_url = ad_logo.image.absolute_url.replace(
      "version",
      "original"
    );
  }

  if (getValue(header_logo, "image.absolute_url")) {
    header_logo.image.absolute_url = header_logo.image.absolute_url.replace(
      "version",
      "original"
    );
  }

  if (getValue(paymentPlans, "image.absolute_url")) {
    paymentPlans.image.absolute_url = paymentPlans.image.absolute_url.replace(
      "version",
      "original"
    );
  }

  if (getValue(overview_details, "overviewImage.absolute_url")) {
    overview_details.overviewImage.absolute_url =
      overview_details.overviewImage.absolute_url.replace("version", "original");
  }

  project_carousel.carousel_images = updateValue(
    project_carousel.carousel_images,
    "imageData.absolute_url"
  );

  overview_details.overviewImages = updateValue(
    overview_details.overview_images,
    "imageData.absolute_url"
  );

  realmCarousel.forEach((element, index) => {
    if (element.imageData) {
      realmCarousel[index].imageData.absolute_url = realmCarousel[
        index
      ].imageData.absolute_url.replace("version", "original");
    }
  });

  testimonials.forEach((element, index) => {
    if (element.image) {
      testimonials[index].image.absolute_url = testimonials[
        index
      ].image.absolute_url.replace("version", "original");
    }
  });

  let urlText = "";
  if (redirectionURL) {
    urlText = redirectionURL.split("/")[1].split("-");
    urlText.shift();
    urlText = urlText.join("-");
  }
  const parsedData = {
    slotDetails: {
      slotId,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      duration: updateDuration(startDate, endDate),
      deliver_developer_broker_leads,
    },
    header_logo,
    ad_logo,
    productDetails: {
      opportunityId,
      amount,
      urlText,
    },
    projectDetails: {
      projectName,
      projectId: entityId,
    },
    polygonDetails: {
      polygonName,
      polygonId,
    },
    sellerDetails: {
      sellerId,
      sellerName,
    },
    themeDetails: {
      theme,
    },
    carouselTag: { carouselTag: carousel_tag },
    project_carousel,
    carouselImages: project_carousel.carousel_images || [],
    realmCarousel,
    offer_details: {
      offer_title,
    },
    overview_details,
    overviewImages: overview_images,
    digitour,
    testimonials,
    uspDetails: {
      project_usp: projectUSP,
    },
    offer_items,
    highlights,
    paymentPlans,
    highlights_image,
    firstUpdate: !theme,
  };
  return parsedData;
};
