import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const DISCOUNT_APPROVAL_MATRIX = "DISCOUNT_APPROVAL_MATRIX";
export const DISCOUNT_MATRIX_NETWORK_ERR = "DISCOUNT_MATRIX_NETWORK_ERR";

export const getDiscountApprovalMatrix = () => {
  return (dispatch) => {
    const url = "/sapna/v1/disount-approving-authorities/get-all-entries";
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: DISCOUNT_APPROVAL_MATRIX,
          payload: getDiscountApprovalMatrixForBrokerAndOwner(data),
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

function getDiscountApprovalMatrixForBrokerAndOwner(data) {
  return data
    ? data.filter(
        (level) =>
          level.clientCategoryId === Constants.clientCategoryId.Broker ||
          Constants.clientCategoryId.Owner
      )
    : [];
}

export const addDiscountApprovalEntry = (data, clientTypeId) => {
  return (dispatch) => {
    const url = `/sapna/v1/disount-approving-authorities/update/add-entries?clientTypeId=${clientTypeId}`;
    return instance({
      url,
      method: "POST",
      data,
    })
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: DISCOUNT_APPROVAL_MATRIX,
          payload: getDiscountApprovalMatrixForBrokerAndOwner(data),
        });
        dispatch({
          type: Constants.API_SUCCESS,
          errMsg: "Discount approval level added successfully!",
        });
      })
      .catch((err) => {
        dispatch({ type: DISCOUNT_MATRIX_NETWORK_ERR });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const deleteDiscountApprovalEntry = (data, clientTypeId) => {
  return (dispatch) => {
    const url = `/sapna/v1/disount-approving-authorities/update/delete-entries?clientTypeId=${clientTypeId}`;
    return instance({
      url,
      method: "DELETE",
      data,
    })
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: DISCOUNT_APPROVAL_MATRIX,
          payload: getDiscountApprovalMatrixForBrokerAndOwner(data),
        });
        dispatch({
          type: Constants.API_SUCCESS,
          errMsg: "Discount approval level removed successfully!",
        });
      })
      .catch((err) => {
        dispatch({ type: DISCOUNT_MATRIX_NETWORK_ERR });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
