import { instance } from '../../lib/api';
import Constants from '../../utils/CommonConstants';

export const GOT_CONTENT_OPPORTUNITIES = 'GOT_CONTENT_OPPORTUNITIES';
export const OPPORTUNITY_PACKAGE_STATUS_CHANGE = 'OPPORTUNITY_PACKAGE_STATUS_CHANGE';

export const getContentOpportunities = ({statusTypeId, statusIds}) => {
    const statusIdsParam = statusIds && statusIds.length ? `&packageStatusIds=${statusIds}` : '';
    const url = `/sapna/v2/opportunity?packageStatusTypeId=${statusTypeId}${statusIdsParam}`;
    return (dispatch) => {
      instance({
        url,
        method: 'GET'
      })
      .then((response) => {
        dispatch({
            type: GOT_CONTENT_OPPORTUNITIES,
            contentOpportunities: response.data.data
        });
      })
      .catch((err) => {
        dispatch({
          type: 'API_FAIL',
          errMsg: Constants.getErrorMessage(err)
        });
      });
    };
};

export const updateOpportunityPackageStatus = ( packageMappingId, statusId ) => {
    const url = `/sapna/v1/opportunity/package-status/change-status/${packageMappingId}`
    return (dispatch) => {
        instance({
          url,
          data:{statusId},
          method: 'PUT'
        })
        .then((response) => {
          let result = { status: (response.data && response.data.statusCode === '2XX')};
          dispatch({
              type: OPPORTUNITY_PACKAGE_STATUS_CHANGE,
              payload: result
          });
          if(!result.status){
            dispatch({
                type: 'API_FAIL',
                errMsg: Constants.getErrorMessage(response)
            });
          }
        })
        .catch((err) => {
            dispatch({
                type: OPPORTUNITY_PACKAGE_STATUS_CHANGE,
                payload: { status: false}
            });
            dispatch({
                type: 'API_FAIL',
                errMsg: Constants.getErrorMessage(err)
            });
        });
    };
};

  