import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";

export const PRODUCTS = "PRODUCTS";
export const PRODUCTS_DEACTIVATED = "PRODUCTS_DEACTIVATED";
export const PRODUCT_UPDATED = "PRODUCT_UPDATED";
export const PRODUCT_CREATED = "PRODUCT_CREATED";
export const RESET_PRODUCT_UPDATION_STATUS = "RESET_PRODUCT_UPDATION_STATUS";

const showError = (err, dispatch) => {
  dispatch({
    type: Constants.API_FAIL,
    errMsg: Constants.getErrorMessage(err),
  });
};

export const deactivateProducts = (productIds) => (dispatch) => {
  let url = `/sapna/v1/products/delete`;
  return instance({
    url,
    method: "PUT",
    data: [...productIds],
  })
    .then(() => {
      dispatch({ type: PRODUCTS_DEACTIVATED });
    })
    .catch((err) => showError(err, dispatch));
};

export const updateProduct = (id, data) => (dispatch) => {
  let url = `/sapna/v1/products/update/${id}`;
  return instance({
    url,
    method: "PUT",
    data,
  })
    .then(() => {
      dispatch({ type: PRODUCT_UPDATED });
    })
    .catch((err) => showError(err, dispatch));
};

export const createProduct = (data) => (dispatch) => {
  let url = `/sapna/v1/products/create`;
  return instance({
    url,
    method: "POST",
    data,
  })
    .then(() => {
      dispatch({ type: PRODUCT_CREATED });
    })
    .catch((err) => showError(err, dispatch));
};

export const resetProductUpdateStatus = () => (dispatch) =>
  dispatch({ type: RESET_PRODUCT_UPDATION_STATUS });
