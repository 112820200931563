import {
  PACKAGE_FAMILIES_DEACTIVATED,
  PACKAGE_FAMILIY_CREATED,
  PACKAGE_FAMILIY_UPDATED,
  RESET_PACKAGE_FAMILIY_UPDATION_STATUS,
} from "../actions/productPackageFamily.actions";

const defaultState = {};
const productPackageFamilyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PACKAGE_FAMILIY_CREATED:
      return {
        ...state,
        packageFamiliesModified: true,
        snackbar: {
          state: true,
          message: "Package family created successfully",
          type: "success",
        },
      };
    case PACKAGE_FAMILIES_DEACTIVATED:
    case PACKAGE_FAMILIY_UPDATED:
      return {
        ...state,
        packageFamiliesModified: true,
        snackbar: {
          state: true,
          message: "Package family updated successfully",
          type: "success",
        },
      };
    case RESET_PACKAGE_FAMILIY_UPDATION_STATUS:
      return {
        ...state,
        packageFamiliesModified: false,
      };
  }
};

export default productPackageFamilyReducer;
