import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const UPDATE_SLOT_AVAILABILTY = "UPDATE_SLOT_AVAILABILTY";

// const mockResponse = {
//     "dce9290ec3fe8834a293":{
//        "35":{
//           "available":false,
//           "message":"Upper limit exceeded for polygons for product 'Resale Fixed Static Listing 1': Powai,",
//           "expected":[
//              {
//                 "start_date":"2019-02-17",
//                 "end_date":null
//              }
//           ]
//        },
//        "48":{
//           "available":false,
//           "message":"Upper limit exceeded for polygons for product 'Resale Locality Star Agent': Powai,",
//           "expected":[
//              {
//                 "start_date":"2019-01-15",
//                 "end_date":"2019-01-23"
//              },
//              {
//                 "start_date":"2019-01-31",
//                 "end_date":null
//              }
//           ]
//        }
//     },
//     "526acdc6c33455e9e4e9":{
//        "35":{
//           "available":false,
//           "message":"Inventory not found for Resale Fixed Static Listing 1"
//        },
//        "48":{
//           "available":false,
//           "message":"Inventory not found for Resale Locality Star Agent"
//        }
//     },
//     "ddf026e221e7d54728db":{
//        "35":{
//           "available":true,
//           "availability_count":1
//        },
//        "48":{
//           "available":true,
//           "availability_count":2
//        }
//     }
//  };

export const getSlotAvailabilityDetails = (data) => {
  return (dispatch) => {
    const profile_uuid = data.profile_uuid
      ? `&profile_uuid=${data.profile_uuid}`
      : "";
    const url = `${process.env.DOMAINS.AP}api/v0/get-slot-availability?polygon_uuids=${data.polygon_uuids}&product_ids=${data.product_ids}&start_date=${data.start_date}&end_date=${data.end_date}${profile_uuid}`;
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        dispatch({
          type: UPDATE_SLOT_AVAILABILTY,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
