/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const PAYMENT_DETAILS = "PAYMENT_DETAILS";
export const PAYMENT_CREATED = "PAYMENT_CREATED";
export const PAYMENT_UPDATED = "PAYMENT_UPDATED";
export const PAYMENT_REPRESENTED = "PAYMENT_REPRESENTED";
export const PAYMENT_RECOLLECTED = "PAYMENT_RECOLLECTED";
export const PAYMENT_DETAIL_BY_ID = "PAYMENT_DETAIL_BY_ID";
export const RESET_PAYMENT_DETAIL = "RESET_PAYMENT_DETAIL";
export const PAYMENT_CREATION_FAILED = "PAYMENT_CREATION_FAILED";
export const PRE_PAYMENT_LINK_SEND = "PRE_PAYMENT_LINK_SEND";
export const ACCOUNT_PAYMENT_BUCKET_DETAILS_SUCCESS =
  "ACCOUNT_PAYMENT_BUCKET_DETAILS_SUCCESS";
export const ACCOUNT_PAYMENT_BUCKET_DETAILS_ERROR =
  "ACCOUNT_PAYMENT_BUCKET_DETAILS_ERROR";
export const ACCOUNT_PAYMENT_BUCKET_DETAILS_IN_PROGRESS =
  "ACCOUNT_PAYMENT_BUCKET_DETAILS_IN_PROGRESS";
export const UNUTILIZED_PAYMENTS_SUCCESS = "UNUTILIZED_PAYMENTS_SUCCESS";
export const UNUTILIZED_PAYMENTS_ERROR = "UNUTILIZED_PAYMENTS_ERROR";
export const UNUTILIZED_PAYMENTS_IN_PROGRESS = "UNUTILIZED_PAYMENTS_IN_PROGRESS";

const sellerWalletPaymentModeId = 10;

export const getPaymentDetailById = (paymentId) => {
  return (dispatch) => {
    const url = `/sapna/v1/payment-page/${paymentId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: PAYMENT_DETAIL_BY_ID,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const createPayment = (data, paymentType) => {
  return (dispatch) => {
    const { yesBankResponseId = "" } = data;
    const apiVersion =
      data.modeOfPaymentId === sellerWalletPaymentModeId
        ? "v2"
        : yesBankResponseId
        ? "v3"
        : "v1";
    const url = `/sapna/${apiVersion}/payment`;
    if (paymentType === "Recollection") {
      data.paymentType = "Recollection";
    }
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        if (paymentType === "Recollection") {
          dispatch({
            type: PAYMENT_RECOLLECTED,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: PAYMENT_CREATED,
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: PAYMENT_CREATION_FAILED,
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const updatePayment = (paymentId, data, sendToRCM) => {
  return (dispatch) => {
    const url = `/sapna/v1/payment/${paymentId}?sendToRCM=${!!sendToRCM}`;
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        if (data.paymentType === "Recollection") {
          dispatch({
            type: PAYMENT_RECOLLECTED,
            payload: response.data.data,
          });
        } else if (data.paymentType === "Representation") {
          dispatch({
            type: PAYMENT_REPRESENTED,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: PAYMENT_UPDATED,
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: PAYMENT_CREATION_FAILED,
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetPayment = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PAYMENT_DETAIL,
    });
  };
};

export const sendPrePaymentLink = (data) => {
  return (dispatch) => {
    const url = "/sapna/v1/account/share-payment-link";
    return instance({
      url,
      method: "post",
      data,
    });
  };
};

export const getWalletAmountByAccounytId = (accountId, entityTypeId) => {
  const url = `/sapna/v2/transactions/get-wallet-amount?walletEntityId=${accountId}&walletEntityTypeId=${entityTypeId}&walletType=SELLER`;
  return instance({
    url,
    method: "get",
  });
};
export const getLatestActiveLinkDetail = (accountId) => {
  const url = `/sapna/v1/wallet-payments/latest?accountId=${accountId}`;
  return instance({
    url,
    method: "get",
  });
};

export const getThreshold = (id) => {
  return (dispatch) => {
    const url = `/sapna/v1/get-threshold-details?milestoneId=${id}`;
    return instance({
      url,
      method: "get",
    })
      .then(({ data: { data = {} } = {} }) => {
        return data;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getTDSList = (id) => {
  return (dispatch) => {
    const url = `sapna/v1/payment-tds-rate?milestoneId=${id}`;
    return instance({
      url,
      method: "get",
    })
      .then(({ data: { data = {} } = {} }) => {
        return data;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getTDSListByAccountId = (id) => {
  if (!id) return;

  return (dispatch) => {
    const url = `/sapna/v1/payment-tds-rate-by-accountId?accountId=${id}`;
    return instance({
      url,
      method: "get",
    })
      .then(({ data: { data = {} } = {} }) => {
        return data;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAccountPaymentBucketDetails = (accountId, type) => {
  return (dispatch) => {
    const url = `sapna/v1/bucketInfo/${accountId}?type=${type.toUpperCase()}`;
    dispatch({
      type: ACCOUNT_PAYMENT_BUCKET_DETAILS_IN_PROGRESS,
      payload: true,
      accountId,
      paymentType: type,
    });
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data: { data: { [`${type}Bucket`]: details = [] } } = {} } =
          response;
        dispatch({
          type: ACCOUNT_PAYMENT_BUCKET_DETAILS_SUCCESS,
          payload: details,
          accountId,
          paymentType: type,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACCOUNT_PAYMENT_BUCKET_DETAILS_ERROR,
          payload: Constants.getErrorMessage(err),
          accountId,
          paymentType: type,
        });
      });
  };
};
const extractSellerWalletDetails = (sellerPayments) => {
  const modifiedData = sellerPayments.map(payment => ({
      amount: payment.remainingAmount,                // Keeping only the remainingAmount
      paymentReceivedDate: payment.paymentDate,                // Renaming paymentDate to paymentReceivedDate
      type: "Seller Wallet",
      referenceNumber: payment.referenceNumber,
      }))

    return modifiedData;
}

export const getUnutilizedSellerWalletPayment = (accountId) => {
  const modeId = 10;
  return (dispatch) => {
    const url = `sapna/v1/seller-wallet-payments/${accountId}`;
    dispatch({
      type:UNUTILIZED_PAYMENTS_IN_PROGRESS,
      payload:true,
      modeId,
      accountId,
    });
    return instance({
      url,
      method:"get",
    })
    .then((response)=> {
      const { data : { data : sellerPayments = [] } = {} } = response;
      const clearedPayments = extractSellerWalletDetails(sellerPayments);
       dispatch({
          type:UNUTILIZED_PAYMENTS_SUCCESS,
          payload: {clearedPayments},
          modeId,
          accountId,
       })
    })
    .catch((err)=> {
      dispatch({
        type: UNUTILIZED_PAYMENTS_ERROR,
        payload: Constants.getErrorMessage(err),
        modeId,
        accountId,
      });
    })
  }
}
export const getUnutilizedPaymentsByMode = (accountId, modeId) => {
  return (dispatch) => {
    const url = `/sapna/v3/payment/${accountId}?modeId=${modeId}`;
    dispatch({
      type: UNUTILIZED_PAYMENTS_IN_PROGRESS,
      payload: true,
      modeId,
      accountId,
    });
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const {
          data: {
            data: {
              clearedPayments = [],
              unClearedPayments = [],
              bouncedPayments = [],
            },
          },
        } = response;
        dispatch({
          type: UNUTILIZED_PAYMENTS_SUCCESS,
          payload: { clearedPayments, unClearedPayments, bouncedPayments },
          modeId,
          accountId,
        });
      })
      .catch((err) => {
        dispatch({
          type: UNUTILIZED_PAYMENTS_ERROR,
          payload: Constants.getErrorMessage(err),
          modeId,
          accountId,
        });
      });
  };
};
