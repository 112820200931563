import { GET_PRODUCT_CONFIG } from "../actions/productConfig.actions";

const defaultState = {};
const productConfigReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PRODUCT_CONFIG:
      return {
        ...state,
        productConfig: action.productConfig,
      };
  }
};

export default productConfigReducer;
