import {
  LOCALITY_LIST,
  FILE_CHOOSEN_TICKET,
  FILE_CHOOSEN_TICKET_RESET,
  RESET_CREATED_TICKET_ID,
} from "../actions/detailDialog.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOCALITY_LIST:
      return {
        ...state,
        localities: action.localities,
      };
    case FILE_CHOOSEN_TICKET:
      return {
        ...state,
        fileChoosenForTicket: action.fileChoosenForTicket,
      };
    case FILE_CHOOSEN_TICKET_RESET:
      return {
        ...state,
        fileChoosenForTicket: null,
      };
    case RESET_CREATED_TICKET_ID:
      return {
        ...state,
        createdTicketId: null,
      };
  }
};

export default reducer;
