import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";
import { DONT_INITIATE_NEXT_CALL, INITIATE_NEXT_CALL } from "./opportunity.actions";

export const GOT_COMMENTS = "GOT_COMMENTS";
export const GET_COMMENTS_FAILED = "GET_COMMENTS_FAILED";
export const GET_TERMINAL_COMMENTS = "GET_TERMINAL_COMMENTS";
export const COMMENT_ADDED = "COMMENT_ADDED";
export const COMMENT_RESET = "COMMENT_RESET";
export const COMMENT_FAILED = "COMMENT_FAILED";
export const COMMENT_USERS = "COMMENT_USERS";
export const GOT_CALL_CATEGORY = "GOT_CALL_CATEGORY";
export const GET_CALL_CATEGORY_FAILED = "GET_CALL_CATEGORY_FAILED";

export const getComments = (
  currentStatusId,
  caseTypeId,
  isHousingOwnerLead = false
) => {
  if (!currentStatusId || !caseTypeId) return;

  return (dispatch) => {
    dispatch(
      getCaseReasonStatusMapping(currentStatusId, caseTypeId, isHousingOwnerLead)
    )
      .then((reasonId) => {
        if (reasonId.length === 0) {
          dispatch({
            type: GOT_COMMENTS,
            payload: [],
          });
          return;
        }

        const url = `/mystique/v1/reference-data/case-reasons?filters=(${reasonId
          .map((id) => `id==${id}`)
          .join(",")})`;

        return instance({
          url,
          method: "get",
        })
          .then((response) => {
            const { data } = response.data;
            dispatch({
              type: GOT_COMMENTS,
              payload: data.results || [],
            });
          })
          .catch((err) => {
            dispatch({
              type: GET_COMMENTS_FAILED,
              messsage: err.messsage,
            });
          });
      })
      .catch((err) => {
        dispatch({
          type: GET_COMMENTS_FAILED,
          messsage: err.messsage,
        });
      });
  };
};

export const addComment = (id, data, type, initiateNextCall = false) => {
  const url = `/sapna/v1/${type}/${id}/change-status`;
  return (dispatch) => {
    return instance({
      url,
      method: "patch",
      data,
    })
      .then(() => {
        dispatch({
          type: COMMENT_ADDED,
        });
        setTimeout(() => {
          dispatch({
            type: initiateNextCall ? INITIATE_NEXT_CALL : DONT_INITIATE_NEXT_CALL,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: COMMENT_FAILED,
          errMsg: Constants.getErrorMessage(err) || "Unable to post comment",
        });
      });
  };
};

export const disposeCall = (agentPhone, disposition, subDisposition) => {
  const DISPOSE_CALL_URL = "kira/bspl/dispose";
  const queryParams = new URLSearchParams({
    agentPhone,
    disposition,
    subDisposition,
  });
  const url = `${DISPOSE_CALL_URL}?${queryParams.toString()}`;
  return (dispatch) => {
    return instance({
      url,
      method: "post",
    })
      .then((response) => {
        const { messsage } = response.data;
        dispatch({
          type: Constants.API_SUCCESS,
          errMsg: messsage,
        });
        return response.data;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err) || "Unable to Dispose Call",
        });
        return Promise.reject(err);
      });
  };
};

export const resetComment = () => {
  return (dispatch) => {
    dispatch({
      type: COMMENT_RESET,
    });
  };
};

const getCaseReasonStatusMapping = (currentStatusId, caseTypeId, caseSubTypeId) => {
  return (dispatch) => {
    const url = `/mystique/v1/reference-data/case-reason-status-mapping?filters=currentStatusId==${currentStatusId};caseTypeId==${caseTypeId};active==true${
      caseSubTypeId ? ";caseSubTypeId==" + caseSubTypeId : ""
    }`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data } = response.data;
        return data.results.map((d) => d.reasonId);
      })
      .catch((err) => {
        dispatch({
          type: GET_COMMENTS_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getTerminalComments = (nextStatusIds, caseTypeId) => {
  const url = `/mystique/v1/reference-data/case-reason-status-mapping?filters=active==true;caseTypeId==${caseTypeId};${nextStatusIds
    .map((nextStatusId) => `nextStatusId==${nextStatusId}`)
    .join(",")}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data } = response.data;
        const terminalComments = data.results.map((d) => d.reasonId);
        dispatch({
          type: GET_TERMINAL_COMMENTS,
          payload: terminalComments,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAllChilds = (companyId = Constants.companyId.housing) => {
  const url = `/madrox/data/v1/entity/company/${companyId}/domain/company-users?fields=userId,name`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data } = response.data;
        const children = [];
        data.forEach((child) => {
          children.push({
            id: child.userId,
            name: child.name,
          });
        });
        dispatch({
          type: COMMENT_USERS,
          payload: children,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getCallCategory = () => {
  return (dispatch) => {
    const url = `/mystique/v1/reference-data/reason-categories?filters=category==${Constants.ownerConnected},category==${Constants.ownerNotConnected}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: GOT_CALL_CATEGORY,
          payload: data.results || [],
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CALL_CATEGORY_FAILED,
          messsage: err.messsage,
        });
      });
  };
};
