import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";
import { getAccountDetailsFromHousing } from "../../redux/actions/myAccounts.actions";

export const RESET_PAYMENT = "RESET_PAYMENT";
export const PAYMENT_DETAILS = "PAYMENT_DETAILS";
export const MILESTONE_DETAILS = "MILESTONE_DETAILS";
export const TERMSHEET_DETAILS = "TERMSHEET_DETAILS";
export const TERMSHEET_CREATED = "TERMSHEET_CREATED";
export const TERMSHEET_UPDATED = "TERMSHEET_UPDATED";
export const LEGAL_ENTITY_DETAILS = "LEGAL_ENTITY_DETAILS";
export const RESET_TERMSHEET_CREATION = "RESET_TERMSHEET_CREATION";
export const TERMSHEET_CREATION_FAILED = "TERMSHEET_CREATION_FAILED";
export const TERMSHEET_UPDATION_FAILED = "TERMSHEET_UPDATION_FAILED";
export const MILESTONE_DETAIL_FOR_PAYMENT = "MILESTONE_DETAIL_FOR_PAYMENT";
export const PACKAGE_ATTRIBUTES = "PACKAGE_ATTRIBUTES";
export const TERMSHEET_PDF_URL = "TERMSHEET_PDF_URL";
export const MILESTONE_PDF_URL = "MILESTONE_PDF_URL";
export const PACKAGE_SLOT_DETAILS = "PACKAGE_SLOT_DETAILS";
export const TERMSHEET_SEND_BACK_TO_RCM = "TERMSHEET_SEND_BACK_TO_RCM";

export const PRODUCT_ACTIVATION_DONE = "PRODUCT_ACTIVATION_DONE";
export const FETCH_TRANSACTION_HISTORY = "FETCH_TRANSACTION_HISTORY";
export const RESET_PRODUCT_ACTIVATION = "RESET_PRODUCT_ACTIVATION";
export const SENT_FOR_REFUND_FLOW = "SENT_FOR_REFUND_FLOW";
export const LATE_CHEQUE_APPROVAL_HISTORY = "LATE_CHEQUE_APPROVAL_HISTORY";
export const REFUNDS_REVIEWERS_LIST = "REFUNDS_REVIEWERS_LIST";
export const SENT_PAYMENT_LINK = "SENT_PAYMENT_LINK";
export const CANCELED_INVOICE = "CANCELED_INVOICE";
export const SHARE_TERMSHEET_WITH_SELLER = "SHARE_TERMSHEET_WITH_SELLER";
export const VIEW_TERMSHEET = "VIEW_TERMSHEET";
export const SENT_DEVELOPER_PAYMENT_LINK = "SENT_DEVELOPER_PAYMENT_LINK";
export const SENT_PERFOMA_LINK = "SENT_PERFOMA_LINK";
export const BROKER_PRODUCT_ACTIVATION = "BROKER_PRODUCT_ACTIVATION";
export const BROKER_PRODUCT_DETAILS_MAP = "BROKER_PRODUCT_DETAILS_MAP";
export const SEND_TERMSHEET_RCM = "SEND_TERMSHEET_RCM";
export const SHARE_TAX_INVOICE_CLIENT = "SHARE_TAX_INVOICE_CLIENT";
export const UPDATE_ACTIVATION_PARAMS_LISTING_ID =
  "UPDATE_ACTIVATION_PARAMS_LISTING_ID";
export const RCM_CASE_FIXED = "RCM_CASE_FIXED";
export const RCM_CASE_FIX_FAILED = "RCM_CASE_FIX_FAILED";
export const GET_AUTO_ACTIVATION_LIST = "GET_AUTO_ACTIVATION_LIST";

const {
  paymentStatus: { pending: pendingPaymentIds = [] },
} = Constants || {};

let parseData = (data) => {
  try {
    data.termsheetOpportunityPackageMappingDetails.forEach((pkg) => {
      pkg.opportunityProductMappingDtos.forEach((obj) => {
        obj.activationParameters = JSON.parse(obj.activationParameters);
        obj.extraDetail = JSON.parse(obj.extraDetail);
        obj.fixedParameters = JSON.parse(obj.fixedParameters);
        obj.requiredActivationParameters = JSON.parse(
          obj.requiredActivationParameters
        );
      });
    });
    return data;
  } catch (e) {
    return null;
  }
};

export const resetActivationStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PRODUCT_ACTIVATION,
    });
  };
};

export const getTransactionHistory = (termsheetId) => {
  const url = `/sapna/v1/transactions?termsheetId=${termsheetId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: FETCH_TRANSACTION_HISTORY,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const activateProduct = (termsheetId, data) => {
  const url = `/sapna/v1/product-activation?termsheetId=${termsheetId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        const { data = [] } = response.data;
        if (data.length == 0) {
          dispatch({
            type: PRODUCT_ACTIVATION_DONE,
            payload: {
              processed: false,
              errMsg: "Activation cannot be done right now!",
            },
          });
          return;
        }
        const housingResponse =
          data.length !== 0 && data[0].extraData && JSON.parse(data[0].extraData);
        if (housingResponse) {
          const {
            response: { products_response = {} },
          } = housingResponse;
          const productId = Object.keys(products_response)[0];
          if (!data[0].processed && productId && products_response[productId]) {
            dispatch({
              type: PRODUCT_ACTIVATION_DONE,
              payload: {
                processed: false,
                errMsg: Constants.getErrorMessage(products_response[productId]),
              },
            });
            return;
          }
        }
        dispatch({
          type: PRODUCT_ACTIVATION_DONE,
          payload: response.data.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: PRODUCT_ACTIVATION_DONE,
          payload: {
            processed: false,
            errMsg: Constants.getErrorMessage(err),
          },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getTermsheetDetail = (termsheetId) => {
  const url = `/sapna/v3/termsheet-page/${termsheetId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        //  termsheet details
        const { data } = response.data;
        const {
          milestoneDetails,
          accountLegalEntityDetail,
          accountHousingProfileId,
        } = data;
        const { addressDetails, ...child } = accountLegalEntityDetail;
        let billingAddress = "Not Available";
        let shippingAddress = "Not Available";
        if (addressDetails) {
          addressDetails.forEach((address) => {
            if (address.type === "Billing") {
              billingAddress = address.address || billingAddress;
            } else if (address.type === "Shipping") {
              shippingAddress = address.address || shippingAddress;
            }
          });
        }
        dispatch({
          type: LEGAL_ENTITY_DETAILS,
          payload: {
            termsheetId,
            data: {
              ...child,
              billingAddress,
              shippingAddress,
            },
          },
        });
        dispatch(getAccountDetailsFromHousing(accountHousingProfileId));
        let allPayments = [];
        let allRefunds = [];
        const milestones = milestoneDetails
          ? milestoneDetails
              .filter((d) => d.active)
              .map((d) => {
                const {
                  id,
                  name,
                  amount,
                  digislateAmount,
                  gst,
                  startDate,
                  action,
                  accountLegalEntityDetail,
                  paymentDetails,
                  refundDetails,
                  invoiceDetails,
                } = d;
                let approvedPaymentAmount = 0;
                let pendingPaymentAmount = 0;
                allPayments = allPayments.concat(
                  paymentDetails.map((p) => {
                    if (p.statusId === 6) approvedPaymentAmount += p.amount;
                    if (pendingPaymentIds.indexOf(p.statusId) !== -1)
                      pendingPaymentAmount += p.amount;
                    return { ...p, milestoneName: name };
                  })
                );
                allRefunds = refundDetails.map((refund) => ({
                  ...refund,
                  milestoneName: name,
                }));
                return {
                  id,
                  gst,
                  name,
                  amount,
                  action,
                  startDate,
                  digislateAmount,
                  invoiceDetails,
                  approvedPaymentAmount,
                  pendingPaymentAmount,
                  paymentDetailCount: paymentDetails.length,
                  legalName: accountLegalEntityDetail
                    ? accountLegalEntityDetail.legalName
                    : null,
                  accountLegalEntityId: accountLegalEntityDetail
                    ? accountLegalEntityDetail.id
                    : null,
                };
              })
          : [];
        dispatch({
          type: MILESTONE_DETAILS,
          payload: {
            termsheetId,
            data: milestones,
          },
        });

        dispatch({
          type: TERMSHEET_DETAILS,
          payload: {
            termsheetId,
            data: {
              ...parseData(response.data.data),
              payments: allPayments,
              refunds: allRefunds,
              milestoneDetails: null,
              accountLegalEntityDetail: null,
            },
          },
        });
        return response;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const createTermsheet = (opportunityId, accountLegalEntityId, contactId) => {
  return (dispatch) => {
    const url = "/sapna/v1/termsheet";
    const data = {
      opportunityId,
      accountLegalEntityId,
      contactId,
    };
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        dispatch({
          type: TERMSHEET_CREATED,
          payload: {
            termsheetId: response.data.data.id + "",
            data: response.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: TERMSHEET_CREATION_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const updateTermsheet = (termsheetId, data) => {
  return (dispatch) => {
    const url = `/sapna/v1/termsheet/${termsheetId}`;
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        dispatch({
          type: TERMSHEET_UPDATED,
          payload: {
            termsheetId,
            data: response.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: TERMSHEET_UPDATION_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const sendBackToRAM = (termsheetId, data) => {
  return (dispatch) => {
    const url = `/sapna/v1/termsheet/${termsheetId}`;
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        dispatch({
          type: TERMSHEET_SEND_BACK_TO_RCM,
          payload: {
            status: true,
            data: response.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        dispatch({
          type: TERMSHEET_SEND_BACK_TO_RCM,
          payload: {
            status: false,
            data: {},
          },
        });
      });
  };
};

export const resetTermsheetCreation = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_TERMSHEET_CREATION,
    });
  };
};

export const getPaymentDetailByMilestoneId = (milestoneId) => {
  return (dispatch) => {
    const url = `/sapna/v1/payment/milestone/${milestoneId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: PAYMENT_DETAILS,
          payload: {
            milestoneId,
            data: response.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getMilestoneById = (milestoneId) => {
  return (dispatch) => {
    const url = `/sapna/v1/milestone-details/${milestoneId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: MILESTONE_DETAIL_FOR_PAYMENT,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getPackageAttributes = (
  ticketingCategory = Constants.ticketingCategories.packageActivation
) => {
  const url = `/makaanTickets/api/v1/ticket/master/category/${ticketingCategory}/attributes`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const packageAttributes = response.data.data;
        dispatch({
          type: PACKAGE_ATTRIBUTES,
          packageAttributes,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const generatePDF = (entityId, entityType) => {
  let url = "";
  if (entityType === "termsheet") {
    url = `/sapna/v1/termsheet-pdf?id=${entityId}`;
  } else if (entityType === "milestone") {
    url = `/sapna/v1/milestone/proforma-invoice/${entityId}`;
  }
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        let pdfUrl = {};
        if (entityType === "termsheet") {
          pdfUrl = response.data.data;
          dispatch({
            type: TERMSHEET_PDF_URL,
            termsheetPDFUrl: pdfUrl,
          });
        } else if (entityType === "milestone") {
          pdfUrl = response.data.data;
          dispatch({
            type: MILESTONE_PDF_URL,
            milestonePDFUrl: {
              id: entityId,
              data: pdfUrl,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const fixRcmCaseApi = (termsheetId) => {
  return (dispatch) => {
    const url = `/sapna/v1/termsheet/${termsheetId}/correct-rcm-case`;
    return instance({
      url,
      method: "put",
    })
      .then((response) => {
        dispatch({
          type: RCM_CASE_FIXED,
          fixCaseStatus: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RCM_CASE_FIX_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getPackageSlotDetails = (slotIds, showSlotDetails = true) => {
  if (!(slotIds && slotIds.length)) return;
  return (dispatch) => {
    return instance({
      url: `${
        process.env.DOMAINS.ADVERT
      }api/v0/get-slots-details?slot_ids=${slotIds.join()}`,
      method: "GET",
    }).then(
      (response) => {
        dispatch({
          type: PACKAGE_SLOT_DETAILS,
          housingSlotDetails: response.data?.data,
          showSlotDetails: showSlotDetails,
        });
      },
      (err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      }
    );
  };
};

export const resetPayment = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PAYMENT,
    });
  };
};

export const sendForRefundFlow = (data) => {
  return (dispatch) => {
    const url = "/sapna/refund";
    return instance({
      url,
      method: "POST",
      data,
    })
      .then((response) => {
        dispatch({
          type: SENT_FOR_REFUND_FLOW,
          payload: { status: true, data: response.data.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SENT_FOR_REFUND_FLOW,
          payload: { status: false },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAllApprovalHistory = ({
  pendingApprovalIds,
  approvalType,
  getApprovalTabularData = false,
}) => {
  const url = `/sapna/v1/approval/all-approvals?entityTypeId=${
    Constants.approvalTypes_entityTypeIdsMap[approvalType]
  }&entityIds=${pendingApprovalIds.join()}&approvalType=${approvalType}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data } = response.data;
        if (getApprovalTabularData) handleApprovalResponse(dispatch, data[0]);
        else if (data && data.length) {
          const result = {};
          data.forEach((approvalDto) => {
            let reviewerList =
              approvalDto.approvalReviewerMappings &&
              approvalDto.approvalReviewerMappings.map((mapping) => ({
                id: mapping.reviewerId,
                status: mapping.status,
              }));
            reviewerList &&
              (reviewerList.discountApprovingUserId =
                approvalDto.approvingAuthority.userId);
            result[approvalDto.entityId] = reviewerList;
          }, {});
          dispatch({
            type: REFUNDS_REVIEWERS_LIST,
            refundsReviewerList: result,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const generatePaymentLink = (milestoneId, contactId, data = {}) => {
  return (dispatch) => {
    const url = `/sapna/v1/owner/payment-link-generation?milestoneId=${milestoneId}&contactId=${contactId}`;
    return instance({
      url,
      method: "POST",
      data,
    })
      .then((response) => {
        dispatch({
          type: SENT_PAYMENT_LINK,
          payload: { status: true, data: response.data.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SENT_PAYMENT_LINK,
          payload: { status: false },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const generateDeveloperPaymentLink = (
  milestoneId,
  contactId,
  tdsPercentage
) => {
  return (dispatch) => {
    const url = `/sapna/v1/milestone/share-payment-link?milestoneId=${milestoneId}&contactId=${contactId}&tdsPercentage=${tdsPercentage}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: SENT_DEVELOPER_PAYMENT_LINK,
          payload: { status: true, data: response.data.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SENT_DEVELOPER_PAYMENT_LINK,
          payload: { status: false },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const generatePerfomaLink = (milestoneId, contactId) => {
  return (dispatch) => {
    const url = `/sapna/v1/milestone/email-proforma-invoice/${milestoneId}?contactId=${contactId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: SENT_PERFOMA_LINK,
          payload: { status: true, data: response.data.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SENT_PERFOMA_LINK,
          payload: { status: false },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const handleApprovalResponse = (dispatch, data) => {
  let lateChequeApprovalHistory = [];
  if (data) {
    lateChequeApprovalHistory.push({
      userName: `${data.approvingAuthority.name} - ${data.approvingAuthority.designation.label}`,
      role: "Approver",
      status: Constants.camelCaseToTitle(data.status),
      updatedAt: data.updatedAt,
    });
  }
  if (data && data.approvalReviewerMappings) {
    data.approvalReviewerMappings.forEach((mapping) => {
      lateChequeApprovalHistory.push({
        userName: `${mapping.reviewer.name} - ${mapping.reviewer.designation.label}`,
        role: "Reviewer",
        status: Constants.camelCaseToTitle(mapping.status),
        updatedAt: mapping.updatedAt,
      });
    });
  }
  dispatch({
    type: LATE_CHEQUE_APPROVAL_HISTORY,
    lateChequeApprovalHistory,
  });
};

export const cancelInvoice = (invoiceId) => {
  const url = `/sapna/v1/digislate-invoice/${invoiceId}?generateNewInvoice=false`;
  return (dispatch) => {
    return instance({
      url,
      method: "delete",
    })
      .then((response) => {
        dispatch({
          type: CANCELED_INVOICE,
          payload: response.data.data
            ? "Digislate invoice cancelled"
            : "Invoice cancelation failed",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const shareTermsheet = (termsheetId, contactId) => {
  const url = `/sapna/v1/termsheet/share-termsheet-link/${termsheetId}?contactId=${contactId}`;
  return (dispatch) => {
    return (
      instance({
        url,
        method: "get",
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          dispatch({
            type: SHARE_TERMSHEET_WITH_SELLER,
            msg: "Termsheet will be shared shortly",
            status: true,
          });
        })
        .catch((err) => {
          dispatch({
            type: SHARE_TERMSHEET_WITH_SELLER,
            msg: Constants.getErrorMessage(err),
            status: false,
          });
          // dispatch({
          //   type: Constants.API_FAIL,
          //   errMsg: Constants.getErrorMessage(err)
          // })
        })
    );
  };
};

export const getViewTSTemplate = (termsheetId) => {
  const url = `/sapna/v1/termsheet/${termsheetId}/view?isSeller=false`;
  return (dispatch) => {
    let newWindow = window.open("");
    newWindow.document.write("<div id='termsheet'>Loading Order Form ...</div>");
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { page = "" } = response.data.data;
        let termsheetData = page.replace(/\\"/g, '"');
        newWindow.document.getElementById(
          "termsheet"
        ).innerHTML = `<div>${termsheetData}</div>`;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const activateBrokerProduct = (productActivationIds) => {
  return (dispatch) => {
    const url = `/sapna/v1/product-activation/force-activate`;
    return (
      instance({
        url,
        method: "post",
        data: productActivationIds,
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          dispatch({
            type: BROKER_PRODUCT_ACTIVATION,
          });
        })
        .catch((err) => {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
        })
    );
  };
};

export const getProductWiseActivationDetails = (termsheetId) => (dispatch) => {
  const url = `/sapna/v1/product-activation/termsheet-product-details/${termsheetId}`;
  return instance({
    url,
    method: "get",
  })
    .then((response) => {
      const { data = [] } = response.data;
      let result = {};
      data.forEach((product) => {
        const { opportunityProductMappingId: OPM = "", productActivations = [] } =
          product;
        if (!result[OPM]) {
          result[OPM] = productActivations;
        } else result[OPM] = result[OPM].concat(productActivations);
      });
      dispatch({
        type: BROKER_PRODUCT_DETAILS_MAP,
        payload: result,
      });
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};

export const sendTermsheetToRCM = (termsheetId) => {
  return (dispatch) => {
    const url = `/sapna/v1/termsheet/${termsheetId}/case`;
    return (
      instance({
        url,
        method: "post",
        data: termsheetId,
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          dispatch({
            type: SEND_TERMSHEET_RCM,
            payload: { status: true },
          });
        })
        .catch((err) => {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
        })
    );
  };
};

export const shareTaxInvoiceToClient = (paymentId, contactId) => {
  return (dispatch) => {
    const url = `/sapna/v1/payment/email-tax-invoice/${paymentId}?contactId=${contactId}`;
    return instance({
      url,
      method: "post",
      data: paymentId,
    })
      .then((response) => {
        dispatch({
          type: SHARE_TAX_INVOICE_CLIENT,
          msg: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const mapOwnerListingToPackage = (payload, housingProfileId) => {
  return (dispatch, getState) => {
    const {
      termsheet: {
        data: { termsheetOpportunityPackageMappingDetails },
      },
    } = getState();
    const url = `/sapna/v1/product-activation/tag-product-listings/package?profileUuid=${housingProfileId}`;
    return instance({
      url,
      method: "post",
      data: payload,
    })
      .then((response) => {
        const index = termsheetOpportunityPackageMappingDetails.findIndex(
          (res) =>
            res.opportunityPackageMappingId === payload.opportunityPackageMappingId
        );
        const newTermsheetOpportunityPackageMappingDetails = [
          ...termsheetOpportunityPackageMappingDetails,
        ];
        newTermsheetOpportunityPackageMappingDetails[
          index
        ].opportunityProductMappingDtos[0].activationParameters.promotedListings =
          payload.listingIds;
        dispatch({
          type: UPDATE_ACTIVATION_PARAMS_LISTING_ID,
          payload: {
            termsheetOpportunityPackageMappingDetails:
              newTermsheetOpportunityPackageMappingDetails,
          },
        });
        return response;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
  };
};
export const clearMsg = () => {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_ACTIVATION_DONE,
      payload: {
        processed: false,
        errMsg: "",
      },
    });
  };
};

export const getDeveloperAutoActivationList = () => {
  const url = `/sapna/v1/developer-combined-auto-activation-list`;
  return (dispatch) =>
    instance({
      url,
    })
      .then((response) => {
        const { data: { data = {} } = {} } = response;
        dispatch({
          type: GET_AUTO_ACTIVATION_LIST,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
};
