const groupByListingId = (imageIds = {}, imagesData) => {
  return imageIds.reduce((obj, imageId) => {
    const data = imagesData[imageId];
    const listingId = data.listing_id;

    const modifiedData = {
      imageId,
      absolute_url: data.absolute_url,
      tag: data.tag,
      listingId,
      mlRejectionReasons: data.ml_rejection_reasons,
      listingInfo: data.listing_info,
      uploadFlow: data.upload_flow,
    };

    if (obj[listingId]) {
      obj[listingId].push(modifiedData);
    } else {
      obj[listingId] = [modifiedData];
    }
    return obj;
  }, {});
};

export const parseImagesData = (imagesData, listingArr = []) => {
  const imageIds = Object.keys(imagesData);
  const imagesByListingId = groupByListingId(imageIds, imagesData);

  const parsedData = listingArr.reduce((arr, listingId) => {
    if (imagesByListingId[listingId]) {
      return arr.concat(imagesByListingId[listingId]);
    }
    return arr;
  }, []);

  return parsedData;
};
