import Constants from "../../utils/CommonConstants";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Constants.API_FAIL:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          duration: action.duration || null,
          message: action.errMsg || "Something went wrong!",
        },
      };
    case Constants.API_SUCCESS:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "success",
          duration: action.duration || null,
          message: action.errMsg || "Action successfully completed!",
        },
      };
    case Constants.RESET_ACCOUNT:
      return {
        ...state,
        account: null,
      };
    case Constants.RESET_OPPORTUNITY:
      return {
        ...state,
        opportunity: null,
      };
    case Constants.CLOSE_COMMON_DIALOG:
      return {
        ...state,
        dialog: {
          state: false,
        },
      };
    case Constants.CLEAR_TICKETS_LIST:
      return {
        ...state,
        ticketsList: null,
      };
    case Constants.PACKAGE_QUANTITY_MISMATCH:
      return {
        ...state,
        snackbar: {
          state: true,
          message: `Cannot Add more that ${action.quantity} projects!`,
          type: "warning",
        },
      };
    case Constants.SNACKBAR_NOTIFICATION:
      return {
        ...state,
        snackbar: {
          state: true,
          type: action.snackBarType || "success",
          duration: action.duration || null,
          message: action.msg || "Success!!",
        },
      };
  }
};

export default reducer;
