import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";

const showError = (err, dispatch) => {
  {
    return dispatch({
      type: Constants.API_FAIL,
      errMsg: Constants.getErrorMessage(err),
    });
  }
};
export const NOTIFICATION_GET = "NOTIFICATIONS_GET";
export const GET_VARIOUS_CONFIG = "GET_VARIOUS_CONFIG";

export const getNotifications = () => {
  const url = "sapna/sse/paymentNotifications/recent";
  const method = "get";
  return (dispatch) => {
    return instance({
      url,
      method,
    })
      .then((response) => {
        dispatch({
          type: NOTIFICATION_GET,
          payload: response.data.data,
        });
      })
      .catch((err) => showError(err, dispatch));
  };
};

export const readNotification = (notificationId) => {
  const url = `/sapna/sse/paymentNotifications/read?notificationIds=${notificationId}`;
  const method = "put";
  return (dispatch) => {
    return instance({
      url,
      method,
    })
      .then(() => {
        return dispatch(getNotifications());
      })
      .catch((err) => showError(err, dispatch));
  };
};

export const callVariousConfigApis = () => {
  return (dispatch) => {
    return dispatch({
      type: GET_VARIOUS_CONFIG,
    });
  };
};
