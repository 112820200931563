import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const MORTGAGE_CITY_HEADS = "MORTGAGE_CITY_HEADS";
export const MORTGAGE_PARTNER_ACTION_FAILED = "MORTGAGE_PARTNER_ACTION_FAILED";

export const getMortgageCityHeads = () => {
  return (dispatch) => {
    let url = `mortgage/api/v0/mortgage-city-heads`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data.data;
        dispatch({
          type: MORTGAGE_CITY_HEADS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MORTGAGE_PARTNER_ACTION_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
