import { UPDATE_SLOT_AVAILABILTY } from "../actions/slotAvailability.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SLOT_AVAILABILTY:
      return {
        ...state,
        slotAvailabilityDetails: action.payload,
      };
  }
};

export default reducer;
