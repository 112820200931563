export default {
  SHARE_LINK_OPENED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "share_link_cta",
      globalState,
      localState,
    }),
  SHARE_LINK_CLICKED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "share_link_confirm",
      globalState,
      localState,
    }),
  CALL_BUTTON_CLICKED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "call_initiated",
      globalState,
      localState,
    }),
  SUBMIT_CALL_STATUS: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "disposition_submit",
      globalState,
      localState,
    }),
  POC_OPEN_TELESALES_OPPORTUNITY: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "customer_contact_viewed",
      globalState,
      localState,
    }),
  ACTIVATION_SUCCESS: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "activation_successful",
      globalState,
      localState,
    }),
  DASHBOARD_TILE_CLICKED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "owner_dashboard_tile_clicked",
      globalState,
      localState,
    }),
  OPPORTUNITY_ID_CLICKED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "opportunity_id_clicked",
      globalState,
      localState,
    }),
  ACCOUNT_ID_CLICKED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "account_id_clicked",
      globalState,
      localState,
    }),
  REASSIGNED_ACCOUNTS: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "account_reassigned",
      globalState,
      localState,
    }),
  VIRTUAL_NUMBER_UPDATED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "virtual_number_updated",
      globalState,
      localState,
    }),
  LIVE_VIEW_OPEN: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "live_view_open",
      globalState,
      localState,
    }),
  LIVE_VIEW_CALL_OPTIONS_CLICKED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "live_view_call_options_clicked",
      globalState,
      localState,
    }),

  LIVE_VIEW_CALL_BARGE_IN_SUCCESS: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "live_view_call_bargein_success",
      globalState,
      localState,
    }),
  LIVE_VIEW_CALL_BARGE_IN_FAILED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "live_view_call_bargein_failed",
      globalState,
      localState,
    }),
  LIVE_VIEW_CONNECT_TO_MANAGER_SUCCESS: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "live_view_connect_to_manager_success",
      globalState,
      localState,
    }),
  LIVE_VIEW_CONNECT_TO_MANAGER_FAILED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "live_view_connect_to_manager_failed",
      globalState,
      localState,
    }),
  LIVE_VIEW_BARGEIN_INITIATED: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "live_view_bargein_initiated",
      globalState,
      localState,
    }),
  MASKER_PHONE_NUMBER_IN_VIEW: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "phone_number_masked",
      globalState,
      localState,
    }),
  DASHBOARD_TILE_PHONE_VIEW: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "owner_dashboard_tile_phone_view",
      globalState,
      localState,
    }),
  ACCOUNT_SEARCHED_PHONE_VIEW: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "owner_account_searched",
      globalState,
      localState,
    }),
  SEND_PAYMENT_LINK_DROPDOWN_PHONE_VIEW: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "termsheet_send_payment_link_contact_dropdown",
      globalState,
      localState,
    }),
  ADD_TERMSHEET_POC: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "add_termsheet_poc",
      globalState,
      localState,
    }),
  ADD_PAYMENT_POC: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "add_payment_poc",
      globalState,
      localState,
    }),
  MANUAL_DIALLING_PHONE: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "manual_call_phone",
      globalState,
      localState,
    }),
  MANUAL_DIALLING_OPPORTUNITY_ID: (globalState, localState) =>
    ownerOpportunityCommonLabel({
      action: "manual_call_opp",
      globalState,
      localState,
    }),
};

export const ownerOpportunityCommonLabel = ({ globalState, localState, action }) => {
  const { userInfo = {} } = globalState;
  const { isHousingOwnerAgent, id } = userInfo || {};
  return (
    isHousingOwnerAgent && {
      action,
      category: "owner_crm",
      label: { ...localState, agentId: id, ...getOpportunity(globalState) },
    }
  );
};

const getOpportunity = (globalState) => {
  const { callingQueueOpportunity: { opportunityId } = {} } = globalState;
  return { opportunityId };
};
