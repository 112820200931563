import {
  getImageListFromObject,
  getSellerUuidFromList,
  storiesSlotsParser,
} from "./storiesUtils";
import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const SET_STORIES_FORM_DATA = "SET_STORIES_FORM_DATA";
export const RESET_STORIES_FORM_DATA = "RESET_STORIES_FORM_DATA";
export const SET_SELLERS_LIST = "SET_SELLERS_LIST";
export const PROJECTS_LIST = "PROJECTS_LIST";
export const SET_PROJECT_ID = "SET_PROJECT_ID";
export const STORIES_LOCALITY = "STORIES_LOCALITY";
export const SET_STORIES_LIST = "SET_STORIES_LIST";
export const SET_SLOT_DEDICATED_DATA = "SET_SLOT_DEDICATED_DATA";
export const SET_STORIES_LANGUAGES = "SET_STORIES_LANGUAGES";

const generateRandomName = () => {
  const timestamp = new Date().getTime().toString(16); // Convert current timestamp to hex
  const randomPart = [...Array(16)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
  return timestamp + randomPart;
};

export const getProjectIds = () => {
  const url = `${process.env.DOMAINS.AP}products-mapping`;
  return new Promise((resolve, reject) => {
    instance({ url, method: "GET" })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProjectIdData = () => (dispatch) => {
  getProjectIds()
    .then((data) => {
      dispatch({ type: SET_PROJECT_ID, payload: data });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const setFormData = (section, data, index) => (dispatch) =>
  dispatch({
    type: SET_STORIES_FORM_DATA,
    payload: { section, data, index },
  });

export const resetFormData = () => (dispatch) =>
  dispatch({ type: RESET_STORIES_FORM_DATA });

export const getStoriesSlotList = (pageNo, totalPages) => (dispatch) => {
  let promise = getProjectIds();
  return promise.then((data) => {
    const slotId = data["Video Stories"] || "";
    dispatch({ type: SET_PROJECT_ID, payload: data });
    const url = `${process.env.DOMAINS.ADVERT}products/get-all-packages?row_per_page=10&current_page_no=${pageNo}&promoted_entity_type_id=2&slot_status_id=0&placeholder_ids=${slotId}`;
    return instance({
      url,
      withCredentials: true,
      method: "GET",
    }).then(({ data: { data = {} } = {} }) => {
      dispatch({
        type: SET_STORIES_LIST,
        payload: {
          results: storiesSlotsParser(data.results),
          hasNextPage: pageNo === totalPages ? data.next_page_exist : false,
          totalPages,
        },
      });
    });
  });
};

export const getProjectsData =
  (projectIds = "") =>
  (dispatch) => {
    const url = `${process.env.DOMAINS.VENUS}api/v7/new-projects/bulk-projects-data?project_ids=${projectIds}`;
    return new instance({ url, method: "GET" })
      .then(({ data: { data = [] } = {} }) => {
        return data;
      })
      .catch((error) => {
        const {
          response: {
            data: { apiErrors: { details = "", displayPhrase = "" } = {} } = {},
          } = {},
        } = error || {};
        dispatch({
          type: Constants.API_FAIL,
          errMsg: details || displayPhrase,
        });
        return [];
      });
  };

export const getStoriesSlotData = (slotId) => (dispatch) => {
  const url = `/sapna/v1/housing/helper/8?slot_id=${slotId}&source=stories_panel`;
  let promise = getProjectIds();
  let projectMap = {};
  let slotData = {};
  return promise
    .then((data) => {
      projectMap = data;
      return instance({ url, method: "GET" })
        .then(({ data: { data = {} } = {} }) => {
          slotData = data;
          const { promoted_entity = {} } = slotData;
          const { entity_id: projectId = "" } = promoted_entity;
          return dispatch(getProjectsData(projectId));
        })
        .then((projectArr = []) => {
          const { name = "" } = projectArr[0] || {};
          dispatch({
            type: SET_SLOT_DEDICATED_DATA,
            payload: storiesSlotsParser(slotData, projectMap, name),
          });
        });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const createStoriesSlot =
  (data, updateForm = false) =>
  (dispatch) => {
    const url = `${process.env.DOMAINS.ADVERT}products/create-packages`;
    const editUrl = `${process.env.DOMAINS.ADVERT}products/update-packages?jsession=true`;
    return instance({
      url: updateForm ? editUrl : url,
      withCredentials: true,
      method: "POST",
      data,
    })
      .then((response) => {
        let { data: { data: { message: msg } = {} } = {} } = response;
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: msg,
        });
      })
      .catch((err) => {
        const errMsg = Constants.getErrorMessage(err);
        dispatch({
          type: Constants.API_FAIL,
          errMsg: errMsg,
        });
        return Promise.reject(errMsg);
      });
  };

export const getSellerUUID = (projectId, updateProjectImagesOnly) => (dispatch) => {
  let url = `${process.env.DOMAINS.VENUS}/api/v8/new-projects/${projectId}/webapp?version=v3`;
  let sellerList = [];
  return instance({ url, method: "GET" })
    .then((response) => {
      const sellersInfo = response?.data?.data?.sellers_info ?? [];
      const imagesObj = response?.data?.data?.images_hash ?? {};
      const coverPhotoUrl = response?.data?.data?.cover_photo_url ?? "";
      sellerList = getSellerUuidFromList(sellersInfo);
      const imagesInfo = getImageListFromObject(imagesObj, coverPhotoUrl);
      const locality = response.data?.data?.polygons_hash?.locality ?? null;
      const projectRegion = locality ? [{ ...locality }] : [];
      if (updateProjectImagesOnly) {
        dispatch({
          type: SET_SELLERS_LIST,
          payload: {
            imagesInfo,
          },
        });
        return;
      } else {
        dispatch({
          type: SET_SELLERS_LIST,
          payload: {
            sellerList,
            projectRegion,
            imagesInfo,
          },
        });
      }
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const getProjectList = (project) => (dispatch) => {
  const url = `${
    process.env.DOMAINS.SEARCH
  }api/v1/search/suggest?property_category=residential&service=buy&string=${project}&type=project${
    !isNaN(project) ? `&entity_ids[]=${project}` : ""
  }`;
  if (!project) return;
  return instance({
    url,
    method: "GET",
  })
    .then((response) => {
      dispatch({ type: PROJECTS_LIST, payload: response.data.data.results });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const getHousingLocality = (searchTerm) => {
  return (dispatch) => {
    const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/suggest?source=web&super_type=polygon&service_type=buy&input=${searchTerm}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: STORIES_LOCALITY,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const uploadVideoToS3 = (formData, extension) => (dispatch) => {
  const randomName = generateRandomName();
  const queryParams = new URLSearchParams({
    name: randomName,
    attr_name: "video_stories",
    app_name: "NewProjects",
    resource_name: "Project",
    extension,
  }).toString();
  const url = `${process.env.DOMAINS.IMAGES}/api/v1/files/upload-media-to-s3?${queryParams}`;
  return instance({
    url,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    withCredentials: true,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const errMsg = Constants.getErrorMessage(err);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const uploadImageToS3 = (formData, extension) => (dispatch) => {
  const randomName = generateRandomName();
  const queryParams = new URLSearchParams({
    name: randomName,
    attr_name: "video_stories_thumbnail",
    app_name: "NewProjects",
    resource_name: "Project",
    extension,
  }).toString();
  const url = `${process.env.DOMAINS.IMAGES}/api/v1/files/upload-media-to-s3?${queryParams}`;
  return instance({
    url,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    withCredentials: true,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const errMsg = Constants.getErrorMessage(err);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const fetchStoriesLanguages = () => (dispatch) => {
  const url = `${process.env.DOMAINS.MEDIA}api/v1/languages`;
  return instance({
    url,
    method: "GET",
  })
    .then(({ data: { data: { languages = [] } = {} } = {} }) => {
      // form component has handling for uuid rather than id
      const modifiedLanguages = languages.map((lang) => ({
        uuid: lang?.id,
        name: lang?.name,
      }));
      dispatch({ type: SET_STORIES_LANGUAGES, payload: modifiedLanguages });
      return modifiedLanguages;
    })
    .catch((err) => {
      const errMsg = Constants.getErrorMessage(err);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};
