import constant from "../../utils/CommonConstants";
import { getFormattedDate, getCitiesByPolygons } from "./siteTakeoverUtils";
import { updateDuration } from "../../utils/dateUtils";

export const createAudienceMaximizerApiData = (
  formData = {},
  projectFormData = {},
  projectMap,
  update = false,
  slotId
) => {
  const {
    slotDetails: {
      audience_maximizer_type,
      entity_type_id,
      start_date,
      end_date,
      opportunity_id,
      inner_churn_radius,
      outer_churn_radius,
      amount,
      omni_channel_amount,
      send_activation_mail = false,
      impressions_count,
      polygon_id,
      entity_id,
      product_type_id,
      product_activation_id,
      notes,
      is_locality_churn,
      supply_user_uuid,
      fixed_static_listing,
      fixed_ad_slot,
      lead_exclusivity,
      event_id,
      organic_soft_target,
      organic_hard_target,
      deliver_developer_broker_leads = true,
    } = {},
    image1 = {},
    image2 = {},
    image3 = {},
    image4 = {},
    image5 = {},
    image6 = {},
  } = formData;
  const audienceMaximizerId = projectMap["Audience Maximizer"] || "";
  const fixedStaticListingId = projectMap["Project Fixed Static Listing"] || "";
  const fixedAdSlotId = projectMap["Fixed Ad Slot"] || "";
  const leadExclusivityId = projectMap["Lead Exclusivity"];
  const { fasCity, fslCity, ...rest } = projectFormData;
  return {
    package_details: {
      entity_type_id,
      start_date: getFormattedDate(start_date),
      end_date: getFormattedDate(end_date),
      inner_churn_radius,
      amount,
      send_activation_mail,
      outer_churn_radius,
      opportunity_id,
      entity_id,
      supply_user_uuid,
      product_type_id,
      product_activation_id,
      notes,
      is_locality_churn,
      event_id,
      organic_soft_target,
      organic_hard_target,
      deliver_developer_broker_leads,
    },
    promoted_products: {
      [audienceMaximizerId]: {
        polygon_id,
        product_details: {
          audience_maximizer_type,
          impressions_count,
          omni_channel_amount:
            omni_channel_amount === 0 ? null : omni_channel_amount,
          ...rest,
        },
        slot_product_cards: update
          ? getImageCardDataForEdit(
              slotId,
              {
                image1,
                image2,
                image3,
                image4,
                image5,
                image6,
              },
              audienceMaximizerId
            )
          : [1, 2, 3, 4, 5, 6].map((item) => ({
              content: "",
              priority: item,
              product_id: audienceMaximizerId,
              slot_id: null,
              status: "active",
              sub_title: "",
              title: "",
            })),
      },
      ...(lead_exclusivity && { [leadExclusivityId]: {} }),
      ...(fixed_ad_slot && { [fixedAdSlotId]: { polygon_id: fasCity } }),
      ...(fixed_static_listing && {
        [fixedStaticListingId]: { polygon_id: fslCity },
      }),
    },
    ...(update && { slot_id: parseInt(slotId) }),
  };
};

export const validateCreateAudienceMaximizerApiData = (
  apiData = {},
  questions,
  projectMap
) => {
  const { package_details: slotDetails, promoted_products: promotedProducts } =
    apiData;
  let errors = [];
  const audienceMaximizerId = projectMap["Audience Maximizer"] || "";
  const {
    product_details: productDetails = {},
    polygon_id: polygonId = "",
    slot_product_cards: slotProductCards,
    product_details: { omni_channel_amount: omniChannelAmount } = {},
  } = promotedProducts[audienceMaximizerId] || {};
  const { audience_maximizer_type: audienceMaximizerType } = productDetails;
  const questionFieldArray = questions.map((question) => question.fields).flat();

  for (let question of questionFieldArray) {
    if (question.required) {
      if (
        question.propKey === "start_date" &&
        slotDetails["start_date"] === getFormattedDate("")
      ) {
        errors.push(`Please enter ${question.title}`);
      } else if (
        question.propKey === "end_date" &&
        slotDetails["end_date"] === getFormattedDate("")
      ) {
        errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "polygon_id") {
        if (!polygonId) errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "am_target_polygon_id") {
        if (
          !productDetails["am_target_polygon_id"] ||
          !productDetails["am_target_polygon_id"].length
        )
          errors.push(`Please enter ${question.title}`);
      } else if (
        slotDetails[question.propKey] === undefined &&
        productDetails[question.propKey] === undefined
      ) {
        errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "opportunity_id") {
        if (!slotDetails["opportunity_id"] || !slotDetails["opportunity_id"].length)
          errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "project_ids") {
        if (!productDetails["project_ids"] || !productDetails["project_ids"].length)
          errors.push(`Please enter ${question.title}`);
        if (
          productDetails["project_ids"] &&
          productDetails["project_ids"].split(",").length < 1
        )
          errors.push("You have to enter a minimum of 3 competitor projects");
      } else if (question.propKey === "supply_user_uuid") {
        if (!slotDetails[question.propKey])
          errors.push(`Please enter ${question.title}`);
      } else if (question.propKey === "amount") {
        if (slotDetails["amount"] <= 0)
          errors.push("Please enter an amount greater than 0");
      } else if (
        question.propKey === "omni_channel_amount" &&
        omniChannelAmount < 0
      ) {
        errors.push(
          "Please enter an omni channel amount greater than or equal to 0"
        );
      }
    }
  }

  //added due to existing bug in BE which is giving false values when only one of the two is filled by user
  if (
    checkUndefinedValue(slotDetails, "organic_hard_target") &&
    !checkUndefinedValue(slotDetails, "organic_soft_target")
  ) {
    errors.push("Please enter value for Organic Hard Target");
  } else if (
    checkUndefinedValue(slotDetails, "organic_soft_target") &&
    !checkUndefinedValue(slotDetails, "organic_hard_target")
  ) {
    errors.push("Please enter value for Organic Soft Target");
  }

  for (let i = 0; i < slotProductCards.length; i++) {
    const { active_from_date: activeFromDate } = slotProductCards[i] || {};
    if (
      activeFromDate &&
      (getUtcTime(activeFromDate) < getUtcTime(slotDetails["start_date"]) ||
        getUtcTime(activeFromDate) > getUtcTime(slotDetails["end_date"]))
    ) {
      errors.push(
        "Active from date for an image should be greater than or equal to start date and should be less than end date"
      );
      break;
    }
  }

  if (getUtcTime(slotDetails["start_date"]) > getUtcTime(slotDetails["end_date"])) {
    errors.push("Start Date should be earlier than or equal to End Date");
  }
  if (audienceMaximizerType === constant.audienceMaximizerTypes.project_level) {
    if (
      Array.isArray(productDetails["project_ids"]) &&
      productDetails["project_ids"].indexOf(slotDetails["entity_id"]) !== -1
    )
      errors.push(
        "Please select competitor projects different than the primary project"
      );
  }
  return errors;
};

export const audienceMaximizerSlotsParser = (
  audienceMaximizerSlotData,
  cityData,
  projectMap = {},
  projectName
) => {
  if (Array.isArray(audienceMaximizerSlotData))
    return audienceMaximizerSlotData.map((slot) => {
      const {
        slot_id: slotId,
        start_date: startDate,
        end_date: endDate,
        promoted_entity_type: promotedEntity = "New Projects",
        opportunity_id: opportunityId,
        entity_id: entityId,
        polygon_uuids: polygonIds = [],
        slot_status: slotStatus,
        product_type_id,
        builder_name: sellerUuid,
        entity_id: projectId,
        polygon_name: polygonName,
        ...rest
      } = slot;
      return {
        slotId,
        startDate,
        endDate,
        promotedEntity,
        opportunityId,
        entityId,
        polygonIds: getCitiesByPolygons(polygonIds, cityData),
        slotStatus,
        productType: Object.values(constant.productTypes).reduce((res, item) => {
          const test = { ...res };
          test[item.value] = item.label;
          return test;
        }, {})[product_type_id],
        sellerUuid,
        projectId,
        polygonName,
        ...rest,
      };
    });
  const {
    start_datetime,
    end_datetime,
    promoted_entity,
    slot_product_mappings,
    locality_details,
    slot_product_cards,
    product_ids,
    ...rest
  } = audienceMaximizerSlotData;
  const { promoted_entity_type_id, is_locality_churn, entity_id, supply_user_uuid } =
    promoted_entity;
  const audienceMaximizerId = projectMap["Audience Maximizer"] || "";
  const fixedStaticListingId = projectMap["Project Fixed Static Listing"] || "";
  const fixedAdSlotId = projectMap["Fixed Ad Slot"] || "";
  const leadExclusivityId = projectMap["Lead Exclusivity"];
  const {
    impressions_count,
    audience_maximizer_type,
    am_target_polygon_id,
    min_budget,
    max_budget,
    locality_city,
    project_ids,
    omni_channel_amount = 0,
  } = slot_product_mappings[audienceMaximizerId] || {};
  const { polygon_uuid, polygon_name } = locality_details.find(
    (item) => item.product_id === audienceMaximizerId
  );
  const fslCityArr =
    locality_details
      .filter((item) => item.product_id === fixedStaticListingId)
      .map(({ polygon_uuid: uuid = "", polygon_name: name }) => ({
        name,
        uuid,
      })) || [];
  const fasCityArr =
    locality_details
      .filter((item) => item.product_id === fixedAdSlotId)
      .map(({ polygon_uuid: uuid = "", polygon_name: name }) => ({
        name,
        uuid,
      })) || [];
  return {
    slotDetails: {
      start_date: start_datetime,
      end_date: end_datetime,
      entity_type_id: promoted_entity_type_id,
      impressions_count,
      omni_channel_amount,
      slot_product_mappings,
      promoted_entity,
      audience_maximizer_type,
      is_locality_churn,
      polygon_id: polygon_uuid,
      polygon_name,
      entity_id,
      fixed_ad_slot: product_ids.indexOf(fixedAdSlotId) > -1,
      fixed_static_listing: product_ids.indexOf(fixedStaticListingId) > -1,
      lead_exclusivity: product_ids.indexOf(leadExclusivityId) > -1,
      supply_user_uuid,
      projectName,
      duration: updateDuration(start_datetime, end_datetime),
      ...rest,
    },
    editProduct: {
      fasCityArr,
      fslCityArr,
      locality_city: {
        uuid: locality_city,
        name: getCitiesByPolygons([locality_city], cityData)[0],
      },
      min_budget,
      max_budget,
      project_ids,
      am_target_polygon_id:
        audience_maximizer_type !== constant.audienceMaximizerTypes.locality
          ? getCitiesByPolygons(am_target_polygon_id || [], cityData).map(
              (item, index) => ({
                uuid: am_target_polygon_id[index],
                name: item,
              })
            )
          : am_target_polygon_id,
    },
    slot_product_cards,
    ...slot_product_cards.reduce(
      (res, item) => ({
        ...res,
        [`image${item.priority}`]: {
          ...item,
          image: { absolute_url: item.card_images_info.banner_image_url },
        },
      }),
      {}
    ),
  };
};

export const getSellerUuidFromList = (data = []) => {
  if (data && data.length > 0) {
    return data.map(({ profile_uuid, name }) => ({ uuid: profile_uuid, name }));
  }
};

const getImageCardDataForEdit = (id, imageObj, audienceMaximizerId) => {
  let imageArr = [];
  for (let key in imageObj) {
    if (imageObj[key] && imageObj[key].card_id) {
      imageArr.push({
        content: imageObj[key].content,
        priority: imageObj[key].priority,
        product_id: audienceMaximizerId,
        slot_id: id,
        id: imageObj[key].card_id,
        status: "active",
        sub_title: imageObj[key].sub_title,
        title: imageObj[key].title,
        active_from_date: imageObj[key].active_from_date
          ? getFormattedDate(imageObj[key].active_from_date)
          : null,
      });
    }
  }
  return imageArr;
};

const getUtcTime = (date) => {
  const dateSplitArr = date.split("/");
  const utcFormatDate = `${dateSplitArr[1]}/${dateSplitArr[0]}/${dateSplitArr[2]}`;
  return new Date(utcFormatDate).getTime();
};

const checkUndefinedValue = (object, key) => typeof object[key] === "undefined";
