import { AGENT_LOCALITY_MAPPING } from "../actions/localityAgentMapping.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case AGENT_LOCALITY_MAPPING:
      return {
        ...state,
        agentIdLocalityMappingMap: action.agentIdLocalityMappingMap,
      };
  }
};

export default reducer;
