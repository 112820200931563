import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const CAP_DETAILS = "CAP_DETAILS";
export const SLOT_DEACTIVATED = "SLOT_DEACTIVATED";
export const SLOT_CREATED = "SLOT_CREATED";
export const SLOT_UPDATED = "SLOT_UPDATED";
const showError = (err, dispatch) => {
  dispatch({
    type: Constants.API_FAIL,
    errMsg: Constants.getErrorMessage(err),
  });
};

export const getCapDetails = () => {
  return (dispatch) => {
    const url = "/sapna/v1/cap/all-caps";
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: CAP_DETAILS,
          payload: response.data.data,
        });
      })
      .catch((err) => showError(err, dispatch));
  };
};

export const deactivateSlotCaps = (slotId) => {
  const url = `/sapna/v1/cap/${slotId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "DELETE",
    })
      .then(() => {
        dispatch({ type: SLOT_DEACTIVATED });
      })
      .catch((err) => showError(err, dispatch));
  };
};

export const createSlotCap = (data) => {
  const url = "sapna/v1/cap";
  return (dispatch) => {
    return instance({
      url,
      method: "POST",
      data,
    })
      .then(() => {
        dispatch({ type: SLOT_CREATED });
      })
      .catch((err) => showError(err, dispatch));
  };
};

export const updateSlotCap = (id, data) => {
  const url = `sapna/v1/cap/${id}`;
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data,
    })
      .then(() => {
        dispatch({ type: SLOT_UPDATED });
      })
      .catch((err) => showError(err, dispatch));
  };
};
