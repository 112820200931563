import { instance } from "../../lib/api"
import Constants from "../../utils/CommonConstants"

export const ACTIVE_OWNER_CASES_OLD = "ACTIVE_OWNER_CASES_OLD"
export const ACTIVE_OWNER_CASES_NEW = "ACTIVE_OWNER_CASES_NEW"
export const ACTIVE_AGENTS = "ACTIVE_AGENTS"
export const OWNER_CASES_MIGRATED = "OWNER_CASES_MIGRATED"
export const RESET_OWNER_CASES = "RESET_OWNER_CASES"

export const setAgentValues = () => {
  return (dispatch) => {
    const url = "mystique/v1/phoenix/active-agents"
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data } = response.data
        dispatch({
          type: ACTIVE_AGENTS,
          payload: data,
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        })
      })
  }
}
const getActiveOwnerCases = (agentId) => {
  return (dispatch) => {
    const url = `/mystique/v1/phoenix/owner-cases/${agentId}`
    return instance({
      url,
      method: "get",
    }).then((response) => {
      const { data } = response.data
      return data
    })
  }
}
export const setActiveOwnerCases = (oldAgentId, newAgentId) => {
  return (dispatch) => {
    Promise.all([
      dispatch(getActiveOwnerCases(oldAgentId, true)),
      dispatch(getActiveOwnerCases(newAgentId, false)),
    ])
      .then((ownerCaseLists) => {
        dispatch({
          type: ACTIVE_OWNER_CASES_OLD,
          payload: ownerCaseLists[0],
        })
        dispatch({
          type: ACTIVE_OWNER_CASES_NEW,
          payload: ownerCaseLists[1],
        })
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        })
      })
  }
}
export const transferOwnerCases = (oldAgentId, newAgentId, ownerCaseIds) => {
  return (dispatch) => {
    const url = "/mystique/v1/phoenix/reassign-agent"
    const data = {
      oldAgent: oldAgentId,
      newAgent: newAgentId,
      ownerCaseIds: ownerCaseIds,
    }
    return instance({
      url,
      method: "post",
      data: data,
      header: {
        "Content-type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: OWNER_CASES_MIGRATED,
        })
        return response.data
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        })
      })
  }
}
export const resetOwnerCases = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_OWNER_CASES,
    })
  }
}
