import Constant from "./CommonConstants";
import telesalesOpportunityConstants from "./TelesalesOpportunityConstants";
const ownerConstant = {
  userRoleMappingForStatus: {
    [Constant.TSTL]: "TEAM_LEADER",
    [Constant.TSPO]: "PROCESS_MANAGER",
    [Constant.HOM]: "ADMIN",
    [Constant.TSA]: "SALES_AGENT",
    [Constant.adminRole]: "ADMIN",
  },
  reasonMapping: {
    LoginActivity: "Login Activity",
    CallActivity: "Call Activity Logging",
  },
  agentStatusMappingRequired: [
    "Offline",
    "Online",
    "Oncall",
    "Idle",
    "Login Activity",
    "Call Activity Logging",
    telesalesOpportunityConstants.NO_OZONTEL_CALLBACK,
  ],
  liveDataChannelName: "activity",
  liveDataEventName: "activity-event",
  shareLinkEnableEmails: [
    "testingAgentlv@housing.com",
    "ownertestingagent@housing.com",
    "rahul.mallick@housing.com",
  ],
  newActivityLoginAgents: [
    "kamalpandit902756@gmail.com",
    "Ss0865534@gmail.com",
    "mehtaavni930@gmail.com",
    "vikrantsharma4k@gmail.com",
    "Saindeepak958281@gmail.com",
  ],
  shareLinkEnableDynamicProperty: "account.link.share.option.enable",
  getOwnerShareLinkDynamicPpty: (
    { isAdmin, isHousingOwnerAgent },
    getDynamicPropertyByKey
  ) => {
    if (!(isAdmin || isHousingOwnerAgent)) {
      return;
    }
    getDynamicPropertyByKey(ownerConstant.shareLinkEnableDynamicProperty);
  },
  ownerSalesConfigUserEmails: [
    "dealdesk-product@housing.com",
    "dealdesk-tech@housing.com",
    "dealdesk@housing.com",
  ],
  ownerDynamicPropertiesEmail: "pulkit.garg@housing.com",
  additionalOwnerOpportunityStages: [
    {
      name: "LINK_SENT_PAYMENT_FAILED",
      filterName: "PAYMENT_FAILED",
      mystiqueStatusId: 97,
      opportunitySource: "PaymentLink",
    },
  ],
  ownerOpportunityStageObjLastIndex: 7,
  tsaTilesCount: 4,
  tilesCount: 9,
  createOwnerLinkSentFilter: ({
    filters,
    filterName,
    mystiqueStatusId,
    opportunitySource,
    name,
    setFilterParams,
  }) => {
    if (name === "linkSent") {
      filters[name] = name;
    } else if (name === "linkSentPaymentFailed") {
      filters[name] = setFilterParams({
        filterName,
        mystiqueStatusId,
        opportunitySource,
      });
    }
    return filters;
  },
  commercial: {
    ACTIVE_LISTING_STATUS: 2,
    listingApi: () => (process.env.NODE_ENV === "production" ? 26 : 30),
  },
  PG_PROPERTY: 3,
  RESIDENTIAL_PROPERTY: 1,
  RENT_MONEYBACK_PACKAGE_ID: 1749,
  RESALE_MONEYBACK_PACKAGE_ID: 1763,
  RESALE_FIELD_ASSIST_PACKAGE_ID: 1759,
  RENT_FIELD_ASSIST_PACKAGE_ID: 230,
  activeListingsPayload: {
    profileType: "Owner",
    propertyCategoryId: [1, 3],
  },
  destructurePropertiesFromFlatsResponse: ({
    data: { data: { hits: { properties = [] } = {} } = {} } = {},
  }) => properties,
  parseFlatsData: (properties) => {
    return properties.map((property) => {
      const {
        id,
        status,
        flat_slot_details: flatSlotDetails,
        polygons_hash: polygonsHash,
        inventory_configs: inventoryConfigs,
      } = property;

      return {
        id,
        status,
        paid: !!flatSlotDetails.length,
        polygons_hash: polygonsHash,
        inventory_configs: inventoryConfigs,
      };
    });
  },
  isOwnerProfile: (profileType) => profileType === "Owner",
  discontinuedOwnerPackages: [
    232, // sell basic
    225, // rent basic
  ],
  ownerPackageDataMap: {
    BASIC: {
      title: "Basic",
    },
    PREMIUM: {
      title: "Premium",
    },
    PREMIUM_PLUS: {
      title: "Premium +",
    },
    ASSISTED: {
      title: "Assist",
    },
    FIELD_ASSIST: {
      title: "Assist +",
    },
    ASSISTED_MONEYBACK: {
      title: "Guarantee",
    },
    TRIAL_PACKAGE_1: {
      title: "Relax",
    },
    TRIAL_PACKAGE_2: {
      title: "Super Relax",
    },
  },
  paidDiscountEntities: {
    TELESALES_UPSELL_PACKAGE_DISCOUNT_OFFER: "upsell",
    TELESALES_RENEW_EXISTING_PACKAGE_DISCOUNT_OFFER: "renew",
  },
  listingFilters: {
    UNPAID: (_, { paid }) => !paid,
    UPSELL_RENEW: ({ ownerPaidDiscountData = {} }, { paid, id }) =>
      !!(paid && ownerPaidDiscountData[id]),
    ALL: (extraData, listing) => {
      const { listingFilters } = ownerConstant;
      return (
        listingFilters.UNPAID(extraData, listing) ||
        listingFilters.UPSELL_RENEW(extraData, listing)
      );
    },
  },
};

export default ownerConstant;
