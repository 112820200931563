export default function (key) {
  const localStore = {};
  localStore[key] = readBrowserStorage(key);

  const isBrowserStorageAccessible = (() => {
    let isStorageAccessible;
    return function (type) {
      if (!isStorageAccessible) {
        isStorageAccessible = !!(window && window[type]);
      }
      return isStorageAccessible;
    };
  })();

  function readBrowserStorage(key, { type = "localStorage" } = {}) {
    try {
      if (localStore?.[key]) {
        return localStore[key];
      } else if (
        isBrowserStorageAccessible(type) &&
        window[type][key] &&
        window[type][key] !== "undefined"
      ) {
        return window[type][key];
      }
      return null;
    } catch (e) {
      return null;
    }
  }
  function writeBrowserStorage(key, value, { type = "localStorage" } = {}) {
    try {
      localStore[key] = value;
      return isBrowserStorageAccessible(type) && window[type].setItem(key, value);
    } catch (e) {
      return null;
    }
  }

  function deleteBrowserStorage(key, { type = "localStorage" } = {}) {
    try {
      if (localStore[key]) {
        delete localStore[key];
      }
      return (
        isBrowserStorageAccessible(type) &&
        key &&
        delete window[type].removeItem(key)
      );
    } catch (e) {
      return null;
    }
  }
  return { readBrowserStorage, deleteBrowserStorage, writeBrowserStorage };
}
