import {
  SET_REJECTION_REASONS,
  RESET_FORM_DATA,
  SET_FORM_DATA,
} from "../actions/flatPanel.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_REJECTION_REASONS: {
      return {
        ...state,
        flatPanel: {
          ...state.flatPanel,
          rejectionReasons: { ...action.payload },
        },
      };
    }
    case SET_FORM_DATA: {
      const { section, data } = action.payload;
      return {
        ...state,
        flatPanel: {
          ...state.flatPanel,
          formData: {
            ...state.flatPanel.formData,
            [section]: data,
          },
        },
      };
    }
    case RESET_FORM_DATA: {
      return {
        ...state,
        flatPanel: {
          ...state.flatPanel,
          formData: {},
        },
      };
    }
  }
};

export default reducer;
