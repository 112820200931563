const getCredentialsMap = (category, housingShorts) => {
  switch (category) {
    case "commercial":
      return {
        attr_id: 128,
        housing_app_name: "jasprr",
        resource_name: "commercial",
      };
    case "flatmate":
      return {
        attr_id: 29,
        resource_name: "Flatmates",
        housing_app_name: "HousingFlatmates",
      };
    case "residential":
      return {
        attr_id: housingShorts ? 171 : 4,
        housing_app_name: "HousingFlats",
        resource_name: "Flat",
      };
    default:
      return {
        attr_id: 4,
        housing_app_name: "HousingFlats",
        resource_name: "Flat",
      };
  }
};

export default getCredentialsMap;
