import {
  MERGE_ACCOUNT_DETAILS,
  MERGED_ACCOUNTS,
  GET_ACCOUNT_ID,
} from "../actions/mergeAccounts.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case MERGE_ACCOUNT_DETAILS:
      return {
        ...state,
        mergeAccountDetails: action.mergeAccountDetails,
      };
    case MERGED_ACCOUNTS:
      return {
        ...state,
        mergedAccountsStatus: action.status,
      };
    case GET_ACCOUNT_ID:
      return {
        ...state,
        accountInfo: action.payload,
      };
  }
};

export default reducer;
