import { NETSUIT_DATA_PUSH } from "../actions/netsuit.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case NETSUIT_DATA_PUSH:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "NetSuit Data has been pushed.",
          type: "success",
        },
      };
  }
};

export default reducer;
