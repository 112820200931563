import {
  NP_PROJECT_CONFIG_SUCCESS,
  NP_PROJECT_CONFIG_ERROR,
  NP_PROJECT_CONFIG_ERROR_INLINE,
  NP_PROJECT_CONFIG_IN_PROGRESS,
  NP_PROJECT_CONFIG_RESET,
  NP_SLOT_DETAILS_IN_PROGRESS,
  NP_SLOT_DETAILS_SUCCESS,
  NP_SLOT_DETAILS_ERROR,
  NP_SLOT_STOP_SUCCESS,
  NP_SLOT_STOP_IN_PROGRESS,
  NP_SLOT_STOP_ERROR,
  NP_SLOT_SUMMARY_ERROR,
  NP_SLOT_SUMMARY_IN_PROGRESS,
  NP_SLOT_SUMMARY_SUCCESS,
  NP_SLOT_ADD_SUCCESS,
  NP_SLOT_ADD_IN_PROGRESS,
  NP_SLOT_ADD_ERROR,
  NP_SLOT_ADD_RESET,
  NP_SLOT_POC_DETAILS_ERROR,
  NP_SLOT_POC_DETAILS_SUCCESS,
  NP_SLOT_POC_DETAILS_IN_PROGRESS,
  NP_SLOT_ALL_POC_DETAILS_ERROR,
  NP_SLOT_ALL_POC_DETAILS_SUCCESS,
  NP_SLOT_ALL_POC_DETAILS_IN_PROGRESS,
  NP_SLOT_PENDING_ACTIVATION_ERROR,
  NP_SLOT_PENDING_ACTIVATION_SUCCESS,
  NP_SLOT_PENDING_ACTIVATION_IN_PROGRESS,
  NP_SLOT_SWAP_ERROR,
  NP_SLOT_SWAP_IN_PROGRESS,
  NP_SLOT_SWAP_SUCCESS,
  NP_SWAP_PROJECT_DETAILS_ERROR,
  NP_SWAP_PROJECT_DETAILS_SUCCESS,
  NP_SWAP_PROJECT_DETAILS_IN_PROGRESS,
  GET_PROJECT_PROFILE_UUID,
} from "../actions/npSlot.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case NP_PROJECT_CONFIG_SUCCESS:
      return {
        ...state,
        npProjectConfig: action.payload.inventoryConfigs,
        npProjectLocalityConfig: action.payload.localityConfig,
        npProjectCityConfig: action.payload.cityConfig,
        npProjectConfigError: false,
        npProjectConfigErrorInline: false,
        npProjectConfigInProgress: false,
      };
    case NP_PROJECT_CONFIG_ERROR:
      return {
        ...state,
        npProjectConfigError: action.payload,
        npProjectConfigErrorInline: false,
        npProjectConfig: [],
        npProjectLocalityConfig: null,
        npProjectCityConfig: null,
        npProjectConfigInProgress: false,
      };
    case NP_PROJECT_CONFIG_ERROR_INLINE:
      return {
        ...state,
        npProjectConfigErrorInline: action.payload,
        npProjectConfigError: false,
        npProjectConfig: [],
        npProjectLocalityConfig: null,
        npProjectCityConfig: null,
        npProjectConfigInProgress: false,
      };
    case NP_PROJECT_CONFIG_IN_PROGRESS:
      return {
        ...state,
        npProjectConfigInProgress: true,
        npProjectConfigError: false,
        npProjectConfig: [],
        npProjectLocalityConfig: null,
        npProjectCityConfig: null,
        npProjectConfigErrorInline: false,
      };
    case NP_PROJECT_CONFIG_RESET:
      return {
        ...state,
        npProjectConfigInProgress: false,
        npProjectConfigErrorInline: false,
        npProjectConfigError: false,
        npProjectConfig: [],
        npProjectLocalityConfig: null,
        npProjectCityConfig: null,
      };
    case NP_SLOT_DETAILS_SUCCESS:
      return {
        ...state,
        npSlotDetails: action.payload,
        npSlotDetailsError: false,
        npSlotDetailsInProgress: false,
      };
    case NP_SLOT_DETAILS_ERROR:
      return {
        ...state,
        npSlotDetails: false,
        npSlotDetailsError: action.payload,
        npSlotDetailsInProgress: false,
      };
    case NP_SLOT_DETAILS_IN_PROGRESS:
      return {
        ...state,
        npSlotDetails: false,
        npSlotDetailsError: false,
        npSlotDetailsInProgress: action.payload,
      };
    case NP_SLOT_STOP_SUCCESS:
      return {
        ...state,
        npStopSlotMap: {
          ...state.npStopSlotMap,
          [action.slotId]: {
            success: action.payload,
            error: false,
            inProgress: false,
          },
        },
        // should refresh the list after stopping a slot
        npSlotDetails: state?.npSlotDetails.map((details) => {
          const { slotId } = details;
          if (slotId === action.slotId) {
            return action.payload;
          }
          return details;
        }),
      };
    case NP_SLOT_SWAP_ERROR:
      return {
        ...state,
        npSwapSlotMap: {
          ...state.npSwapSlotMap,
          [action.parentId]: {
            success: false,
            error: action.payload,
            inProgress: false,
          },
        },
        snackbar: {
          state: true,
          message: action.payload,
          type: "error",
        },
      };
    case NP_SLOT_SWAP_SUCCESS:
      return {
        ...state,
        npSwapSlotMap: {
          ...state.npSwapSlotMap,
          [action.parentId]: {
            success: true,
            error: null,
            inProgress: false,
          },
        },
        snackbar: {
          state: true,
          message: "Slot approval request sent successfully!",
          type: "success",
        },
      };
    case NP_SLOT_SWAP_IN_PROGRESS:
      return {
        ...state,
        npSwapSlotMap: {
          ...state.npSwapSlotMap,
          [action.parentId]: {
            success: null,
            error: null,
            inProgress: true,
          },
        },
      };
    case NP_SLOT_STOP_ERROR:
      return {
        ...state,
        npStopSlotMap: {
          ...state.npStopSlotMap,
          [action.slotId]: {
            success: false,
            error: action.payload,
            inProgress: false,
          },
        },
      };
    case NP_SLOT_STOP_IN_PROGRESS:
      return {
        ...state,
        npStopSlotMap: {
          ...state.npStopSlotMap,
          [action.slotId]: {
            success: false,
            error: false,
            inProgress: action.payload,
          },
        },
      };
    case NP_SLOT_SUMMARY_SUCCESS:
      return {
        ...state,
        npSlotSummary: action.payload,
        npSlotRegionId: action.payload && action.payload.regionId,
        npSlotSummaryInProgress: false,
        npSlotSummaryError: null,
      };
    case NP_SLOT_SUMMARY_ERROR:
      return {
        ...state,
        npSlotSummary: null,
        npSlotRegionId: "",
        npSlotSummaryInProgress: false,
        npSlotSummaryError: action.payload,
      };
    case NP_SLOT_SUMMARY_IN_PROGRESS:
      return {
        ...state,
        npSlotSummary: null,
        npSlotRegionId: "",
        npSlotSummaryInProgress: true,
        npSlotSummaryError: null,
      };
    case NP_SLOT_ADD_SUCCESS:
      return {
        ...state,
        npSlotAddSuccess: action.payload,
        npSlotAddInProgress: false,
        npSlotAddError: false,
        npSlotDetails: [action.payload, ...state.npSlotDetails], // should refresh the list after adding a slot
        snackbar: {
          state: true,
          message: "Slot added successfully!",
          type: "success",
        },
      };
    case NP_SLOT_ADD_IN_PROGRESS:
      return {
        ...state,
        npSlotAddSuccess: null,
        npSlotAddInProgress: true,
        npSlotAddError: false,
      };
    case NP_SLOT_ADD_ERROR:
      return {
        ...state,
        npSlotAddSuccess: null,
        npSlotAddInProgress: false,
        npSlotAddError: action.payload,
        snackbar: {
          state: true,
          message: action.payload,
          type: "error",
        },
      };
    case NP_SLOT_POC_DETAILS_SUCCESS:
      return {
        ...state,
        npSlotPocDetails: action.payload,
        npSlotPocDetailsError: null,
        npSlotPocDetailsInProgress: false,
      };
    case NP_SLOT_POC_DETAILS_ERROR:
      return {
        ...state,
        npSlotPocDetails: null,
        npSlotPocDetailsError: action.payload,
        npSlotPocDetailsInProgress: false,
      };
    case NP_SLOT_POC_DETAILS_IN_PROGRESS:
      return {
        ...state,
        npSlotPocDetails: null,
        npSlotPocDetailsError: null,
        npSlotPocDetailsInProgress: true,
      };
    case NP_SLOT_ALL_POC_DETAILS_SUCCESS:
      return {
        ...state,
        npSlotAllPocDetails: action.payload,
        npSlotAllPocDetailsError: null,
        npSlotAllPocDetailsInProgress: false,
      };
    case NP_SLOT_ALL_POC_DETAILS_ERROR:
      return {
        ...state,
        npSlotAllPocDetails: null,
        npSlotAllPocDetailsError: action.payload,
        npSlotAllPocDetailsInProgress: false,
      };
    case NP_SLOT_ALL_POC_DETAILS_IN_PROGRESS:
      return {
        ...state,
        npSlotAllPocDetails: null,
        npSlotAllPocDetailsError: null,
        npSlotAllPocDetailsInProgress: true,
      };
    case NP_SLOT_ADD_RESET:
      return {
        ...state,
        npSlotAddSuccess: null,
        npSlotAddInProgress: false,
        npSlotAddError: null,
      };
    case NP_SLOT_PENDING_ACTIVATION_SUCCESS:
      return {
        ...state,
        npSlotPendingActivations: action.payload,
        npSlotPendingActivationsInProgress: false,
        npSlotPendingActivationsError: null,
      };
    case NP_SLOT_PENDING_ACTIVATION_IN_PROGRESS:
      return {
        ...state,
        npSlotPendingActivations: null,
        npSlotPendingActivationsInProgress: true,
        npSlotPendingActivationsError: null,
      };
    case NP_SLOT_PENDING_ACTIVATION_ERROR:
      return {
        ...state,
        npSlotPendingActivations: null,
        npSlotPendingActivationsInProgress: false,
        npSlotPendingActivationsError: action.payload,
      };
    case NP_SWAP_PROJECT_DETAILS_IN_PROGRESS:
      return {
        ...state,
        npSwapProjectApprovalDetails: null,
        npSwapProjectApprovalDetailsError: null,
        npSwapProjectApprovalDetailsInProgress: true,
      };
    case NP_SWAP_PROJECT_DETAILS_ERROR:
      return {
        ...state,
        npSwapProjectApprovalDetails: null,
        npSwapProjectApprovalDetailsError: action.payload,
        npSwapProjectApprovalDetailsInProgress: false,
      };
    case NP_SWAP_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        npSwapProjectApprovalDetails: action.payload,
        npSwapProjectApprovalDetailsError: null,
        npSwapProjectApprovalDetailsInProgress: false,
      };
    case GET_PROJECT_PROFILE_UUID:
      return {
        ...state,
        projectInfoID: action.payload,
      };
  }
};

export default reducer;
