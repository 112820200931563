import {
  INCENTIVE_RULE_SLAB,
  INCENTIVE_PARTICIPANTS_CATEGORIES,
  SLAB_UPDATE_SUCCESS,
  SLAB_UPDATE_FAIL,
  SLAB_UPDATE_RESET,
  RULE_UPDATE_SUCCESS,
  RULE_UPDATE_FAIL,
  RULE_UPDATE_RESET,
  SALES_INCENTIVE_CYCLES,
  SALES_INCENTIVE_TARGET,
  RULES_BY_USERID,
  TARGET_UPDATE_SUCCESS,
  TARGET_UPDATE_FAIL,
  TARGET_UPDATE_RESET,
  CALCULATED_INCENTIVE_GET_SUCCESS,
  CALCULATED_INCENTIVE_SUMMARY_GET_SUCCESS,
  CALCULATED_INCENTIVE_BY_ID_GET_RESET,
  SIGN_OFF_SUCCESS,
  SIGN_OFF_FAIL,
  SIGN_OFF_RESET,
  MARK_AS_PAID_SUCCESS,
  MARK_AS_PAID_FAIL,
  MARK_AS_PAID_RESET,
  CALCULATED_INCENTIVE_BY_ID_GET_SUCCESS,
  SEND_CYCLE_WISE_INCENTIVE_REPORT,
} from "../actions/incentive.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case INCENTIVE_RULE_SLAB:
      return {
        ...state,
        incentiveRules: action.rules,
        incentiveParticipantsCategoriesMapping:
          action.incentiveParticipantsCategoriesMapping,
        slabs: action.slabs,
      };
    case INCENTIVE_PARTICIPANTS_CATEGORIES:
      return {
        ...state,
        incentiveParticipantsCategoriesMapping:
          action.incentiveParticipantsCategoriesMapping,
      };
    case SLAB_UPDATE_SUCCESS:
      return {
        ...state,
        slabUpdationSuccess: "success",
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Slab updated successfully",
        },
      };
    case SLAB_UPDATE_FAIL:
      return {
        ...state,
        slabUpdationSuccess: "failure",
        snackbar: {
          state: true,
          type: "error",
          duration: null,
          message:
            action.errMsg || "Some error occurred, please try after some time",
        },
      };
    case SLAB_UPDATE_RESET: {
      return {
        ...state,
        slabUpdationSuccess: "",
      };
    }
    case RULE_UPDATE_SUCCESS:
      return {
        ...state,
        ruleUpdationSuccess: "success",
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Slab updated successfully",
        },
      };
    case RULE_UPDATE_FAIL:
      return {
        ...state,
        ruleUpdationSuccess: "failure",
        snackbar: {
          state: true,
          type: "error",
          duration: null,
          message:
            action.errMsg || "Some error occurred, please try after some time",
        },
      };
    case RULE_UPDATE_RESET: {
      return {
        ...state,
        ruleUpdationSuccess: "",
      };
    }
    case SALES_INCENTIVE_CYCLES: {
      return {
        ...state,
        currentCycles: action.currentCycles,
      };
    }
    case SALES_INCENTIVE_TARGET: {
      return {
        ...state,
        salesTargets: action.salesTargets,
        salesUserTargetMap: action.salesUserTargetMap,
      };
    }
    case RULES_BY_USERID: {
      return {
        ...state,
        rulesByUserId: action.rulesByUserId,
        rulesUserMap: action.rulesUserMap,
      };
    }
    case TARGET_UPDATE_SUCCESS:
      return {
        ...state,
        targetUpdationSuccess: "success",
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Target updated successfully",
        },
      };
    case TARGET_UPDATE_FAIL:
      return {
        ...state,
        targetUpdationSuccess: "failure",
        snackbar: {
          state: true,
          type: "error",
          duration: null,
          message:
            action.errMsg || "Some error occurred, please try after some time",
        },
      };
    case TARGET_UPDATE_RESET: {
      return {
        ...state,
        targetUpdationSuccess: "",
      };
    }
    case CALCULATED_INCENTIVE_GET_SUCCESS: {
      return {
        ...state,
        userIncentives: action.userIncentives,
        userIncentiveMap: action.userIncentiveMap,
      };
    }
    case CALCULATED_INCENTIVE_SUMMARY_GET_SUCCESS: {
      return {
        ...state,
        userIncentivesSummary: action.userIncentivesSummary,
        userIncentiveSummaryMap: action.userIncentiveSummaryMap,
      };
    }
    case CALCULATED_INCENTIVE_BY_ID_GET_SUCCESS: {
      return {
        ...state,
        userIncentiveById: action.userIncentiveById,
        userIncentiveByIdStatus: "success",
      };
    }
    case CALCULATED_INCENTIVE_BY_ID_GET_RESET: {
      return {
        ...state,
        userIncentiveByIdStatus: "",
      };
    }
    case SIGN_OFF_SUCCESS:
      return {
        ...state,
        signOffUpdationSuccess: "success",
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Incentive has been Signed Off",
        },
      };
    case SIGN_OFF_FAIL:
      return {
        ...state,
        signOffUpdationSuccess: "failure",
        snackbar: {
          state: true,
          type: "error",
          duration: null,
          message:
            action.errMsg || "Some error occurred, please try after some time",
        },
      };
    case SIGN_OFF_RESET: {
      return {
        ...state,
        signOffUpdationSuccess: "",
      };
    }
    case MARK_AS_PAID_SUCCESS: {
      return {
        ...state,
        paidUpdationSuccess: "success",
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg || "Incentive Updated",
        },
      };
    }
    case MARK_AS_PAID_FAIL:
      return {
        ...state,
        paidUpdationSuccess: "failure",
        snackbar: {
          state: true,
          type: "error",
          duration: null,
          message:
            action.errMsg || "Some error occurred, please try after some time",
        },
      };
    case MARK_AS_PAID_RESET: {
      return {
        ...state,
        paidUpdationSuccess: "",
      };
    }
    case SEND_CYCLE_WISE_INCENTIVE_REPORT:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: action.msg,
        },
      };
  }
};

export default reducer;
