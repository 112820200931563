import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

//const HOUSING_PROJECTS_URL = "https://newprojects.housing.com";
export const NP_PROJECT_CONFIG_SUCCESS = "NP_PROJECT_CONFIG_SUCCESS";
export const NP_PROJECT_CONFIG_ERROR = "NP_PROJECT_CONFIG_ERROR";
export const NP_PROJECT_CONFIG_ERROR_INLINE = "NP_PROJECT_CONFIG_ERROR_INLINE";
export const NP_PROJECT_CONFIG_IN_PROGRESS = "NP_PROJECT_CONFIG_IN_PROGRESS";
export const NP_PROJECT_CONFIG_RESET = "NP_PROJECT_CONFIG_RESET";
export const NP_SLOT_DETAILS_SUCCESS = "NP_SLOT_DETAILS_SUCCESS";
export const NP_SLOT_DETAILS_ERROR = "NP_SLOT_DETAILS_ERROR";
export const NP_SLOT_DETAILS_IN_PROGRESS = "NP_SLOT_DETAILS_IN_PROGRESS";
export const NP_SLOT_STOP_IN_PROGRESS = "NP_SLOT_STOP_IN_PROGRESS";
export const NP_SLOT_STOP_SUCCESS = "NP_SLOT_STOP_SUCCESS";
export const NP_SLOT_STOP_ERROR = "NP_SLOT_STOP_ERROR";
export const NP_SLOT_SUMMARY_SUCCESS = "NP_SLOT_SUMMARY_SUCCESS";
export const NP_SLOT_SUMMARY_ERROR = "NP_SLOT_SUMMARY_ERROR";
export const NP_SLOT_SUMMARY_IN_PROGRESS = "NP_SLOT_SUMMARY_IN_PROGRESS";
export const NP_SLOT_ADD_SUCCESS = "NP_SLOT_ADD_SUCCESS";
export const NP_SLOT_ADD_IN_PROGRESS = "NP_SLOT_ADD_IN_PROGRESS";
export const NP_SLOT_ADD_ERROR = "NP_SLOT_ADD_ERROR";
export const NP_SLOT_ADD_RESET = "NP_SLOT_ADD_RESET";
export const NP_SLOT_POC_DETAILS_SUCCESS = "NP_SLOT_POC_DETAILS_SUCCESS";
export const NP_SLOT_POC_DETAILS_ERROR = "NP_SLOT_POC_DETAILS_ERROR";
export const NP_SLOT_POC_DETAILS_IN_PROGRESS = "NP_SLOT_POC_DETAILS_IN_PROGRESS";
export const NP_SLOT_ALL_POC_DETAILS_SUCCESS = "NP_SLOT_ALL_POC_DETAILS_SUCCESS";
export const NP_SLOT_ALL_POC_DETAILS_ERROR = "NP_SLOT_ALL_POC_DETAILS_ERROR";
export const NP_SLOT_ALL_POC_DETAILS_IN_PROGRESS =
  "NP_SLOT_ALL_POC_DETAILS_IN_PROGRESS";
export const NP_SLOT_PENDING_ACTIVATION_SUCCESS =
  "NP_SLOT_PENDING_ACTIVATION_SUCCESS";
export const NP_SLOT_PENDING_ACTIVATION_ERROR = "NP_SLOT_PENDING_ACTIVATION_ERROR";
export const NP_SLOT_PENDING_ACTIVATION_IN_PROGRESS =
  "NP_SLOT_PENDING_ACTIVATION_IN_PROGRESS";
export const NP_SLOT_SWAP_IN_PROGRESS = "NP_SLOT_SWAP_IN_PROGRESS";
export const NP_SLOT_SWAP_ERROR = "NP_SLOT_SWAP_ERROR";
export const NP_SLOT_SWAP_SUCCESS = "NP_SLOT_SWAP_SUCCESS";
export const NP_SWAP_PROJECT_DETAILS_SUCCESS = "NP_SWAP_PROJECT_DETAILS_SUCCESS";
export const NP_SWAP_PROJECT_DETAILS_ERROR = "NP_SWAP_PROJECT_DETAILS_ERROR";
export const NP_SWAP_PROJECT_DETAILS_IN_PROGRESS =
  "NP_SWAP_PROJECT_DETAILS_IN_PROGRESS";
export const GET_PROJECT_PROFILE_UUID = "GET_PROJECT_PROFILE_UUID";

const COVER_IMAGE_ERROR =
  "Selected Project has no cover image. Please raise a Storm Ticket for the same.";

const NO_PRICE_AVAILABLE =
  "Selected Project has no configuration with available price. Please select some other project";

const parseProjectConfigDetails = (projectConfigList) => {
  const configDetails = [];
  for (let key in projectConfigList) {
    const { id, selected_area, price, apartment_group_name } =
      projectConfigList[key];
    configDetails.push({
      apartmentType: apartment_group_name,
      areaSize: selected_area,
      price,
      flatConfigurationId: id,
    });
  }
  return configDetails;
};

const getUniqueConfig = (configs = []) => {
  const uniqueIds = {};
  configs.forEach((config) => (uniqueIds[config.id] = config));
  return Object.values(uniqueIds);
};

export const getProjectConfig = (projectId, selectedCategory) => {
  return (dispatch) => {
    let urlMap = {
      residential: `${process.env.DOMAINS.VENUS}api/v7/new-projects/${projectId}/webapp?version=v3`,
      commercial: `${process.env.DOMAINS.VENUS}api/v1/${projectId}`,
    };
    const url = urlMap[selectedCategory];
    dispatch({
      type: NP_PROJECT_CONFIG_IN_PROGRESS,
    });
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        let data = response?.data?.data ?? {};
        const {
          inventory_configs = [],
          cover_photo_url = "",
          polygons_hash,
          bounding_box,
        } = data || {};
        const { locality } = polygons_hash;
        const localityUuid = locality.uuid;
        const cityUuid = bounding_box.uuid;

        if (cover_photo_url) {
          const configsWithNoPrice = inventory_configs.filter(
            (config) => !config.price
          );
          if (configsWithNoPrice.length === inventory_configs.length) {
            dispatch({
              type: NP_PROJECT_CONFIG_ERROR_INLINE,
              payload: NO_PRICE_AVAILABLE,
            });
          } else {
            dispatch({
              type: NP_PROJECT_CONFIG_SUCCESS,
              payload: {
                inventoryConfigs: getUniqueConfig(inventory_configs),
                localityConfig: { uuid: localityUuid },
                cityConfig: { uuid: cityUuid },
              },
            });
          }
        } else {
          dispatch({
            type: NP_PROJECT_CONFIG_ERROR_INLINE,
            payload: COVER_IMAGE_ERROR,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: NP_PROJECT_CONFIG_ERROR,
          payload: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getSlotDetails = (opmId) => {
  return (dispatch) => {
    dispatch({
      type: NP_SLOT_DETAILS_IN_PROGRESS,
      payload: true,
    });
    const url = `/sapna/np/get-all-slots?opmV2Ids=${opmId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const {
          data: { npPinningSlotDetails: { [opmId]: details = [] } = {} } = {},
        } = response.data;
        dispatch({
          type: NP_SLOT_DETAILS_SUCCESS,
          payload: details,
        });
      })
      .catch((err) => {
        dispatch({
          type: NP_SLOT_DETAILS_ERROR,
          payload: Constants.getErrorMessage(err),
        });
      });
  };
};

export const addSlot = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const { npSlotSummary: { opmv2Id = "", opportunityId } = {} } = state;
    if (opmv2Id && opportunityId) {
      const url = `/sapna/np/create-slot`;
      dispatch({
        type: NP_SLOT_ADD_IN_PROGRESS,
        payload: true,
      });
      const { projectConfigList, ...restData } = data;
      const dataToSend = {
        opmV2Id: opmv2Id,
        opportunityId,
        configDetails: parseProjectConfigDetails(projectConfigList),
        ...restData,
      };
      return instance({
        url,
        method: "post",
        data: dataToSend,
      })
        .then((response) => {
          const { data } = response.data;
          dispatch({
            type: NP_SLOT_ADD_SUCCESS,
            payload: data,
          });
        })
        .catch((err) => {
          dispatch({
            type: NP_SLOT_ADD_ERROR,
            payload: Constants.getErrorMessage(err),
          });
        });
    }
  };
};

export const swapProject = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const { npSlotSummary: { opmv2Id = "", opportunityId } = {} } = state;
    if (opmv2Id && opportunityId) {
      const url = `/sapna/np/swap-slot`;
      const { projectConfigList, parentId, ...restData } = data;
      dispatch({
        type: NP_SLOT_SWAP_IN_PROGRESS,
        payload: true,
        parentId,
      });
      // const { projectConfigList, ...restData } = data;
      const dataToSend = {
        opmV2Id: opmv2Id,
        opportunityId,
        configDetails: parseProjectConfigDetails(projectConfigList),
        parentId,
        ...restData,
      };
      return instance({
        url,
        method: "post",
        data: dataToSend,
      })
        .then((response) => {
          const { data } = response.data;
          dispatch({
            type: NP_SLOT_SWAP_SUCCESS,
            payload: data,
            parentId,
          });
        })
        .catch((err) => {
          dispatch({
            type: NP_SLOT_SWAP_ERROR,
            payload: Constants.getErrorMessage(err),
            parentId,
          });
        });
    }
  };
};

export const stopRunningSlot = (slotId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { npSlotSummary: { opmv2Id = "" } = {} } = state;
    if (opmv2Id) {
      dispatch({
        type: NP_SLOT_STOP_IN_PROGRESS,
        payload: true,
        slotId,
      });
      const url = `/sapna/np/stop-slot`;
      return instance({
        url,
        method: "post",
        data: { opmV2Id: opmv2Id, slotId },
      })
        .then((response) => {
          const { data } = response.data;
          dispatch({
            type: NP_SLOT_STOP_SUCCESS,
            payload: data,
            slotId,
          });
        })
        .catch((err) => {
          dispatch({
            type: NP_SLOT_STOP_ERROR,
            payload: Constants.getErrorMessage(err),
            slotId,
          });
        });
    }
  };
};

export const getSummaryDetails = (opmId) => {
  return (dispatch) => {
    dispatch({
      type: NP_SLOT_SUMMARY_IN_PROGRESS,
      payload: true,
    });
    const url = `/sapna/np/get-summary-details?opmV2Ids=${opmId}`;
    return instance({
      url,
    })
      .then((response) => {
        const {
          data: [summary],
        } = response.data;

        dispatch({
          type: NP_SLOT_SUMMARY_SUCCESS,
          payload: summary,
        });
      })
      .catch((err) => {
        dispatch({
          type: NP_SLOT_SUMMARY_ERROR,
          payload: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getPocDetails = () => {
  return (dispatch, getState) => {
    dispatch({
      type: NP_SLOT_POC_DETAILS_IN_PROGRESS,
      payload: true,
    });
    const state = getState();
    let accountId = "";
    const { npSlotSummary, opportunity, account, termsheet } = state;
    if (npSlotSummary) {
      accountId = npSlotSummary.accountId;
    } else if (opportunity) {
      accountId = opportunity.data.accountId;
    } else if (account) {
      accountId = account.accountId;
    } else if (termsheet) {
      accountId = termsheet.data.accountId;
    }
    if (accountId) {
      const url = `/sapna/v1/primaryAndChildPoc/${accountId}`;
      return instance({
        url,
      })
        .then((response) => {
          const { data: { primaryPoc = [], childPoc = [] } = {} } = response.data;

          dispatch({
            type: NP_SLOT_POC_DETAILS_SUCCESS,
            payload: [
              ...primaryPoc.map((poc) => ({ ...poc, type: "Primary" })),
              ...childPoc.map((poc) => ({ ...poc, type: "Child" })),
            ],
          });
        })
        .catch(() => {
          dispatch({
            type: NP_SLOT_POC_DETAILS_ERROR,
            payload: "Something went wrong while trying to fetch POC list.",
          });
        });
    }
  };
};

export const getPrimaryChildAndSubPocDetails = () => {
  return (dispatch, getState) => {
    dispatch({
      type: NP_SLOT_ALL_POC_DETAILS_IN_PROGRESS,
      payload: true,
    });
    const state = getState();
    let accountId = "";
    const { npSlotSummary, opportunity, account, termsheet } = state;
    const {
      pocTypes: { subUser, primary, child },
    } = Constants;
    if (npSlotSummary) {
      accountId = npSlotSummary.accountId;
    } else if (opportunity) {
      accountId = opportunity.data.accountId;
    } else if (account) {
      accountId = account.accountId;
    } else if (termsheet) {
      accountId = termsheet.data.accountId;
    }
    if (accountId) {
      const url = `/sapna/v1/primaryChildAndSubbrokerPoc/${accountId}`;
      return instance({
        url,
      })
        .then((response) => {
          const {
            data: { primaryPoc = [], childPoc = [], subBrokerPoc = [] } = {},
          } = response.data;

          const primaryHousingIds =
            primaryPoc?.map((poc) => poc.housingProfileId) || [];

          const filteredSubBrokerPoc = subBrokerPoc?.filter((subBroker) => {
            return !primaryHousingIds.includes(subBroker.housingProfileId);
          });

          dispatch({
            type: NP_SLOT_ALL_POC_DETAILS_SUCCESS,
            payload: [
              ...primaryPoc.map((poc) => ({ ...poc, type: primary })),
              ...childPoc.map((poc) => ({ ...poc, type: child })),
              ...filteredSubBrokerPoc.map((poc) => ({ ...poc, type: subUser })),
            ],
          });
        })
        .catch(() => {
          dispatch({
            type: NP_SLOT_ALL_POC_DETAILS_ERROR,
            payload: "Something went wrong while trying to fetch POC list.",
          });
        });
    }
  };
};

export const getPendingActivations = () => {
  return (dispatch) => {
    dispatch({
      type: NP_SLOT_PENDING_ACTIVATION_IN_PROGRESS,
      payload: true,
    });

    const url = `/sapna/np/get-all-pending-activations`;
    return instance({
      url,
    })
      .then((response) => {
        const { data = [] } = response.data;

        dispatch({
          type: NP_SLOT_PENDING_ACTIVATION_SUCCESS,
          payload: data,
        });
      })
      .catch(() => {
        dispatch({
          type: NP_SLOT_PENDING_ACTIVATION_ERROR,
          payload:
            "Something went wrong while fetching data. Please refresh the page.",
        });
      });
  };
};

export const getSwapApprovalDetails = (swapId) => {
  return (dispatch) => {
    dispatch({
      type: NP_SWAP_PROJECT_DETAILS_IN_PROGRESS,
      payload: true,
    });

    const url = `/sapna/np/approval-page-details/${swapId}`;
    return instance({
      url,
    })
      .then((response) => {
        const { data = [] } = response.data;

        dispatch({
          type: NP_SWAP_PROJECT_DETAILS_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: NP_SWAP_PROJECT_DETAILS_ERROR,
          payload: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetProjectConfig = () => ({
  type: NP_PROJECT_CONFIG_RESET,
});

export const resetAddSlot = () => ({
  type: NP_SLOT_ADD_RESET,
});
