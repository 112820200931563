import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const COMMON_MATRIX = "COMMON_MATRIX";
export const COMMON_MATRIX_NETWORK_ERR = "COMMON_MATRIX_NETWORK_ERR";

export const getCommonMatrixData = (type) => {
  return (dispatch) => {
    const url = `/sapna/approving-designations?approvalType=${type}&domainId=5`;
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        const { data: matrixData } = response.data;
        dispatch({
          type: COMMON_MATRIX,
          matrixData,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const addCommonMatrixEntry = (type, data) => {
  return (dispatch) => {
    const url = `/sapna/approving-designations?approvalType=${type}&domainId=5`;
    return instance({
      url,
      method: "POST",
      data,
    })
      .then((response) => {
        const { data: matrixData } = response.data;
        dispatch({
          type: COMMON_MATRIX,
          matrixData,
        });
        dispatch({
          type: Constants.API_SUCCESS,
          errMsg: "Matrix level added successfully!",
        });
      })
      .catch((err) => {
        dispatch({ type: COMMON_MATRIX_NETWORK_ERR });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const deleteCommonMatrixEntry = (type, id) => {
  return (dispatch) => {
    const url = `/sapna/approving-designations?approvalType=${type}&domainId=5`;
    return instance({
      url,
      method: "DELETE",
      data: id,
    })
      .then((response) => {
        const { data: matrixData } = response.data;
        dispatch({
          type: COMMON_MATRIX,
          matrixData,
        });
        dispatch({
          type: Constants.API_SUCCESS,
          errMsg: "Discount approval level removed successfully!",
        });
      })
      .catch((err) => {
        dispatch({ type: COMMON_MATRIX_NETWORK_ERR });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
