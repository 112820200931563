import {
  ACTION_LIST,
  PROCESSED_DATA,
  TICKETS_DATA,
  PAYMENTS_DATA,
  APPROVALS_DATA,
} from "../actions/home.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_LIST:
      return {
        ...state,
        actionList: action.actionList,
      };
    case PROCESSED_DATA:
      return {
        ...state,
        processedData: action.processedData,
      };
    case TICKETS_DATA:
      return {
        ...state,
        ticketsData: action.responseData,
      };
    case PAYMENTS_DATA:
      return {
        ...state,
        paymentsData: action.responseData,
      };
    case APPROVALS_DATA:
      return {
        ...state,
        approvalsData: action.responseData,
      };
  }
};

export default reducer;
