import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT_UUIDS = "PROJECT_UUIDS";
export const UUID_LIST = "UUID_LIST";
export const GET_PROJECT_DETAIL_CHURN = "GET_PROJECT_DETAIL_CHURN";
export const GET_RESALE_RENT_PRICE_CHURN = "GET_RESALE_RENT_PRICE_CHURN";
export const GET_MIN_MAX_PRICE_CHURN = "GET_MIN_MAX_PRICE_CHURN";
export const LOCALITY_LIST = "LOCALITY_LIST";
export const API_FAIL = "API_FAIL";
export const PROPERTY_TYPE_LIST = "PROPERTY_TYPE_LIST";
export const CONFIG_LIST = "CONFIG_LIST";
export const CHURN_SCHEDULER_LIST = "CHURN_SCHEDULER_LIST";
export const CHURN_SCHEDULER_LIST_APPEND = "CHURN_SCHEDULER_LIST_APPEND";
export const CHURN_RESPONSE = "CHURN_RESPONSE";
export const STOP_MANUAL_CHURN = "STOP_MANUAL_CHURN";
export const DRY_CHURN_RESPONSE = "DRY_CHURN_RESPONSE";
export const MULTI_DRY_CHURN_RESPONSE = "MULTI_DRY_CHURN_RESPONSE";
export const DRY_CHURN_LOADING = "DRY_CHURN_LOADING";
export const SELLER_SEARCH_UUID_LIST = "SELLER_SEARCH_UUID_LIST";
export const SELLER_OPERATIONAL_LOCALITY_LIST = "SELLER_OPERATIONAL_LOCALITY_LIST";

const getProjectList = (data) => {
  const projects = {};
  if (data.results && data.results.length > 0) {
    data.results.forEach((value) => {
      let key = `${value.uuid} - ${value.name}`;
      projects[key] = {
        id: value.uuid,
        name: value.name,
      };
    });
  }
  return projects;
};

export const getProjects = (string) => {
  let url = `${
    process.env.DOMAINS.SEARCH
  }api/v1/search/suggest?property_category=residential&service=buy&type=project&string=${string}${
    !isNaN(string) ? `&entity_ids[]=${string}` : ""
  }`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data.data;
        const projects = getProjectList(data);
        dispatch({
          type: PROJECT_LIST,
          projects,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const getUUIDsList = (data) => {
  const uuids = {};
  if (data && data.length > 0) {
    data.forEach((value) => {
      let key = `${value.profile_type} - ${value.name}`;
      uuids[key] = {
        profile_type: value.profile_type,
        name: value.name,
        profile_uuid: value.profile_uuid,
      };
    });
  }
  return uuids;
};

export const getUUIDs = (string) => {
  let url = `${process.env.DOMAINS.PAHAL}leads/manual-churn-pocs?filter_type=Project&jsession=true&entity_id=${string}`;
  return (dispatch) => {
    return instance({
      url,
      withCredentials: true,
      method: "get",
    })
      .then((response) => {
        const data =
          (response.data &&
            response.data.data &&
            response.data.data.contact_details) ||
          {};
        const uuids = getUUIDsList(data);
        dispatch({
          type: UUID_LIST,
          uuids,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getProjectDetail = (projectId, uuid) => {
  let url = `${process.env.DOMAINS.VENUS}api/v7/new-projects/${projectId}/webapp?keys=sellers_info,property_type_ids`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const {
          data: { data: { sellers_info = [], property_type_ids = [] } = {} } = {},
        } = response || {};
        const [projectDetail = {}] = sellers_info
          .filter(({ profile_uuid = "" }) => profile_uuid === uuid)
          .map(({ max_price, min_price, apartment_type_ids }) => ({
            max_price,
            min_price,
            apartment_type_ids,
            property_type_ids,
          }));
        dispatch({
          type: GET_PROJECT_DETAIL_CHURN,
          projectDetail,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const getMinMaxPriceDataBody = (
  leadServiceType,
  locality,
  propertyType,
  config,
  uuid,
  order
) => {
  const key = leadServiceType === "buy" ? "user_flats.price" : "user_flats.rent";
  return {
    service_type: leadServiceType,
    apartment_type_id: config.length ? config : undefined,
    property_type_id: propertyType.length ? propertyType : undefined,
    region_locality_uuid: locality,
    user_flats: {
      is_rent: leadServiceType === "rent" ? true : undefined,
      is_buy: leadServiceType === "buy" ? true : undefined,
      final_status: ["ACTIVE"],
    },
    profile_uuid: uuid,
    sort: {
      [key]: {
        order: order,
      },
    },
    page: 1,
    page_size: 1,
    components: "hits",
  };
};

export const getMinMaxPrice = (
  leadServiceType,
  locality,
  propertyType,
  config,
  uuid
) => {
  let url = `${process.env.DOMAINS.CASA}/api/v1/filter/filter-properties`;
  const promiseArray = [
    instance({
      url,
      withCredentials: true,
      method: "post",
      data: getMinMaxPriceDataBody(
        leadServiceType,
        locality,
        propertyType,
        config,
        uuid,
        "asc"
      ),
    }),
    instance({
      url,
      withCredentials: true,
      method: "post",
      data: getMinMaxPriceDataBody(
        leadServiceType,
        locality,
        propertyType,
        config,
        uuid,
        "desc"
      ),
    }),
  ];
  return (dispatch) => {
    return Promise.all(promiseArray)
      .then((response) => {
        const [
          {
            data: {
              data: {
                hits: {
                  properties: [
                    {
                      user_flats: [
                        {
                          user_flat_details: {
                            price: minPrice = null,
                            rent: minRent = null,
                          } = {},
                        } = {},
                      ] = [],
                    } = {},
                  ] = [],
                } = {},
              } = {},
            } = {},
          },
          {
            data: {
              data: {
                hits: {
                  properties: [
                    {
                      user_flats: [
                        {
                          user_flat_details: {
                            price: maxPrice = null,
                            rent: maxRent = null,
                          } = {},
                        } = {},
                      ] = [],
                    } = {},
                  ] = [],
                } = {},
              } = {},
            } = {},
          },
        ] = response || {};
        const resaleRentDetail =
          leadServiceType === "buy"
            ? {
                max_price: maxPrice,
                min_price: minPrice,
              }
            : {
                max_price: maxRent,
                min_price: minRent,
              };
        dispatch({
          type: GET_RESALE_RENT_PRICE_CHURN,
          resaleRentDetail,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const getLocalityList = (data) => {
  const localities = {};
  if (data && data.length > 0) {
    data.forEach((value) => {
      let key = `${value.name}`;
      localities[key] = {
        id: value.uuid,
        name: value.name,
      };
    });
  }
  return localities;
};

export const getLocalities = (string, cities) => {
  let url = `${
    process.env.DOMAINS.REGIONS
  }api/v1/polygon/suggest?size=20&source=web&super_type=polygon&service_type=buy&feature_type=locality&input=${string}&polygon_uuid=${Object.values(
    cities
  ).join(",")}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data;
        const localities = getLocalityList(data);
        dispatch({
          type: LOCALITY_LIST,
          localitiesByCity: localities,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const getConfigList = (data) => {
  const config = {};
  if (data && data.length > 0) {
    data.forEach((value) => {
      let key = `${value.display_name}`;
      config[key] = {
        id: value.mapped_id,
        name: value.display_name,
      };
    });
  }
  return config;
};

export const getConfig = () => {
  let url = `${process.env.DOMAINS.FLAT}api/v1/apartment_type/all`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data;
        const config = getConfigList(data);
        dispatch({
          type: CONFIG_LIST,
          config,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const getPropertyTypeList = (data) => {
  const propertyType = {};
  if (data && data.length > 0) {
    data.forEach((value) => {
      if (value.pc_name === "residential" || value.pc_name === "commercial") {
        let key = `${value.pt_name}`;
        propertyType[key] = {
          id: value.id,
          property_type_id: value.property_type_id,
          name: value.pt_name,
        };
      }
    });
  }
  return propertyType;
};

export const getPropertyType = () => {
  let url = `${process.env.DOMAINS.META}api/v0/property_category_type_mappings`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data;
        const propertyType = getPropertyTypeList(data);
        dispatch({
          type: PROPERTY_TYPE_LIST,
          propertyType,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const getEntityType = (value) => {
  if (value === "Resale") {
    return value;
  } else if (value === "NP - UUID" || value === "NP - Project") {
    return "np";
  }
  return value.toLowerCase();
};

const propertyCategoryValueMap = {
  Commercial: 2,
  Residential: 1,
};

const getSelectedProject = (value) => {
  if (value && value.id) {
    return value.id;
  }
  return "";
};

const getSelectedUUID = ({ profile_uuid: profileUuid, id: sellerUuid }) => {
  if (profileUuid || sellerUuid) {
    return profileUuid || sellerUuid;
  }
  return "";
};

const getSelectedCities = (value) => {
  const data = Object.values(value).join(",");
  return data ? data : null;
};

const getSelectedLocalities = (obj) => {
  let data = "";
  for (const prop in obj) {
    if (obj[prop] && obj[prop].id) {
      data += obj[prop].id + ",";
    }
  }
  return data.slice(0, -1) ? data.slice(0, -1) : null;
};

const getLeadServiceTypeValues = (array) => {
  let arr = array.map((val) => val.toLowerCase());
  return arr.join(",");
};

export const showNotification = (msg) => {
  return (dispatch) =>
    dispatch({
      type: Constants.SNACKBAR_NOTIFICATION,
      snackBarType: "error",
      msg,
    });
};

export const postResult = ({
  startDate,
  endDate,
  possessionValue,
  tillDate,
  lastNDays,
  maxPrice,
  minPrice,
  totalLeads,
  innerRadius,
  outerRadius,
  propertyCategory,
  criteriaValue,
  leadServiceTypeValues,
  propertyTypeValues,
  configValues,
  selectedProject,
  selectedUUID,
  selectedLocalities,
  selectedCities = [],
  responseMultiProjectIds = [],
  isDryRun = false,
  adDeficit = false,
  minPriceRelaxation,
  maxPriceRelaxation,
  selectedSellerUUID = {},
  selectedSellerLocality = [],
  distanceValue,
}) => {
  const isNP = criteriaValue === "NP - Project";
  const isNPUuid = criteriaValue === "NP - UUID";
  const isCommercial = propertyCategory === "Commercial";
  const url = isDryRun
    ? `${process.env.DOMAINS.PAHAL}leads/simulate-manual-churn-schedule`
    : isNPUuid
    ? `${process.env.DOMAINS.PAHAL}leads/create-manual-churn-schedules-in-bulk`
    : `${process.env.DOMAINS.PAHAL}leads/create-manual-churn-schedule`;
  return (dispatch) => {
    dispatch({
      type: DRY_CHURN_LOADING,
      payload: true,
    });
    return instance({
      url,
      method: "post",
      withCredentials: true,
      data: {
        manual_churn: {
          churn_start_date: startDate.toISOString().slice(0, 10),
          churn_end_date: endDate.toISOString().slice(0, 10),
          last_n_days: parseInt(lastNDays),
          total_leads: parseInt(totalLeads),
          entity_type: getEntityType(criteriaValue),
          search_criteria: {
            inner_radius: parseInt(innerRadius),
            outer_radius: parseInt(outerRadius),
            ...(isNPUuid && {
              criteria_type: distanceValue === "Radius" ? "radius" : "locality",
            }),
            ...(!isNPUuid && {
              city_uuids: getSelectedCities(selectedCities),
              locality_uuids: isCommercial
                ? ""
                : isNP
                ? getSelectedLocalities(selectedLocalities)
                : selectedSellerLocality.toString(),
              project_ids: responseMultiProjectIds,
            }),
          },
          lead_service_type: getLeadServiceTypeValues(leadServiceTypeValues),
          min_price: isNPUuid ? parseInt(minPriceRelaxation) : parseInt(minPrice),
          max_price: isNPUuid ? parseInt(maxPriceRelaxation) : parseInt(maxPrice),
          profile_uuid: getSelectedUUID(isNP ? selectedUUID : selectedSellerUUID),
          ...(isNPUuid && {
            project_ids: selectedProject,
          }),
          bypass_ad_deficit: adDeficit,
          ...(!isNPUuid && {
            property_category_id: propertyCategoryValueMap[propertyCategory],
            property_type: propertyTypeValues.length ? propertyTypeValues : null,
            bhk_type: configValues.length ? configValues : null,
            eligible_lead_generator: null,
          }),
          ...(isNP && {
            entity_id: getSelectedProject(selectedProject),
            possession: tillDate ? tillDate.toISOString().slice(0, 10) : null,
            possession_flag: possessionValue,
          }),
          ...(!isNP &&
            !isNPUuid && {
              min_price_relax: parseInt(minPriceRelaxation),
              max_price_relax: parseInt(maxPriceRelaxation),
            }),
        },
      },
    })
      .then((response) => {
        const data = response?.data?.data || {};
        if (isDryRun) {
          const { eligible_lead_count: dryRunChrunNumberResponse } = data;
          const result = [
            {
              entity_id: selectedProject?.id,
              leads_count: dryRunChrunNumberResponse,
            },
          ];
          dispatch({
            type: DRY_CHURN_RESPONSE,
            dryRunChrunNumberResponse: result,
          });
        } else {
          const churnResponse = isNPUuid
            ? data.manual_churn_schedule.map(
                ({ manual_churn_schedule }) => manual_churn_schedule
              )
            : [{ ...data.manual_churn_schedule }];
          dispatch({
            type: CHURN_RESPONSE,
            churnSchedulerResponse: churnResponse,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        dispatch({
          type: DRY_CHURN_LOADING,
          payload: false,
        });
      });
  };
};

export const isSuccessError = (response) => {
  const errorData = (response || []).find(
    ({ data: { message, eligible_lead_count: eligibleLeadCount } = {} } = {}) =>
      message && !eligibleLeadCount
  );
  const { data: { message } = {} } = errorData || {};
  if (message) throw new Error(message);
};

export const getDryRunNPUuidResults = (data) => {
  const url = `${process.env.DOMAINS.PAHAL}leads/simulate-manual-churn-schedule`;
  return instance({
    url,
    method: "post",
    withCredentials: true,
    data,
  }).then((response) => {
    return response.data;
  });
};
const getPromises = (data, projectIdValues, index, apiCount) => {
  const promises = [];

  for (
    let apiIndex = 0;
    apiIndex < apiCount && index + apiIndex < projectIdValues.length;
    apiIndex++
  ) {
    data.manual_churn.entity_id = projectIdValues[index + apiIndex].toString();
    promises.push(getDryRunNPUuidResults(data));
  }
  return promises;
};

export const postResultDryRunNPUuid = ({
  startDate,
  endDate,
  adDeficit = false,
  lastNDays,
  totalLeads,
  innerRadius,
  outerRadius,
  criteriaValue,
  distanceValue,
  leadServiceTypeValues,
  projectIdValues,
  minPriceRelaxation,
  maxPriceRelaxation,
  selectedSellerUUID,
}) => {
  return (dispatch) => {
    dispatch({
      type: DRY_CHURN_LOADING,
      payload: true,
    });
    let results = [];
    const getData = async () => {
      const apiCount = process.env.BUILD_ENV === "prod" ? 5 : 2;
      for (let index = 0; index < projectIdValues.length; index = index + apiCount) {
        const data = {
          manual_churn: {
            churn_start_date: startDate.toISOString().slice(0, 10),
            churn_end_date: endDate.toISOString().slice(0, 10),
            last_n_days: parseInt(lastNDays),
            total_leads: parseInt(totalLeads),
            entity_type: getEntityType(criteriaValue),
            search_criteria: {
              inner_radius: parseInt(innerRadius),
              outer_radius: parseInt(outerRadius),
              criteria_type: distanceValue === "Radius" ? "radius" : "locality",
              is_bulk_created: true,
            },
            lead_service_type: getLeadServiceTypeValues(leadServiceTypeValues),
            min_price: parseInt(minPriceRelaxation),
            max_price: parseInt(maxPriceRelaxation),
            profile_uuid: getSelectedUUID(selectedSellerUUID),
            entity_id: projectIdValues[index].toString(),
            bypass_ad_deficit: adDeficit,
          },
        };
        let response;
        try {
          const promises = getPromises(data, projectIdValues, index, apiCount);
          response = await Promise.all(promises);
          isSuccessError(response);
        } catch (error) {
          try {
            const promises = getPromises(data, projectIdValues, index, apiCount);
            response = await Promise.all(promises);
            isSuccessError(response);
          } catch (error) {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: Constants.getErrorMessage(error),
            });
            dispatch({
              type: DRY_CHURN_LOADING,
              payload: false,
            });
            return;
          }
        }
        const result = [];
        for (
          let apiIndex = 0;
          apiIndex < apiCount && index + apiIndex < projectIdValues.length;
          apiIndex++
        ) {
          const { eligible_lead_count: dryRunChrunNumberResponse } =
            response[apiIndex].data || {};
          result.push({
            entity_id: projectIdValues[index + apiIndex],
            leads_count: dryRunChrunNumberResponse,
          });
        }

        results = results.concat(result);
        dispatch({
          type: MULTI_DRY_CHURN_RESPONSE,
          dryRunChrunNumberResponse: results,
        });
        if (
          index ===
          Math.floor((projectIdValues.length - 1) / apiCount) * apiCount
        ) {
          dispatch({
            type: DRY_CHURN_LOADING,
            payload: false,
          });
        }
      }
    };
    getData();
  };
};

const getChurnSchedulerData = (data) => {
  return data;
};

export const getChurnSchedulerList = (page, append, size, filters = {}) => {
  const {
    status = "",
    projectId = "",
    profileUuuid = "",
    createdBy = "",
    sortOrder = "",
    sortKey = "",
  } = filters;
  let url = `${process.env.DOMAINS.PAHAL}leads/manual-churn-schedules?`;
  return (dispatch) => {
    return instance({
      url,
      withCredentials: true,
      method: "get",
      params: {
        status,
        profile_uuid: profileUuuid,
        entity_id: projectId,
        created_by: createdBy,
        page,
        size,
        sort_order: sortOrder,
        sort_key: sortKey,
      },
    })
      .then((response) => {
        const data = (response.data && response.data.data) || {};
        const churnSchedulerList = getChurnSchedulerData(data.manual_churns);
        const churnSchedulerListCount = getChurnSchedulerData(data.total_count);
        if (append)
          return dispatch({
            type: CHURN_SCHEDULER_LIST_APPEND,
            churnSchedulerList,
            churnSchedulerListCount,
          });
        return dispatch({
          type: CHURN_SCHEDULER_LIST,
          churnSchedulerList,
          churnSchedulerListCount,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const stopManualChurn = (ids) => {
  let url = `${process.env.DOMAINS.PAHAL}leads/stop-manual-churn-schedule`;
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data: { ids: ids },
      withCredentials: true,
    })
      .then((response) => {
        const stopManualChurnResponse = response.data;
        dispatch({
          type: STOP_MANUAL_CHURN,
          stopManualChurnResponse,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getSellerUuids = (id, criteriaValue, profile) => {
  let profileType =
    criteriaValue === "NP - UUID"
      ? "Broker,Builder,NewProjects::Promoter"
      : "Broker";
  profile === "Owner" && (profileType = "User");
  let url = `${process.env.DOMAINS.LOGIN}api/v1/predict-profiles`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
      withCredentials: true,
      params: {
        profile_type: profileType,
        include_inactive: false,
        q: id,
      },
    })
      .then(({ data = [] } = {}) => {
        const sellerUuids = {};
        if (data.length > 0) {
          data.forEach((value) => {
            sellerUuids[value.id] = { ...value };
          });
        }
        dispatch({
          type: SELLER_SEARCH_UUID_LIST,
          payload: sellerUuids,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getSellerProfileDetails = (id) => {
  let url = `${process.env.DOMAINS.LOGIN}api/v1/profile-details-public`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
      params: {
        profile_uuids: id,
      },
    })
      .then(({ data: { results = {} } = {} } = {}) => {
        if (results[id]) {
          const { operating_localities: operationLocalities = [] } =
            results[id] || {};
          const sellerOperationLocalities = {};
          if (operationLocalities.length > 0) {
            operationLocalities.forEach((value) => {
              sellerOperationLocalities[`${value.uuid} - ${value.name}`] = {
                ...value,
              };
            });
          }
          dispatch({
            type: SELLER_OPERATIONAL_LOCALITY_LIST,
            payload: sellerOperationLocalities,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const fetchSellerProjects = (id, page, cursor, npTotalCount, resaleTotalCount) => {
  const url = `${
    process.env.DOMAINS.KHOJ
  }api/v7/buy/index/internal_services_filter_api?uuid=${id}&results_per_page=50&p=${page}${
    cursor ? "&cursor=" + cursor : ""
  }${npTotalCount !== null ? "&np_total_count=" + npTotalCount : ""}${
    resaleTotalCount !== null ? "&resale_total_count=" + resaleTotalCount : ""
  }`;
  return instance({
    url,
    method: "get",
  }).then((response) => {
    return response;
  });
};

async function getSellerProjectsData(id) {
  let isLast = false;
  let pageNumber = 1;
  let cursor = null;
  let npTotalCount = null;
  let resaleTotalCount = null;
  const apiPromises = [];
  while (!isLast && pageNumber < 10) {
    const response = await fetchSellerProjects(
      id,
      pageNumber,
      cursor,
      npTotalCount,
      resaleTotalCount
    );
    const { data: { data } = {} } = response || {};
    isLast = data.is_last_page;
    cursor = data.cursor;
    if (pageNumber === 1) {
      npTotalCount = data.np_total_count;
      resaleTotalCount = data.resale_total_count;
    }
    pageNumber++;
    apiPromises.push(Promise.resolve(data));
  }

  const response = await Promise.all(apiPromises);
  let projects = [];
  response.forEach((result) => {
    const { hits = [] } = result || {};
    const sellerProjects = (hits || [])
      .filter(
        ({ type, price_on_request: priceOnRequest } = {}) =>
          !priceOnRequest && type === "project"
      )
      .map(({ id, name } = {}) => ({
        id,
        name,
      }));
    projects = projects.concat(sellerProjects);
  });
  return projects;
}

export const getSellerProjects = (id) => {
  return (dispatch) => {
    const getData = async () => {
      try {
        const projects = await getSellerProjectsData(id);
        dispatch({
          type: PROJECT_UUIDS,
          sellerProjects: projects,
        });
        return projects;
      } catch (err) {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      }
    };
    return getData();
  };
};
