export default {
  RENEWAL_DASHBOARD_VISITED: (globalState, localState) =>
    renewalDashboardCommonLabel({
      action: "renewal_dashboard_visited",
      globalState,
      localState,
    }),
};

const renewalDashboardCommonLabel = ({ globalState, localState, action }) => {
  const { userInfo = {} } = globalState;
  const { id } = userInfo || {};
  return {
    action,
    category: "renewal_dashboard",
    label: { ...localState, agentId: id },
  };
};
