import {
  DASHBOARD_STATS,
  GET_LIST,
  GET_COLLECTION,
  DASHBOARD_RESET,
  DASHBOARD_STATS_RESET,
  TEAM_SELF_CLICKED,
  SET_TOTAL_COUNT,
  SET_MORTGAGE_CITIES,
} from "../actions/myOpportunities.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case DASHBOARD_STATS:
      return {
        ...state,
        filters: action.payload.filters,
        dashboard: {
          ...state.dashboard,
          ...action.payload.dashboard,
        },
      };
    case GET_LIST:
      return {
        ...state,
        data: action.data,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        mortgageStatusWiseCount: action.payload,
      };
    case SET_MORTGAGE_CITIES:
      return {
        ...state,
        mortgageCities: action.payload,
      };
    case GET_COLLECTION:
      return {
        ...state,
        collectionAmt: action.collectionAmt,
      };
    case DASHBOARD_RESET:
      return {
        ...state,
        filters: null,
        dashboard: null,
      };
    case DASHBOARD_STATS_RESET:
      return {
        ...state,
        dashboard: null,
      };
    case TEAM_SELF_CLICKED:
      return {
        ...state,
        isSelfClicked: action.isSelfClicked,
      };
  }
};

export default reducer;
