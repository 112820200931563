import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";

export const PACKAGE_FAMILIES_DEACTIVATED = "PACKAGE_FAMILIES_DEACTIVATED";
export const PACKAGE_FAMILIY_UPDATED = "PACKAGE_FAMILIY_UPDATED";
export const PACKAGE_FAMILIY_CREATED = "PACKAGE_FAMILIY_CREATED";
export const RESET_PACKAGE_FAMILIY_UPDATION_STATUS =
  "RESET_PACKAGE_FAMILIY_UPDATION_STATUS";
const dispatchError = (err, dispatch) => {
  dispatch({
    type: Constants.API_FAIL,
    errMsg: Constants.getErrorMessage(err),
  });
};
export const deactivatePackageFamilies = (pacakgeFamiliyIds) => {
  const url = "/sapna/v2/package-families/delete";
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data: [...pacakgeFamiliyIds],
    })
      .then(() => {
        dispatch({ type: PACKAGE_FAMILIES_DEACTIVATED });
      })
      .catch((err) => dispatchError(err, dispatch));
  };
};

export const updatePackageFamily = (id, data) => {
  const url = `/sapna/v2/package-families/update/${id}`;
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data,
    })
      .then(() => {
        dispatch({ type: PACKAGE_FAMILIY_UPDATED });
      })
      .catch((err) => dispatchError(err, dispatch));
  };
};

export const createPackageFamily = (data, copyFromPackageFamilyId) => {
  let url = "";
  if (copyFromPackageFamilyId)
    url = `/sapna/v2/package-families/create/?copyFromPackageFamilyId=${copyFromPackageFamilyId}`;
  else url = "/sapna/v2/package-families/create";
  return (dispatch) => {
    return instance({
      url,
      method: "POST",
      data,
    })
      .then(() => {
        dispatch({ type: PACKAGE_FAMILIY_CREATED });
      })
      .catch((err) => dispatchError(err, dispatch));
  };
};

export const resetPackageFamilyUpdateStatus = () => (dispatch) =>
  dispatch({ type: RESET_PACKAGE_FAMILIY_UPDATION_STATUS });
