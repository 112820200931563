const fromEntries = (list) => {
  const result = {};
  for (let [key, value] of list) {
    result[key] = value;
  }
  return result;
};

const addAsset = ([name, assetPromise]) =>
  assetPromise.then((asset) => [name, asset]);

export const resolveObjectOfPromises = (promises) =>
  Promise.all(Object.entries(promises).map(addAsset)).then(fromEntries);
