import classNames from "classnames";
import React, { forwardRef } from "react";

import { NavLink as BaseNavLink } from "react-router-dom";

let CustomNavLink = ({ activeClassName, activeStyle, ...props }, ref) => {
  return (
    <BaseNavLink
      ref={ref}
      to="."
      {...props}
      className={({ isActive }) =>
        classNames(props.className, isActive ? activeClassName : null)
      }
      style={({ isActive }) => ({
        ...props.style,
        ...(isActive ? activeStyle : null),
      })}
    />
  );
};
const NavLink = forwardRef(CustomNavLink);

export default NavLink;
