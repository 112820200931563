import {
  AGENT,
  AGGREGATED_PERFORMANCE_DAILY,
  AGGREGATED_PERFORMANCE_MTD,
  CALL_PERFORMANCE_DAILY,
  CALL_PERFORMANCE_MTD,
  LEADERBOARD_CHILD,
  LEADERBOARD_USER,
  PROCESS_MANAGERS,
  TEAM_LEADER,
  DAY_WISE_PERFORMANCE,
  DATA_NOT_FOUND,
  RESET_PROCESS_MANAGER,
  RESET_TEAM_LEADERS,
  RESET_AGENTS,
  DAY_WISE_DATA_UNAVAILABLE,
  TEAM_COMPARISON_MTD_USER,
  CLOSE_TEAM_COMPARISON_VIEW,
  TEAM_COMPARISON_MTD_CHILD,
  TEAM_COMPARISON_DAILY_USER,
  TEAM_COMPARISON_DAILY_CHILD,
  appendNestedList,
  INVALID_AGENT_FIELD,
  RESET_LEADERBOARD,
} from "../actions/performance.actions";
const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case PROCESS_MANAGERS:
      return {
        ...state,
        processManagers: action.payload,
      };
    case TEAM_LEADER:
      return {
        ...state,
        teamLeaders: action.payload,
      };
    case AGENT:
      return {
        ...state,
        agents: action.payload,
      };
    case LEADERBOARD_USER:
      return {
        ...state,
        leaderBoard: {
          leaderBoardList: action.payload,
        },
      };
    case LEADERBOARD_CHILD: {
      const { data, location, nestedIndex } = action.payload;
      return {
        ...state,
        leaderBoard: {
          ...state.leaderBoard,
          leaderBoardList: appendNestedList(
            state.leaderBoard.leaderBoardList,
            location,
            nestedIndex,
            data
          ),
        },
      };
    }
    case AGGREGATED_PERFORMANCE_DAILY:
      return {
        ...state,
        aggregatedPerformanceDaily: action.payload,
      };
    case AGGREGATED_PERFORMANCE_MTD:
      return {
        ...state,
        aggregatedPerformanceMtd: action.payload,
      };
    case CALL_PERFORMANCE_MTD: {
      const {
        attempted,
        connected,
        callTime,
        talkTime,
        callTimeInHours,
        talkTimeInHours,
      } = action.payload;
      return {
        ...state,
        callingMtd: {
          attempted,
          connected,
          callTime,
          talkTime,
          callTimeInHours,
          talkTimeInHours,
        },
      };
    }
    case CALL_PERFORMANCE_DAILY: {
      const {
        attempted,
        connected,
        callTime,
        talkTime,
        callTimeInHours,
        talkTimeInHours,
      } = action.payload;
      return {
        ...state,
        callingDaily: {
          attempted,
          connected,
          callTime,
          talkTime,
          callTimeInHours,
          talkTimeInHours,
        },
      };
    }
    case DAY_WISE_PERFORMANCE:
      return {
        ...state,
        dayWisePerformanceData: action.payload,
      };
    case DATA_NOT_FOUND:
      return {
        ...state,
        snackbar: {
          state: true,
          duration: null,
          message: "No data available under agent",
          type: "warning",
        },
      };
    case RESET_PROCESS_MANAGER:
      return {
        ...state,
        processManagers: [],
      };
    case RESET_LEADERBOARD:
      return {
        ...state,
        leaderBoard: undefined,
      };

    case RESET_TEAM_LEADERS:
      return {
        ...state,
        teamLeaders: [],
      };
    case RESET_AGENTS:
      return {
        ...state,
        agents: [],
      };
    case DAY_WISE_DATA_UNAVAILABLE:
      return {
        ...state,
        dayWisePerformanceData: {},
      };
    case TEAM_COMPARISON_MTD_USER:
      return {
        ...state,
        teamComparisonData: {
          ...state.teamComparisonData,
          teamComparisonMtd: action.payload,
        },
      };
    case TEAM_COMPARISON_MTD_CHILD: {
      const { data, location, nestedIndex } = action.payload;
      return {
        ...state,
        teamComparisonData: {
          ...state.teamComparisonData,
          teamComparisonMtd: appendNestedList(
            state.teamComparisonData.teamComparisonMtd,
            location,
            nestedIndex,
            data
          ),
        },
      };
    }
    case TEAM_COMPARISON_DAILY_USER:
      return {
        ...state,
        teamComparisonData: {
          ...state.teamComparisonData,
          teamComparisonDaily: action.payload,
        },
      };
    case TEAM_COMPARISON_DAILY_CHILD: {
      const { data, location, nestedIndex } = action.payload;
      return {
        ...state,
        teamComparisonData: {
          ...state.teamComparisonData,
          teamComparisonDaily: appendNestedList(
            state.teamComparisonData.teamComparisonDaily,
            location,
            nestedIndex,
            data
          ),
        },
      };
    }
    case CLOSE_TEAM_COMPARISON_VIEW:
      return {
        ...state,
        teamComparisonData: {},
      };
    case INVALID_AGENT_FIELD:
      return {
        ...state,
        snackbar: {
          state: true,
          duration: null,
          message: "Invalid ID selected in dropdown field",
          type: "warning",
        },
      };
  }
};
export default reducer;
