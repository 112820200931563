import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";
import Store from "../../utils/localStorage";

const { readBrowserStorage, deleteBrowserStorage } = Store(
  Constants.ESCAPERECORDINGIDS
);

export const SET_RECORDINGS_DATA = "SET_RECORDINGS_DATA";
export const SET_RECORDINGS_FORM_DATA = "SET_RECORDINGS_FORM_DATA";
export const SET_FILTERS_DATA = "SET_FILTERS_DATA";
export const SET_FILTERS_DROPDOWN_DATA = "SET_FILTERS_DROPDOWN_DATA";
export const SET_FILTERS_DATE_DATA = "SET_FILTERS_DATE_DATA";

const modifyParams = (params) => {
  Object.keys(params).forEach((key) => {
    if (key == "city_select_uuids") {
      params[key] = params[key] ? [params[key]] : [];
    } else if (
      key === "start_date" ||
      key === "end_date" ||
      key === "lead_start_time" ||
      key === "lead_end_time"
    ) {
      const date = new Date(params[key]);
      params[key] = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    } else if (key !== "final_statuses" && Array.isArray(params[key])) {
      params[key] = params[key].join(",");
    }
  });
  return params;
};

export const fetchRecordingsData =
  ({ currentPage, params = {} }) =>
  (dispatch) => {
    let url = `${process.env.DOMAINS.PAHAL}api/V0/call-logs/post-filtered`;
    const recordingParams = { ...params };
    let apiParams = {
      ...modifyParams(recordingParams),
      results_per_page: 20,
      page_no: currentPage,
    };
    const { exclude_ids } = params;
    let escpaeIdsArr = readBrowserStorage(Constants.ESCAPERECORDINGIDS);
    if (escpaeIdsArr) {
      if (exclude_ids) {
        try {
          const parsedArr = JSON.parse(escpaeIdsArr);
          if (parsedArr) {
            apiParams.exclude_call_log_ids = parsedArr;
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      } else {
        deleteBrowserStorage(Constants.ESCAPERECORDINGIDS);
      }
    }
    return instance({
      url,
      withCredentials: true,
      method: "POST",
      data: apiParams,
    })
      .then(({ data: { data } = {} }) => {
        const {
          call_log_feedback_details: callLogFeedbackDetails = [],
          is_last_page: isLastPage,
          total_count: totalCount,
        } = data || {};
        let formData = {};
        callLogFeedbackDetails
          .filter((call) => call.recording_url)
          .forEach(
            ({
              id,
              site_visit_intent,
              final_status,
              detailed_remarks,
              seller_city_uuid,
              buyer_city_uuid,
            } = {}) => {
              formData[id] = {
                site_visit_intent,
                final_status,
                detailed_remarks,
                seller_city_uuid,
                buyer_city_uuid,
              };
            }
          );
        dispatch(setFormData("callLogDetails", formData));
        dispatch({
          type: SET_RECORDINGS_DATA,
          payload: {
            callLogFeedbackDetails,
            hasNextPage: !isLastPage,
            totalPages: Math.floor(totalCount / 20),
          },
        });
      })
      .catch((error) => {
        const errMsg = Constants.getErrorMessage(error);
        dispatch({
          type: Constants.API_FAIL,
          errMsg: errMsg,
        });
      });
  };

export const setFormData = (section, data) => (dispatch) => {
  dispatch({
    type: SET_RECORDINGS_FORM_DATA,
    payload: { section, data },
  });
};

export const setFilters = (section, data) => (dispatch) => {
  dispatch({
    type: SET_FILTERS_DATA,
    payload: { section, data },
  });
};

export const submitFeeback = (data) => (dispatch) => {
  const url = `${process.env.DOMAINS.PAHAL}/api/V0/call-logs/feedback`;
  return instance({
    url,
    withCredentials: true,
    method: "POST",
    data,
  })
    .then((response) => {
      let {
        data: { data: { message: msg, ids_with_error: idsWithError } = {} } = {},
      } = response;
      if (idsWithError && idsWithError.length) {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: msg,
        });
      } else {
        dispatch({
          type: Constants.SNACKBAR_NOTIFICATION,
          msg: msg,
        });
      }
    })
    .catch((err) => {
      const errMsg = Constants.getErrorMessage(err);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
      return Promise.reject(errMsg);
    });
};

export const fetchChannel = () => (dispatch) => {
  const url = `${process.env.DOMAINS.PAHAL}api/V0/call-logs/list/channels`;
  return instance({
    url,
    method: "GET",
  }).then(({ data: { data } = {} }) => {
    dispatch({
      type: SET_FILTERS_DROPDOWN_DATA,
      payload: { channels: data },
    });
  });
};

export const fetchLeadGenerator = () => (dispatch) => {
  const url = `${process.env.DOMAINS.PAHAL}api/V0/call-logs/list/lead_generators`;
  return instance({
    url,
    method: "GET",
  }).then(({ data: { data } = {} }) => {
    dispatch({
      type: SET_FILTERS_DROPDOWN_DATA,
      payload: { leadGenerators: data },
    });
  });
};
