import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const PROCESS_MANAGERS = "PROCESS_MANAGERS";
export const TEAM_LEADER = "TEAM_LEADER";
export const AGENT = "AGENTS";
export const LEADERBOARD_USER = "LEADERBOARD_USER";
export const LEADERBOARD_CHILD = "LEADERBOARD_CHILD";
export const PERFORMANCE_USER = "PERFORMANCE_USER";
export const PERFORMANCE_CHILD = "PERFORMANCE_CHILD";
export const AGGREGATED_PERFORMANCE_DAILY = "AGGREGATED_PERFORMANCE_DAILY";
export const AGGREGATED_PERFORMANCE_MTD = "AGGREGATED_PERFORMANCE_MTD";
export const CALL_PERFORMANCE_DAILY = "CALL_PERFORMANCE_DAILY";
export const CALL_PERFORMANCE_MTD = "CALL_PERFORMANCE_MTD";
export const DAY_WISE_PERFORMANCE = "DAY_WISE_PERFORMANCE";
export const DATA_NOT_FOUND = "DATA_NOT_FOUND";
export const DAY_WISE_DATA_UNAVAILABLE = "DAY_WISE_DATA_UNAVAILABLE";
export const RESET_PROCESS_MANAGER = "RESET_PROCESS_MANAGER";
export const RESET_TEAM_LEADERS = "RESET_TEAM_LEADERS";
export const RESET_AGENTS = "RESET_AGENTS";
export const TEAM_COMPARISON_MTD_USER = "TEAM_COMPARISON_MTD_USER";
export const TEAM_COMPARISON_MTD_CHILD = "TEAM_COMPARISON_MTD_CHILD";
export const TEAM_COMPARISON_DAILY_USER = "TEAM_COMPARISON_DAILY_USER";
export const TEAM_COMPARISON_DAILY_CHILD = "TEAM_COMPARISON_DAILY_CHILD";
export const CLOSE_TEAM_COMPARISON_VIEW = "CLOSE_TEAM_COMPARISON_VIEW";
export const INVALID_AGENT_FIELD = "INVALID_AGENT_FIELD";
export const RESET_LEADERBOARD = "RESET_LEADERBOARD";

export const getNestedAgents = ({ id, filter }) => {
  return (dispatch) => {
    let url = `/madrox/app/v3/entity/direct-reports?userId=${id}${
      filter ? "&filter=" + filter : ""
    }`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data } = response.data;
        const managerChildren = data;
        managerChildren.sort(Constants.stringComparer("name"));
        return managerChildren;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const setAgentsData = ({ role, type, managerChildren }) => {
  return (dispatch) => {
    switch (role) {
      case Constants.HOM:
      case "admin-broker":
      case "admin-owner":
        dispatch({
          type: PROCESS_MANAGERS,
          payload: managerChildren,
        });
        break;
      case Constants.TSPO:
      case Constants.BTPM:
        dispatch({
          type: TEAM_LEADER,
          payload: managerChildren,
        });
        break;
      case Constants.TSTL:
      case Constants.BTTL:
        dispatch({
          type: AGENT,
          payload: managerChildren,
        });
        break;
      case Constants.TSA:
      case Constants.BTS:
        dispatch({
          type: AGENT,
          payload: managerChildren,
        });
        break;
      default:
        dispatch({
          type:
            type === Constants.TSPO
              ? PROCESS_MANAGERS
              : type === Constants.TSTL
              ? TEAM_LEADER
              : AGENT,
          payload: managerChildren,
        });
    }
  };
};

export const getManagerChildren = ({
  id,
  role,
  type,
  startTime,
  endTime,
  timeDuration,
  filter,
  teamFilter,
}) => {
  return (dispatch) => {
    dispatch(getNestedAgents({ id, filter }))
      .then((managerChildren) => {
        if (startTime && endTime) {
          dispatch(
            getTeamComparisonData(
              startTime,
              endTime,
              id,
              managerChildren,
              timeDuration,
              null,
              teamFilter
            )
          );
          return;
        }
        dispatch(setAgentsData({ type, role, managerChildren }));
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
const nestedStatePopulationFunction = (
  stateVariable,
  agentId,
  parentAction,
  childAction,
  dataToPopulate
) => {
  return (dispatch) => {
    if (agentId && stateVariable && Array.isArray(stateVariable)) {
      let location = checkIndex(stateVariable, agentId);
      let nestedIndex = -1;
      if (location === -1) {
        stateVariable.every((item, index) => {
          if (item.nestedItems) {
            const newIndex = checkIndex(item.nestedItems, agentId);
            if (newIndex !== -1) {
              location = index;
              nestedIndex = newIndex;
              return false;
            }
            return true;
          }
          return true;
        });
      } //for every call, the list is added as the children of leaderboard element id for which the api is called creating a nested data element with multiple levels
      //in the absence of a corresponding id, the list is added to the state as it is forming the topmost level of the structure
      (location !== -1 || nestedIndex !== -1) &&
        dispatch({
          type: childAction,
          payload: {
            data: dataToPopulate,
            location,
            nestedIndex,
          },
        });
    } else {
      dispatch({
        type: parentAction,
        payload: dataToPopulate,
      });
    }
  };
};
export const getTeamComparisonData = (
  startTime,
  endTime,
  agentId,
  agentList,
  timeDuration,
  firstLevel,
  filter
) => {
  return (dispatch, getState) => {
    const url = "/mystique/soc-performance/trends-day-wise";
    const promises = agentList
      ?.map(({ userId }) => userId)
      .map((userId) =>
        instance({
          url,
          method: "post",
          timeout: 120000,
          data: {
            startTime,
            endTime,
            agentId: userId,
            filter,
          },
        })
      );
    return Promise.all(promises)
      .then((response) => {
        const {
          teamComparisonData: { teamComparisonMtd, teamComparisonDaily } = {},
        } = getState();
        let data = response.map(
          ({
            data: {
              data: { attempted, connected, callTimeInHours, talkTimeInHours } = {},
            } = {},
          }) => ({
            attempted,
            connected,
            callTime: Constants.callingTimeConverter(callTimeInHours),
            talkTime: Constants.callingTimeConverter(talkTimeInHours),
          })
        );
        if (data.length) {
          agentList.forEach(({ userId, name }, index) => {
            data[index] && (data[index] = { ...data[index], agentId: userId, name });
          });
          data.sort((obj1, obj2) => obj2.attempted - obj1.attempted);
        } else {
          data = dispatch(onChildDataUnavailable(firstLevel));
          if (data) {
            return;
          }
        }
        timeDuration === "mtd"
          ? dispatch(
              nestedStatePopulationFunction(
                teamComparisonMtd,
                agentId,
                TEAM_COMPARISON_MTD_USER,
                TEAM_COMPARISON_MTD_CHILD,
                data
              )
            )
          : dispatch(
              nestedStatePopulationFunction(
                teamComparisonDaily,
                agentId,
                TEAM_COMPARISON_DAILY_USER,
                TEAM_COMPARISON_DAILY_CHILD,
                data
              )
            );
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getLeaderBoardData = (
  startTime,
  endTime,
  agentId,
  filter,
  firstLevel
) => {
  return (dispatch, getState) => {
    const url = "/sapna/aggregated-payment-link-data/leader-board";
    return instance({
      url,
      method: "post",
      data: {
        startTime,
        endTime,
        agentId,
        filter,
      },
      timeout: 120000,
    })
      .then((response) => {
        let { data } = response.data;
        if (!data.length) {
          data = dispatch(onChildDataUnavailable(firstLevel));
          if (data) {
            return;
          }
        } else {
          data.sort((agent1, agent2) => agent2.salesValue - agent1.salesValue);
        }
        const { leaderBoard: { leaderBoardList } = {} } = getState();
        dispatch(
          nestedStatePopulationFunction(
            leaderBoardList,
            agentId,
            LEADERBOARD_USER,
            LEADERBOARD_CHILD,
            data
          )
        );
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const aggregatedPaymentslinkData = (
  timeDuration,
  startTime,
  endTime,
  agentId,
  filter
) => {
  return (dispatch, getState) => {
    const url = "/sapna/aggregated-payment-link-data";
    const { userInfo } = getState();
    const cacheParams = performanceApisCacheParams(timeDuration, filter);
    return instance({
      url,
      method: "post",
      data: {
        startTime,
        endTime,
        agentId,
        filter,
      },
      ...cacheParams,
    })
      .then((response) => {
        const { data } = response.data;
        switch (timeDuration) {
          case "daily":
            dispatch({
              type: AGGREGATED_PERFORMANCE_DAILY,
              payload: populateSalesData(data, userInfo),
            });
            break;
          case "mtd":
            dispatch({
              type: AGGREGATED_PERFORMANCE_MTD,
              payload: populateSalesData(data, userInfo),
            });
            break;
          default:
            dispatch({
              type: DATA_NOT_FOUND,
            });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const dailyTrends = (startTime, endTime, agentId, timeDuration, filter) => {
  return (dispatch) => {
    const url = "/mystique/soc-performance/trends-day-wise";
    const cacheParams = performanceApisCacheParams(timeDuration, filter);
    const dataBucketingData = Constants.getDataBucketTypeData(timeDuration);
    return instance({
      url,
      method: "post",
      data: {
        startTime,
        endTime,
        agentId,
        filter,
        ...dataBucketingData,
      },
      timeout: 120000,
      ...cacheParams,
    })
      .then((response) => {
        if (timeDuration) {
          const {
            data: {
              attempted,
              connected,
              talkTime,
              callTime,
              callTimeInHours,
              talkTimeInHours,
            } = {},
          } = response.data;
          const aggregatedData = {
            attempted,
            connected,
            talkTime,
            callTime,
            callTimeInHours,
            talkTimeInHours,
          };
          if (timeDuration === "daily") {
            dispatch({
              type: CALL_PERFORMANCE_DAILY,
              payload: aggregatedData,
            });
          } else if (timeDuration === "mtd") {
            dispatch({
              type: CALL_PERFORMANCE_MTD,
              payload: aggregatedData,
            });
          }
        } else {
          const {
            data: { data },
          } = response.data;
          if (!data) {
            dispatch({
              type: DAY_WISE_DATA_UNAVAILABLE,
            });
            return;
          }
          const dayWiseCallingData = data.map(({ attempted, connected, date }) => ({
            attempted,
            connected,
            date,
          }));
          const dayWiseCallTimeData = data.map(
            ({ callTime, avgTalkTime, date }) => ({
              callTime: parseFloat(callTime / 60),
              talkTime: parseFloat(avgTalkTime),
              date,
            })
          );
          dispatch({
            type: DAY_WISE_PERFORMANCE,
            payload: {
              dayWiseCallingData,
              dayWiseCallTimeData,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetAgents = (signedInRole) => {
  return (dispatch) => {
    let teamLeader, agent;
    if ([Constants.HOM, "admin-broker", "admin-owner"].includes(signedInRole)) {
      teamLeader = true;
      agent = true;
    } else if ([Constants.TSPO, Constants.BTPM].includes(signedInRole)) {
      agent = true;
    }
    agent && dispatch(resetTeleSalesAgents());
    teamLeader && dispatch(resetTeleSalesTeamLeaders());
  };
};
export const resetTeleSalesProcessManager = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PROCESS_MANAGER,
    });
  };
};
export const resetTeleSalesTeamLeaders = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_TEAM_LEADERS,
    });
  };
};
export const resetLeaderBoard = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_LEADERBOARD,
    });
  };
};
export const resetTeleSalesAgents = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_AGENTS,
    });
  };
};

const checkIndex = (data, agentId) =>
  data.map((item) => item.agentId).indexOf(agentId);

export const appendNestedList = (stateVariable, location, nestedIndex, data) => [
  ...stateVariable.slice(0, location),
  {
    ...stateVariable[location],
    nestedItems:
      nestedIndex !== -1
        ? stateVariable[location].nestedItems.map((item, index) => {
            if (index === nestedIndex) {
              return { ...item, nestedItems: data }; //append data on the second level with nestedItems as key
            }
            return item;
          })
        : data, //appending data onto the first level with nestedItems as its key
  },
  ...stateVariable.slice(location + 1),
];

export const upgradeSalesPerformanceData = (salesPerformance, entry, pos) => {
  return Object.entries(salesPerformance).reduce((acc = {}, [key, value], i) => {
    if (i === pos - 1) {
      acc[entry.key] = entry.value;
    }
    acc[key] = value;
    return acc;
  }, {});
};

export const populateSalesData = (
  { aggregatedPerformance, targetResponse },
  { isAdmin, isHousingOwnerAgent }
) => {
  const { linksShared, salesCount, salesValue, packageActivatedCount } =
    aggregatedPerformance || {};
  const salesData = {
    aggregatedPerformance: {
      "Links Shared": linksShared || 0,
      "Sales Count": salesCount || 0,
      "Sales Value(in lakhs)": Constants.salesValueConverter(salesValue),
    },
    targetResponse,
  };
  if (isAdmin || isHousingOwnerAgent) {
    salesData.aggregatedPerformance = upgradeSalesPerformanceData(
      salesData.aggregatedPerformance,
      { key: "Packages Activated", value: packageActivatedCount },
      3
    );
  }
  return salesData;
};

const onChildDataUnavailable = (firstLevel) => {
  return (dispatch) => {
    dispatch({
      type: DATA_NOT_FOUND,
    });
    return firstLevel ? true : false; //if no data is found the first time, then role is agent and leaderboard shouldnt render- true
    //if data isnt found for already existing agent on leaderboard we return false and use that to prevent click on the same agent
  };
};

const performanceApisCacheParams = (timeDuration, filter) => {
  if (timeDuration !== "mtd" || filter == "BROKER_ADMIN" || filter == "ADMIN") {
    // admin can see data of both owner and broker
    return {};
  }
  const expiryDateObj = new Date();
  expiryDateObj.setHours(23, 59, 59, 999);
  return {
    cacheExpirytime: expiryDateObj.getTime(),
    cacheable: true,
    cacheKeyBuilder: (url, divider, { agentId } = {}) => url + divider + agentId,
  };
};

export const getLiveStatusData = () => {};
