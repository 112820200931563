import { instance } from '../../lib/api';
import axios from 'axios';
import Constants from '../../utils/CommonConstants';

export const INCENTIVE_RULE_SLAB = 'INCENTIVE_RULE_SLAB';
export const RULES_SLABS_FAILED = 'RULES_SLABS_FAILED';
export const INCENTIVE_PARTICIPANTS_CATEGORIES = 'INCENTIVE_PARTICIPANTS_CATEGORIES';
export const INCENTIVE_PARTICIPANTS_CATEGORIES_FAILED = 'INCENTIVE_PARTICIPANTS_CATEGORIES_FAILED';
export const SLAB_UPDATE_SUCCESS = "SLAB_UPDATE_SUCCESS";
export const SLAB_UPDATE_FAIL = "SLAB_UPDATE_FAIL";
export const SLAB_UPDATE_RESET = "SLAB_UPDATE_RESET";
export const RULE_UPDATE_SUCCESS = "RULE_UPDATE_SUCCESS";
export const RULE_UPDATE_FAIL = "RULE_UPDATE_FAIL";
export const RULE_UPDATE_RESET = "RULE_UPDATE_RESET";
export const SALES_INCENTIVE_CYCLES = "SALES_INCENTIVE_CYCLES";
export const SALES_INCENTIVE_CYCLES_FAILED = "SALES_INCENTIVE_CYCLES_FAILED";
export const SALES_INCENTIVE_TARGET = "SALES_INCENTIVE_TARGET";
export const SALES_INCENTIVE_TARGET_FAILED = "SALES_INCENTIVE_TARGET_FAILED";
export const RULES_BY_USERID = "RULES_BY_USERID";
export const RULES_BY_USERID_FAILED = "RULES_BY_USERID_FAILED";
export const TARGET_UPDATE_SUCCESS = "TARGET_UPDATE_SUCCESS";
export const TARGET_UPDATE_FAIL = "TARGET_UPDATE_FAIL";
export const TARGET_UPDATE_RESET = "TARGET_UPDATE_RESET";
export const CALCULATED_INCENTIVE_GET_SUCCESS = "CALCULATED_INCENTIVE_GET_SUCCESS";
export const CALCULATED_INCENTIVE_GET_FAILED = "CALCULATED_INCENTIVE_GET_FAILED";
export const CALCULATED_INCENTIVE_SUMMARY_GET_SUCCESS = "CALCULATED_INCENTIVE_SUMMARY_GET_SUCCESS";
export const CALCULATED_INCENTIVE_SUMMARY_GET_FAILED = "CALCULATED_INCENTIVE_SUMMARY_GET_FAILED";
export const CALCULATED_INCENTIVE_BY_ID_GET_SUCCESS = "CALCULATED_INCENTIVE_BY_ID_GET_SUCCESS";
export const CALCULATED_INCENTIVE_BY_ID_GET_FAILED = "CALCULATED_INCENTIVE_BY_ID_GET_FAILED";
export const CALCULATED_INCENTIVE_BY_ID_GET_RESET = "CALCULATED_INCENTIVE_BY_ID_GET_RESET";
export const SIGN_OFF_SUCCESS = "SIGN_OFF_SUCCESS";
export const SIGN_OFF_FAIL = "SIGN_OFF_FAIL";
export const SIGN_OFF_RESET = "SIGN_OFF_RESET";
export const MARK_AS_PAID_SUCCESS = "MARK_AS_PAID_SUCCESS";
export const MARK_AS_PAID_FAIL = "MARK_AS_PAID_FAIL";
export const MARK_AS_PAID_RESET = "MARK_AS_PAID_RESET";
export const SEND_CYCLE_WISE_INCENTIVE_REPORT = "SEND_CYCLE_WISE_INCENTIVE_REPORT";

const cyclesCount = 3;

const _getIdNameMap = (objArr) => {
    const obj = {};
    objArr.forEach((o) => {
      obj[o.id] = o;
    });
    return obj;
  };

export const getIncentiveRulesAndSlabs = () => {
    const urls = [
        `/sapna/v2/incentive-rules`,
        `/sapna/v1/incentive-participant-categories`,
        `/sapna/v1/incentive-slabs`
    ];
    const promises = urls.map(url => axios.get(url));
    return (dispatch) => {
        return axios.all(promises)
        .then(axios.spread((incentiveRules, incentiveParticipantCategories, incentiveSlabs) => {
            const { 
                data: {
                    data: rules
                } 
            } = incentiveRules;
            const { 
                data: {
                    data: incentiveParticipantsCategoriesMapping
                } 
            } = incentiveParticipantCategories;
            const { 
                data: {
                    data: slabs
                } 
            } = incentiveSlabs;
            dispatch({
                type: INCENTIVE_RULE_SLAB,
                rules,
                incentiveParticipantsCategoriesMapping: _getIdNameMap(incentiveParticipantsCategoriesMapping),
                slabs
            });
        }))
        .catch((err) => {
            dispatch({
                type: RULES_SLABS_FAILED,
                errMsg: Constants.getErrorMessage(err) || 'Unable to get incentive rules'
            });
        });
    }
}

export const getIncentiveParticipantCategories = () => {
    const url = `/sapna/v1/incentive-participant-categories`;
    return (dispatch) => {
        return instance({
            url,
            method: 'get'
        })
        .then((response) => {
            const { 
                data: {
                    data: incentiveParticipantsCategoriesMapping
                } 
            } = response;
            dispatch({
                type: INCENTIVE_PARTICIPANTS_CATEGORIES,
                incentiveParticipantsCategoriesMapping
            });
        })
        .catch((err) => {
            dispatch({
                type: INCENTIVE_PARTICIPANTS_CATEGORIES_FAILED,
                errMsg: Constants.getErrorMessage(err) || 'Unable to get Incentive Participant Categories'
            });
        });
    }
}

export const submitSlab = (slab) => {
    return (dispatch) => {
        ((slab.id) ? _updateSlab : _createSlab)(slab, dispatch);
    }
}

const _updateSlab = (slab, dispatch) => {
    if(slab.id) {
      const url = `/sapna/v1/incentive-slabs/update/${slab.id}`;
      const {
        targetMinPercentage,
        targetMaxPercentage,
        accountsClosedMin,
        accountsClosedMax,
        incentivePercentage,
        effectiveDate
      } = slab;
      const data = {
        targetMinPercentage,
        targetMaxPercentage,
        accountsClosedMin,
        accountsClosedMax,
        incentivePercentage,
        effectiveDate: effectiveDate.setHours(0,0,0,0),
      };
      return instance({
          url,
          method: 'put',
          data
      })
      .then((response) => {
          dispatch({
            type: SLAB_UPDATE_SUCCESS
          });
      }, (err)=>{
          dispatch({
            type: SLAB_UPDATE_FAIL,
            errMsg: Constants.getErrorMessage(err)
          });
          return false;
      });
    }
  }
  const _createSlab = (slab, dispatch) => {
      const url = `/sapna/v1/incentive-slabs/add`;
      const {
        targetMinPercentage,
        targetMaxPercentage,
        accountsClosedMin,
        accountsClosedMax,
        incentivePercentage,
        effectiveDate,
        designationId,
        departmentId,
        clientTypeId
      } = slab;
      
      

      const data = {
        targetMinPercentage,
        targetMaxPercentage,
        accountsClosedMin,
        accountsClosedMax,
        incentivePercentage,
        effectiveDate: effectiveDate.setHours(0,0,0,0),// convert date to epoch time  
        participantCategoryDto: {
            designationId,
            departmentId,
            clientTypeId
        }
      };
      return instance({
          url,
          method: 'post',
          data
      })
      .then((response) => {
          dispatch({
            type: SLAB_UPDATE_SUCCESS,
            msg: "Slab created successfully"
          });
          return response.data.data;
      }, (err)=>{
          dispatch({
            type: SLAB_UPDATE_FAIL,
            errMsg: Constants.getErrorMessage(err)
          });
          return false;
      });
  }

  export const resetSlabUpdateStatus = () => {
      return (dispatch) => {
          dispatch({
              type: SLAB_UPDATE_RESET
          })
      }
  }

export const submitRule = (rule) => {
    return (dispatch) => {
        ((rule.id) ? _updateRule : _createRule)(rule, dispatch);
    }
}

const _updateRule = (rule, dispatch) => {
    if(rule.id) {
      const url = `/sapna/v1/incentive-rules/update/${rule.id}`;
      const {
        allowedMinTarget,
        allowedMaxTarget,
        frequency,
        incentiveCap,
        effectiveDate,
        participantAllowedIncentiveTypes
      } = rule;
      const data = {
        allowedMinTarget,
        allowedMaxTarget,
        frequency,
        incentiveCap,
        effectiveDate: effectiveDate.setHours(0,0,0,0),
        participantAllowedIncentiveTypes
      };
      return instance({
          url,
          method: 'put',
          data
      })
      .then((response) => {
          dispatch({
            type: RULE_UPDATE_SUCCESS
          });
          return response.data.data;
      }, (err)=>{
          dispatch({
            type: RULE_UPDATE_FAIL,
            errMsg: Constants.getErrorMessage(err)
          });
          return false;
      });
    }
}
const _createRule = (rule, dispatch) => {
      const url = `/sapna/v1/incentive-rules/add`;
      const {
        allowedMinTarget,
        allowedMaxTarget,
        frequency,
        incentiveCap,
        effectiveDate,
        designationId,
        departmentId,
        clientTypeId,
        participantAllowedIncentiveTypes
      } = rule;
      
      

      const data = {
        allowedMinTarget,
        allowedMaxTarget,
        frequency,
        incentiveCap,
        effectiveDate: effectiveDate.setHours(0,0,0,0),// convert date to epoch time  
        participantAllowedIncentiveTypes,
        participantCategoryDto: {
            designationId,
            departmentId,
            clientTypeId
        }
      };
      return instance({
          url,
          method: 'post',
          data
      })
      .then((response) => {
          dispatch({
            type: RULE_UPDATE_SUCCESS,
            msg: "Rule created successfully"
          });
          return response.data.data;
      }, (err)=>{
          dispatch({
            type: RULE_UPDATE_FAIL,
            errMsg: Constants.getErrorMessage(err)
          });
          return false;
      });
}

  export const resetRuleUpdateStatus = () => {
      return (dispatch) => {
        dispatch({
            type: RULE_UPDATE_RESET
        })
    }
  }

  export const getIncentiveAutomationCycles = () => {
    return (dispatch) => {
        const url = `sapna/v1/incentive-cycles/?count=${cyclesCount}`
        return instance({
            url,
            method: 'get'
        })
        .then((response) => {
            const { 
                data: {
                    data: currentCycles
                } 
            } = response;
            dispatch({
                type: SALES_INCENTIVE_CYCLES,
                currentCycles
            })
        })
        .catch((err) => {
            dispatch({
                type: SALES_INCENTIVE_CYCLES_FAILED,
                errMsg: Constants.getErrorMessage(err) || 'Unable to get Sales Target Setting Cycles'
            });
        });
    }
  }

  export const getTeamAndIndividualTargetDetails = ({cycleId, userInfo}) => {
    return (dispatch) => {
        const url = `/sapna/v1/user-targets/?cycleId=${cycleId}&userId=${userInfo.id}&getTeamTargets=true`;
        return instance({
            url,
            method: 'get'
        })
        .then((response) => {
            const { 
                data: {
                    data: salesTargets
                } 
            } = response;
            dispatch({
                type: SALES_INCENTIVE_TARGET,
                salesTargets,
                salesUserTargetMap: salesTargets.reduce((acc, target) => {
                    const {userId} = target;
                    acc[userId] = target;
                    return acc;
                }, {})
            })
        })
        .catch((err) => {
            dispatch({
                type: SALES_INCENTIVE_TARGET_FAILED,
                errMsg: Constants.getErrorMessage(err) || 'Unable to get Sales Targets'
            });
        });
    }
  }

  export const getRulesByUserIds = (userIds) => {
    return (dispatch) => {
        const url = `/sapna/v2/incentive-rules/get-rules`;
        return instance({
            url,
            method: 'post',
            data: {userIds}
        })
        .then((response) => {
            const { 
                data: {
                    data: rulesByUserId
                } 
            } = response;
            dispatch({
                type: RULES_BY_USERID,
                rulesByUserId,
                rulesUserMap: rulesByUserId.reduce((acc, rule) => {
                    const {userId} = rule;
                    acc[userId] = rule;
                    return acc;
                }, {})
            })
        })
        .catch((err) => {
            dispatch({
                type: RULES_BY_USERID_FAILED,
                errMsg: Constants.getErrorMessage(err) || 'Unable to get Rules'
            });
        });
    }
}

export const resetTargetUpdateStatus = () => {
    return (dispatch) => {
      dispatch({
          type: TARGET_UPDATE_RESET
      })
  }
}

export const updateTarget = (target) => {
    return (dispatch) => {
        ((target.targetId) ? _updateExistingTarget : _createTarget)(target, dispatch);
    }
}

const _updateExistingTarget = (target, dispatch) => {
    const {
        individualTarget,
        teamTarget,
        targetId
    } = target;
    if(targetId) {
        const url = `/sapna/v1/user-targets/update/${targetId}`;
        let data = {
            individualTarget,
            teamTarget,
        }
        
        return instance({
            url,
            method: 'put',
            data
        })
        .then((response) => {
            dispatch({
                type: TARGET_UPDATE_SUCCESS
            });
            return response.data.data;
        }, (err)=>{
            dispatch({
                type: TARGET_UPDATE_FAIL,
                errMsg: Constants.getErrorMessage(err)
            });
            return false;
        });
    }
}
const _createTarget = (target, dispatch) => {
    const {
        teamTarget,
        cycleId,
        userId
    } = target;
    const url = `/sapna/v1/user-targets/add`;
      
    const data = {
        individualTarget: 0,
        cycleId,
        userId,
        teamTarget
    };
    return instance({
        url,
        method: 'post',
        data
    })
    .then((response) => {
        dispatch({
        type: TARGET_UPDATE_SUCCESS,
        msg: "Target Updated successfully"
        });
        return response.data.data;
    }, (err)=>{
        dispatch({
        type: TARGET_UPDATE_FAIL,
        errMsg: Constants.getErrorMessage(err)
        });
        return false;
    });
}

export const getCalculatedIncentive = (userId, cycleId) => {
    return (dispatch) => {
        const url = `/sapna/v1/user-incentives?userId=${userId}&cycleIds=${cycleId}&team=true`;
        return instance({
            url,
            method: 'get'
        })
        .then((response) => {
            const { 
                data: {
                    data: userIncentives
                } 
            } = response;
            let userIncentiveMap = userIncentives.reduce((acc, rule) => {
                if(!acc[rule.userId])
                    acc[rule.userId] = [];
                acc[rule.userId].push(rule);
                return acc;
            }, {})
            dispatch({
                type: CALCULATED_INCENTIVE_GET_SUCCESS,
                userIncentives,
                userIncentiveMap
            })
        })
        .catch((err) => {
            dispatch({
                type: CALCULATED_INCENTIVE_GET_FAILED,
                errMsg: Constants.getErrorMessage(err) || 'Unable to Fetch Incentive'
            });
        });
    }
}

export const getCalculatedIncentiveSummary = (cycleId) => {
    return (dispatch) => {
        const url = `/sapna/v1/user-incentives/by-cycle?cycleId=${cycleId}&debug=true`;
        return instance({
            url,
            method: 'get'
        })
        .then((response) => {
            const { 
                data: {
                    data: userIncentivesSummary
                } 
            } = response;
            let userIncentiveSummaryMap = userIncentivesSummary.reduce((acc, rule) => {
                if(!acc[rule.userId])
                    acc[rule.userId] = [];
                acc[rule.userId].push(rule);
                return acc;
            }, {})
            dispatch({
                type: CALCULATED_INCENTIVE_SUMMARY_GET_SUCCESS,
                userIncentivesSummary,
                userIncentiveSummaryMap
            })
        })
        .catch((err) => {
            dispatch({
                type: CALCULATED_INCENTIVE_SUMMARY_GET_FAILED,
                errMsg: Constants.getErrorMessage(err) || 'Unable to Fetch Incentive'
            });
        });
    }
}

export const getCalculatedIncentiveDetailById = (incentiveId) => {
    return (dispatch) => {
        const url = `/sapna/v1/user-incentives/get-details?incentiveId=${incentiveId}`;
        return instance({
            url,
            method: 'get'
        })
        .then((response) => {
            const { 
                data: {
                    data: userIncentiveById
                } 
            } = response;
            dispatch({
                type: CALCULATED_INCENTIVE_BY_ID_GET_SUCCESS,
                userIncentiveById
            })
        })
        .catch((err) => {
            dispatch({
                type: CALCULATED_INCENTIVE_BY_ID_GET_FAILED,
                errMsg: Constants.getErrorMessage(err) || 'Unable to Fetch Incentive'
            });
        });
    }
}

export const resetCalculatedIncentiveDetailById = () => {
    return (dispatch) => {
        dispatch({
            type: CALCULATED_INCENTIVE_BY_ID_GET_RESET
        })
      }
}

export const signOffIncentive = (id, newId) => {
    return (dispatch) => {
        if(id) {
            const url = `sapna/v1/user-incentives/update/${id}`;
            let data = {statusId: newId, remark: "Signed Off"};
            return instance({
                url,
                method: 'put',
                data
            })
            .then((response) => {
                dispatch({
                    type: SIGN_OFF_SUCCESS
                });
                return response.data.data;
            }, (err)=>{
                dispatch({
                    type: SIGN_OFF_FAIL,
                    errMsg: Constants.getErrorMessage(err)
                });
                return false;
            });
        }
    }
}

export const resetSignOffUpdateStatus = () => {
    return (dispatch) => {
      dispatch({
          type: SIGN_OFF_RESET
      })
    }
}

export const updateIncentive = ({id, statusId, remark, paidIncentive}) => {
    return (dispatch) => {
        if(id) {
            const url = `sapna/v1/user-incentives/update/${id}`;
            let data = {statusId, remark, paidIncentive};
            return instance({
                url,
                method: 'put',
                data
            })
            .then((response) => {
                dispatch({
                    type: MARK_AS_PAID_SUCCESS
                });
                return response.data.data;
            }, (err)=>{
                dispatch({
                    type: MARK_AS_PAID_FAIL,
                    errMsg: Constants.getErrorMessage(err)
                });
                return false;
            });
        }
    }
}

export const resetUpdateIncentiveStatus = () => {
    return (dispatch) => {
      dispatch({
          type: MARK_AS_PAID_RESET
      })
    }
}

export const sendCycleWiseIncentiveReport = (cycleId) => {
    return (dispatch) => {
        if(cycleId) {
            const url = `/sapna/v1/user-incentives/cycle-wise-payment-report.csv?cycleId=${cycleId}`;
            window.open(url, '_blank');
            dispatch({
                type: SEND_CYCLE_WISE_INCENTIVE_REPORT,
                msg: 'Downloading Report...'
            });
        }
    }
}