import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const API_FAIL = "API_FAIL";
export const LOCALITY_LIST = "LOCALITY_LIST";
export const FILE_CHOOSEN_TICKET = "FILE_CHOOSEN_TICKET";
export const FILE_CHOOSEN_TICKET_RESET = "FILE_CHOOSEN_TICKET_RESET";
export const RESET_CREATED_TICKET_ID = "RESET_CREATED_TICKET_ID";

export const getLocalities = (city_uuid, typeahead) => {
  return (dispatch) => {
    const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/suggest?service_type=leads&feature_type=locality&polygon_uuid=${city_uuid}&input=${typeahead}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data;
        const localities = [];
        data.forEach((locality) => {
          localities[locality.name] = locality.uuid;
        });
        dispatch({
          type: LOCALITY_LIST,
          localities,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const chooseFileForTicket = (file) => {
  return (dispatch) => {
    dispatch({
      type: FILE_CHOOSEN_TICKET,
      fileChoosenForTicket: file,
    });
  };
};

export const resetFileChoosenForTicket = () => {
  return (dispatch) => {
    dispatch({
      type: FILE_CHOOSEN_TICKET_RESET,
    });
  };
};

export const resetCreatedTicketId = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CREATED_TICKET_ID,
    });
  };
};
