import {
  REPORT_TOKEN,
  OWNER_CALL_REPORT,
  SALES_REPORT,
  GET_SALES_REPORT_OPP,
  GET_USER_REPORT_OPP,
  RESET_SALES_REPORT_OPP,
  RESET_STATEMOVEMENT_REPORT,
  GET_STATE_MOVEMENT_REPORT,
  GET_ALL_CITIES_DATA,
  SALES_REPORT_FAILURE,
  OWNER_CALL_REPORT_FAILURE,
  OWNER_INVOICE_REPORT_FAILURE,
  AGENT_PERFORMANCE_REPORT_FAILURE,
} from "../actions/reports.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case REPORT_TOKEN:
      return {
        ...state,
        reportToken: action.reportToken,
      };
    case SALES_REPORT:
    case OWNER_CALL_REPORT:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "success",
          duration: 5000,
          message: action.msg,
        },
      };

    case SALES_REPORT_FAILURE:
    case OWNER_CALL_REPORT_FAILURE:
    case OWNER_INVOICE_REPORT_FAILURE:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "failure",
          duration: null,
          message: action.msg,
        },
      };
    case AGENT_PERFORMANCE_REPORT_FAILURE:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "failure",
          duration: null,
          message: action.msg,
        },
      };
    case GET_SALES_REPORT_OPP:
      return {
        ...state,
        saleConnReports: action.saleConnReports,
      };
    case GET_USER_REPORT_OPP:
      return {
        ...state,
        userDetailsReports: action.userDetailsReports,
      };
    case RESET_SALES_REPORT_OPP:
      return {
        ...state,
        userDetailsReports: null,
        saleConnReports: null,
      };
    case RESET_STATEMOVEMENT_REPORT:
      return {
        ...state,
        stateMovementReports: null,
      };
    case GET_STATE_MOVEMENT_REPORT:
      return {
        ...state,
        stateMovementReports: action.stateMovementReports,
      };
    case GET_ALL_CITIES_DATA:
      return {
        ...state,
        getAllCities: action.value,
      };
  }
};

export default reducer;
