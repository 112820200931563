import {
  SET_RECORDINGS_DATA,
  SET_RECORDINGS_FORM_DATA,
  SET_FILTERS_DATA,
  SET_FILTERS_DROPDOWN_DATA,
  SET_FILTERS_DATE_DATA,
} from "../actions/leadFeedback.actions";

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_RECORDINGS_DATA: {
      return {
        ...state,
        recordings: {
          ...state.recordings,
          call_log_feedback_details: action.payload.callLogFeedbackDetails,
          totalPages:
            (action.payload.totalPages || 1) + (action.payload.hasNextPage ? 1 : 0),
        },
      };
    }
    case SET_RECORDINGS_FORM_DATA: {
      const { section, data } = action.payload;
      return {
        ...state,
        recordings: {
          ...state.recordings,
          formData: {
            ...(state.recordings || {}).formData,
            [section]: data,
          },
        },
      };
    }
    case SET_FILTERS_DATA: {
      const { section, data } = action.payload;
      return {
        ...state,
        recordings: {
          ...state.recordings,
          filtersData: {
            ...(state.recordings || {}).filtersData,
            [section]: data,
          },
        },
      };
    }
    case SET_FILTERS_DROPDOWN_DATA: {
      return {
        ...state,
        recordings: {
          ...state.recordings,
          ...action.payload,
        },
      };
    }
    case SET_FILTERS_DATE_DATA: {
      return {
        ...state,
        recordings: {
          ...state.recordings,
          ...action.payload,
        },
      };
    }
  }
};

export default reducer;
