import {
  CITY_LOCALITIES,
  REGION_CREATE_SUCCESS,
  REGION_DELETE_SUCCESS,
  GOT_MANAGE_REGIONS,
} from "../actions/manageRegions.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case CITY_LOCALITIES:
      return {
        ...state,
        cityLocalities: action.cityLocalities,
      };
    case REGION_CREATE_SUCCESS:
      return {
        ...state,
        refreshRegion: {},
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: "Region created successfully!",
        },
      };
    case REGION_DELETE_SUCCESS:
      return {
        ...state,
        refreshRegion: {},
        snackbar: {
          state: true,
          type: "success",
          duration: null,
          message: "Region deleted successfully!",
        },
      };
    case GOT_MANAGE_REGIONS:
      return {
        ...state,
        manageRegions: action.payload,
        localitiesNameIdMapByManageRegions:
          action.localitiesNameIdMapByManageRegions,
      };
  }
};

export default reducer;
