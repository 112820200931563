import { parseCampaignFilters } from "../../containers/CampaignModule/util";
import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const GET_CAMPAIGN_FILTERS = "GET_CAMPAIGN_FILTERS";
export const GET_ACTIVE_CAMPAIGNS = "GET_ACTIVE_CAMPAIGNS";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const SNACKBAR_STATUS = "SNACKBAR_STATUS";

export const getCampaignDataAndFilters = () => {
  return (dispatch) => {
    const url = "/mystique/v1/agent-campaign/definition";
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const {
          data: { campaignDefinitionFilter, campaignDefinitionResponseDTOs },
        } = response.data;
        dispatch({
          type: GET_CAMPAIGN_FILTERS,
          payload: parseCampaignFilters(campaignDefinitionFilter),
        });
        dispatch({
          type: GET_ACTIVE_CAMPAIGNS,
          payload: campaignDefinitionResponseDTOs.map(
            (
              {
                name,
                manualCampaign,
                rechurn,
                caseTypeId,
                id,
                createdAt,
                agentToCampaignMappingEntities = [],
                campaignDefinitionAdditionalInformation: {
                  startDate,
                  csvUploadProcessorResults: [
                    { totalRecords, totalSuccessfulRecords } = {},
                  ] = [{}],
                } = {},
                active,
                campaignDefinitionFilter: { cities = [] } = {},
              },
              index
            ) => {
              return {
                index: index + 1,
                name,
                manualCampaign,
                rechurn,
                caseTypeId,
                campaignId: id,
                agentList: agentToCampaignMappingEntities.map(
                  ({ agentName }) => agentName || "Name unavailable"
                ),
                startDate,
                totalRecords,
                totalSuccessfulRecords,
                active,
                createdAt,
                cities,
              };
            }
          ),
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const showSnackbar = (type, msg, status) => (dispatch) =>
  dispatch({
    type,
    msg,
    status,
  });

export const createCampaign = (
  name,
  rechurn,
  manualCampaign,
  campaignDefinitionFilter,
  uploadedManualCSV,
  startDate
) => {
  return (dispatch) => {
    const url = manualCampaign ? `${campaignUrl}/manual-campaign` : campaignUrl;
    const data = {
      name,
      caseTypeId: "SOCASE",
      rechurn,
      manualCampaign,
      campaignDefinitionFilter,
      campaignDefinitionAdditionalInformation: {
        startDate: new Date(startDate).getTime(),
      },
    };
    const formData = new FormData();
    const blob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });
    formData.append("campaignDefinitionRequestDTO", blob);
    manualCampaign &&
      uploadedManualCSV &&
      formData.append("mFile", uploadedManualCSV);
    const headers = {
      "Content-Type": manualCampaign ? "undefined" : "application/json",
    };
    return instance({
      url,
      method: "post",
      data: manualCampaign ? formData : data,
      headers,
    })
      .then(() => {
        dispatch(
          showSnackbar(
            SNACKBAR_STATUS,
            "Successfully created new campaign",
            "success"
          )
        );
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const deleteCampaign = (id) => {
  return (dispatch) => {
    const url = `${campaignUrl}/${id}`;
    return instance({
      url,
      method: "delete",
    })
      .then(() => {
        dispatch(
          showSnackbar(SNACKBAR_STATUS, "Successfully deleted campaign", "success")
        );
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const campaignUrl = "/mystique/v1/agent-campaign";
