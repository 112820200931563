import {
  MA_SHOW_SNACKBAR,
  MA_RESET_SNACKBAR,
  POST_PACKAGE_ADD_SUCCESS,
  POST_PACKAGE_ADD_RESET,
  STOP_PACKAGE_ADD_SUCCESS,
  STOP_PACKAGE_ADD_RESET,
  GET_PROFILE_UUIDS_SUCCESS,
  GET_PROFILE_UUIDS_RESET,
  GET_PACKAGES_SUCCESS,
} from "../actions/manualActivation.actions";

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        maPackages: action.payload,
      };
    case GET_PROFILE_UUIDS_SUCCESS:
      return {
        ...state,
        [action.key]: action.payload,
      };
    case GET_PROFILE_UUIDS_RESET:
      return {
        ...state,
        editProfileUuids: null,
      };
    case POST_PACKAGE_ADD_RESET:
      return {
        ...state,
        isMAPackageAdded: false,
      };
    case POST_PACKAGE_ADD_SUCCESS:
      return {
        ...state,
        isMAPackageAdded: true,
      };
    case STOP_PACKAGE_ADD_RESET:
      return {
        ...state,
        isMAPackagePackageStop: false,
      };
    case STOP_PACKAGE_ADD_SUCCESS:
      return {
        ...state,
        isMAPackagePackageStop: true,
      };
    case MA_SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: {
          state: true,
          type: action.snackbarType || "success",
          duration: action.duration || null,
          message: action.msg || "Action successfully completed!",
        },
      };
    case MA_RESET_SNACKBAR:
      return {
        ...state,
        snackbar: {},
      };
  }
};

export default reducer;
