import flatCombineReducers from "./flat-combine-reducers";
import reducers from "./reducers";
import Constants from "../utils/CommonConstants";

export const defaultState = {
  churnSchedulerList: [],
  userInfo: null,
  agentStatus: Constants.agentStatus.INACTIVE,
  chosenTab: {},
  agentBranchCityMap: {},
  makaanCitiesMap: {},
  isPlatformMakaan: false,
  housingSlotDetails: {},
  discountMatrixNetworkErr: {},
  slotAvailabilityDetails: {},
  accountDetailsUpdated: null,
  mergedAccountsStatus: {},
  liveListings: {
    BUY: null,
    RENT: null,
  },
  salesClosureDateMap: {},
  microsite: {},
  virtualTour: { list: [], data: {}, formData: {} },
  siteTakeover: { data: {}, formData: {} },
  siteTakeoverList: [],
  micrositeList: [],
  message: "",
  adProductConfig: {},
  flatPanel: { formData: {}, rejectionReasons: [] },
  upComingRenewals: {
    weekly: null,
    monthly: null,
  },
  modalInactiveProfileHousing: {},
  npStopSlotMap: {},
  parentComplimentaryProduct: null,
  complimentaryProductsList: [],
  deactivateComplimentaryProductMap: {},
  approvalLandingPageDetails: null,
  approvalLandingPageDetailsError: null,
  accountPaymentBucketTypeDetails: {},
  unutilizedPayments: {},
  npSlotPocDetails: null,
  npSwapSlotMap: {},
  resendAccountVerificationMap: {},
  hrisDetailsMap: {},
  audienceMaximizer: { data: {}, formData: {} },
  recordings: {
    filtersData: {
      filter: {
        missing_feedback: true,
        exclude_ids: true,
      },
    },
  },
};

const rootReducer = flatCombineReducers(...reducers);
export default rootReducer;
