import {
  SET_STORIES_FORM_DATA,
  RESET_STORIES_FORM_DATA,
  SET_SELLERS_LIST,
  PROJECTS_LIST,
  SET_PROJECT_ID,
  STORIES_LOCALITY,
  SET_STORIES_LIST,
  SET_SLOT_DEDICATED_DATA,
  SET_STORIES_LANGUAGES,
} from "../actions/stories.actions";

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_STORIES_FORM_DATA: {
      const { section, data } = action.payload;
      return {
        ...state,
        stories: {
          ...state.stories,
          formData: {
            ...(state.stories || {}).formData,
            [section]: data,
          },
        },
      };
    }
    case RESET_STORIES_FORM_DATA: {
      return {
        ...state,
        stories: {
          ...state.stories,
          formData: {},
          editDataLoaded: false,
        },
      };
    }
    case SET_SELLERS_LIST: {
      return {
        ...state,
        stories: {
          ...state.stories,
          sellerList: action.payload.sellerList,
          projectRegion: action.payload.projectRegion,
          projectImages: action.payload.imagesInfo,
        },
      };
    }
    case PROJECTS_LIST: {
      return {
        ...state,
        stories: {
          ...state.stories,
          projects: action.payload || [],
        },
      };
    }
    case SET_PROJECT_ID: {
      return {
        ...state,
        stories: {
          ...state.stories,
          projectMap: action.payload,
        },
      };
    }
    case STORIES_LOCALITY: {
      return {
        ...state,
        stories: {
          ...state.stories,
          localities: action.payload,
        },
      };
    }
    case SET_STORIES_LIST: {
      return {
        ...state,
        stories: {
          ...state.stories,
          list: action.payload.results || [],
          totalPages:
            action.payload.totalPages + (action.payload.hasNextPage ? 1 : 0),
        },
      };
    }
    case SET_SLOT_DEDICATED_DATA: {
      return {
        ...state,
        stories: {
          ...state.stories,
          formData: {
            ...action.payload,
          },
          editDataLoaded: true,
        },
      };
    }
    case SET_STORIES_LANGUAGES: {
      return {
        ...state,
        stories: {
          ...state.stories,
          languageList: action.payload || [],
        },
      };
    }
  }
};

export default reducer;
