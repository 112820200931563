import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";
import getKeySearchPromises from "../../utils/getKeySearchPromises";
import { hasValidRoles as hasValidOwnerRoles } from "../../utils/masker";

export const AGENT_LIST = "AGENT_LIST";
export const API_FAIL = "API_FAIL";
export const CITY_LIST = "CITY_LIST";
export const HOUSING_TOP_CITY_LIST = "HOUSING_TOP_CITY_LIST";
export const OPPORTUNITY_SEARCH_LIST = "OPPORTUNITY_SEARCH_LIST";
export const PT_CITY_LIST = "PT_CITY_LIST";
export const OPPORTUNITY_MASS_ASSIGN = "OPPORTUNITY_MASS_ASSIGN";
export const RESHARE_OWNER_OPP_LINK = "RESHARE_OWNER_OPP_LINK";
export const PACKAGE_CITY_LIST = "PACKAGE_CITY_LIST";
export const PT_CITY_LIST_FAIL = "PT_CITY_LIST_FAIL";

export const getCities = (regions = null) => {
  return (dispatch) => {
    const url = `${process.env.DOMAINS.REGIONS}/api/v1/polygon/top/cities/list`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data;
        let topCities = data.top_cities;
        let cityToCopy = null;
        if (data.other_cities) {
          for (let i = data.other_cities.length - 1; i >= 0; i--) {
            // this check is hard coded for now to handle all mumbai regions
            const { name, ...otherProps } = data.other_cities[i];
            if (name === Constants.Zunheboto_city_name) {
              cityToCopy = {
                ...otherProps,
                name: Constants.mumbai_all_city_name,
              };
              // remove zunheboto from list
              data.other_cities.splice(i, 1);
              break;
            }
          }
        }
        if (regions) {
          const uniqueCitiesInRegions = {};
          regions.forEach((region) => {
            uniqueCitiesInRegions[region.cityId] = true;
          });
          data.other_cities.forEach((city) => {
            if (uniqueCitiesInRegions[city.uuid]) {
              topCities.push(city);
            }
          });
        }
        dispatch({
          type: HOUSING_TOP_CITY_LIST,
          payload: topCities,
        });
        if (cityToCopy) {
          data.other_cities.push(cityToCopy);
        }
        const cities = [];
        const citiesMap = {};
        const allCities = [];
        data.top_cities.forEach((city) => {
          allCities.push(city);
          cities[city.name] = city.uuid;
          citiesMap[city.uuid] = city.name;
        });
        data.other_cities.forEach((city) => {
          allCities.push(city);
          cities[city.name] = city.uuid;
          citiesMap[city.uuid] = city.name;
        });
        dispatch({
          type: CITY_LIST,
          citiesMap,
          allCities,
          cities,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getPTCities = () => {
  return (dispatch) => {
    const url =
      '/petra/data/v1/entity/city?selector={"sort":[{"field":"displayPriority","sortOrder":"ASC"},{"field":"displayOrder","sortOrder":"ASC"}],"paging":{"start":0,"rows":10000},"fields":["id","label"]}';
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data.data;
        const ptCities = {},
          ptCitiesIdtoLabel = {};
        data.forEach((city) => {
          ptCities[city.label] = city.id;
          ptCitiesIdtoLabel[city.id] = city.label;
        });
        dispatch({
          type: PT_CITY_LIST,
          ptCitiesIdtoLabel,
          citiesData: data,
          ptCities,
        });
      })
      .catch((err) => {
        dispatch({
          type: PT_CITY_LIST_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const opportunityMassAssign = (opportunityIds, targetAgentId) => {
  const url = `/sapna/v1/opportunity/reassign/${targetAgentId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "PUT",
      data: opportunityIds,
    })
      .then(() => {
        dispatch({
          type: OPPORTUNITY_MASS_ASSIGN,
          payload: { status: true },
        });
      })
      .catch((err) => {
        dispatch({
          type: OPPORTUNITY_MASS_ASSIGN,
          payload: { status: false },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const addToFIQL = (fiqlFilter, key, value) => {
  const equal = {};
  equal[key] = value;
  fiqlFilter.push({
    equal,
  });
  return fiqlFilter;
};

const addDateRangeFilter = (fiqlFilter = [], key, dateRange) => {
  const dates = {};
  dates.from = dateRange.start ? dateRange.start.valueOf() : 0;
  dates.to = dateRange.end ? dateRange.end.valueOf() : new Date().getTime();
  const range = {};
  range[key] = dates;
  fiqlFilter.push({ range });
  return fiqlFilter;
};

export const getOpportunityList = (filters) => {
  return (dispatch, getState) => {
    const { userInfo: { roles, isHousingOwnerAgent } = {} } = getState();

    const rows = Constants.tableSize;
    const start = (filters.currentPage - 1) * rows;
    const sort = [
      {
        field: "opportunityId",
        sortOrder: "DESC",
      },
    ];
    const finalFilter = {
      team: isHousingOwnerAgent ? hasValidOwnerRoles(roles) : true,
      sort,
      paging: filters.isRefreshAll
        ? { start: 0, rows: filters.currentPage * rows }
        : { start, rows: rows },
    };
    let fiqlFilter = [];
    let values;
    if (filters.statusValues?.length) {
      values = filters.statusValues.map((status) => {
        return filters.statusMapping[status];
      });
      fiqlFilter = addToFIQL(fiqlFilter, "statusId", values);
    }
    if (filters.orderIds?.length) {
      fiqlFilter = addToFIQL(fiqlFilter, "opportunityId", filters.orderIds);
    }
    if (Object.keys(filters.selectedAgents || {}).length) {
      values = Object.keys(filters.selectedAgents).map((agent) => {
        return filters.selectedAgents[agent].id;
      });
      fiqlFilter = addToFIQL(fiqlFilter, "agentId", values);
    }
    if (Object.keys(filters.selectedCities || {}).length) {
      values = Object.keys(filters.selectedCities).map((city) => {
        return filters.selectedCities[city];
      });
      fiqlFilter = addToFIQL(fiqlFilter, "cityIds", values);
    }
    if (filters.opportunityName) {
      fiqlFilter = addToFIQL(
        fiqlFilter,
        "opportunityName",
        encodeURIComponent(filters.opportunityName)
      );
    }
    if (filters.accountName) {
      fiqlFilter = addToFIQL(
        fiqlFilter,
        "accountName",
        encodeURIComponent(filters.accountName)
      );
    }
    if (filters.contactNumber) {
      fiqlFilter = addToFIQL(
        fiqlFilter,
        "phoneNumbers",
        encodeURIComponent(filters.contactNumber)
      );
    }
    if (filters.accountIds?.length) {
      fiqlFilter = addToFIQL(fiqlFilter, "accountId", filters.accountIds);
    }
    if (filters.createdAt?.start || filters.createdAt?.end) {
      fiqlFilter = addDateRangeFilter(fiqlFilter, "createdAt", filters.createdAt);
    }
    if (filters.updatedAt?.start || filters.updatedAt?.end) {
      fiqlFilter = addDateRangeFilter(fiqlFilter, "updatedAt", filters.updatedAt);
    }
    finalFilter.filters = { and: fiqlFilter };
    if (filters.userId && filters.userIsHousingOwnerAgent) {
      finalFilter.key = hasValidOwnerRoles(roles)
        ? "BASED_ON_OUTER_FILTER"
        : "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER";
      finalFilter.caseSubTypeId = 13;
    } else {
      finalFilter.key = "BASED_ON_OUTER_FILTER_WITH_COMPANY_ID_AGENT_FILTER";
    }
    const promise = getKeySearchPromises(
      "/mystique/v1/soc/key-search?salesOpportunitySelectorsArray=",
      `${JSON.stringify([finalFilter])}`
    );

    promise
      .then((response) => {
        let list = {
          totalCount: 0,
          results: [],
        };
        if (response && response[0]) {
          list = response[0];
          if (!list.results) {
            list.results = [];
          }
        }
        dispatch({
          type: OPPORTUNITY_SEARCH_LIST,
          opportunitySearchList: list,
        });
        return response;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getPackageCities = () => {
  const packageId = 232; // to get all cities using packageId of basic rent plan
  return (dispatch) => {
    const url = `/sapna/v1/cities-for-pkg?packageIds=${packageId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const {
          data: { data = {} },
        } = response;
        const packageCities = [];
        data[packageId].forEach((city) => {
          packageCities.push(city);
        });
        dispatch({
          type: PACKAGE_CITY_LIST,
          packageCities,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
const parseCityList = (data) => {
  let { other_cities = [], top_cities = [] } = data;
  let otherCities = other_cities.map((city) => {
    return { name: city.name, uuid: city.polygon_uuid };
  });
  let topCities = top_cities.map((city) => {
    return { name: city.name, uuid: city.polygon_uuid };
  });
  return [...otherCities, ...topCities];
};

export const getAllCitiesList = () => (dispatch) => {
  const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/top/cities/list`;
  return instance({
    url,
    method: "GET",
  })
    .then((response) => {
      let packageCities = parseCityList(response.data);
      dispatch({
        type: PACKAGE_CITY_LIST,
        packageCities,
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};
