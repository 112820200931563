import { MANUAL_CALL, SEARCH_OPPORTUNITY } from "../actions/manualDialing.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case MANUAL_CALL:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Call initiated successfully",
          type: "success",
        },
        manualCallData: action.manualCallData,
      };
    case SEARCH_OPPORTUNITY:
      return {
        ...state,
        manualCallData: {
          ...state.manualCallData,
          ...action.opportunitySearchData,
        },
      };
  }
};

export default reducer;
