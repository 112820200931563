import Cache from "../utils/cache";

export const requestHandler = (request) => {
  const { url, cacheable, data, cacheKeyBuilder } = request;
  if (cacheable) {
    const { isValid, value } = Cache.retrieveFromCache(url, data, cacheKeyBuilder);
    if (isValid) {
      //using axios adapter to resolve request if response is in cache
      request.adapter = () => {
        return Promise.resolve({
          data: value,
          status: request.status,
          statusText: request.statusText,
          headers: request.headers,
          config: request,
          request: request,
        });
      };
    }
  }
};

export const responseHandler = (response) => {
  const {
    cacheable,
    url,
    data: requestData,
    cacheExpirytime: expiryTime,
    cacheKeyBuilder,
  } = response.config;
  const { data: responseData } = response || {};
  if (cacheable) {
    const { isValid } = Cache.retrieveFromCache(url, requestData, cacheKeyBuilder);
    if (!isValid) {
      //store response if it is cacheable and not in cache
      Cache.storeInCache({
        url,
        requestData,
        responseData,
        expiryTime,
        cacheKeyBuilder,
      });
    }
  }
};
