import {
  CAP_DETAILS,
  SLOT_DEACTIVATED,
  SLOT_CREATED,
  SLOT_UPDATED,
} from "../actions/slotCapping.actions";

const defaultState = {};
const slotCappingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CAP_DETAILS: {
      return {
        ...state,
        slotCaps: action.payload,
      };
    }
    case SLOT_DEACTIVATED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Slot Deleted successfully",
          type: "success",
        },
      };
    case SLOT_CREATED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Slot Created Successfully",
          type: "success",
        },
      };
    case SLOT_UPDATED:
      return {
        ...state,
        snackbar: {
          state: true,
          message: "Slot Updated successfully",
          type: "success",
        },
      };
  }
};

export default slotCappingReducer;
