export const paddingForCalendar = (value) => {
  return value < 10 ? "0" + value : value;
};

export const dateFormat = (date, reverse = false) => {
  return reverse
    ? `${date.getFullYear()}-${paddingForCalendar(
        date.getMonth() + 1
      )}-${paddingForCalendar(date.getDate())}`
    : `${paddingForCalendar(date.getDate())}-${paddingForCalendar(
        date.getMonth() + 1
      )}-${date.getFullYear()}`;
};

export const updateEndDate = (startDate, dur) => {
  const start = new Date(startDate);
  const newEndDate = new Date(start);
  newEndDate.setDate(start.getDate() + parseInt(dur) - 1);
  return newEndDate;
};
export const updateDuration = (startDate, end) => {
  const start = new Date(startDate);
  const endDate = new Date(end);
  const diffTime = Math.abs(endDate - start);
  const duration = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return duration + 1;
};
