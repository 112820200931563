import Store from "../utils/localStorage";
import Constant from "../utils/CommonConstants";

const SEPARATOR = "*//*";
const CLIENT_CACHE = "CLIENT_CACHE";

//NOTES:
//to use this cache, you must send three keys in the instance object while making the api call
//1) cacheable - flag so the cacehHelper knows we have to cache the response (REQUIRED)
//2) cacheExpirytime - time of resource expiry
//3) cacheKeyBuilder - if you want to set your cache key logic (mostly for POST requests) else key=url (GET)

//we abstract localStore  by default) in order support a hydration on every refresh
//the idea is to give the option to use other storage APIs and to ensure
//as less read/writes to the web apis as we can by using a property-> localStore of Storage that mimics our storage mechanism
const { readBrowserStorage, writeBrowserStorage, deleteBrowserStorage } = Store(
  CLIENT_CACHE
);

const convertToCacheKey = (url, requestData, cacheKeyBuilder) =>
  cacheKeyBuilder ? cacheKeyBuilder(url, SEPARATOR, requestData) : url;

const storeInCache = ({
  url,
  requestData,
  responseData = {},
  expiryTime,
  cacheKeyBuilder,
}) => {
  let date;
  if (typeof expiryTime !== "undefined") {
    if (!Constant.isTimestampValid(expiryTime)) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.trace("Value of cache expiry time is invalid");
      }
      throw new Error("Cache expiry timestamp is invalid");
    }
  } else {
    //default lifetime freshness for resource = 24 hours
    date = new Date();
    date.setHours(date.getHours() + 24);
  }

  //value format is JSON Response Object separated by expiry date of resource
  const value = {
    response: responseData,
    expiryTime: expiryTime || date.getTime(),
  };
  const key = convertToCacheKey(url, JSON.parse(requestData), cacheKeyBuilder);

  //write to client cache
  let updatedClientCache = {};
  let clientCacheJSON = readBrowserStorage(CLIENT_CACHE);
  if (clientCacheJSON === null) {
    updatedClientCache = { [key]: value };
  } else {
    updatedClientCache = JSON.parse(clientCacheJSON);
    updatedClientCache = {
      ...updatedClientCache,
      [key]: value,
    };
  }
  writeBrowserStorage(CLIENT_CACHE, JSON.stringify(updatedClientCache));
};

//this function returns and object with keys:
//isValid - if resource is stale/fresh
//value - response if it is fresh and found with appropriate key
const retrieveFromCache = (url, reqData, cacheKeyBuilder) => {
  const key = convertToCacheKey(url, reqData, cacheKeyBuilder);
  const clientCacheJSON = readBrowserStorage(CLIENT_CACHE);
  const cachedDataStatus = { isValid: false };

  //no such key in cache
  if (clientCacheJSON === null) {
    return cachedDataStatus;
  }
  const clientCache = JSON.parse(clientCacheJSON);
  const cachedKey = Object.keys(clientCache).find((cacheKey) => key === cacheKey);

  if (!cachedKey) {
    return cachedDataStatus;
  }
  const { expiryTime, response } = clientCache[key];

  // first two cases to check if the timestamp is illegitimate
  if (!Constant.isTimestampValid(expiryTime)) {
    //delete stale resource because value expiry time was invalid
    deleteKeyFromClientCache(key, clientCache);
    return cachedDataStatus;
  }

  const currentTimeStamp = new Date().getTime();
  if (currentTimeStamp <= expiryTime) {
    cachedDataStatus.isValid = true;
    cachedDataStatus.value = response;
  } else {
    //delete stale resource
    deleteKeyFromClientCache(key, clientCache);
  }
  return cachedDataStatus;
};

const deleteKeyFromClientCache = (key, clientCache) => {
  delete clientCache[key];
  writeBrowserStorage(CLIENT_CACHE, JSON.stringify(clientCache));
};

const eraseClientCache = () => {
  deleteBrowserStorage(CLIENT_CACHE);
};

export default {
  storeInCache,
  retrieveFromCache,
  eraseClientCache,
  deleteKeyFromClientCache,
  CLIENT_CACHE,
};
